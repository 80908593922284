import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import '../../../assets/js/leaflet-routing-openroute';
import 'leaflet-simple-map-screenshoter'
import {SchuleKoordinateDTO} from "../../models/schule-koordinate/SchuleKoordinateDTO";
import {TextbausteinService} from "../../@core/services/textbaustein/textbaustein.service";
import {FahrkostenService} from "../../@core/services/fahrkosten/fahrkosten.service";
import {ActivatedRoute, Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {CustomToastService} from "../../@core/utils/custom-toast.service";
import {RouteDTO} from "../../models/routing/RouteDTO";
import {WaypointDTO} from "../../models/waypoint/WaypointDTO";
import {WaypointCreateOrUpdateDTO} from "../../models/waypoint/WaypointCreateOrUpdateDTO";
import {RoutingService} from "../../@core/services/routing/routing.service";
import {SessionService} from "../../@core/services/session/session.service";
import {KeyString} from "../../models/textbaustein/KeyString";

@Component({
  selector: 'app-geo-map',
  templateUrl: './geo-map.component.html',
  styleUrls: ['./geo-map.component.scss']
})
export class GeoMapComponent implements OnInit, AfterViewInit {


  @Input() antragTicketId: string;
  @Input() fahrkostenId: string;
  @Input() benutzerId: string;
  @Input() travelMode: string;
  @Input() showRouteInformation: boolean = false;
  @Input() showWaypointButtons: boolean = false;
  @Input() coordinateList: SchuleKoordinateDTO[];
  @Input() selectedCoordinate: SchuleKoordinateDTO;
  @Input() isNewMarkerPossible: boolean = false;
  @Input() isPraktikum: boolean = false;
  @Input() showRouteImageButton: boolean = false;
  @Output() getLatLon = new EventEmitter<any>();
  @Output() hideMap = new EventEmitter<boolean>();
  private map;
  currentMarker: L.Marker<any>;
  route: RouteDTO;
  redIcon: L.Icon;
  schuleId: string;
  @Input() schulname: string = "";
  waypoints: WaypointDTO[] = [];
  waypointsToSave: WaypointCreateOrUpdateDTO[] = [];
  routeControl: L.Routing.Control;
  screenshotter;
  @Output() screenshot = new EventEmitter<string>();
  routeImage;
  errorString: string = '';

  existsSessionStorageTextbausteine : boolean = false;

  constructor(
    private routingService: RoutingService,
    private customToastService: CustomToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fahrkostenService: FahrkostenService,
    public textbausteinService: TextbausteinService,
    private sessionService: SessionService
  ) { }

  ngAfterViewInit(): void {
    if (this.coordinateList === undefined) {
      if (!this.isPraktikum) {
        this.routingService.getRouteToSchule(this.antragTicketId, this.fahrkostenId, this.travelMode)
          .pipe(
            catchError((err) => {
              this.handleRouteErrors(err);
              return EMPTY;
            })
          )
          .subscribe(result => {
            this.route = result;
            //this.initRouteMap();
          });
      } else {
        this.routingService.getRouteToPraktikum(this.antragTicketId, this.fahrkostenId, this.travelMode)
          .pipe(
            catchError((err) => {
              this.handleRouteErrors(err);
              return EMPTY;
            })
          )
          .subscribe(result => {
            this.route = result;
            //this.initRouteMap();
          });
      }

    } else {
      this.initCoordinateMap();
    }
  }

  private handleRouteErrors(err) {
    if (err.status === 500) {
      this.errorString = "Bei der Routenberechnung ist etwas schiefgelaufen. Möglicherweise ist der externe Dienst für die Berechnung der Route momentan nicht erreichbar oder die Adresse im Antrag existiert nicht.";
      this.customToastService.showError(this.errorString);
    } else if (err.status === 400) {
      if (err.error.trace.includes('DistanceTooBigException')) {
        this.errorString = "Die Distanz zwischen den beiden Punkten ist größer als 100 km und somit zu groß für eine Routenberechnung.";
        this.customToastService.showError(this.errorString);
      } else {
        this.errorString = "Bei der Routenberechnung ist ein unerwarteter Fehler aufgetreten.";
        this.customToastService.showError(this.errorString);
      }
    }
    //this.hideMap.emit(true);
  }

  ngOnInit(): void {

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    if (this.benutzerId !== undefined) {
      this.routingService.getWaypointsByBenutzer(this.benutzerId).subscribe(data => {
        this.waypoints = data;
      });
    }

    this.schuleId = this.activatedRoute.snapshot.queryParamMap.get('schuleId');
    if (this.schuleId === null) {
      this.schuleId = this.activatedRoute.snapshot.paramMap.get('id');
    }
    if (this.schulname === "") {
      this.schulname = this.activatedRoute.snapshot.queryParamMap.get('schulname');
    }
    L.Icon.Default.imagePath = "../../../assets/images/leaflet/";
    this.redIcon = L.icon({
      iconUrl: '../../../assets/images/leaflet/marker-icon-red.png',
      shadowUrl: '../../../assets/images/leaflet/marker-shadow.png',
      iconAnchor:   [13, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [13, 41]  // the same for the shadow
    });
  }

  // private initRouteMap(): void {
  //   // initialize map
  //   this.map = L.map('map', {
  //     center: [ 50.774871, 6.082734 ],
  //     zoom: 13
  //   });
  //
  //   // center map on route
  //   this.map.fitBounds([[this.route.startPoint?.x, this.route.startPoint?.y], [this.route.current.destination?.x,  this.route.current.destination?.y]]);
  //
  //
  //   // get map tiles
  //   const tiles = L.tileLayer(environment.tileServer, {
  //     maxZoom: 18,
  //     minZoom: 3,
  //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  //   });
  //   tiles.addTo(this.map);
  //
  //   // configure openrouteservice router. ts-ignore is mandatory because "L.routing.openrouteservice" is recognized only at runtime
  //   // @ts-ignore
  //   const osrRouter = L.routing.openrouteservice(null, {
  //     "timeout": 30 * 1000, // 30",
  //     "format": "json",                           // default, gpx not yet supported
  //     "host": environment.routingUrl,
  //     "service": "directions",                    // default (for routing)
  //     "api_version": "v2",                        // default
  //     "profile": "foot-walking",                  // default
  //     "routingQueryParams": {
  //       "language": "de-de",
  //       "coordinates": [
  //         [this.route.startPoint.x, this.route.startPoint.y],
  //         [this.route.current.destination.x,  this.route.current.destination.y]
  //       ]
  //     }
  //   });
  //
  //   // initiate waypoints with start point
  //   let waypointsForRoute = [
  //     L.latLng(this.route.startPoint.y, this.route.startPoint.x),
  //   ];
  //
  //   // push waypoints that are between start and endpoint
  //   this.waypoints.forEach(waypoint => {
  //     waypointsForRoute.push(L.latLng(+waypoint.waypointLat, +waypoint.waypointLon));
  //   });
  //
  //   // push endpoint
  //   waypointsForRoute.push(L.latLng(this.route.current.destination.y, this.route.current.destination.x));
  //
  //   this.routeControl = L.Routing.control({
  //     show: false,
  //     router: osrRouter,
  //     waypoints: waypointsForRoute,
  //     lineOptions: {
  //       styles: [{color: 'blue', opacity: 1, weight: 5}],
  //       extendToWaypoints: true,
  //       missingRouteTolerance: 0
  //     }
  //   }).addTo(this.map);
  //
  //   this.routeControl.on('waypointschanged', e => {
  //     this.waypointsToSave = [];
  //     // remove start- and endpoint with .shift().pop()
  //     e.waypoints.shift();
  //     e.waypoints.pop();
  //     e.waypoints.forEach((waypoint: L.Routing.Waypoint) => {
  //       this.waypointsToSave.push({waypointLat: waypoint.latLng.lat.toString(), waypointLon: waypoint.latLng.lng.toString()})
  //     });
  //   });
  //
  //   // Add screenshotter to map
  //   this.screenshotter = L.simpleMapScreenshoter().addTo(this.map);
  //
  //   // Take initial screenshot
  //   setTimeout(() => {
  //     this.takeMapScreenshot();
  //   }, 2000);
  // }

  private initCoordinateMap(): void {
    // initialize map
    this.map = L.map('map', {
      center: [50.774871, 6.082734],
      zoom: 13
    });

    // set markers for all coordinates
    this.coordinateList.forEach(coordinate => {
      let marker: L.Marker;

      // selected marker looks different
      if (coordinate.lat === this.selectedCoordinate?.lat && coordinate.lon === this.selectedCoordinate?.lon) {
        marker = L.marker([+coordinate.lat, +coordinate.lon], {icon: this.redIcon}).addTo(this.map);
        this.currentMarker = marker;
      } else {
        marker = L.marker([+coordinate.lat, +coordinate.lon]).addTo(this.map);
      }

      // set title for reference
      marker.options.title = coordinate.id.toString();

      // change edit url on marker click
      marker.on('click', e => {
        this.router.navigate(['/pages/schulekoordinaten/'+e.target.options.title],{queryParams:{schuleId: this.schuleId, schulname: this.schulname}} );
      });
    });

    // center map on route
    const coordinates: any = this.coordinateList.map((schuleKoordinateDTO: SchuleKoordinateDTO) => [schuleKoordinateDTO.lat, schuleKoordinateDTO.lon]);
    if (coordinates.length > 0) {
      this.map.fitBounds(coordinates);
    }

    // get map tiles
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map);

    // add new marker on map click
    if (this.isNewMarkerPossible) {
      this.map.on('click', e => {
        // remove old marker
        if (this.currentMarker !== undefined) {
          this.currentMarker.remove();
        }
        const lat = e.latlng.lat;
        const lon = e.latlng.lng;

        // set coordinate values to SchuleKoordinaten Component
        this.getLatLon.emit([lat, lon]);

        // add new marker
        this.currentMarker = L.marker([lat, lon], {icon: this.redIcon}).addTo(this.map);
      });
    }
  }

  saveWaypoints() {
    this.routingService.createOrUpdateWaypointList(this.benutzerId, this.waypointsToSave)
      .pipe(
        catchError(() => {
          this.customToastService.showError("Die Wegpunkte konnten nicht gespeichert werden.");
          return EMPTY;
        })
      )
      .subscribe(data => {
        this.takeMapScreenshot();
        this.customToastService.showSuccess('Die Wegpunkte wurden erfolgreich gespeichert.');
      });
  }

  deleteWaypoints() {
    if (confirm('Wollen Sie die Route wirklich löschen?')) {
      this.routingService.deleteWaypointsByBenutzer(this.benutzerId)
        .pipe(
          catchError(() => {
            this.customToastService.showError("Die Wegpunkte konnten nicht gelöscht werden.");
            return EMPTY;
          })
        )
        .subscribe(data => {
          this.takeMapScreenshot();
          this.customToastService.showSuccess('Die Wegpunkte wurden erfolgreich gelöscht.');
          this.routeControl.setWaypoints([
            L.latLng(this.route.current.destination.y, this.route.current.destination.x),
            L.latLng(this.route.startPoint.y, this.route.startPoint.x)
          ]);
        });
    }
  }

  private takeMapScreenshot() {
    let format = 'image' // 'image' - return base64, 'canvas' - return canvas
    let overridedPluginOptions = {
      mimeType: 'image/png'
    }
    this.screenshotter.takeScreen(format, overridedPluginOptions).then(image => {
      this.screenshot.emit(image);
    })
  }

  // getRouteImage() {
  //   if (this.antragTicketId !== undefined) {
  //     this.getRouteImageByAntragTicket();
  //   } else if (this.fahrkostenId !== undefined) {
  //     this.getRouteImageByFahrkosten();
  //   }
  // }

  // getRouteImageByAntragTicket() {
  //   this.antragTicketService.getRouteImageByAntragTicket(this.antragTicketId)
  //     .pipe(
  //       catchError((err) => {
  //         this.customToastService.showError("Es ist keine originale Route hinterlegt.");
  //         return EMPTY;
  //       })
  //     )
  //     .subscribe(data => {
  //       this.routeImage = data.image;
  //     });
  // }

  // getRouteImageByFahrkosten() {
  //   this.fahrkostenService.getRouteImageByFahrkosten(this.fahrkostenId)
  //     .pipe(
  //       catchError((err) => {
  //         this.customToastService.showError("Es ist keine originale Route hinterlegt.");
  //         return EMPTY;
  //       })
  //     )
  //     .subscribe(data => {
  //       this.routeImage = data.image;
  //     });
  // }
    protected readonly KeyString = KeyString;
}
