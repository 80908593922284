import {Router} from "@angular/router";
import {Observable, of} from "rxjs";
import {inject} from "@angular/core";
import {BenutzerSessionService} from "../services/benutzer/benutzer-session.service";
import {Rolle} from "../../models/benutzer/Rolle";
import {BenutzerDTO} from "../../models/benutzer/BenutzerDTO";
import {isAnyMissingOrEmpty} from "../utils/forms/formvalue-utils";
import {CustomToastService} from "../utils/custom-toast.service";
import {ConfigService} from "../config/services/config.service";

export const profileGuard = (): Observable<boolean> => {

  const benutzerSessionService = inject(BenutzerSessionService);
  const router = inject(Router);
  const customToastService = inject(CustomToastService);
  const configService = inject(ConfigService);
  const benutzer = benutzerSessionService.getCurrentBenutzer();

  // benutzer is missing or not role antragsteller
  if(!benutzer)
    return of(false);
  if(!benutzer.roles.includes(Rolle.ROLE_ANTRAGSTELLER)) {
    router.navigate(['/pages/home'])
      .then(()=> customToastService.showError('Benutzer wurde nicht als Antragsteller erkannt.'));
    return of(false);
  }

  if(isProfileComplete(benutzer)) // benutzer is antragsteller and has passed profile check
    return of(true);

  else{ // benutzer is antragsteller and has NOT passed profile check

    if (configService.featureConfig.jwtAuth) {
      router.navigate(['/pages/profil'])
        .then(()=> customToastService.showError('Sie müssen Ihr Profil vervollständigen, bevor Sie einen Antrag stellen können.'));
    }
    else if (configService.featureConfig.kommunalportalAuth) {
      // redirect to home page and give link to portal in toast
      const portalUrl = sessionStorage.getItem('portalUrl');
      router.navigate(['/pages/home'])
        .then(()=> customToastService.showError('Sie müssen Ihr Profil im ' +
            '<a class="text-reset fw-bold" style=text-decoration:none href="' + portalUrl + '">Portal</a>'
            + ' vervollständigen, bevor Sie einen Antrag stellen können.'));
    }

    return of(false);
  }

};

function isProfileComplete(benutzer: BenutzerDTO): boolean {
  return !isAnyMissingOrEmpty([
    benutzer.name,
    benutzer.vorname,
    benutzer.strasse,
    benutzer.plz,
    benutzer.ort,
    benutzer.geburtsdatum,
    benutzer.email
  ]);
}
