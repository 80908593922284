import {FormControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {MIN_PWD_LENGTH} from "../../../../assets/constants/constants";

export const pwFormatValidator: ValidatorFn = (fc: FormControl) : ValidationErrors | null => {

  const value = fc?.value || undefined;
  let isInvalid : boolean = false;

  if(!value || value.length==0)
    return null;    // missing value is handled by Validators.required

  if(value.length < MIN_PWD_LENGTH){
    fc.setErrors({pwTooShort: true});
    fc['lengthKey'] = 'AUTHENTICATION.FORM_ERRORS.PASSWORD_LENGTH';
    isInvalid = true;
  } else {
    if (fc?.errors && fc?.errors['pwTooShort'])
      delete fc?.errors['pwTooShort'];
    fc['lengthKey'] = undefined;
  }

  if(!/\d/.test(value)){
    fc.setErrors({pwMissingNumber: true});
    fc['numKey'] = 'AUTHENTICATION.FORM_ERRORS.PASSWORD_NUMBER';
    isInvalid = true;
  } else {
    if (fc?.errors && fc?.errors['pwMissingNumber'])
      delete fc?.errors['pwMissingNumber'];
    fc['numKey'] = undefined;
  }

  if(!/\p{Lu}/u.test(value)){
    fc.setErrors({pwMissingUppercase: true});
    fc['upperKey'] = 'AUTHENTICATION.FORM_ERRORS.PASSWORD_UPPER';
    isInvalid = true;
  } else {
    if (fc?.errors && fc?.errors['pwMissingUppercase'])
      delete fc?.errors['pwMissingUppercase'];
    fc['upperKey'] = undefined;
  }

  if (!/\p{Ll}/u.test(value)) {
    fc.setErrors({pwMissingLowercase: true});
    fc['lowerKey'] = 'AUTHENTICATION.FORM_ERRORS.PASSWORD_LOWER';
    isInvalid = true;
  } else {
    if (fc?.errors && fc?.errors['pwMissingLowercase'])
      delete fc?.errors['pwMissingLowercase'];
    fc['lowerKey'] = undefined;
  }

  if(!/[!@#€$%"'§{}\[\]()\\\/.,~<>=^*+\-&?:;_|`]/.test(value)){
    fc.setErrors({pwMissingSpecialChar: true});
    fc['specialKey'] = 'AUTHENTICATION.FORM_ERRORS.PASSWORD_SPECIAL';
    isInvalid = true;
  } else {
    if (fc?.errors && fc?.errors['pwMissingSpecialChar'])
      delete fc?.errors['pwMissingSpecialChar'];
    fc['specialKey'] = undefined;
  }

  return isInvalid? {pwInvalid: true} : null;

}
