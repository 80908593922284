<div class="form-group" [formGroup]="customFormGroup">
  <label [for]="'input-'+id" [id]="'sfklabel-'+id" data-cy="custom-label">
    {{ label }}
  </label>
  <fa-icon class="infoIcon"
           *ngIf="!!infoText && infoText.length > 0"
           [icon]="faInfo"
           [title]="infoText"
           data-cy="custom-info"
  ></fa-icon>
  <span *ngIf="environment.displayTextbausteinKeys">{{infoText}}</span>
  <ng-select
    class="form-control"
    [labelForId]="'select-' + id"
    [attr.aria-label]="'Select Box ' + id"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [multiple]="multiple"
    [closeOnSelect]="!multiple"
    [searchable]="searchable"
    [clearable]="clearable"
    [placeholder]="placeholder"
    [formControlName]="id"
    [readonly]="readonly ? true : null"
    [notFoundText]="notfoundtext"
    [loading]="loading"
    [loadingText]="loadingText"
    [hideSelected]="hideSelected"
    (change)="triggerOnChange($event)"
    (search)="triggerSearch($event)"
    (scrollToEnd)="triggerScrollToEnd($event)"
    data-cy="custom-select"
  >
  </ng-select>
  <small
    *ngIf="isInvalid()"
    [id]="id + '-help-block'"
    class="form-text text-muted"
  >
    {{ noteText }}
  </small>
  <p *ngIf="!!infoTextBelowInput && infoTextBelowInput.length > 0">
    {{ infoTextBelowInput }}
  </p>
</div>
