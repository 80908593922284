import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer-custom',
  templateUrl: './footer-custom.component.html',
  styleUrls: ['./footer-custom.component.scss']
})
export class FooterCustomComponent {

  currYear = new Date().getFullYear();
  version = environment.version;

}
