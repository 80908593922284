import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";


export interface LoginCredentials {
  username: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  configUrl = 'auth/token';
  options;

  constructor(private http: HttpClient) {}

  login(loginCredentials: LoginCredentials){
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });

    this.options = {
      headers: headers
    };

    const utf8ToBase64 = (str: string) => {
      return btoa(
        new TextEncoder().encode(str).reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
    };

    const rawPostMessage = `grant_type=password&email=${loginCredentials.username}&password=${utf8ToBase64(loginCredentials.password)}`;

    const finalPath = this.configUrl;
    return this.http.post<any>(finalPath, rawPostMessage, this.options);
  }

}

