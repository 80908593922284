<div class="d-flex flex-column form-group" id="{{id}}-group"  [formGroup]="customFormGroup">
  <div class="flex-grow-1" style="margin-top:1ch;margin-bottom:1ch;">
    <label [class.sr-only]="hideLabel" [for]="'input-'+id"  [id]="'sfklabel-'+id" data-cy="custom-label">
      {{ label }}
    </label>
    <fa-icon class="infoIcon"
             *ngIf="!!infoText && infoText.length > 0"
             [icon]="faInfo"
             [title]="infoText"
             data-cy="custom-info"
    ></fa-icon>
    <span *ngIf="environment.displayTextbausteinKeys">{{infoText}}</span>
  </div>
  <p *ngIf="!!infoTextBelowInput && infoTextBelowInput.length > 0">
    <strong>{{ infoTextBelowInput }}</strong>
  </p>
	<div>
		<div *ngFor="let option of options" class="form-check " [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}"><!--uncomment class for nebeneinander und nicht untereinander form-check-inline -->
			<label class="form-check-label custom-radio-container"
				>{{option.name | translate}}
				<input *ngIf="!readonly"
					class="form-check-input mx-2"
					type="radio"
					[id]="'input-' + id"
					[value]="option.value"
					[formControlName]="id"
					(change)="triggerOnChange($event)"
          data-cy="custom-input"
        />
				<input *ngIf="readonly"
							 class="form-check-input mx-2"
							 type="radio"
							 [id]="'input-' + id"
							 [value]="option.value"
							 [formControlName]="id"
               [attr.disabled]="readonly"
				/>
        <span class="checkmark"
              [attr.disabled]="readonly"
              [attr.data-cy]="'radio-option-'+option.value"
        ></span>
				</label>
			</div>
	</div>
</div>

