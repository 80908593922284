import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {

  // Title at the top of the layout
  @Input() title: string;

  // Subitle at the top of the layout (below title)
  @Input() subtitle: string;

  // Removes topbar
  @Input() height100 = false;

  // Shows add button in the top right of the layout
  @Input() showAdd = true;

  // Link which will be opened when clicking on add button. Why string array? https://angular.io/api/router/Router#navigate
  @Input() addLink: string[];

  // Link which will be opened when clicking on back button. Why string array? https://angular.io/api/router/Router#navigate
  @Input() backUrl: string[];

  // Shows back button in the top left of the layout
  @Input() showBack = true;

  // Sets backgroundClass (css class needs to exist in the firstplace)
  @Input() backgroundClass = '';

  @Input() noHeaderMargin = false;

  constructor(public translate: TranslateService) { }

  getCustomBackground() {
    return this.backgroundClass;
  }

}
