import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../utils/custom-toast.service";
import {BenutzerService} from "../../services/benutzer/benutzer.service";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Component({
  selector: 'app-aktivierung',
  templateUrl: './aktivierung.component.html',
  styleUrls: ['./aktivierung.component.css']
})
export class AktivierungComponent implements OnInit {

  public aktivierungForm: UntypedFormGroup;
  public dto: BenutzerDTO;

  token: string;
  email: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private customToastService: CustomToastService,
              private benutzerService: BenutzerService,
              private fb: UntypedFormBuilder
  ) {}

  getFormItem(s: string) {
    return this.aktivierungForm?.get(s);
  }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
        this.aktivierungForm = this.fb.group({
          email:[params.email],
        });
          this.token = params.token;
      });

  }

  activate(){
    if(this.aktivierungForm.invalid){
      this.customToastService.showError('Die Angaben sind falsch oder unvollständig.');
      return;
    }
    const emailInput =  this.getFormItem('email')?.value.length !== 0 ? this.getFormItem('email').value : null;
      this.benutzerService.activateBenutzer({
        email : emailInput,
        kennwort: null,
        token : this.token
      })
        .pipe(
          catchError(() => {
            this.customToastService.showError('Die Aktivierung konnte nicht durchgeführt werden.');
            return EMPTY;
          }))
        .subscribe((data: BenutzerDTO) => {
          this.dto = {...data};
          this.router.navigate(['/authentication/login']);
          this.customToastService.showSuccess("Der Nutzer wurde erfolgreich aktiviert");
        });
  }

}
