import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SchuljahrDTO} from "../../../models/schuljahr/SchuljahrDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class SchuljahrService {

  configUrl = 'api/schuljahr';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getSchuljahre(): Observable<Array<SchuljahrDTO>>{
    return this.http.get<Array<SchuljahrDTO>>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

}
