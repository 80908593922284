<app-default-layout
  [title]="title"
  [subtitle]="subtitle"
>

  <app-fahrkosten-detail-sb-true
    *ngIf="this.hasSachbearbeiterBackend===true"
    [fahrkostenId] = "fahrkostenId"
    [benutzer] = "benutzer"
    [benutzerRoles] = "benutzerRoles"
    [fieldConfig] = "fieldConfig"
    [optionTyp] = "optionTyp"
    [optionGeschlecht] = "optionGeschlecht"
    [optionSonderfall] = "optionSonderfall"
    [optionWegtyp] = "optionWegtyp"
    [optionBefoerderung] = "optionBefoerderung"
    [landList] = "landList"
    [visibleFields] = "visibleFields"
    [requiredFields]= "requiredFields"
    [vormundOnOff] = "vormundOnOff"
    [schuleOnOff] = "schuleOnOff"
    [berufskollegOnOff] = "berufskollegOnOff"
    [schulwegOnOff] = "schulwegOnOff"
    [praktikumOnOff] = "praktikumOnOff"
    [beidesOnOff] = "beidesOnOff"
    [pkwOnOff] = "pkwOnOff"
    [mitfahrerOnOff] = "mitfahrerOnOff"
    [oepnvOnOff]="oepnvOnOff"
    [vormundAdresseAbweichendOnOff] = "vormundAdresseAbweichendOnOff"
    [kontoinhaberOnOff] = "kontoinhaberOnOff"
    [sonderfallOnOff] = "sonderfallOnOff"
    [sonderfallSonstOnOff] = "sonderfallSonstOnOff"
    [asylOnOff]="asylOnOff"
    [fahrkostenFormFields]="fahrkostenFormFields"
  ></app-fahrkosten-detail-sb-true>

  <app-fahrkosten-detail-sb-false
    *ngIf="this.hasSachbearbeiterBackend===false"
    [fahrkostenId] = "fahrkostenId"
    [benutzer] = "benutzer"
    [benutzerRoles] = "benutzerRoles"
    [fieldConfig] = "fieldConfig"
    [optionTyp] = "optionTyp"
    [optionGeschlecht] = "optionGeschlecht"
    [optionSonderfall] = "optionSonderfall"
    [optionWegtyp] = "optionWegtyp"
    [optionBefoerderung] = "optionBefoerderung"
    [landList] = "landList"
    [visibleFields] = "visibleFields"
    [requiredFields]= "requiredFields"
    [vormundOnOff] = "vormundOnOff"
    [schuleOnOff] = "schuleOnOff"
    [berufskollegOnOff] = "berufskollegOnOff"
    [schulwegOnOff] = "schulwegOnOff"
    [praktikumOnOff] = "praktikumOnOff"
    [beidesOnOff] = "beidesOnOff"
    [pkwOnOff] = "pkwOnOff"
    [mitfahrerOnOff] = "mitfahrerOnOff"
    [oepnvOnOff]="oepnvOnOff"
    [vormundAdresseAbweichendOnOff] = "vormundAdresseAbweichendOnOff"
    [kontoinhaberOnOff] = "kontoinhaberOnOff"
    [sonderfallOnOff] = "sonderfallOnOff"
    [sonderfallSonstOnOff] = "sonderfallSonstOnOff"
    [asylOnOff]="asylOnOff"
    [fahrkostenFormFields]="fahrkostenFormFields"
  ></app-fahrkosten-detail-sb-false>

</app-default-layout>
