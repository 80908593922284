export enum Land {

  DEUTSCHLAND = "Deutschland",        //000	Deutschland	Deutschland	die Bundesrepublik Deutschland	deutsch	1949-05-23	DEU	DE
  ALBANIEN = "Albanien",           //121	Albanien	Albanien	die Republik Albanien	albanisch		ALB	AL
  BOSNIEN = "Bosnien",            //122	Bosnien und Herzegowina	Bosnien und Herzegowina	Bosnien und Herzegowina	bosnisch-herzegowinisch	1992-03-01	BIH	BA
  ANDORRA = "Andorra",            //123	Andorra	Andorra	das Fürstentum Andorra	andorranisch		AND	AD
  BELGIEN = "Belgien",            //124	Belgien	Belgien	das Königreich Belgien	belgisch		BEL	BE
  BULGARIEN = "Bulgarien",          //125	Bulgarien	Bulgarien	die Republik Bulgarien	bulgarisch		BGR	BG
  DAENEMARK = "Dänemark",          //126	Dänemark	Dänemark	das Königreich Dänemark	dänisch		DNK	DK
  ESTLAND = "Estland",            //127	Estland	Estland	die Republik Estland	estnisch	1991-08-20	EST	EE
  FINNLAND = "Finnland",           //128	Finnland	Finnland	die Republik Finnland	finnisch		FIN	FI
  FRANKREICH = "Frankreich",         //129	Frankreich	Frankreich	die Französische Republik	französisch		FRA	FR
  KROATIEN = "Kroatien",           //130	Kroatien	Kroatien	die Republik Kroatien	kroatisch	1991-06-25	HRV	HR
  SLOWENIEN = "Slowenien",           //131	Slowenien	Slowenien	die Republik Slowenien	slowenisch	1991-06-25	SVN	SI
  GRIECHENLAND = "Griechenland",       //134	Griechenland	Griechenland	die Hellenische Republik	griechisch		GRC	GR
  IRLAND = "Irland",             //135	Irland	Irland	Irland	irisch		IRL	IE
  ISLAND = "Island",             //136	Island	Island	die Republik Island	isländisch		ISL	IS
  ITALIEN = "Italien",            //137	Italien	Italien	die Italienische Republik	italienisch		ITA	IT
  LETTLAND = "Lettland",           //139	Lettland	Lettland	die Republik Lettland	lettisch	1991-08-21	LVA	LV
  MONTENEGRO = "Montenegro",         //140	Montenegro	Montenegro	Montenegro	montenegrinisch	2006-06-03	MNE	ME
  LIECHTENSTEIN = "Liechtenstein",      //141	Liechtenstein	Liechtenstein	das Fürstentum Liechtenstein	liechtensteinisch		LIE	LI
  LITAUEN = "Litauen",            //142	Litauen	Litauen	die Republik Litauen	litauisch	1990-03-11	LTU	LT
  LUXEMBURG = "Luxemburg",          //143	Luxemburg	Luxemburg	das Großherzogtum Luxemburg	luxemburgisch		LUX	LU
  NORDMAZEDONIEN = "Nordmazedonien",     //144	Nordmazedonien	Nordmazedonien	die Republik Nordmazedonien	mazedonisch/Bürger der Republik Nordmazedonien	1991-09-08	MKD	MK	Die Landessprache wird weiterhin als Mazedonisch bezeichnet, die Staatsbürgerschaft als "mazedonisch" bzw. "Bürger der Republik Nordmazedonien". An Stelle eines Adjektivs verwendet man zur Bezeichnung des Staates, seiner offiziellen Organe und anderer öffentlicher Einheiten "von Nordmazedonien" bzw. "der Republik Nordmazedonien.
  MALTA = "Malta",              //145	Malta	Malta	die Republik Malta	maltesisch	1964-09-21	MLT	MT
  MOLDAU = "Moldau",             //146	Moldau	Republik Moldau	die Republik Moldau	moldauisch	1991-08-27	MDA	MD
  MONACO = "Monaco",             //147	Monaco	Monaco	das Fürstentum Monaco	monegassisch		MCO	MC
  NIEDERLANDE = "Niederlande",        //148	Niederlande	Niederlande	das Königreich der Niederlande	niederländisch		NLD	NL
  NORWEGEN = "Norwegen",           //149	Norwegen	Norwegen	das Königreich Norwegen	norwegisch		NOR	NO
  KOSOVO = "Kosovo",             //150	Kosovo	Kosovo	die Republik Kosovo	kosovarisch	2008-02-17	XXK	XK	Für Kosovo ist bislang kein Code nach DIN EN ISO 3166-1 festgelegt worden. Die Europäische Kommission verwendet provisorisch den benutzerdefinierten 2-Buchstaben-Code XK; die EU-Ratsarbeitsgruppe Visa den benutzerdefinierten 3-Buchstaben-Code XXK.
  OESTERREICH = "Österreich",        //151	Österreich	Österreich	die Republik Österreich	österreichisch		AUT	AT
  POLEN = "Polen",              //152	Polen	Polen	die Republik Polen	polnisch		POL	PL
  PORTUGAL = "Portugal",           //153	Portugal	Portugal	die Portugiesische Republik	portugiesisch		PRT	PT
  RUMAENIEN = "Rumänien",          //154	Rumänien	Rumänien	Rumänien	rumänisch		ROU	RO
  SLOWAKEI = "Slowakei",           //155	Slowakei	Slowakei	die Slowakische Republik	slowakisch	1993-01-01	SVK	SK
  SAN_MARINO = "San Marino",         //156	San Marino	San Marino	die Republik San Marino	san-marinesisch		SMR	SM
  SCHWEDEN = "Schweden",           //157	Schweden	Schweden	das Königreich Schweden	schwedisch		SWE	SE
  SCHWEIZ = "Schweiz",            //158	Schweiz	Schweiz	die Schweizerische Eidgenossenschaft	schweizerisch		CHE	CH
  RUSSISCHE_FOEDERATION = "Russische Föderation", //160	Russische Föderation	Russische Föderation	die Russische Föderation	russisch	1991-12-25	RUS	RU
  SPANIEN = "Spanien",            //161	Spanien	Spanien	das Königreich Spanien	spanisch		ESP	ES
  TUERKEI = "Türkei",            //163	Türkei	Türkei	die Republik Türkei	türkisch		TUR	TR
  TSCHECHIEN = "Tschechien",         //164	Tschechien	Tschechien	die Tschechische Republik	tschechisch	1993-01-01	CZE	CZ
  UNGARN = "Ungarn",             //165	Ungarn	Ungarn	Ungarn	ungarisch		HUN	HU
  UKRAINE = "Ukraine",            //166	Ukraine	Ukraine	die Ukraine	ukrainisch	1991-08-24	UKR	UA
  VATIKANSTADT = "Vatikanstadt",       //167	Vatikanstadt	Vatikanstadt	der Staat Vatikanstadt	vatikanisch		VAT	VA
  VEREINIGTES_KOENIGREICH = "Vereinigtes Königreich",    //168	Vereinigtes Königreich	Vereinigtes Königreich	das Vereinigte Königreich Großbritannien und Nordirland	britisch		GBR	GB	Staatsangehörigkeit "British Citizen" im Feld "Nationality" auf der Pass-Personaldatenseite.
  BELARUS = "Belarus",            //169	Belarus	Belarus	die Republik Belarus	belarussisch	1991-08-27	BLR	BY
  SERBIEN = "Serbien",            //170	Serbien	Serbien	die Republik Serbien	serbisch	2008-02-17	SRB	RS
  ZYPERN = "Zypern",             //181	Zypern	Zypern	die Republik Zypern	zyprisch	1960-08-16	CYP	CY
  ALGERIEN = "Algerien",           //221	Algerien	Algerien	die Demokratische Volksrepublik Algerien	algerisch	1962-07-05	DZA	DZ
  ANGOLA = "Angola",             //223	Angola	Angola	die Republik Angola	angolanisch	1975-11-11	AGO	AO
  ERITREA = "Eritrea",            //224	Eritrea	Eritrea	der Staat Eritrea	eritreisch	1993-05-24	ERI	ER
  AETHIOPIEN = "Äthiopien",         //225	Äthiopien	Äthiopien	die Demokratische Bundesrepublik Äthiopien	äthiopisch		ETH	ET
  LESOTHO = "Lesotho",            //226	Lesotho	Lesotho	das Königreich Lesotho	lesothisch	1966-10-04	LSO	LS
  BOTSUANA = "Botsuana",           //227	Botsuana	Botsuana	die Republik Botsuana	botsuanisch	1966-09-30	BWA	BW
  BENIN = "Benin",              //229	Benin	Benin	die Republik Benin	beninisch	1960-08-01	BEN	BJ
  DSCHIBUTI = "Dschibuti",          //230	Dschibuti	Dschibuti	die Republik Dschibuti	dschibutisch	1977-06-27	DJI	DJ
  COTE_D_IVOIRE = "Côte d’Ivoire",      //231	Côte d’Ivoire	Côte d’Ivoire	die Republik Côte d’Ivoire	ivorisch	1960-08-07	CIV	CI
  NIGERIA = "Nigeria",            //232	Nigeria	Nigeria	die Bundesrepublik Nigeria	nigerianisch	1960-10-01	NGA	NG
  SIMBABWE = "Simbabwe",           //233	Simbabwe	Simbabwe	die Republik Simbabwe	simbabwisch	1980-04-18	ZWE	ZW
  GABUN = "Gabun",              //236	Gabun	Gabun	die Gabunische Republik	gabunisch	1960-08-17	GAB	GA
  GAMBIA = "Gambia",             //237	Gambia	Gambia	die Republik Gambia	gambisch	1965-02-18	GMB	GM
  GHANA = "Ghana",           //238	Ghana	Ghana	die Republik Ghana	ghanaisch	1957-03-06	GHA	GH
  MAURETANIEN = "Mauretanien",    //239	Mauretanien	Mauretanien	die Islamische Republik Mauretanien	mauretanisch	1960-11-28	MRT	MR
  CABO_VERDE = "Cabo Verde",           //242	Cabo Verde	Cabo Verde	die Republik Cabo Verde	cabo-verdisch	1975-07-05	CPV	CV
  KENIA = "Kenia",          //243	Kenia	Kenia	die Republik Kenia	kenianisch	1963-12-12	KEN	KE
  KOMOREN = "Komoren",        //244	Komoren	Komoren	die Union der Komoren	komorisch	1975-07-06	COM	KM
  KONGO_REPUBLIK = "Republik Kongo",    //245	Kongo	Kongo	die Republik Kongo	kongolesisch	1960-08-15	COG	CG
  KONGO_DEMOKRATISCHE_REPUBLIK = "Demokratische Republik Kongo", //246	Kongo, Demokratische Republik	Demokratische Republik Kongo	die Demokratische Republik Kongo	der Demokratischen Republik Kongo	1960-06-30	COD	CD
  LIBERIA = "Liberia",        //247	Liberia	Liberia	die Republik Liberia	liberianisch		LBR	LR
  LIBYEN = "Libyen",         //248	Libyen	Libyen	der Staat Libyen	libysch	1951-12-24	LBY	LY
  MADAGASKAR = "Madagaskar",     //249	Madagaskar	Madagaskar	die Republik Madagaskar	madagassisch	1960-06-26	MDG	MG
  MALI = "Mali",           //251	Mali	Mali	die Republik Mali	malisch	1960-09-22	MLI	ML
  MAROKKO = "Marokko",        //252	Marokko	Marokko	das Königreich Marokko	marokkanisch	1956-03-02	MAR	MA
  MAURITIUS = "Mauritius",      //253	Mauritius	Mauritius	die Republik Mauritius	mauritisch	1968-03-12	MUS	MU
  MOSAMBIK = "Mosambik",           //254	Mosambik	Mosambik	die Republik Mosambik	mosambikanisch	1975-06-25	MOZ	MZ
  NIGER = "Niger",           //255	Niger	Niger	die Republik Niger	nigrisch	1960-08-03	NER	NE
  MALAWI = "Malawi",           //256	Malawi	Malawi	die Republik Malawi	malawisch	1964-07-06	MWI	MW
  SAMBIA = "Sambia",         //257	Sambia	Sambia	die Republik Sambia	sambisch	1964-10-24	ZMB	ZM
  BURKINA_FASO = "Burkina Faso",   //258	Burkina Faso	Burkina Faso	Burkina Faso	burkinisch	1960-08-05	BFA	BF
  GUINEA_BISSAU = "Guinea-Bissau",  //259	Guinea-Bissau	Guinea-Bissau	die Republik Guinea-Bissau	guinea-bissauisch	1973-09-24	GNB	GW
  GUINEA = "Guinea",         //261	Guinea	Guinea	die Republik Guinea	guineisch	1958-10-02	GIN	GN
  KAMERUN = "Kamerun",           //262	Kamerun	Kamerun	die Republik Kamerun	kamerunisch	1960-01-01	CMR	CM
  SUEDAFRIKA = "Südafrika",     //263	Südafrika	Südafrika	die Republik Südafrika	südafrikanisch		ZAF	ZA
  RUANDA = "Ruanda",     //265	Ruanda	Ruanda	die Republik Ruanda	ruandisch	1962-07-01	RWA	RW
  NAMIBIA = "Namibia",    //267	Namibia	Namibia	die Republik Namibia	namibisch	1990-03-21	NAM	NA
  SAO_TOME_UND_PRINCIPE = "São Tomé und Príncipe",  //268	São Tomé und Príncipe	São Tomé und Príncipe	die Demokratische Republik São Tomé und Príncipe	são-toméisch	1975-07-12	STP	ST
  SENEGAL = "Senegal",    //269	Senegal	Senegal	die Republik Senegal	senegalesisch	1960-08-20	SEN	SN
  SEYCHELLEN = "Seychellen", //271	Seychellen	Seychellen	die Republik Seychellen	seychellisch	1976-06-28	SYC	SC
  SIERRA_LEONE = "Sierra Leone",   //272	Sierra Leone	Sierra Leone	die Republik Sierra Leone	sierra-leonisch	1961-04-27	SLE	SL
  SOMALIA = "Somalia",    //273	Somalia	Somalia	die Bundesrepublik Somalia	somalisch	1960-07-01	SOM	SO
  AEQUATORIALGUINEA = "Äquatorialguinea",  //274	Äquatorialguinea	Äquatorialguinea	die Republik Äquatorialguinea	äquatorialguineisch	1968-10-12	GNQ	GQ
  SUDAN = "Sudan",      //277	Sudan	Sudan	die Republik Sudan	sudanesisch	2011-07-09	SDN	SD
  SUEDSUDAN = "Südsudan",      //278	Südsudan	Südsudan	die Republik Südsudan	südsudanesisch	2011-07-09	SSD	SS
  ESWATINI = "Eswatini",   //281	Eswatini	Eswatini	das Königreich Eswatini	eswatinisch	1968-09-06	SWZ	SZ
  TANSANIA = "Tansania",           //282	Tansania	Vereinigte Republik Tansania	die Vereinigte Republik Tansania	tansanisch		TZA	TZ
  TOGO = "Togo",   //283	Togo	Togo	die Republik Togo	togoisch	1960-04-27	TGO	TG
  TSCHAD = "Tschad",     //284	Tschad	Tschad	die Republik Tschad	tschadisch	1960-08-11	TCD	TD
  TUNESIEN = "Tunesien",           //285	Tunesien	Tunesien	die Tunesische Republik	tunesisch	1956-03-20	TUN	TN
  UGANDA = "Uganda",     //286	Uganda	Uganda	die Republik Uganda	ugandisch	1962-10-09	UGA	UG
  AEGYPTEN = "Ägypten",   //287	Ägypten	Ägypten	die Arabische Republik Ägypten	ägyptisch		EGY	EG
  ZENTRALAFRIKANISCHE_REPUBLIK = "Zentralafrikanische Republik",   //289	Zentralafrikanische Republik	Zentralafrikanische Republik	die Zentralafrikanische Republik	zentralafrikanisch	1960-08-13	CAF	CF
  BURUNDI = "Burundi",    //291	Burundi	Burundi	die Republik Burundi	burundisch	1962-07-01	BDI	BI
  ANTIGUA_UND_BARBUDA = "Antigua und Barbuda",    //320	Antigua und Barbuda	Antigua und Barbuda	Antigua und Barbuda	antiguanisch	1981-11-01	ATG	AG
  BARBADOS = "Barbados",   //322	Barbados	Barbados	Barbados	barbadisch	1966-11-30	BRB	BB
  ARGENTINIEN = "Argentinien",    //323	Argentinien	Argentinien	die Argentinische Republik	argentinisch		ARG	AR
  BAHAMAS = "Bahamas",           //324	Bahamas	Bahamas	das Commonwealth der Bahamas	bahamaisch	1973-07-10	BHS	BS
  BOLIVIEN = "Bolivien",       //326	Bolivien	Plurinationaler Staat Bolivien	der Plurinationale Staat Bolivien	bolivianisch		BOL	BO
  BRASILIEN = "Brasilien",      //327	Brasilien	Brasilien	die Föderative Republik Brasilien	brasilianisch		BRA	BR
  GUYANA = "Guyana",     //328	Guyana	Guyana	die Kooperative Republik Guyana	guyanisch	1966-05-26	GUY	GY
  BELIZE = "Belize", //330	Belize	Belize	Belize	belizisch	1981-09-21	BLZ	BZ
  CHILE = "Chile",  //332	Chile	Chile	die Republik Chile	chilenisch		CHL	CL
  DOMINICA = "Dominica",   //333	Dominica	Dominica	das Commonwealth Dominica	dominicanisch	1978-11-03	DMA	DM
  COSTA_RICA = "Costa Rica", //334	Costa Rica	Costa Rica	die Republik Costa Rica	costa-ricanisch		CRI	CR
  DOMINIKANISCHE_REPUBLIK = "Dominikanische Republik",    //335	Dominikanische Republik	Dominikanische Republik	die Dominikanische Republik	dominikanisch		DOM	DO
  ECUADOR = "Ecuador",    //336	Ecuador	Ecuador	die Republik Ecuador	ecuadorianisch		ECU	EC
  EL_SALVADOR = "El Salvador",    //337	El Salvador	El Salvador	die Republik El Salvador	salvadorianisch		SLV	SV
  GRENADA = "Grenada",    //340	Grenada	Grenada	Grenada	grenadisch	1974-02-07	GRD	GD
  GUATEMALA = "Guatemala",  //345	Guatemala	Guatemala	die Republik Guatemala	guatemaltekisch		GTM	GT
  HAITI = "Haiti",            //346	Haiti	Haiti	die Republik Haiti	haitianisch		HTI	HT
  HONDURAS = "Honduras",            //347	Honduras	Honduras	die Republik Honduras	honduranisch		HND	HN
  KANADA = "Kanada",            //348	Kanada	Kanada	Kanada	kanadisch		CAN	CA
  KOLUMBIEN = "Kolumbien",             //349	Kolumbien	Kolumbien	die Republik Kolumbien	kolumbianisch		COL	CO
  KUBA = "Kuba",   //351	Kuba	Kuba	die Republik Kuba	kubanisch		CUB	CU
  MEXIKO = "Mexiko", //353	Mexiko	Mexiko	die Vereinigten Mexikanischen Staaten	mexikanisch		MEX	MX
  NICARAGUA = "Nicaragua",  //354	Nicaragua	Nicaragua	die Republik Nicaragua	nicaraguanisch		NIC	NI
  JAMAIKA = "Jamaika",            //355	Jamaika	Jamaika	Jamaika	jamaikanisch	1962-08-06	JAM	JM
  PANAMA = "Panama", //357	Panama	Panama	die Republik Panama	panamaisch		PAN	PA
  PARAGUAY = "Paraguay",   //359	Paraguay	Paraguay	die Republik Paraguay	paraguayisch		PRY	PY
  PERU = "Peru",   //361	Peru	Peru	die Republik Peru	peruanisch		PER	PE
  SURINAME = "Suriname",   //364	Suriname	Suriname	die Republik Suriname	surinamisch	1975-11-25	SUR	SR
  URUGUAY = "Uruguay",           //365	Uruguay	Uruguay	die Republik Östlich des Uruguay	uruguayisch		URY	UY
  ST_LUCIA = "St. Lucia",    //366	St. Lucia	St. Lucia	St. Lucia	lucianisch	1979-02-22	LCA	LC
  VENEZUELA = "Venezuela",  //367	Venezuela	Bolivarische Republik Venezuela	die Bolivarische Republik Venezuela	venezolanisch		VEN	VE
  VEREINGTE_STAATEN = "Vereinigte Staaten",  //368	Vereinigte Staaten	Vereinigte Staaten	die Vereinigten Staaten von Amerika	amerikanisch		USA	US
  ST_VINCENT_UND_DIE_GRENADINEN = "St. Vincent und die Grenadinen",  //369	St. Vincent und die Grenadinen	St. Vincent und die Grenadinen	St. Vincent und die Grenadinen	vincentisch	1979-10-27	VCT	VC
  ST_KITTS_UND_NEVIS = "St. Kitts und Nevis", //370	St. Kitts und Nevis	St. Kitts und Nevis	die Föderation St. Kitts und Nevis	von St. Kitts und Nevis	1983-09-19	KNA	KN
  TRINIDAD_UND_TOBAGO = "Trinidad und Tobago",    //371	Trinidad und Tobago	Trinidad und Tobago	die Republik Trinidad und Tobago	von Trinidad und Tobago	1962-08-31	TTO	TT
  JEMEN = "Jemen",  //421	Jemen	Jemen	die Republik Jemen	jemenitisch	1967-11-30	YEM	YE
  ARMENIEN = "Armenien",           //422	Armenien	Armenien	die Republik Armenien	armenisch	1991-09-21	ARM	AM
  AFGHANISTAN = "Afghanistan",           //423	Afghanistan	Afghanistan	die Islamische Republik Afghanistan	afghanisch		AFG	AF
  BAHRAIN = "Bahrain", //424	Bahrain	Bahrain	das Königreich Bahrain	bahrainisch	1971-08-14	BHR	BH
  ASERBAIDSCHAN = "Aserbaidschan",           //425	Aserbaidschan	Aserbaidschan	die Republik Aserbaidschan	aserbaidschanisch	1991-08-30	AZE	AZ
  BHUTAN = "Bhutan", //426	Bhutan	Bhutan	das Königreich Bhutan	bhutanisch		BTN	BT
  MYANMAR = "Myanmar",    //427	Myanmar	Myanmar	die Republik der Union Myanmar	myanmarisch	1948-01-04	MMR	MM
  BRUNEI = "Brunei", //429	Brunei Darussalam	Brunei Darussalam	Brunei Darussalam	bruneiisch	1984-01-01	BRN	BN
  GEORGIEN = "Georgien",   //430	Georgien	Georgien	Georgien	georgisch	1991-04-09	GEO	GE
  SRI_LANKA = "Sri Lanka",  //431	Sri Lanka	Sri Lanka	die Demokratische Sozialistische Republik Sri Lanka	sri-lankisch	1948-02-04	LKA	LK
  VIETNAM = "Vietnam",    //432	Vietnam	Vietnam	die Sozialistische Republik Vietnam	vietnamesisch	1976-07-02	VNM	VN
  KOREA_DEMOKRATISCHE_VOLKSREPUBLIK = "Demokratische Volksrepublik Korea",  //434	Korea = " ,Demokratische Volksrepublik	Demokratische Volksrepublik Korea	die Demokratische Volksrepublik Korea	der Demokratischen Volksrepublik Korea	1948-09-09	PRK	KP
  INDIEN = "Indien", //436	Indien	Indien	die Republik Indien	indisch	1947-08-15	IND	IN
  INDONESIEN = "Indonesien", //437	Indonesien	Indonesien	die Republik Indonesien	indonesisch	1945-08-17	IDN	ID
  IRAK = "Irak",   //438	Irak	Irak	die Republik Irak	irakisch		IRQ	IQ
  IRAN = "Iran",   //439	Iran	Islamische Republik Iran	die Islamische Republik Iran	iranisch		IRN	IR
  ISRAEL = "Israel", //441	Israel	Israel	der Staat Israel	israelisch	1948-05-14	ISR	IL
  JAPAN = "Japan",          //442	Japan	Japan	Japan	japanisch		JPN	JP
  KASACHSTAN = "Kasachstan",     //444	Kasachstan	Kasachstan	die Republik Kasachstan	kasachisch	1991-12-16	KAZ	KZ
  JORDANIEN = "Jordanien",      //445	Jordanien	Jordanien	das Haschemitische Königreich Jordanien	jordanisch	1946-05-25	JOR	JO
  KAMBODSCHA = "Kambodscha",     //446	Kambodscha	Kambodscha	das Königreich Kambodscha	kambodschanisch	1953-11-09	KHM	KH
  KATAR = "Katar",          //447	Katar	Katar	der Staat Katar	katarisch	1971-09-01	QAT	QA
  KUWAIT = "Kuwait",         //448	Kuwait	Kuwait	der Staat Kuwait	kuwaitisch	1961-06-19	KWT	KW
  LAOS = "Laos",           //449	Laos	Demokratische Volksrepublik Laos	die Demokratische Volksrepublik Laos	laotisch	1954-07-20	LAO	LA
  KIRGISISTAN = "Kirgisistan",    //450	Kirgisistan	Kirgisistan	die Kirgisische Republik	kirgisisch	1991-08-31	KGZ	KG
  LIBANON = "Libanon",             //451	Libanon	Libanon	die Libanesische Republik	libanesisch		LBN	LB
  MALEDIVEN = "Malediven",  //454	Malediven	Malediven	die Republik Malediven	maledivisch	1965-07-26	MDV	MV
  OMAN = "Oman",   //456	Oman	Oman	das Sultanat Oman	omanisch		OMN	OM
  MONGOLEI = "Mongolei",            //457	Mongolei	Mongolei	die Mongolei	mongolisch		MNG	MN
  NEPAL = "Nepal",  //458	Nepal	Nepal	Nepal	nepalesisch		NPL	NP
  BANGLADESCH = "Bangladesch",    //460	Bangladesch	Bangladesch	die Volksrepublik Bangladesch	bangladeschisch	1971-03-26	BGD	BD
  PAKISTAN = "Pakistan",           //461	Pakistan	Pakistan	die Islamische Republik Pakistan	pakistanisch	1947-08-14	PAK	PK
  PHILIPPINEN = "Philippinen",             //462	Philippinen	Philippinen	die Republik der Philippinen	philippinisch		PHL	PH
  KOREA_REPUBLIK = "Republik Korea", //467	Korea = " ,Republik	Republik Korea	die Republik Korea	der Republik Korea	1948-08-15	KOR	KR
  VEREINIGTE_ARABISCHE_EMIRATE = "Vereinigte Arabische Emirate",   //469	Vereinigte Arabische Emirate	Vereinigte Arabische Emirate	die Vereinigten Arabischen Emirate (Abu Dhabi = " ,Adschman = " ,Dubai = " ,Fudschaira = " ,Ras al Chaima = " ,Schardscha = " ,Umm al Kaiwain)	der Vereinigten Arabischen Emirate	1971-12-02	ARE	AE
  TADSCHIKISTAN = "Tadschikistan",  //470	Tadschikistan	Tadschikistan	die Republik Tadschikistan	tadschikisch	1991-09-09	TJK	TJ
  TURKMENISTAN = "Turkmenistan", //471	Turkmenistan	Turkmenistan	Turkmenistan	turkmenisch	1991-10-27	TKM	TM
  SAUDI_ARABIEN = "Saudi-Arabien",  //472	Saudi-Arabien	Saudi-Arabien	das Königreich Saudi-Arabien	saudi-arabisch		SAU	SA
  SINGAPUR = "Singapur",//474	Singapur	Singapur	die Republik Singapur	singapurisch	1980-04-08	SGP	SG
  SYRIEN = "Syrien",           //475	Syrien	Arabische Republik Syrien	die Arabische Republik Syrien	syrisch	1946-04-17	SYR	SY
  THAILAND = "Thailand",   //476	Thailand	Thailand	das Königreich Thailand	thailändisch		THA	TH
  USBEKISTAN = "Usbekistan",            //477	Usbekistan	Usbekistan	die Republik Usbekistan	usbekisch	1991-09-01	UZB	UZ
  CHINA = "China",  //479	China	China	die Volksrepublik China	chinesisch		CHN	CN
  MALAYSIA = "Malaysia", //482	Malaysia	Malaysia	Malaysia	malaysisch	1957-08-31	MYS	MY
  TIMOR_LESTE = "Timor-Leste",    //483	Timor-Leste	Timor-Leste	die Demokratische Republik Timor-Leste	von Timor-Leste	2002-05-20	TLS	TL
  AUSTRALIEN = "Australien",//523	Australien	Australien	Australien	australisch		AUS	AU
  SALOMONEN = "Salomonen",             //524	Salomonen	Salomonen	die Salomonen	salomonisch	1978-07-07	SLB	SB
  FIDSCHI = "Fidschi", //526	Fidschi	Fidschi	die Republik Fidschi	fidschianisch	1970-10-10	FJI	FJ
  COOKINSELN = "Cookinseln", //527	Cookinseln	Cookinseln	die Cookinseln	neuseeländisch		COK	CK	Die Cookinseln verfügen über keine eigene Staatsangehörigkeit. Die Staatsangehörigen weisen sich mit Dokumenten von Neuseeland aus = " ,mit dem die Cookinseln durch entsprechende Verträge "in freier Assoziiierung" verbunden sind.
  KIRIBATI = "Kiribati",   //530	Kiribati	Kiribati	die Republik Kiribati	kiribatisch	1979-07-12	KIR	KI
  NAURU = "Nauru",  //531	Nauru	Nauru	die Republik Nauru	nauruisch	1968-01-31	NRU	NR
  VANUATU = "Vanuatu",    //532	Vanuatu	Vanuatu	die Republik Vanuatu	vanuatuisch	1980-07-30	VUT	VU
  NIUE = "Niue", //533	Niue	Niue	Niue	neuseeländisch		NIU	NU	Niue verfügt über keine eigene Staatsangehörigkeit. Die Staatsangehörigen weisen sich mit Dokumenten von Neuseeland aus = " ,mit denen Niue durch entsprechende Verträge "in freier Assoziiierung" verbunden ist
  NEUSEELAND = "Neuseeland", //536	Neuseeland	Neuseeland	Neuseeland	neuseeländisch		NZL	NZ
  PALAU = "Palau",  //537	Palau	Palau	die Republik Palau	palauisch	1994-10-01	PLW	PW
  PAPUA_NEUGUINEA = "Papua-Neuguinea",    //538	Papua-Neuguinea	Papua-Neuguinea	der Unabhängige Staat Papua-Neuguinea	papua-neuguineisch	1975-09-16	PNG	PG
  TUVALU = "Tuvalu", //540	Tuvalu	Tuvalu	Tuvalu	tuvaluisch	1978-10-01	TUV	TV
  TONGA = "Tonga",  //541	Tonga	Tonga	das Königreich Tonga	tongaisch	1970-06-04	TON	TO
  SAMOA = "Samoa",  //543	Samoa	Samoa	der Unabhängige Staat Samoa	samoanisch	1962-01-01	WSM	WS
  MARSHALLINSELN = "Marshallinseln", //544	Marshallinseln	Marshallinseln	die Republik Marshallinseln	marshallisch	1990-12-22	MHL	MH
  MIKRONESIEN = "Mikronesien",           //545	Mikronesien	Föderierte Staaten von Mikronesien	die Föderierten Staaten von Mikronesien	mikronesisch	1990-12-22	FSM	FM


  /*
  schluessel-staat	suchbegriff	staatenname-kurz	staatsname-voll	staatsangehoerigkeit	existenz-ab	iso-3	iso-2	hinweis
  000	Deutschland	Deutschland	die Bundesrepublik Deutschland	deutsch	1949-05-23	DEU	DE
  121	Albanien	Albanien	die Republik Albanien	albanisch		ALB	AL
  122	Bosnien und Herzegowina	Bosnien und Herzegowina	Bosnien und Herzegowina	bosnisch-herzegowinisch	1992-03-01	BIH	BA
  123	Andorra	Andorra	das Fürstentum Andorra	andorranisch		AND	AD
  124	Belgien	Belgien	das Königreich Belgien	belgisch		BEL	BE
  125	Bulgarien	Bulgarien	die Republik Bulgarien	bulgarisch		BGR	BG
  126	Dänemark	Dänemark	das Königreich Dänemark	dänisch		DNK	DK
  127	Estland	Estland	die Republik Estland	estnisch	1991-08-20	EST	EE
  128	Finnland	Finnland	die Republik Finnland	finnisch		FIN	FI
  129	Frankreich	Frankreich	die Französische Republik	französisch		FRA	FR
  130	Kroatien	Kroatien	die Republik Kroatien	kroatisch	1991-06-25	HRV	HR
  131	Slowenien	Slowenien	die Republik Slowenien	slowenisch	1991-06-25	SVN	SI
  134	Griechenland	Griechenland	die Hellenische Republik	griechisch		GRC	GR
  135	Irland	Irland	Irland	irisch		IRL	IE
  136	Island	Island	die Republik Island	isländisch		ISL	IS
  137	Italien	Italien	die Italienische Republik	italienisch		ITA	IT
  139	Lettland	Lettland	die Republik Lettland	lettisch	1991-08-21	LVA	LV
  140	Montenegro	Montenegro	Montenegro	montenegrinisch	2006-06-03	MNE	ME
  141	Liechtenstein	Liechtenstein	das Fürstentum Liechtenstein	liechtensteinisch		LIE	LI
  142	Litauen	Litauen	die Republik Litauen	litauisch	1990-03-11	LTU	LT
  143	Luxemburg	Luxemburg	das Großherzogtum Luxemburg	luxemburgisch		LUX	LU
  144	Nordmazedonien	Nordmazedonien	die Republik Nordmazedonien	mazedonisch/Bürger der Republik Nordmazedonien	1991-09-08	MKD	MK	Die Landessprache wird weiterhin als Mazedonisch bezeichnet, die Staatsbürgerschaft als "mazedonisch" bzw. "Bürger der Republik Nordmazedonien". An Stelle eines Adjektivs verwendet man zur Bezeichnung des Staates, seiner offiziellen Organe und anderer öffentlicher Einheiten "von Nordmazedonien" bzw. "der Republik Nordmazedonien.
  145	Malta	Malta	die Republik Malta	maltesisch	1964-09-21	MLT	MT
  146	Moldau	Republik Moldau	die Republik Moldau	moldauisch	1991-08-27	MDA	MD
  147	Monaco	Monaco	das Fürstentum Monaco	monegassisch		MCO	MC
  148	Niederlande	Niederlande	das Königreich der Niederlande	niederländisch		NLD	NL
  149	Norwegen	Norwegen	das Königreich Norwegen	norwegisch		NOR	NO
  150	Kosovo	Kosovo	die Republik Kosovo	kosovarisch	2008-02-17	XXK	XK	Für Kosovo ist bislang kein Code nach DIN EN ISO 3166-1 festgelegt worden. Die Europäische Kommission verwendet provisorisch den benutzerdefinierten 2-Buchstaben-Code XK; die EU-Ratsarbeitsgruppe Visa den benutzerdefinierten 3-Buchstaben-Code XXK.
  151	Österreich	Österreich	die Republik Österreich	österreichisch		AUT	AT
  152	Polen	Polen	die Republik Polen	polnisch		POL	PL
  153	Portugal	Portugal	die Portugiesische Republik	portugiesisch		PRT	PT
  154	Rumänien	Rumänien	Rumänien	rumänisch		ROU	RO
  155	Slowakei	Slowakei	die Slowakische Republik	slowakisch	1993-01-01	SVK	SK
  156	San Marino	San Marino	die Republik San Marino	san-marinesisch		SMR	SM
  157	Schweden	Schweden	das Königreich Schweden	schwedisch		SWE	SE
  158	Schweiz	Schweiz	die Schweizerische Eidgenossenschaft	schweizerisch		CHE	CH
  160	Russische Föderation	Russische Föderation	die Russische Föderation	russisch	1991-12-25	RUS	RU
  161	Spanien	Spanien	das Königreich Spanien	spanisch		ESP	ES
  163	Türkei	Türkei	die Republik Türkei	türkisch		TUR	TR
  164	Tschechien	Tschechien	die Tschechische Republik	tschechisch	1993-01-01	CZE	CZ
  165	Ungarn	Ungarn	Ungarn	ungarisch		HUN	HU
  166	Ukraine	Ukraine	die Ukraine	ukrainisch	1991-08-24	UKR	UA
  167	Vatikanstadt	Vatikanstadt	der Staat Vatikanstadt	vatikanisch		VAT	VA
  168	Vereinigtes Königreich	Vereinigtes Königreich	das Vereinigte Königreich Großbritannien und Nordirland	britisch		GBR	GB	Staatsangehörigkeit "British Citizen" im Feld "Nationality" auf der Pass-Personaldatenseite.
  169	Belarus	Belarus	die Republik Belarus	belarussisch	1991-08-27	BLR	BY
  170	Serbien	Serbien	die Republik Serbien	serbisch	2008-02-17	SRB	RS
  181	Zypern	Zypern	die Republik Zypern	zyprisch	1960-08-16	CYP	CY
  221	Algerien	Algerien	die Demokratische Volksrepublik Algerien	algerisch	1962-07-05	DZA	DZ
  223	Angola	Angola	die Republik Angola	angolanisch	1975-11-11	AGO	AO
  224	Eritrea	Eritrea	der Staat Eritrea	eritreisch	1993-05-24	ERI	ER
  225	Äthiopien	Äthiopien	die Demokratische Bundesrepublik Äthiopien	äthiopisch		ETH	ET
  226	Lesotho	Lesotho	das Königreich Lesotho	lesothisch	1966-10-04	LSO	LS
  227	Botsuana	Botsuana	die Republik Botsuana	botsuanisch	1966-09-30	BWA	BW
  229	Benin	Benin	die Republik Benin	beninisch	1960-08-01	BEN	BJ
  230	Dschibuti	Dschibuti	die Republik Dschibuti	dschibutisch	1977-06-27	DJI	DJ
  231	Côte d’Ivoire	Côte d’Ivoire	die Republik Côte d’Ivoire	ivorisch	1960-08-07	CIV	CI
  232	Nigeria	Nigeria	die Bundesrepublik Nigeria	nigerianisch	1960-10-01	NGA	NG
  233	Simbabwe	Simbabwe	die Republik Simbabwe	simbabwisch	1980-04-18	ZWE	ZW
  236	Gabun	Gabun	die Gabunische Republik	gabunisch	1960-08-17	GAB	GA
  237	Gambia	Gambia	die Republik Gambia	gambisch	1965-02-18	GMB	GM
  238	Ghana	Ghana	die Republik Ghana	ghanaisch	1957-03-06	GHA	GH
  239	Mauretanien	Mauretanien	die Islamische Republik Mauretanien	mauretanisch	1960-11-28	MRT	MR
  242	Cabo Verde	Cabo Verde	die Republik Cabo Verde	cabo-verdisch	1975-07-05	CPV	CV
  243	Kenia	Kenia	die Republik Kenia	kenianisch	1963-12-12	KEN	KE
  244	Komoren	Komoren	die Union der Komoren	komorisch	1975-07-06	COM	KM
  245	Kongo	Kongo	die Republik Kongo	kongolesisch	1960-08-15	COG	CG
  246	Kongo, Demokratische Republik	Demokratische Republik Kongo	die Demokratische Republik Kongo	der Demokratischen Republik Kongo	1960-06-30	COD	CD
  247	Liberia	Liberia	die Republik Liberia	liberianisch		LBR	LR
  248	Libyen	Libyen	der Staat Libyen	libysch	1951-12-24	LBY	LY
  249	Madagaskar	Madagaskar	die Republik Madagaskar	madagassisch	1960-06-26	MDG	MG
  251	Mali	Mali	die Republik Mali	malisch	1960-09-22	MLI	ML
  252	Marokko	Marokko	das Königreich Marokko	marokkanisch	1956-03-02	MAR	MA
  253	Mauritius	Mauritius	die Republik Mauritius	mauritisch	1968-03-12	MUS	MU
  254	Mosambik	Mosambik	die Republik Mosambik	mosambikanisch	1975-06-25	MOZ	MZ
  255	Niger	Niger	die Republik Niger	nigrisch	1960-08-03	NER	NE
  256	Malawi	Malawi	die Republik Malawi	malawisch	1964-07-06	MWI	MW
  257	Sambia	Sambia	die Republik Sambia	sambisch	1964-10-24	ZMB	ZM
  258	Burkina Faso	Burkina Faso	Burkina Faso	burkinisch	1960-08-05	BFA	BF
  259	Guinea-Bissau	Guinea-Bissau	die Republik Guinea-Bissau	guinea-bissauisch	1973-09-24	GNB	GW
  261	Guinea	Guinea	die Republik Guinea	guineisch	1958-10-02	GIN	GN
  262	Kamerun	Kamerun	die Republik Kamerun	kamerunisch	1960-01-01	CMR	CM
  263	Südafrika	Südafrika	die Republik Südafrika	südafrikanisch		ZAF	ZA
  265	Ruanda	Ruanda	die Republik Ruanda	ruandisch	1962-07-01	RWA	RW
  267	Namibia	Namibia	die Republik Namibia	namibisch	1990-03-21	NAM	NA
  268	São Tomé und Príncipe	São Tomé und Príncipe	die Demokratische Republik São Tomé und Príncipe	são-toméisch	1975-07-12	STP	ST
  269	Senegal	Senegal	die Republik Senegal	senegalesisch	1960-08-20	SEN	SN
  271	Seychellen	Seychellen	die Republik Seychellen	seychellisch	1976-06-28	SYC	SC
  272	Sierra Leone	Sierra Leone	die Republik Sierra Leone	sierra-leonisch	1961-04-27	SLE	SL
  273	Somalia	Somalia	die Bundesrepublik Somalia	somalisch	1960-07-01	SOM	SO
  274	Äquatorialguinea	Äquatorialguinea	die Republik Äquatorialguinea	äquatorialguineisch	1968-10-12	GNQ	GQ
  277	Sudan	Sudan	die Republik Sudan	sudanesisch	2011-07-09	SDN	SD
  278	Südsudan	Südsudan	die Republik Südsudan	südsudanesisch	2011-07-09	SSD	SS
  281	Eswatini	Eswatini	das Königreich Eswatini	eswatinisch	1968-09-06	SWZ	SZ
  282	Tansania	Vereinigte Republik Tansania	die Vereinigte Republik Tansania	tansanisch		TZA	TZ
  283	Togo	Togo	die Republik Togo	togoisch	1960-04-27	TGO	TG
  284	Tschad	Tschad	die Republik Tschad	tschadisch	1960-08-11	TCD	TD
  285	Tunesien	Tunesien	die Tunesische Republik	tunesisch	1956-03-20	TUN	TN
  286	Uganda	Uganda	die Republik Uganda	ugandisch	1962-10-09	UGA	UG
  287	Ägypten	Ägypten	die Arabische Republik Ägypten	ägyptisch		EGY	EG
  289	Zentralafrikanische Republik	Zentralafrikanische Republik	die Zentralafrikanische Republik	zentralafrikanisch	1960-08-13	CAF	CF
  291	Burundi	Burundi	die Republik Burundi	burundisch	1962-07-01	BDI	BI
  320	Antigua und Barbuda	Antigua und Barbuda	Antigua und Barbuda	antiguanisch	1981-11-01	ATG	AG
  322	Barbados	Barbados	Barbados	barbadisch	1966-11-30	BRB	BB
  323	Argentinien	Argentinien	die Argentinische Republik	argentinisch		ARG	AR
  324	Bahamas	Bahamas	das Commonwealth der Bahamas	bahamaisch	1973-07-10	BHS	BS
  326	Bolivien	Plurinationaler Staat Bolivien	der Plurinationale Staat Bolivien	bolivianisch		BOL	BO
  327	Brasilien	Brasilien	die Föderative Republik Brasilien	brasilianisch		BRA	BR
  328	Guyana	Guyana	die Kooperative Republik Guyana	guyanisch	1966-05-26	GUY	GY
  330	Belize	Belize	Belize	belizisch	1981-09-21	BLZ	BZ
  332	Chile	Chile	die Republik Chile	chilenisch		CHL	CL
  333	Dominica	Dominica	das Commonwealth Dominica	dominicanisch	1978-11-03	DMA	DM
  334	Costa Rica	Costa Rica	die Republik Costa Rica	costa-ricanisch		CRI	CR
  335	Dominikanische Republik	Dominikanische Republik	die Dominikanische Republik	dominikanisch		DOM	DO
  336	Ecuador	Ecuador	die Republik Ecuador	ecuadorianisch		ECU	EC
  337	El Salvador	El Salvador	die Republik El Salvador	salvadorianisch		SLV	SV
  340	Grenada	Grenada	Grenada	grenadisch	1974-02-07	GRD	GD
  345	Guatemala	Guatemala	die Republik Guatemala	guatemaltekisch		GTM	GT
  346	Haiti	Haiti	die Republik Haiti	haitianisch		HTI	HT
  347	Honduras	Honduras	die Republik Honduras	honduranisch		HND	HN
  348	Kanada	Kanada	Kanada	kanadisch		CAN	CA
  349	Kolumbien	Kolumbien	die Republik Kolumbien	kolumbianisch		COL	CO
  351	Kuba	Kuba	die Republik Kuba	kubanisch		CUB	CU
  353	Mexiko	Mexiko	die Vereinigten Mexikanischen Staaten	mexikanisch		MEX	MX
  354	Nicaragua	Nicaragua	die Republik Nicaragua	nicaraguanisch		NIC	NI
  355	Jamaika	Jamaika	Jamaika	jamaikanisch	1962-08-06	JAM	JM
  357	Panama	Panama	die Republik Panama	panamaisch		PAN	PA
  359	Paraguay	Paraguay	die Republik Paraguay	paraguayisch		PRY	PY
  361	Peru	Peru	die Republik Peru	peruanisch		PER	PE
  364	Suriname	Suriname	die Republik Suriname	surinamisch	1975-11-25	SUR	SR
  365	Uruguay	Uruguay	die Republik Östlich des Uruguay	uruguayisch		URY	UY
  366	St. Lucia	St. Lucia	St. Lucia	lucianisch	1979-02-22	LCA	LC
  367	Venezuela	Bolivarische Republik Venezuela	die Bolivarische Republik Venezuela	venezolanisch		VEN	VE
  368	Vereinigte Staaten	Vereinigte Staaten	die Vereinigten Staaten von Amerika	amerikanisch		USA	US
  369	St. Vincent und die Grenadinen	St. Vincent und die Grenadinen	St. Vincent und die Grenadinen	vincentisch	1979-10-27	VCT	VC
  370	St. Kitts und Nevis	St. Kitts und Nevis	die Föderation St. Kitts und Nevis	von St. Kitts und Nevis	1983-09-19	KNA	KN
  371	Trinidad und Tobago	Trinidad und Tobago	die Republik Trinidad und Tobago	von Trinidad und Tobago	1962-08-31	TTO	TT
  421	Jemen	Jemen	die Republik Jemen	jemenitisch	1967-11-30	YEM	YE
  422	Armenien	Armenien	die Republik Armenien	armenisch	1991-09-21	ARM	AM
  423	Afghanistan	Afghanistan	die Islamische Republik Afghanistan	afghanisch		AFG	AF
  424	Bahrain	Bahrain	das Königreich Bahrain	bahrainisch	1971-08-14	BHR	BH
  425	Aserbaidschan	Aserbaidschan	die Republik Aserbaidschan	aserbaidschanisch	1991-08-30	AZE	AZ
  426	Bhutan	Bhutan	das Königreich Bhutan	bhutanisch		BTN	BT
  427	Myanmar	Myanmar	die Republik der Union Myanmar	myanmarisch	1948-01-04	MMR	MM
  429	Brunei Darussalam	Brunei Darussalam	Brunei Darussalam	bruneiisch	1984-01-01	BRN	BN
  430	Georgien	Georgien	Georgien	georgisch	1991-04-09	GEO	GE
  431	Sri Lanka	Sri Lanka	die Demokratische Sozialistische Republik Sri Lanka	sri-lankisch	1948-02-04	LKA	LK
  432	Vietnam	Vietnam	die Sozialistische Republik Vietnam	vietnamesisch	1976-07-02	VNM	VN
  434	Korea, Demokratische Volksrepublik	Demokratische Volksrepublik Korea	die Demokratische Volksrepublik Korea	der Demokratischen Volksrepublik Korea	1948-09-09	PRK	KP
  436	Indien	Indien	die Republik Indien	indisch	1947-08-15	IND	IN
  437	Indonesien	Indonesien	die Republik Indonesien	indonesisch	1945-08-17	IDN	ID
  438	Irak	Irak	die Republik Irak	irakisch		IRQ	IQ
  439	Iran	Islamische Republik Iran	die Islamische Republik Iran	iranisch		IRN	IR
  441	Israel	Israel	der Staat Israel	israelisch	1948-05-14	ISR	IL
  442	Japan	Japan	Japan	japanisch		JPN	JP
  444	Kasachstan	Kasachstan	die Republik Kasachstan	kasachisch	1991-12-16	KAZ	KZ
  445	Jordanien	Jordanien	das Haschemitische Königreich Jordanien	jordanisch	1946-05-25	JOR	JO
  446	Kambodscha	Kambodscha	das Königreich Kambodscha	kambodschanisch	1953-11-09	KHM	KH
  447	Katar	Katar	der Staat Katar	katarisch	1971-09-01	QAT	QA
  448	Kuwait	Kuwait	der Staat Kuwait	kuwaitisch	1961-06-19	KWT	KW
  449	Laos	Demokratische Volksrepublik Laos	die Demokratische Volksrepublik Laos	laotisch	1954-07-20	LAO	LA
  450	Kirgisistan	Kirgisistan	die Kirgisische Republik	kirgisisch	1991-08-31	KGZ	KG
  451	Libanon	Libanon	die Libanesische Republik	libanesisch		LBN	LB
  454	Malediven	Malediven	die Republik Malediven	maledivisch	1965-07-26	MDV	MV
  456	Oman	Oman	das Sultanat Oman	omanisch		OMN	OM
  457	Mongolei	Mongolei	die Mongolei	mongolisch		MNG	MN
  458	Nepal	Nepal	Nepal	nepalesisch		NPL	NP
  460	Bangladesch	Bangladesch	die Volksrepublik Bangladesch	bangladeschisch	1971-03-26	BGD	BD
  461	Pakistan	Pakistan	die Islamische Republik Pakistan	pakistanisch	1947-08-14	PAK	PK
  462	Philippinen	Philippinen	die Republik der Philippinen	philippinisch		PHL	PH
  467	Korea, Republik	Republik Korea	die Republik Korea	der Republik Korea	1948-08-15	KOR	KR
  469	Vereinigte Arabische Emirate	Vereinigte Arabische Emirate	die Vereinigten Arabischen Emirate (Abu Dhabi, Adschman, Dubai, Fudschaira, Ras al Chaima, Schardscha, Umm al Kaiwain)	der Vereinigten Arabischen Emirate	1971-12-02	ARE	AE
  470	Tadschikistan	Tadschikistan	die Republik Tadschikistan	tadschikisch	1991-09-09	TJK	TJ
  471	Turkmenistan	Turkmenistan	Turkmenistan	turkmenisch	1991-10-27	TKM	TM
  472	Saudi-Arabien	Saudi-Arabien	das Königreich Saudi-Arabien	saudi-arabisch		SAU	SA
  474	Singapur	Singapur	die Republik Singapur	singapurisch	1980-04-08	SGP	SG
  475	Syrien	Arabische Republik Syrien	die Arabische Republik Syrien	syrisch	1946-04-17	SYR	SY
  476	Thailand	Thailand	das Königreich Thailand	thailändisch		THA	TH
  477	Usbekistan	Usbekistan	die Republik Usbekistan	usbekisch	1991-09-01	UZB	UZ
  479	China	China	die Volksrepublik China	chinesisch		CHN	CN
  482	Malaysia	Malaysia	Malaysia	malaysisch	1957-08-31	MYS	MY
  483	Timor-Leste	Timor-Leste	die Demokratische Republik Timor-Leste	von Timor-Leste	2002-05-20	TLS	TL
  523	Australien	Australien	Australien	australisch		AUS	AU
  524	Salomonen	Salomonen	die Salomonen	salomonisch	1978-07-07	SLB	SB
  526	Fidschi	Fidschi	die Republik Fidschi	fidschianisch	1970-10-10	FJI	FJ
  527	Cookinseln	Cookinseln	die Cookinseln	neuseeländisch		COK	CK	Die Cookinseln verfügen über keine eigene Staatsangehörigkeit. Die Staatsangehörigen weisen sich mit Dokumenten von Neuseeland aus, mit dem die Cookinseln durch entsprechende Verträge "in freier Assoziiierung" verbunden sind.
  530	Kiribati	Kiribati	die Republik Kiribati	kiribatisch	1979-07-12	KIR	KI
  531	Nauru	Nauru	die Republik Nauru	nauruisch	1968-01-31	NRU	NR
  532	Vanuatu	Vanuatu	die Republik Vanuatu	vanuatuisch	1980-07-30	VUT	VU
  533	Niue	Niue	Niue	neuseeländisch		NIU	NU	Niue verfügt über keine eigene Staatsangehörigkeit. Die Staatsangehörigen weisen sich mit Dokumenten von Neuseeland aus, mit denen Niue durch entsprechende Verträge "in freier Assoziiierung" verbunden ist.
  536	Neuseeland	Neuseeland	Neuseeland	neuseeländisch		NZL	NZ
  537	Palau	Palau	die Republik Palau	palauisch	1994-10-01	PLW	PW
  538	Papua-Neuguinea	Papua-Neuguinea	der Unabhängige Staat Papua-Neuguinea	papua-neuguineisch	1975-09-16	PNG	PG
  540	Tuvalu	Tuvalu	Tuvalu	tuvaluisch	1978-10-01	TUV	TV
  541	Tonga	Tonga	das Königreich Tonga	tongaisch	1970-06-04	TON	TO
  543	Samoa	Samoa	der Unabhängige Staat Samoa	samoanisch	1962-01-01	WSM	WS
  544	Marshallinseln	Marshallinseln	die Republik Marshallinseln	marshallisch	1990-12-22	MHL	MH
  545	Mikronesien	Föderierte Staaten von Mikronesien	die Föderierten Staaten von Mikronesien	mikronesisch	1990-12-22	FSM	FM
  */

}
