import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-picture-file',
  templateUrl: './picture-file.component.html',
  styleUrls: ['./picture-file.component.css']
})
export class PictureFileComponent implements OnInit {

  @Input() image: string;
  @Input() pdf: string;
  @Input() width: number;
  @Input() height: number;

  constructor() { }

  ngOnInit(): void {
  }

}
