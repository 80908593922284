<div *ngIf="!(!image)" class="picture-file-zoom">
  <img  [src]="image" alt="">
</div>
<div *ngIf="!(!pdf)" class="picture-file-zoom">
  <pdf-viewer [src]="this.pdf"
              [render-text]="false"
              [original-size]="false"
              [show-borders]="false"
              [zoom]=".9"
              [zoom-scale]="'page-fit'"
              [style]="'width:'+this.width+'px;height:'+this.height+'px;'"
  ></pdf-viewer>
</div>
