import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  @Input() public title: string = 'Bitte bestätigen Sie';
  @Input() public text: SafeHtml = '';
  @Input() public onYes = (): Function => {
    return null;
  };
  @Input() public onNo = (): Function => {
    return null;
  };

  public confirmFom: UntypedFormGroup;
  faDelete = faTrash;

  constructor(
    public translate: TranslateService,
    private modalService: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { }

  accept(){
    this.onYes();
    this.modalService.close()
  }

  reject(){
    this.onNo();
    this.modalService.close()
  }

  setTitle(title: string){
    this.title = title;
  }

  setText(text: string){
    this.text = this.sanitizer.bypassSecurityTrustHtml(text);
  }

}
