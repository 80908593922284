import {Component, Input, OnInit} from '@angular/core';
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {TextbausteinService} from "../../@core/services/textbaustein/textbaustein.service";
import {SessionService} from "../../@core/services/session/session.service";
import {KeyString} from "../../models/textbaustein/KeyString";

@Component({
  selector: 'app-navbar-responsive',
  templateUrl: './navbar-responsive.component.html',
  styleUrls: ['./navbar-responsive.component.scss']
})
export class NavbarResponsiveComponent implements OnInit {

  @Input() moduleEnabled: Array<Object> = [];
  @Input() roleEnabled : Array<Object> = [];

  protected readonly faHouse = faHouse;

  existsSessionStorageTextbausteine: boolean = false;

  constructor(
    public textbausteinService: TextbausteinService,
    private sessionService: SessionService
  ) {}

  ngOnInit(){
    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });
  }

    protected readonly KeyString = KeyString;
}
