<app-default-layout
  [title]=" this.schuleDto?.id ? 'SCHULEN-DETAIL.TITLE' : 'SCHULEN-DETAIL.TITLE_NEW'  "
>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">
        <div id="edit-schule" class="content scaffold-edit" role="main">
          <form [formGroup]="schuleForm" (ngSubmit)="this.saveOrUpdate()" class="form-horizontal"
                accept-charset="UTF-8">
            <div class="form-group group-for-name col mb-4">
              <div class="d-flex flex-row" style="margin-bottom: -15px">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="name" type="text"
                                    class="flex-grow-1"
                                    label="{{'SCHULEN-DETAIL.name' |translate }}"
                                    [customFormGroup]="schuleForm"
                                    data-cy="name"
                  ></app-custom-input>
                </div>
              </div>
            </div>
            <div class="form-group group-for-schulnummer col mb-4">
              <div class="d-flex flex-row" style="margin-bottom: -15px">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="schulnummer"
                                    type="text"
                                    [customFormGroup]="schuleForm"
                                    label="{{'SCHULEN-DETAIL.schulnummer' |translate }}"
                                    data-cy="schulnummer"
                  ></app-custom-input>
                </div>
              </div>
            </div>
            <div class="form-group col mb-4">
              <!--textbaustein missing for infoText?-->
              <app-custom-radio label="{{'SCHULEN-DETAIL.berufskolleg' |translate }}"
                                id="berufskolleg"
                                [options]="[{name: 'STANDARD.JA', value: true},
                                            {name: 'STANDARD.NEIN' , value: false}]"
                                [customFormGroup]="schuleForm"
                                infoText="{{'SCHULEN-DETAIL.BERUFSKOLLEG_INFO' |translate }}"
                                data-cy="berufskolleg"
              ></app-custom-radio>
            </div>

            <div class="group-for-schultyp col mb-4">
              <div class="d-flex flex-row" style="margin-bottom: -15px">
                <div class="flex-grow-1 mr-2">
                  <!--textbaustein missing for infoText?-->
                  <app-custom-select id="schultyp"
                                     [customFormGroup]="schuleForm"
                                     [multiple]="false"
                                     [clearable]="false"
                                     class="flex-grow-1"
                                     placeholder="-"
                                     tabindex="-1" aria-hidden="true"
                                     [items]="schultypenList"
                                     bindValue="id"
                                     bindLabel="name"
                                     infoText="'SCHULTYP_INFO' |translate "
                                     [label]="'SCHULEN-DETAIL.schultyp' |translate "
                                     data-cy="schultyp"
                  ></app-custom-select>
                </div>
              </div>
            </div>

            <div class="form-group col"><!-- -->
              <div class="d-flex flex-row ">
                <label class="col flex-grow-1 control-label ">
                  {{'SCHULEN-DETAIL.koordinaten' |translate }}
                </label>
                <div *ngIf="!(!schuleId)">
                  <a class="btn btn-outline-primary"
                     (click)="navigateToNewCoordinates()"
                     data-cy="newCoords">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    {{'SCHULEN-DETAIL.koordinate_neu' |translate }}
                  </a>
                </div>
              </div>
              <div class="col-md-12 middle" data-toggle="popover" data-placement="bottom">
                <div class="scroll-if-needed">
                  <table class="table table-striped table-bordered table-hover">
                    <tbody>
                    <tr *ngFor="let koordinate of this.schuleDto?.schuleKoordinaten ;let i = index">
                      <td>
                        <a (click)="navigateToExistingCoordinates(koordinate.id)" data-cy="coords">
                          {{i + 1 + ".&nbsp; (" + parseInternationalNumberToGerman(koordinate.lat) + " | " + parseInternationalNumberToGerman(koordinate.lon) + ")"}}
                        </a>
                      </td>
                    </tr>
                    <tr *ngIf="infoKoordinaten!=''">
                      <td class="text-muted">
                        {{infoKoordinaten}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div  class="flex flex-row">
                <button *ngIf="this.schuleId && !showMap"
                        class="btn btn-outline-primary" type="button"
                        (click)="toggleMap()">
                  {{'SCHULEN-DETAIL.MAP' |translate}}
                </button>

              </div>
              <app-geo-map *ngIf="schuleDto !== undefined && showMap"
                           [schulname]="schuleDto?.name"
                           [coordinateList]="schuleDto.schuleKoordinaten"
              ></app-geo-map>
            </div>

            <div class="d-flex flex-row">
              <span class="flex-grow-1">
                <button type="submit"
                        class="btn btn-primary save"
                        data-cy="saveSchule">
                  <fa-icon [icon]="faSave"></fa-icon>
                  {{this.schuleId?this.translate.instant('APPLICATION.BUTTON_SPEICHERN')
                  :this.translate.instant('SCHULEN-DETAIL.ADD_BUTTON')}}
                </button>
                <button *ngIf="this.schuleId" (click)="showDecisionModal()"
                        class="btn btn-danger delete" type="button"
                        data-cy="delete">
                  <fa-icon [icon]="faDelete"></fa-icon>
                  {{'APPLICATION.BUTTON_DELETE' |translate }}
                </button>
              </span>
              <button [routerLink]="['/pages/schulen']"
                      class="btn btn-outline-primary list"
                      data-cy="gotoList">
                {{'APPLICATION.BUTTON_ABBRECHEN' |translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
