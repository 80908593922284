import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServicesModule} from './services/services.module';

export const CORE_PROVIDERS = [
  ...ServicesModule.forRoot().providers
];

@NgModule({
	declarations: [
  ],
	imports: [CommonModule]
})
export class CoreModule {
	constructor() {

	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				...CORE_PROVIDERS,
			]
		};
	}
}
