<app-default-layout
  [title]="existsSessionStorageTextbausteine?
            textbausteinService.printTextbausteinByKey( KeyString.TITLE_SCHUELER_LIST, ('SCHUELER-TABLE.TITLE'|translate) ):('SCHUELER-TABLE.TITLE'|translate)"
>

  <div class="topbar">
    <div class="row">
      <div class="col-md-8 offset-md-2 ml-auto">
        <button class="btn btn-primary float-end" [routerLink]="['/pages/schueler-detail']"
                data-cy="addSchueler">
          <fa-icon [icon]="faPlus"></fa-icon>
          {{'SCHUELER-TABLE.ADD_BUTTON'|translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">
        <ngx-datatable
          class="bootstrap"
          [rowHeight]="'auto'"
          [footerHeight]="50"
          [rows]="rows"
          [columns]="columns"
          [headerHeight]="40"
          [columnMode]="'force'"
          (activate)="onActivate($event)"
          [loadingIndicator]="isLoading"
          [sorts]="[{ prop: 'name', dir: 'asc' }]"
          [messages] = "{
                  emptyMessage: 'NGX_DATATABLE.EMPTY' | translate,
                  totalMessage: 'NGX_DATATABLE.TOTAL' | translate,
                  selectedMessage: false
                }"
          [externalSorting]="false">
          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"            >
              <div style="padding: 5px 10px">
                <div *ngIf="!isLoading && dataFetched"> <strong>{{ rowCount }}</strong> {{'NGX_DATATABLE.TOTAL' | translate}}</div>
                <div *ngIf="isLoading"> {{'NGX_DATATABLE.LOADING' | translate}} </div>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>

        <ng-template #hdrTpl let-column="column" let-sort="sortFn">
          <div (click)="sort()">
            <fa-icon
              [icon]="this.sortInitial" >
            </fa-icon>
            <span style="padding-right:.5rem;">
              <strong> {{ "SCHUELER-TABLE.COLUMNS."+column.name | translate }}</strong>
            </span>
          </div>
        </ng-template>

      </div>
    </div>
  </div>

</app-default-layout>
