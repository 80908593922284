import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {EMPTY, Observable, of} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {FahrkostenDTO} from "../../../models/fahrkosten/FahrkostenDTO";
import {FahrkostenService} from "../../../@core/services/fahrkosten/fahrkosten.service";
import {SchuleService} from "../../../@core/services/schule/schule.service";
import {SchuljahrService} from "../../../@core/services/schuljahr/schuljahr.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {catchError} from "rxjs/operators";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {CompositMessageService} from "../../../@core/services/compositMessage/compositMessage.service";
import {faPlus, faSort} from '@fortawesome/free-solid-svg-icons';
import {nullLastComparator} from "../../../@core/utils/forms/null-last-comparator";

@Component({
  selector: 'app-fahrkosten-table',
  templateUrl: './fahrkosten-table.component.html',
  styleUrls: ['./fahrkosten-table.component.scss']
})
export class FahrkostenTableComponent implements OnInit {

  faPlus = faPlus;
  sortInitial = faSort;

  @Input() rows$: Observable<FahrkostenDTO[]> = of([]);

  public columns: any;
  public rows: any;

  datePipe : DatePipe = new DatePipe("de-DE");

  isLoading: boolean = false;
  dataFetched: boolean = false;
  entityNameTranslationKey: string = 'FAHRKOSTEN';

  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private fahrkostenService: FahrkostenService,
    private schuleService: SchuleService,
    private schuljahrService: SchuljahrService,
    private customToastService: CustomToastService,
    private compositMessageService: CompositMessageService
  ) {}

  ngOnInit(): void {

    this.columns = [
      { name: 'name', headerTemplate: this.hdrTpl, comparator: nullLastComparator.bind(this) },
      { name: 'vorname', headerTemplate: this.hdrTpl , maxWidth: 150, comparator: nullLastComparator.bind(this) },
      { name: 'schule', headerTemplate: this.hdrTpl , prop: 'schuleName', comparator: nullLastComparator.bind(this) },
      { name: 'schuljahr', headerTemplate: this.hdrTpl , prop: 'schuljahrName', maxWidth: 120, comparator: nullLastComparator.bind(this)},
      { name: 'antragsdatum', headerTemplate: this.hdrTpl , maxWidth: 130 , pipe: this.translateDatePipe(), comparator: nullLastComparator.bind(this) },
      { name: 'statusSimplified', headerTemplate: this.hdrTpl, pipe: this.translatePipe(), comparator: nullLastComparator.bind(this) }
    ];

    this.reloadTable();

  }

  onActivate(event) {
    if(event.type == 'click') {
       this.router.navigate(['/pages/fahrkosten-detail/'+event.row.id ]).catch(()=>{});
    }
  }

  translatePipe () {
    return {transform: (value) =>  this.translate.instant('FAHRKOSTEN-TABLE.STATUS.'+value)};
  }

  translateDatePipe () {
    return {transform: (value) => this.datePipe.transform(value, 'dd.MM.yyyy')};
  }

  reloadTable() {
    this.isLoading = true;
    this.dataFetched = false;
    this.fahrkostenService.getAllFahrkostenMinimal()
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'getBulk'));
          this.isLoading   = false;
          this.dataFetched = null;
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.rows = data;
        this.isLoading = false;
        this.dataFetched = this.rows.length>0;
      });
  }

}
