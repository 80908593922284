<div class="form-group" [formGroup]="customFormGroup">
	<label [for]="'input-'+id"  [id]="'sfklabel-'+id" data-cy="custom-label">
    {{ label }}
  </label>
  <fa-icon class="infoIcon"
           *ngIf="!!infoText && infoText.length > 0"
           [icon]="faInfo"
           [title]="infoText"
           data-cy="custom-info"
  ></fa-icon>
  <span *ngIf="environment.displayTextbausteinKeys">{{infoText}}</span>
	<div style="position:relative;">
		<div *ngIf="showMaxLength" class="position-absolute" style="right: 5px; top: -19px">
			<span
				[class.text-muted]="currentLength / maxLength < 0.6"
				[class.text-warning]="
					currentLength / maxLength >= 0.6 && currentLength / maxLength < 1
				"
				[class.text-danger]="currentLength / maxLength >= 1"
			>
				{{ getFormElement(id)?.value?.length }}/{{ maxLength }}
			</span>
		</div>
		<textarea
			[formControlName]="id"
			class="form-control"
			rows="{{rows}}"
			[placeholder]="placeholder"
			[id]="htmlFieldName"
			[readonly]="readonly"
      [disabled]="disabled"
      data-cy="custom-textarea"
		></textarea>
	</div>
	<small
    *ngIf="getFormElement(id).invalid && (getFormElement(id).dirty || getFormElement(id).touched)"
		[id]="id + 'HelpBlock'"
		class="form-text text-muted"
	>
		{{ noteText }}
	</small>
</div>
