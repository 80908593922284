import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FieldConfigDTO} from "../../../models/field-config/FieldConfigDTO";
import {FieldConfigCreateOrUpdateDTO} from "../../../models/field-config/FieldConfigCreateOrUpdateDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class FieldConfigService {

  configUrl = 'api/field-config';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getFieldConfigs(): Observable<Array<FieldConfigDTO>> {
    return this.http.get<Array<FieldConfigDTO>>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  createOrUpdateFieldConfigs(dtoList: Array<FieldConfigCreateOrUpdateDTO>, module: string): Observable<Array<FieldConfigDTO>> {
    return this.http.post<Array<FieldConfigDTO>>(
      this.configUrl+'/bulk?module=' + module,
      dtoList,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getFieldConfigFromSessionStorage(modules: string[]) {
    if (sessionStorage.getItem('field-config')) {
      return JSON.parse(sessionStorage.getItem('field-config')).filter(fieldConfig => modules.includes(fieldConfig.module));
    }
  }

  getVisibleFieldsForModuleFromSessionStorage(module: string) {
    const moduleFields = this.getFieldConfigFromSessionStorage([module]);
    return moduleFields.filter( it => it.visible );
  }

  getFieldFromFieldConfig(field: string, fieldConfig: any[]) {
    if( !(!field) && field!='' && !(!fieldConfig) && fieldConfig.length>0)
      return fieldConfig.filter(fieldConfig => fieldConfig.field === field)[0];
    else {
      return {field: 'FieldOrModuleNotFound', visible: true, required: false, label: null, module: 'FieldOrModuleNotFound'};
    }
  }

  getFieldLabel(field: string, fieldConfig: any[], defaultLabel: string) {
    const fieldFromFieldConfig = this.getFieldFromFieldConfig(field, fieldConfig);
    if(fieldFromFieldConfig==undefined)
      return defaultLabel;
    if (fieldFromFieldConfig.label === null || fieldFromFieldConfig.label === undefined || fieldFromFieldConfig.label?.length === 0) {
      return defaultLabel;
    } else {
      return fieldFromFieldConfig.label;
    }
  }

}
