import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BenutzerService} from "../../services/benutzer/benutzer.service";
import {EMPTY} from "rxjs";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-unlock',
  templateUrl: './unlock.component.html',
  styleUrls: ['./unlock.component.css']
})
export class UnlockComponent implements OnInit {
  httpFinished: boolean = false;
  email: string;
  text: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private benutzerService: BenutzerService
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.email = params.email;
        this.benutzerService.unlock({email: this.email, unlockToken: params.token})
          .pipe(
            catchError((err) => {
              switch (err.status) {
                case 409: {
                  this.text = "Der Link den Sie versuchen aufzurufen, ist leider abgelaufen."
                  this.httpFinished = true;
                  return EMPTY;
                }
                default: {
                  this.text = "Es ist etwas schiefgelaufen."
                  this.httpFinished = true;
                  return EMPTY;
                }
              }
            })
          )
          .subscribe(result => {
            this.text = "Das Konto mit der Email-Adresse " + this.email + " wurde erfolgreich entsperrt."
            this.httpFinished = true;
        });
      });
  }

}
