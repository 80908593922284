import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {ModuleConfigDto} from "../../../models/module-config/ModuleConfigDto";
import {TextbausteinDTO} from "../../../models/textbaustein/TextbausteinDTO";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  // moduleConfig

  private sessionStorageModuleConfig = new BehaviorSubject<ModuleConfigDto[]>(undefined);

  watchSessionStorageModuleConfig () : Observable<ModuleConfigDto[]> {
    return this.sessionStorageModuleConfig.asObservable();
  }
  broadcastModuleConfigChange () {
    this.sessionStorageModuleConfig.next( JSON.parse(sessionStorage.getItem('module-config')) as ModuleConfigDto[] );
  }

  // textbausteine

  private sessionStorageTextbausteine = new BehaviorSubject<TextbausteinDTO[]>(undefined);
  private sessionStorageExistsTextbausteine = new BehaviorSubject<boolean>(false);

  watchSessionStorageTextbausteine () : Observable<TextbausteinDTO[]> {
    return this.sessionStorageTextbausteine.asObservable();
  }

  watchSessionStorageExistsTextbausteine () : Observable<boolean>{
    return this.sessionStorageExistsTextbausteine.asObservable();
  }

  broadcastTextbausteineChange() {
    this.sessionStorageTextbausteine.next( JSON.parse(sessionStorage.getItem('textbausteine')) as TextbausteinDTO[] );
    this.sessionStorageExistsTextbausteine.next( !!(JSON.parse(sessionStorage.getItem('textbausteine')) as TextbausteinDTO[]) );
  }

}
