export function isMissingOrEmpty(value: any): boolean {
  return !value
    || ( typeof value === 'string' && value.trim().length === 0 )
    || ( Array.isArray(value) && value.length === 0 )
    || value.length === 0
  ;
}

export function isAnyMissingOrEmpty(values: Array<any>): boolean {
  return values.some( it => isMissingOrEmpty(it) );
}
