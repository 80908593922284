import {Injectable} from "@angular/core";
import {Land} from "../../../models/land/Land";

@Injectable({
  providedIn: 'root'
})
export class PlzValidationHelperService {

  private mapPlzNameToLandControlName : Map<string, string> = new Map<string, string>([
    ['vormundPlz','vormundLand'],
    ['praktikumPlz','praktikumLand'],
    ['kontoinhaberPlz','kontoinhaberLand'],
    ['plzSepa','landSepa'],
    ['plz','land']
  ]);

  private mapLandToPlzLength : Map<string, number> = new Map<string, number>([
    [Land.DEUTSCHLAND.toUpperCase(),5],
    [Land.NIEDERLANDE.toUpperCase(),7],
    [Land.BELGIEN.toUpperCase(),4],
  ]);

  private mapLandToValidationPattern : Map<string, string> = new Map<string, string>([
    [Land.DEUTSCHLAND.toUpperCase(),'\\d{5}'],
    [Land.NIEDERLANDE.toUpperCase(),'[1-9]\\d{3} [A-Z]{2}|[1-9]\\d{3}[A-Z]{2}'],
    [Land.BELGIEN.toUpperCase(),'(?:(?:[1-9])(?:\\d{3}))'],
  ]);

  getLandforPlz(plzControlname: string) : string {
    return (!!plzControlname && this.mapPlzNameToLandControlName.has(plzControlname))?
      this.mapPlzNameToLandControlName.get(plzControlname) : '';
  }

  getMaxLength(land: string) : number {
    return (!!land && this.mapLandToPlzLength.has(land))?
      this.mapLandToPlzLength.get(land) : 10;
  }

  getPattern(land: string) : string {
    return (!!land && this.mapLandToValidationPattern.has(land))?
      this.mapLandToValidationPattern.get(land) : '(.*)';
  }


}
