import {Injectable} from "@angular/core";
import {FieldConfigSessionService} from "../field-config/field-config-session.service";
import {HaltestelleDTO} from "../../../models/haltestelle/HaltestelleDTO";
import {HaltestelleMinimalDTO} from "../../../models/haltestelle/HaltestelleMinimalDTO";


@Injectable({
  providedIn: 'root'
})
export class HaltestelleDisplayComboNameService {

  private nameVisible : boolean;
  private tarifgebietNameVisible: boolean;
  private teilTarifgebietNameVisible: boolean;

  constructor(
    private fieldConfigSessionService: FieldConfigSessionService
  ) {
    this.fieldConfigSessionService.watchSessionStorageFieldConfig()
      .subscribe(fieldConfigs=>
          fieldConfigs.forEach(fieldConfig => {
            if(fieldConfig.module === 'haltestellenDisplay')
              if(fieldConfig.field === 'name')
                this.nameVisible = fieldConfig.visible;
              else if(fieldConfig.field === 'tarifgebietName')
                this.tarifgebietNameVisible = fieldConfig.visible;
              else if(fieldConfig.field === 'teilTarifgebietName')
                this.teilTarifgebietNameVisible = fieldConfig.visible;
          })
      );
  }

  getComboName(haltestelle: HaltestelleDTO) : string {
    let parts : Array<string> = [];
    if(this.nameVisible)
      parts.push(haltestelle.name)
    if(this.teilTarifgebietNameVisible)
      parts.push(haltestelle.teiltarifgebietName)
    if(this.tarifgebietNameVisible)
      parts.push(haltestelle.tarifgebietName)
    return parts.join(', ');
  }

  haltestellenListToMinimalList(haltestelleList: HaltestelleDTO[]){
    return haltestelleList.map(haltestelle => ({
      id: haltestelle.id,
      name: haltestelle.name,
      tarifgebietName: haltestelle.tarifgebietName,
      comboName: this.getComboName(haltestelle),
      archiv: haltestelle.archiv
    } as HaltestelleMinimalDTO));
  }

}
