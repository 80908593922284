<app-default-layout
  [title]="this.title"
>

  <div class="row pt-0" style="margin-top:-2ch;">
    <div class="frame frame-default mt-0 pt-0">
      <pre class="confirm-modal-text"
           style="overflow-x: auto;
                  white-space: pre-line;
                  white-space: -moz-pre-wrap;
                  white-space: -o-pre-wrap;
                  word-wrap: break-word;"
           [innerHTML]="this.text"
      >
      </pre>
      <div class="d-flex flex-row-reverse mt-1">
        <button type="button"  (click)="reject()"
                        name="reject" class="btn btn-outline-primary"
                        style="min-width: 10em"
                data-cy="no">
                  {{'APPLICATION.BUTTON_NO'|translate}}

        </button>
        <span style="width: 1em"></span>
        <button type="button" (click)="accept()"
                class="btn btn-primary" style="min-width: 10em"
                data-cy="yes">
          {{'APPLICATION.BUTTON_YES' |translate }}
        </button>
      </div>
    </div>
  </div>
</app-default-layout>
