import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RouteDTO} from "../../../models/routing/RouteDTO";
import {WaypointDTO} from "../../../models/waypoint/WaypointDTO";
import {WaypointCreateOrUpdateDTO} from "../../../models/waypoint/WaypointCreateOrUpdateDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  configUrl = 'api/routing';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getRouteToSchule(antragTicketId: string, fahrkostenId: string, travelMode: string): Observable<RouteDTO> {
    let antragTicketIdParam = this.getAntragTicketParam(antragTicketId);
    let fahrkostenIdParam = this.getFahrkostenIdParam(fahrkostenId);
    return this.http.get<RouteDTO>(
      this.configUrl+'/schule?travelMode=' + travelMode + antragTicketIdParam + fahrkostenIdParam,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getRouteToPraktikum(antragTicketId: string, fahrkostenId: string, travelMode: string): Observable<RouteDTO> {
    let antragTicketIdParam = this.getAntragTicketParam(antragTicketId);
    let fahrkostenIdParam = this.getFahrkostenIdParam(fahrkostenId);
    return this.http.get<RouteDTO>(
      this.configUrl+'/praktikum?travelMode=' + travelMode + antragTicketIdParam + fahrkostenIdParam,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  private getFahrkostenIdParam(fahrkostenId: string) {
    let fahrkostenIdParam = '';
    if (fahrkostenId !== undefined) {
      fahrkostenIdParam = '&fahrkostenId=' + fahrkostenId
    }
    return fahrkostenIdParam;
  }

  private getAntragTicketParam(antragTicketId: string) {
    let antragTicketIdParam = '';
    if (antragTicketId !== undefined) {
      antragTicketIdParam = '&antragTicketId=' + antragTicketId
    }
    return antragTicketIdParam;
  }

  getWaypointsByBenutzer(benutzerId: string): Observable<WaypointDTO[]> {
    return this.http.get<WaypointDTO[]>(
      'api/waypoint/benutzer/'+benutzerId,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  createOrUpdateWaypointList(benutzerId: string, dtoList: WaypointCreateOrUpdateDTO[]): Observable<WaypointDTO[]> {
    return this.http.post<WaypointDTO[]>(
      'api/waypoint/'+benutzerId,
      dtoList,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  deleteWaypointsByBenutzer(benutzerId: string): Observable<any> {
    return this.http.delete(
      'api/waypoint/benutzer/'+benutzerId,
      {headers: this.headerService.getAuthHeader(), responseType: 'text'}
    );
  }
}
