<app-default-layout
  [title]="existsSessionStorageTextbausteine?
    textbausteinService.printTextbausteinByKey( KeyString.TITLE_HOME, ('HOME.TITLE'|translate) ):('HOME.TITLE'|translate)"
  [subtitle]="existsSessionStorageTextbausteine?
    textbausteinService.printTextbausteinByKey( KeyString.SUBTITLE_HOME, ('HOME.SUBTITLE'|translate) ):('HOME.SUBTITLE'|translate)"
>

  <main role="main" class="container-fluid" aria-label="">
    <div class="row justify-content-center">
      <main class="col-4">
        <div class="frame frame-full">
          <div id="welcome-page" class="content scaffold-edit justify-content-center" role="main" aria-label="">
            <div class="d-flex flex-row">
              <div class="flex-grow-1 texthome mb-1">
                {{existsSessionStorageTextbausteine?
                  textbausteinService.printTextbausteinByKey( KeyString.WELCOME_TEXT_HOME, ('HOME.WELCOME_TEXT'|translate) ):('HOME.WELCOME_TEXT'|translate)}}
              </div>
            </div>
            <ul *ngIf="existsSessionUser && roleEnabled['Antragsteller']">
              <li class="listitemhome" >
                <a class="nav-link" [routerLink]="['/pages/schueler-table']" data-cy="newSchuelerList">
                  {{existsSessionStorageTextbausteine?
                    textbausteinService.printTextbausteinByKey( KeyString.LINK_SCHUELER_TABLE_HOME, ('HOME.LINK_SCHUELER_TABLE'|translate) ):('HOME.LINK_SCHUELER_TABLE'|translate)}}
                </a>
              </li>
              <li class="listitemhome" *ngIf="moduleEnabled['antragTicket']">
                <a class="nav-link" [routerLink]="['/pages/ticket-table']" data-cy="newAntragTicketList">
                  {{existsSessionStorageTextbausteine?
                    textbausteinService.printTextbausteinByKey( KeyString.LINK_TICKET_TABLE_HOME, ('HOME.LINK_TICKET_TABLE'|translate) ):('HOME.LINK_TICKET_TABLE'|translate)}}
                </a>
              </li>
              <li class="listitemhome" *ngIf="moduleEnabled['antragTicket']">
                <a class="nav-link" [routerLink]="['/pages/ticket-detail']" data-cy="newAntragTicket">
                  {{existsSessionStorageTextbausteine?
                    textbausteinService.printTextbausteinByKey( KeyString.LINK_TICKET_DETAIL_HOME, ('HOME.LINK_TICKET_DETAIL'|translate) ):('HOME.LINK_TICKET_DETAIL'|translate)}}
                </a>
              </li>
              <li class="listitemhome" *ngIf="moduleEnabled['fahrkosten']" >
                <a class="nav-link" [routerLink]="['/pages/fahrkosten']" data-cy="newFahrkostenList">
                  {{existsSessionStorageTextbausteine?
                    textbausteinService.printTextbausteinByKey( KeyString.LINK_FAHRKOSTEN_TABLE_HOME, ('HOME.LINK_FAHRKOSTEN_TABLE'|translate) ):('HOME.LINK_FAHRKOSTEN_TABLE'|translate)}}
                </a>
              </li>
              <li class="listitemhome" *ngIf="moduleEnabled['fahrkosten']">
                <a class="nav-link" [routerLink]="['/pages/fahrkosten-detail']" data-cy="newFahrkosten">
                  {{existsSessionStorageTextbausteine?
                    textbausteinService.printTextbausteinByKey( KeyString.LINK_FAHRKOSTEN_DETAIL_HOME, ('HOME.LINK_FAHRKOSTEN_DETAIL'|translate) ):('HOME.LINK_FAHRKOSTEN_DETAIL'|translate)}}
                </a>
              </li>
              <li class="listitemhome">
                <a target="_blank" href="https://www.schulministerium.nrw/fragen-und-antworten-zu-schuelerfahrkosten-und-schuelerticket" data-cy="info">
                  {{ "HOME.LINK_GENERAL_INFO" | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </main>

</app-default-layout>
