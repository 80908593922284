import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SchultypDTO} from "../../../models/schultyp/SchultypDTO";
import {SchultypUpdateDTO} from "../../../models/schultyp/SchultypUpdateDTO";
import {SchultypCreateDTO} from "../../../models/schultyp/SchultypCreateDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class SchultypService{

  configUrl = 'api/schultyp';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getSchultypen(): Observable<Array<SchultypDTO>> {
    return this.http.get<Array<SchultypDTO>>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getSchultyp(id: string): Observable<SchultypDTO> {
    return this.http.get<SchultypDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  updateSchultyp(id: number, updateSchultyp: SchultypUpdateDTO): Observable<any> {
    return this.http.patch<SchultypDTO>(
      this.configUrl+'/'+id,
      updateSchultyp,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  createSchultyp(create: SchultypCreateDTO): Observable<any> {
    return this.http.post<SchultypDTO>(
      this.configUrl,
      create,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  deleteSchultyp(id: number): Observable<any>{
    return this.http.delete(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeader(), responseType: 'text' }
    );
  }
}
