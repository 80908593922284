import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {ConfigService} from "./@core/config/services/config.service";

const routesWithoutJwt: Routes = [
  {
    path: 'information',
    loadChildren: () =>
      import('./information/information.module').then((m) => m.InformationModule)
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: 'pages'
  },
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full'
  }
];

const routesWithJwt: Routes = [
  {
    path: 'authentication',
    loadChildren: () =>
      import('./@core/authentication/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'information',
    loadChildren: () =>
      import('./information/information.module').then((m) => m.InformationModule)
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: 'pages'
  },
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routesWithJwt, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private configService: ConfigService,
    private readonly router: Router,
  ) {
    // We need to do this call here because APP_INITIALIZER is not yet initialized
    this.configService.getFeatureConfig().subscribe(featureConfig => {
      if (this.configService?.featureConfig?.kommunalportalAuth) {
        console.log('Routes for kommunalportalAuth set.');
        this.router.resetConfig(routesWithoutJwt);
      } else if (this.configService?.featureConfig?.jwtAuth) {
        console.log('Routes for jwtAuth set.');
        this.router.resetConfig(routesWithJwt);
      } else {
        console.error('Could not load featureConfig. Use default routes for jwtAuth set.');
        this.router.resetConfig(routesWithJwt);
      }

    });
  }
}
