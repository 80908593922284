/**
 * Extracts one object from a list of objects,
 * if its attribute specified by attributeName matches the equivalent attribute
 * of a reference object.
 *
 * Example:
 * const schule = getObjIfExistsInListByAttribute(vorgaenger.schule,this.schuleList,'name') as SchuleDTO
 * >> extracts schule from schuleList, where vorgaenger.schule.name == schuleList.schule.name
 *
 * @param entityInstance      comparable 1
 * @param entityInstanceList  list of potential comparables
 * @param attrName            attribute name by which to compare
 */

export function getObjIfExistsInListByAttribute(entityInstance: any, entityInstanceList: Array<any>, attrName: string) : any {
  const value = !!entityInstance? entityInstance[attrName] || undefined : undefined;
  if( !value || !entityInstanceList || entityInstanceList.length<1 )
    return undefined;
  const match = entityInstanceList.find( item => item[attrName] === value )
  return match || undefined ;
}
