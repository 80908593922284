import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {BenutzerAktivierenDTO} from "../../../models/benutzer/BenutzerAktivierenDTO";
import {BenutzerAktivierungEinladenDTO} from "../../../models/benutzer/BenutzerAktivierungEinladenDTO";
import {BenutzerKennwortAenderungDTO} from "../../../models/benutzer/BenutzerKennwortAenderungDTO";
import {BenutzerMailAenderungDTO} from "../../../models/benutzer/BenutzerMailAenderungDTO";
import {BenutzerCreateKennwortDTO} from "../../../models/benutzer/BenutzerCreateKennwortDTO";
import {BenutzerKennwortVergessenDTO} from "../../../models/benutzer/BenutzerKennwortVergessenDTO";
import {BenutzerUnlockDto} from "../../../models/benutzer/BenutzerUnlockDto";
import {BenutzerCreateDTO} from "../../../models/benutzer/BenutzerCreateDTO";
import {BenutzerUpdateDTO} from "../../../models/benutzer/BenutzerUpdateDTO";

@Injectable({
  providedIn: 'root'
})
export class BenutzerService {

  configUrl = 'api/benutzer';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getMe(): Observable<BenutzerDTO> {
    return this.http.get<BenutzerDTO>(
      this.configUrl+'/me',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getMeExists(): Observable<boolean> {
    return this.http.get<boolean>(
      this.configUrl+'/me-exists',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  activateBenutzer(dto: BenutzerAktivierenDTO){
    return this.http.post<BenutzerDTO>(
      'auth/activate',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  activateBenutzerEinladen(dto: BenutzerAktivierungEinladenDTO){
    return this.http.post<BenutzerDTO>(
      'auth/activateInvitation',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  activateNewPw(dto: BenutzerKennwortAenderungDTO){
    return this.http.post<BenutzerDTO>(
      'auth/activatePwChange',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  activateNewMail(dto: BenutzerMailAenderungDTO){
    return this.http.post<BenutzerDTO>(
      'auth/activateMailChange',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  pwReset(dto: BenutzerCreateKennwortDTO){
    return this.http.post<BenutzerDTO>(
      'auth/resetPassword',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  activatePwReset(dto: BenutzerKennwortVergessenDTO){
    return this.http.post<BenutzerDTO>(
      'auth/activateNewPassword',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  unlock(dto: BenutzerUnlockDto) {
    return this.http.post<any>(
      'auth/unlock',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  createBenutzer(dto: BenutzerCreateDTO){
    return this.http.post<BenutzerDTO>(
      'auth/register',
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  getBenutzer(id: string): Observable<BenutzerDTO> {
    return this.http.get<BenutzerDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }

  updateBenutzer(id: string, updateBenutzer: BenutzerUpdateDTO): Observable<any> {
    return this.http.patch<BenutzerDTO>(
      this.configUrl+'/'+id,
      updateBenutzer,
      { headers: this.headerService.getAuthHeaderApplicationJson() });
  }
}

