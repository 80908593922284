<app-default-layout
  [title]="'Schultyp verwalten'"
>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">

        <div id="create-schultyp" class="content scaffold-create" role="main">
          <form [formGroup]="schultypForm" (ngSubmit)="this.saveOrUpdate()" id="schultypDetailForm"
                class="form-horizontal form-schultypDetail" accept-charset="UTF-8">
            <div class="form-group group-for-name col mb-4">

              <div class="d-flex flex-row" style="margin-bottom: -15px">
                <div class="flex-grow-1 mr-2">

                  <app-custom-input
                    [customFormGroup]="schultypForm"
                    [id]="'name'" [label]="'SCHULTYP-TABLE.COLUMNS.name' | translate">
                    >
                  </app-custom-input>

                </div>
                <div>

                </div>
              </div>
            </div>
            <div class="d-flex flex-row">
        <span class="flex-grow-1">
          <button type="submit" name="create"
                  class="btn btn-primary save btn-block me-3">
            <fa-icon [icon]="faSave"></fa-icon>
            {{'APPLICATION.BUTTON_SPEICHERN'|translate}}
          </button>
          <button type="button" (click)="showDecisionModal()" name="create" class="btn btn-danger">
            <fa-icon [icon]="faDelete"></fa-icon>
            {{'APPLICATION.BUTTON_DELETE'|translate}}
          </button>
        </span>
              <button type="button" name="_action_temp"
                      class="btn btn-outline-primary" [routerLink]="['/pages/schultypen']">
                {{'APPLICATION.BACK_UEBERSICHT'|translate}}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

</app-default-layout>
