import { Component } from '@angular/core';
import {TextbausteinService} from "../../@core/services/textbaustein/textbaustein.service";
import {SessionService} from "../../@core/services/session/session.service";
import {KeyString} from "../../models/textbaustein/KeyString";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent {

  existsSessionStorageTextbausteine: boolean = false;

  constructor(
    public textbausteinService: TextbausteinService,
    private sessionService: SessionService,
    private activeModal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => this.existsSessionStorageTextbausteine = yesNo );
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  defaultText = "Schülerfahrkosten Online ist ein Portal, " +
    "das die Beantragung von Schülerfahrkosten für die Schülerinnen und Schüler " +
    "unter Verwendung zeitgemäßer Technologien vereinfachen soll. " +
    "Die von dem Schüler bzw. der Schülerin besuchte Schule und der Schulträger verwenden für die Weiterverarbeitung " +
    "die durch den Schüler bzw. die Schülerin oder Ihre Erziehungsberechtigten eingegebenen Daten. " +
    "Über das Portal können Zeittickets (Schülermonats- bzw. Schulwegticket) beantragt " +
    "und Anträge auf Erstattung von Schülerfahrkosten gestellt werden. " +
    "Gleichzeitig bietet das Programm die Möglichkeit, " +
    "über den eigenen Account den jeweiligen Antragsstatus zu verfolgen. " +
    "Die Antragstellung erfolgt in elektronischer Form. " +
    "Um einer größtmöglichen Zahl von Schülerinnen und Schülern die Nutzung der elektronischen Beantragung zu ermöglichen, " +
    "wurde auf eine qualifizierte elektronische Signatur bei der Antragstellung verzichtet. " +
    "Bei minderjährigen Schüler:innen muss die Antragstellung durch die Erziehungsberechtigten erfolgen. " +
    "Bei der Registrierung müssen Nutzer:innen diesen Nutzungsbedingungen zustimmen " +
    "und erteilen damit auch die Einwilligung zur Verarbeitung der im Portal bzw. bei der Registrierung genannten Daten " +
    "zur Beantragung von Schülerfahrkosten. " +
    "Erst dann kann die Freischaltung des Accounts erfolgen. " +
    "Die einmalige Zustimmung/Einwilligung gilt dann für alle unter dem Account getätigten Verfahren. " +
    "Die Zustimmung/Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden. " +
    "Der Widerruf muss schriftlich oder per E-Mail an die im Impressum angegebene Adresse erfolgen. " +
    "Für jeden Schüler muss eine eigene E-Mail Adresse zur Verfügung stehen. " +
    "Sollte eine solche nicht vorhanden sein, kann diese problemlos über kostenfreie" +
    " E-Mail-Postfach Anbieter eingerichtet werden. " +
    "Es ist nicht möglich, dass z.B. für 2 Schülerinnen/ Schüler einer Familie jeweils die gleiche E-Mail-Adresse verwendet wird. " +
    "Grundsätzlich erfolgt die Angabe der E-Mail auf freiwilliger Basis. " +
    "Wird jedoch die E-Mail Adresse nicht angegeben, ist eine Teilnahme an dem Onlineverfahren zur Beantragung von" +
    " Schülerfahrkosten nicht möglich. " +
    "Es besteht jedoch in diesen Fällen die Möglichkeit der Teilnahme am manuellen Antragsverfahren. " +
    "Bei Ablehnung eines Antrages erfolgt eine automatisch " +
    "generierte Benachrichtigung an die E-Mail Adresse. " +
    "Die Gründe der Ablehnung sind entweder der Benachrichtigungsemail zu entnehmen " +
    "oder unter dem Antragsstatus nachzulesen. " +
    "Ein gesonderter Bescheid ergeht darüber hinaus nicht mehr. " +
    "Wir, der Schulträger, haben die Software in Zusammenarbeit mit der regio iT entwickelt. "+
    "Die regio iT als kommunales Rechenzentrum mit Sitz in Aachen, Gütersloh und Siegburg " +
    "hat in ihrem Verantwortungsbereich alle notwendigen technischen und organisatorischen Maßnahmen ergriffen, "+
    "um die Daten vor unberechtigtem Zugriff zu schützen. " +
    "Einige dieser Maßnahmen sind auf Anhieb zu erkennen: Verschlüsselung des Datenverkehrs, " +
    "Zugang zum System nur durch Passwort. "+
    "Zu dem internen Sicherheitskonzept gehört aber auch, es nicht offenzulegen. " +
    "Das Informationssicherheits-Managementsystem der regio iT ist bereits im Jahr 2009 "+
    "nach ISO 27001 (einer internationalen Sicherheits-Norm) zertifiziert worden. " +
    "Es ist gewährleistet, dass sich die Datenverarbeitung ausschließlich " +
    "nach den Rechtsnormen der EU-Datenschutzgrundverordnung, "+
    "der Bundesrepublik Deutschland und dem Landes-Schulgesetz " +
    "in Verbindung mit dem Datenschutzgesetz des Landes NRW richtet. " +
    "Datenverarbeitende Stelle ist jedoch nicht die regio iT, sondern der Schulträger. "+
    "Die Daten bleiben in ausschließlicher Verantwortung des Schulträger. " +
    "Nur Personen, zu deren Aufgabe die Verarbeitung der Daten gehört, haben Zugriff darauf. "+
    "Sie sind, wie alle Mitarbeiter des öffentlichen Dienstes, auf das Datengeheimnis dienstverpflichtet. " +
    "Die zur Ticketbeantragung notwendigen erhobenen personenbezogenen Daten "+
    "werden durch den Schulträger erhoben und gespeichert. " +
    "Diese Daten werden 5 Jahre nach Abschluss der Schullaufbahn des Schülers bzw. der Schülerin gelöscht. "+
    "Angaben zur Schwerbehinderung werden nur benötigt, " +
    "wenn der Schüler bzw die Schülerin einen Schwerbehindertenausweis mit dem Merkzeichen \"G\" besitzt. "+
    "Diese Angabe wird ausschließlich genutzt, " +
    "um Schüler:innen bzw. deren Erziehungsberechtigte über mögliche günstigere Ticketvarianten zu beraten. "+
    "Diese Daten verbleiben beim Schulträger. " +
    "Die zur Bestellung von Schülerzeitkarten notwendigen Schülerdaten werden an Servicegesellschaften von Verkehrsunternehmen " +
    "zur Ausstellung der Tickets weitergeleitet. "+
    "Nur Personen, zu deren Aufgabe die Verarbeitung der Daten gehört, haben Zugriff darauf. " +
    "Die Daten werden dort ausschließlich zur Ausstellung der Schülerzeitkarten verwendet. "+
    "Schüler:innen eines Berufskollegs müssen einen Eigenanteil zur Schülerzeitkarte bezahlen. " +
    "Der Eigenanteil wird durch die Schüler:innen bzw. deren Erziehungsberechtigte "+
    "auf ein eigens hierfür durch den Schulträger eingerichtetes Bankkonto überwiesen. " +
    "Die Einzahlungen werden elektronisch eingelesen und den einzelnen Datensätzen der Schüler:innen zugewiesen. "+
    "Hierzu werden die Angaben des Kontoinhabers von dessen Konto der Eigenanteil gezahlt wurde, " +
    "sowie die entsprechende Kontoverbindung für max. 18 Monate gespeichert. "+
    "Die Speicherung erfolgt, um die Einzahlung dem jeweiligen Schülerdatensatz zuordnen zu können " +
    "und um Rückzahlungen, z.B. bei vorzeitiger Rückgabe von Schülerzeitkarten, leisten zu können. "+
    "Als Nutzer:in dieses Portals haben Sie das Recht, " +
    "Auskunft über die konkret zu Ihrer Person gespeicherten Daten zu verlangen. "+
    "Hierzu wenden Sie sich bitte schriftlich oder per E-Mail an die im Impressum angegebene Adresse. "+
    "Bei der Registrierung müssen Nutzer:innen dieser Datenschutzerklärung zustimmen " +
    "und willigen damit in die Verarbeitung ihrer Daten ein. "+
    "Die Zustimmung/Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden. "+
    "Der Widerruf muss schriftlich oder per E-Mail an die im Impressum angegebene Adresse erfolgen. "+
    "Unter den in Art. 15 ff. EU-Datenschutzgrundverordnung genannten Voraussetzungen " +
    "können Sie Auskunft über die erhobenen Daten, "+
    "die Berichtigung unrichtig oder unrichtig gewordener Daten, " +
    "die Löschung, die Einschränkung der Verarbeitung, die Übermittlung der Daten in einem strukturierten, "+
    "gängigen und maschinenlesbaren Format beantragen und Widerspruch gegen die Verarbeitung erheben. "+
    "Darüber hinaus können Sie bei der Landesbeauftragten für Datenschutz und Informationsfreiheit (LDI NRW), Postfach 20 04 44, 40102 Düsseldorf, "+
    "Tel. 0211/38424-0, Fax: -10, E-Mail: poststelle@ldi.nrw.de; Internet: www.ldi.nrw.de Beschwerde einlegen.";

    protected readonly KeyString = KeyString;
}
