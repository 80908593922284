import {Injectable} from "@angular/core";
import {FieldConfigService} from "./field-config.service";
import {FieldConfigDTO} from "../../../models/field-config/FieldConfigDTO";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FieldConfigSessionService {

  constructor(
    private fieldConfigService: FieldConfigService
  ) {
    this.initFieldConfigs();
  }

  initFieldConfigs(): void {
    this.fieldConfigService.getFieldConfigs().subscribe((dtoList: FieldConfigDTO[]) => {
      sessionStorage.setItem('field-config', JSON.stringify(dtoList));
      this.broadcastFieldConfigChange();
    });
  }

  // exposed observables

  private sessionStorageFieldConfig = new BehaviorSubject<FieldConfigDTO[]>(JSON.parse(sessionStorage.getItem('field-config')) as FieldConfigDTO[]);

  watchSessionStorageFieldConfig () : Observable<FieldConfigDTO[]> {
    return this.sessionStorageFieldConfig.asObservable();
  }
  broadcastFieldConfigChange () {
    this.sessionStorageFieldConfig.next(JSON.parse(sessionStorage.getItem('field-config')) as FieldConfigDTO[]);
  }

}
