import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SchuleDTO} from "../../../models/schule/SchuleDTO";
import {CreateSchuleDTO} from "../../../models/schule/CreateSchuleDTO";
import {UpdateSchuleDTO} from "../../../models/schule/UpdateSchuleDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {SchuleMinimalDTO} from "../../../models/schule/SchuleMinimalDTO";

@Injectable({
  providedIn: 'root'
})
export class SchuleService {

  configUrl = 'api/schule';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getSchulen(filterArchived: boolean): Observable<Array<SchuleDTO>> {
    return this.http.get<Array<SchuleDTO>>(
      this.configUrl+'/bulk?filterArchived='+filterArchived,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getSchule(id: string): Observable<SchuleDTO> {
   return this.http.get<SchuleDTO>(
     this.configUrl+'/'+id,
     { headers: this.headerService.getAuthHeaderApplicationJson() }
   );
  }

  createSchule(create: CreateSchuleDTO): Observable<any> {
    return this.http.post<SchuleDTO>(
      this.configUrl,
      create,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  deleteSchule(id: number):Observable<any>{
    return this.http.delete(
      this.configUrl+'/'+id,
      {headers: this.headerService.getAuthHeader(), responseType: 'text'}
    );
  }

  updateSchule(id: number, dto: UpdateSchuleDTO): Observable<any> {
     return this.http.patch<SchuleDTO>(
       this.configUrl+'/'+id,
       dto,
       { headers: this.headerService.getAuthHeaderApplicationJson() }
     );
  }

  getSchuleListBulk(): Observable<Array<SchuleDTO>> {
    return this.http.get<Array<SchuleDTO>>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getSchulenForRegistration(): Observable<Array<SchuleMinimalDTO>> {
    return this.http.get<Array<SchuleMinimalDTO>>(this.configUrl+'/bulk-minimal');
  }

}
