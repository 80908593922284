<app-default-layout
  [title]="'REGISTRIERUNG.TITLE'"
>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <form [formGroup]="registrierungForm" (ngSubmit)="this.create()" class="form-horizontal"
            accept-charset="UTF-8">

        <div class="form-group group-for-name col mb-3"
             *ngIf="isVisible('name')"
        >
          <div class="d-flex flex-row" >
            <div class="flex-grow-1">
              <app-custom-input id="name"
                                type="text"
                                [customFormGroup]="registrierungForm"
                                [label]="getFieldLabelOrTranslate('name','REGISTRIERUNG.NAME')"
              ></app-custom-input>
            </div>
          </div>
        </div>

        <div class="form-group group-for-vorname col mb-3"
             *ngIf="isVisible('vorname')"
        >
          <div class="d-flex flex-row" >
            <div class="flex-grow-1">
              <app-custom-input id="vorname"
                                type="text"
                                [customFormGroup]="registrierungForm"
                                [label]="getFieldLabelOrTranslate('vorname','REGISTRIERUNG.VORNAME')"
              ></app-custom-input>
            </div>
          </div>
        </div>

        <div class="form-group group-for-schuleId col mb-3"
             *ngIf="isVisible('schuleId')"
        >
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <app-custom-select id="schuleId"
                                 [multiple]="false"
                                 [customFormGroup]="registrierungForm"
                                 [label]="getFieldLabelOrTranslate('schuleId','STANDARD.SCHULE')"
                                 [items]="schuleList"
                                 bindLabel="name"
                                 bindValue="id"
              ></app-custom-select>
            </div>
          </div>
        </div>

        <div class="form-group group-for-geburtsdatum col mb-3"
             *ngIf="isVisible('geburtsdatum')"
        >
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <app-custom-input id="geburtsdatum"
                                type="date"
                                [customFormGroup]="registrierungForm"
                                [label]="getFieldLabelOrTranslate('geburtsdatum','REGISTRIERUNG.BDAY')"
                                data-content="dd.mm.yyyy"
                                [max]="this.maxDate | date:'yyyy-MM-dd'"
                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                [infoTextBelowInput]="getTextbausteinOrTranslate(KeyString.GEBURTSDATUM_INFO_BELOW,'')"
              ></app-custom-input>
            </div>
          </div>
        </div>

        <div class="form-group group-for-email col mb-3"
             *ngIf="isVisible('email')"
        >
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <app-custom-input id="email"
                                type="text"
                                [customFormGroup]="registrierungForm"
                                [label]="getFieldLabelOrTranslate('email','REGISTRIERUNG.MAIL')"
              ></app-custom-input>
              <div class="formcontrol-error-infotext" *ngIf="registrierungForm?.controls?.email?.invalid">
                {{ 'AUTHENTICATION.FORM_ERRORS.EMAIL_INVALID' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-group group-for-emailbestaetigung col mb-3"
             *ngIf="isVisible('emailbestaetigung')"
        >
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <app-custom-input id="emailbestaetigung"
                                type="text"
                                [customFormGroup]="registrierungForm"
                                [label]="getFieldLabelOrTranslate('emailbestaetigung','REGISTRIERUNG.MAIL-CONFIRMATION')"
              ></app-custom-input>
              <div class="formcontrol-error-infotext"
                   *ngIf="registrierungForm?.get('emailbestaetigung')?.errors"
              >
                {{ registrierungForm?.get('emailbestaetigung')['infotextKey'] | translate }}
                <!-- error generated by angular's Validators.email, if format is wrong : -->
                <div *ngIf="registrierungForm?.get('emailbestaetigung')?.errors?.email">
                  {{ 'AUTHENTICATION.FORM_ERRORS.EMAIL_INVALID' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group group-for-kennwort col mb-3"
             *ngIf="isVisible('kennwort')"
        >
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <app-custom-input id="kennwort"
                                type="text"
                                [type] = "pwHide? 'password':'text'"
                                [customFormGroup]="registrierungForm"
                                [label]="getFieldLabelOrTranslate('kennwort','REGISTRIERUNG.PW')"
                                [infoTextBelowInput]="translateService.instant('AUTHENTICATION.FORM_ERRORS.PASSWORD_REQUIREMENTS')"
                                data-cy="kennwort"
              ></app-custom-input>
              <div class="formcontrol-error-infotext"
                   *ngIf="registrierungForm?.get('kennwort')?.errors"
                   data-cy="kennwort-error">
                {{ registrierungForm?.get('kennwort')['infotextKey'] | translate }}
                {{ registrierungForm?.get('kennwort')['lengthKey'] | translate }}
                {{ registrierungForm?.get('kennwort')['numKey'] | translate }}
                {{ registrierungForm?.get('kennwort')['upperKey'] | translate }}
                {{ registrierungForm?.get('kennwort')['lowerKey'] | translate }}
                {{ registrierungForm?.get('kennwort')['specialKey'] | translate }}
              </div>
            </div>
            <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:100"
                      [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                      [title]="pwHide? translateService.instant('LOGIN.PW-SHOW'):translateService.instant('LOGIN.PW-HIDE')"
                      data-cy="btn-pwhide"
            >
            </fa-icon>
          </div>
        </div>

        <div class="form-group group-for-kennwortbestaetigung col mb-3"
             *ngIf="isVisible('kennwortbestaetigung')"
        >
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <app-custom-input id="kennwortbestaetigung"
                                type="text"
                                [type] = "pwHide? 'password':'text'"
                                [customFormGroup]="registrierungForm"
                                [label]="getFieldLabelOrTranslate('kennwort','REGISTRIERUNG.PW-CONFIRMATION')"
                                data-cy="kennwortbestaetigung"
              ></app-custom-input>
              <div class="formcontrol-error-infotext"
                   *ngIf="registrierungForm?.controls?.kennwortbestaetigung?.errors"
                   data-cy="kennwortbestaetigung-error"
              >
                {{ registrierungForm?.get('kennwortbestaetigung')['infotextKey'] | translate }}
              </div>
            </div>
            <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                      [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                      [title]="pwHide? translateService.instant('LOGIN.PW-SHOW'):translateService.instant('LOGIN.PW-HIDE')"
                      data-cy="btn-pwhide-repeat"
            >
            </fa-icon>
          </div>
        </div>

        <div class="form-group group-for-nutzungsbedingungen col mb-3">
          <div class="custom-control-inline custom-checkbox group-for-accountExpired d-flex flex-row">
            <label class="checkbox-container">
              {{getFieldLabelOrTranslate('nutzungsbedingungen','REGISTRIERUNG.CHECKBOX1')}}
              <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="nutzungsbedingungen"
                     id="nutzungsbedingungen"
                     name="nutzungsbedingungen"
              >
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="form-group group-for-datenschutz col mb-3">
          <div class="custom-control-inline custom-checkbox group-for-accountExpired d-flex flex-row">
            <label class="checkbox-container">
              {{getFieldLabelOrTranslate('datenschutz','REGISTRIERUNG.CHECKBOX2')}}
              <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="datenschutz"
                     id="datenschutz"
                     name="datenschutz"
              >
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <button type="submit"
                class="btn btn-primary save btn-block me-3">
          {{'APPLICATION.BUTTON_REGISTRIEREN'|translate}}
        </button>
        <button type="button" class="btn btn-secondary btn-block me-3" (click)="openDialog()">Datenschutzerklärung lesen</button>
        <button type="button" class="btn btn-secondary btn-block me-3" [routerLink]="['/authentication/login']">Zurück zum Login</button>
      </form>
    </div>
  </div>
  <br/>
</app-default-layout>
