import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {Observable} from "rxjs";
import {AccountDTO} from "../../../models/account/AccountDTO";
import {EmailChangeRequestDTO} from "../../../models/account/EmailChangeRequestDTO";
import {PasswordChangeRequestDTO} from "../../../models/account/PasswordChangeRequestDTO";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  configUrl = 'api/account';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getBenutzerAccount(id: number): Observable<AccountDTO> {
    return this.http.get<AccountDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  requestEmailChange(id: number, dto: EmailChangeRequestDTO) {
    return this.http.patch<AccountDTO>(
      this.configUrl+'/email-change/'+id, dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  requestPwChange(id: number, dto: PasswordChangeRequestDTO) {
    return this.http.patch<AccountDTO>(
      this.configUrl+'/pw-change/'+id, dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

}
