<div class="d-flex flex-row" *ngIf="!(!beleg)">
  <div *ngIf="!readonly" class="col-sm-auto">
    <div class="image-upload"
         style="display: inline-block;">
      <label [for]="'fileInput '+beleg.kostenId+' '+beleg.belegId"
             title="{{'FAHRKOSTEN-DETAIL.DATEIEN.TEIL-HINZUFUEGEN'|translate}}">
        <fa-icon [icon]=moreBelegIcon
                 class="fa-l custom-card-btn-morebeleg"
        ></fa-icon>
      </label>
      <input [id]="'fileInput '+beleg.kostenId+' '+beleg.belegId" type="file" style="width:0;height:0;"
             [accept]="allowedNachweisFormats"
             (change)="addBelegSeite($event)"/>
    </div>
    <div>
      <fa-icon
        [icon]=deleteBelegIcon
        class="fa-l custom-card-btn-deletebeleg"
        title="{{'FAHRKOSTEN-DETAIL.DATEIEN.ENTFERNEN'|translate}}"
        (click)="deleteBeleg()"
      ></fa-icon>
    </div>
  </div>
  <div  *ngFor="let dateiFahrkosten of beleg?.seiten"
        id="thumb">
    <img *ngIf="dateiFahrkosten.contentType.indexOf('pdf')<0"
         data-ot-ignore
         [src]="beleg.sources[dateiFahrkosten.seiteId-1]"
         (click)="previewImg(beleg.sources[dateiFahrkosten.seiteId-1])"
         [alt]="dateiFahrkosten.contentType"
         class="img-thumbnail mr-2"
         data-cy="belegeThumb"
    />
    <img *ngIf="dateiFahrkosten.contentType.indexOf('pdf')>=0"
         data-ot-ignore
         [src]="sanitize('../../../../../assets/images/icons/pdf-icon.svg')"
         (click)="previewPDF(beleg.sources[dateiFahrkosten.seiteId-1])"
         [alt]="dateiFahrkosten.contentType"
         class="img-thumbnail mr-2"
         data-cy="belegePDF"
    />
  </div>
</div>
