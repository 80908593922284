import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private zone: NgZone, private injector: Injector) {}

	handleError(error: any) {
		this.zone.run(() => {});
		// Not needed right now, but an example how to inject is useful to resolve 'circular dependency in DI detected...' errors!
		// const store = this.injector.get(Store);
		console.log('Error from global error handler', error);
	}
}
