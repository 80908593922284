import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class CompositMessageService {

  constructor(public translateService: TranslateService) {}

  textbausteinSpecialChars(){
    return this.translateService.instant('TEXTBAUSTEINE.MSG_SONDERZEICHEN');
  }

  textbausteinSpecialCharsErr(){
    return this.translateService.instant('TEXTBAUSTEINE.ERR_SONDERZEICHEN');
  }

  textbausteinGoodSyntax(){
    return this.translateService.instant('TEXTBAUSTEINE.MSG_SYNTAX');
  }

  textbausteinBadSyntax(){
    return this.translateService.instant('TEXTBAUSTEINE.ERR_SYNTAX');
  }

  textbausteinSafe(){
    return this.translateService.instant('TEXTBAUSTEINE.MSG_SAFETY');
  }

  textbausteinUnsafe(){
    return this.translateService.instant('TEXTBAUSTEINE.ERR_SAFETY');
  }

  createIncompleteMessage(entityTranslationKey: string){
    let entityNameTranslated: string = this.translateService.instant('COMPOSITE-MESSAGE.DERDIEDAS.'+entityTranslationKey);
    return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.UNVOLLSTAENDIG');
  }

  createSuccessMessage(entityTranslationKey: string, selector: string) : string {
    let entityNameTranslated: string = this.translateService.instant('COMPOSITE-MESSAGE.DERDIEDAS.'+entityTranslationKey);
    switch (selector) {
      case 'benutzereinladen': return this.translateService.instant('COMPOSITE-MESSAGE.EMAIL-VERSCHICKT');
      case 'post': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.GESPEICHERT');
      case 'patch': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.AKTUALISIERT');
      case 'delete': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.GELOESCHT');
      default: return '';
    }
  }

  createDeleteWarning(entityTranslationKey: string) : string {
    let entityNameTranslated: string = this.translateService.instant('COMPOSITE-MESSAGE.DIESE.'+entityTranslationKey);
    return this.translateService.instant('COMPOSITE-MESSAGE.MOECHTEN-SIE')+entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.WIRKLICH-LOESCHEN');
  }

  registrationFailed(err: any){
    switch(err.status) {
      case 409: {
        if (err.error.message.includes ('user_already_exists')) {
          return this.translateService.instant('COMPOSITE-MESSAGE.ALREADY_EXISTS');
        } else if (err.error.message.includes ('user_too_young')) {
          return this.translateService.instant('COMPOSITE-MESSAGE.TOO_YOUNG');
        } else {
          return this.translateService.instant('COMPOSITE-MESSAGE.REGISTER_FAIL');
        }
      }
      default: {
        return this.translateService.instant('COMPOSITE-MESSAGE.REGISTER_FAIL');
      }
    }
  }

  registrationSucceeded(){
    return this.translateService.instant('COMPOSITE-MESSAGE.REGISTER_SUCCESS');
  }

  createErrorMessage(err: any, entityTranslationKey: string, selector: string) : string {
    let entityNameTranslated: string;
    if(selector==='getBulk'){
      entityNameTranslated = this.translateService.instant('COMPOSITE-MESSAGE.PLURAL.'+entityTranslationKey);
    } else {
      entityNameTranslated = this.translateService.instant('COMPOSITE-MESSAGE.DERDIEDAS.'+entityTranslationKey);
    }
    switch(err.status) {
      case 500: {
        switch(selector){
         /* case 'post':  return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NAME_NOT_UNIQUE');
          case 'patch': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NAME_NOT_UNIQUE');
          case 'get':   return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.COULD_NOT_GET');
          case 'getBulk': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.COULD_NOT_GET_BULK');*/
          default: return 'error 500: '+this.translateService.instant('COMPOSITE-MESSAGE.UNKNOWN_ERROR');
        }
      }
      case 413: {
        switch(selector){
          case 'post': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.FILESIZE_TOO_BIG');
          case 'patch': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.FILESIZE_TOO_BIG');
          default: return 'error 413: '+this.translateService.instant('COMPOSITE-MESSAGE.UNKNOWN_ERROR');
        }
      }
      case 409: {
        switch(selector){
          case 'delete': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELOESCHT-REFERENZ');
          default: {
            if (err.error.message.includes ('pw_already_exists')) {
              return this.translateService.instant('COMPOSITE-MESSAGE.PASSWORD_EXISTS');
            } else {
              return 'error 409: ' + this.translateService.instant('COMPOSITE-MESSAGE.UNKNOWN_ERROR');
            }
          }
        }
      }
      case 407: {
        switch(selector){
          case 'benutzereinladen': return this.translateService.instant('COMPOSITE-MESSAGE.EMAIL-FEHLT');
          default: return 'error 407: '+this.translateService.instant('COMPOSITE-MESSAGE.UNKNOWN_ERROR');
        }
      }
      case 404:
        return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GEFUNDEN');
      case 403: {
        switch(selector){
          case 'post': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GESPEICHERT-BERECHTIGUNG');
          case 'patch': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-AKTUALISIERT-BERECHTIGUNG');
          case 'delete': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELOESCHT-BERECHTIGUNG');
          case 'get': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELADEN-BERECHTIGUNG');
          case 'getBulk': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELADEN-BULK-BERECHTIGUNG');
          default: return 'error 403: '+this.translateService.instant('COMPOSITE-MESSAGE.UNKNOWN_ERROR');
        }
      }
      case 401: {
        switch(selector){
          case 'post': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GESPEICHERT-BERECHTIGUNG');
          case 'patch': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-AKTUALISIERT-BERECHTIGUNG');
          case 'delete': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELOESCHT-BERECHTIGUNG');
          case 'get': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELADEN-BERECHTIGUNG');
          case 'getBulk': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELADEN-BULK-BERECHTIGUNG');
          default: return 'error 401: '+this.translateService.instant('COMPOSITE-MESSAGE.UNKNOWN_ERROR');
        }
      }
      default:  {
        switch(selector){
          case 'post': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GESPEICHERT');
          case 'patch': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-AKTUALISIERT');
          case 'delete': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELOESCHT');
          case 'get': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELADEN');
          case 'getBulk': return entityNameTranslated+this.translateService.instant('COMPOSITE-MESSAGE.NICHT-GELADEN-BULK');
          default: return 'error '+err.status+': '+this.translateService.instant('COMPOSITE-MESSAGE.UNKNOWN_ERROR');
        }
      }
    }
  }

}
