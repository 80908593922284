<!--add some space so footer never overlaps anything-->
<div style="height: 150px"></div>
<footer class="text-center text-lg-start bg-light text-muted">

  <div class="bottom-footer fixed-bottom" style="background-color: rgb(243,243,243);">
    <div class="d-flex flex-row justify-content-center gap-3">
      <div class="p-2" style=margin-left:3rem>
        © {{ currYear }} Copyright:
        <a class="text-reset fw-bold" href="https://www.regioit.de/">regio iT</a>
        Gesellschaft für Informationstechnologie mbH
      </div>
      <div class="p-2" style=margin-left:3rem>
        {{'APPLICATION.FOOTER.VERSION'|translate}}: {{version}}
      </div>
      <div class="ms-auto p-2">
        <a class="text-reset fw-bold" style=text-decoration:none [routerLink]="['/pages/home']"
           data-cy="nav-item-home">{{'APPLICATION.NAVBAR.HOME'|translate}}</a>
      </div>
      <div class="p-2">
        <a  class="text-reset fw-bold" style=text-decoration:none [routerLink]="['/information/impressum']"
            data-cy="nav-item-impressum">{{'APPLICATION.FOOTER.IMPRESSUM'|translate}}
        </a>
      </div>
      <div class="p-2" style=margin-right:3rem>
        <a class="text-reset fw-bold" style=text-decoration:none [routerLink]="['/information/datenschutz']"
           data-cy="nav-item-datenschutz">{{'APPLICATION.FOOTER.DATENSCHUTZ'|translate}}</a>
      </div>
    </div>
  </div>

</footer>
