import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FahrzeugtypDTO} from "../../../models/fahrzeugtyp/FahrzeugtypDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class FahrzeugtypService{

  configUrl = 'api/fahrzeugtyp';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getFahrzeugtypen(): Observable<Array<FahrzeugtypDTO>> {
    return this.http.get<Array<FahrzeugtypDTO>>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

}
