import {FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export const accountEmailChangeValidator: ValidatorFn = (fg: FormGroup) : ValidationErrors | null => {

  // this is a validator that applies to entire form,
  // as multiple formcontrols are needed for validation

  const current = fg.get('email')?.value;
  const novel = fg.get('emailNeu')?.value;
  const novelRepeat = fg.get('emailNeuRepeat')?.value;

  if(!current)
    return { currentEmailMissing: true };

  if(!novel) {
    fg.get('emailNeu')?.setErrors({newEmailMissing: true});
    fg.get('emailNeu')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.EMAIL_NEU_MISSING";
    return {newEmailMissing: true};
  }
  if(fg.get('emailNeu')?.errors && fg.get('emailNeu')?.errors['newEmailMissing'])
    delete fg.get('emailNeu')?.errors['newEmailMissing'];
  fg.get('emailNeu')['infotextKey'] = undefined;

  let modCurr = current?.trim().toLowerCase();
  let modNov = novel?.trim().toLowerCase();
  if(modNov === modCurr) {
    fg.get('emailNeu')?.setErrors({duplicateEmail: true});
    fg.get('emailNeu')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.EMAIL_DUPLICATE";
    return {duplicateEmail: true};
  }
  if(fg.get('emailNeu')?.errors && fg.get('emailNeu')?.errors['duplicateEmail'])
    delete fg.get('emailNeu')?.errors['duplicateEmail'];
  fg.get('emailNeu')['infotextKey'] = undefined;

  if(!novelRepeat){
    fg.get('emailNeuRepeat')?.setErrors({newEmailRepeatMissing: true});
    fg.get('emailNeuRepeat')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.EMAIL_NEU_REPEAT_MISSING";
    return {newEmailRepeatMissing: true};
  }
  if(fg.get('emailNeuRepeat')?.errors && fg.get('emailNeuRepeat')?.errors['newEmailRepeatMissing'])
    delete fg.get('emailNeuRepeat')?.errors['newEmailRepeatMissing'];
  fg.get('emailNeuRepeat')['infotextKey'] = undefined;

  if(novel !== novelRepeat) {
    fg.get('emailNeuRepeat')?.setErrors({newEmailMismatch: true});
    fg.get('emailNeuRepeat')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.EMAIL_MISMATCH";
    return {newEmailMismatch: true};
  }
  if(fg.get('emailNeuRepeat')?.errors && fg.get('emailNeuRepeat')?.errors['newEmailMismatch'])
    delete fg.get('emailNeuRepeat')?.errors['newEmailMismatch'];
  fg.get('emailNeuRepeat')['infotextKey'] = undefined;

  return null;

}
