<app-default-layout
  [title]="this.getTitle()"
  [subtitle]="this.getSubTitle()"
>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">

        <div id="create-schuleKoordinate" class="content scaffold-create" role="main">

          <form [formGroup]="koordinatenForm" (ngSubmit)="this.saveOrUpdate()" class="form-horizontal"
                accept-charset="UTF-8">

            <div class="form-group group-for-lat col mb-4">

              <div class="flex-grow-1 mr-2">
                <app-custom-input-german-decimal id="lat"
                                                 [customFormGroup]="koordinatenForm"
                                                 [label]="'SCHULE-KOORDINATE.lat' | translate "
                                                 [placeholder]="'0,000000'"
                                                 [numDigits]="6"
                                                 [nullable]="true"
                                                 data-cy="lat"
                ></app-custom-input-german-decimal>
              </div>
              <div class="formcontrol-error-infotext"
                   *ngIf="this.koordinatenForm.get('lat').invalid && (this.koordinatenForm.get('lat').dirty || this.koordinatenForm.get('lat').touched)"
              >
                <div *ngIf="this.koordinatenForm.get('lat').errors?.['required']">
                  Breitengrad ist Pflichtfeld
                </div>
                <div *ngIf="this.koordinatenForm.get('lat').errors?.['pattern']">
                  Keine gültigen Koordinaten
                </div>
              </div>

            </div>

            <div class="form-group group-for-lon col mb-4">

              <div class="flex-grow-1 mr-2">
                <app-custom-input-german-decimal id="lon"
                                                 [customFormGroup]="koordinatenForm"
                                                 [label]="'SCHULE-KOORDINATE.lon' | translate "
                                                 [placeholder]="'0,000000'"
                                                 [numDigits]="6"
                                                 [nullable]="true"
                                                 data-cy="lon"
                ></app-custom-input-german-decimal>
              </div>
              <div class="formcontrol-error-infotext"
                   *ngIf="this.koordinatenForm.get('lon').invalid && (this.koordinatenForm.get('lon').dirty || this.koordinatenForm.get('lon').touched)"
              >
                <div *ngIf="this.koordinatenForm.get('lon').errors?.['pattern']">
                  {{'SCHULE-KOORDINATE.ERROR_KOORDINATEN' | translate}}
                </div>
                <div *ngIf="this.koordinatenForm.get('lon').errors?.['required']">
                  {{'SCHULE-KOORDINATE.ERROR_REQUIRED' | translate}}
                </div>
              </div>

            </div>
            <!-- Maps -->


            <div class="row">
              <div *ngIf="route.snapshot.paramMap.get('id') && route.snapshot.paramMap.get('id') !== '' && route.snapshot.paramMap.get('id') !== null">
                <app-geo-map *ngIf="coordinateList !== undefined && selectedCoordinate !== undefined"
                             [coordinateList]="coordinateList"
                             [selectedCoordinate]="selectedCoordinate"
                             [isNewMarkerPossible]="true"
                             (getLatLon)="setLatLon($event)"></app-geo-map>
              </div>
              <div *ngIf="!route.snapshot.paramMap.get('id') || route.snapshot.paramMap.get('id') === '' || route.snapshot.paramMap.get('id') === null">
                <app-geo-map *ngIf="coordinateList !== undefined"
                             [coordinateList]="coordinateList"
                             [isNewMarkerPossible]="true"
                             (getLatLon)="setLatLon($event)"></app-geo-map>
              </div>

            </div>

            <div class="d-flex flex-row">
          <span class="flex-grow-1">
              <input type="submit"
                     [disabled]="this.koordinatenForm.invalid"
                     class="btn btn-primary save"
                     [value]="new()? ('APPLICATION.BUTTON_ANLEGEN' |translate ): ('APPLICATION.BUTTON_SPEICHERN' |translate)"
                     id="create"
                     data-cy="save">

              <a *ngIf="!new()" class="delete btn btn-danger"
                 (click)="showDecisionModal()">{{'APPLICATION.BUTTON_DELETE' |translate}}</a>

          </span>

              <a (click)="backClicked()" class="btn btn-secondary" data-cy="backButton">
                {{'SCHULE-KOORDINATE.BACK_BUTTON' |translate}}
              </a>

            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</app-default-layout>
