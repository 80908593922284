import {Component} from '@angular/core';
import {BenutzerSessionService} from "../@core/services/benutzer/benutzer-session.service";
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../@core/config/services/config.service";

@Component({
	selector: 'app-sfk-pages',
	template: `
		<main role="main">
			<router-outlet></router-outlet>
		</main>`
})
export class PagesComponent {

  constructor(
    private route: ActivatedRoute,
    private benutzerSessionService: BenutzerSessionService,
    private configService: ConfigService
  ) {
    if (this.configService.featureConfig.kommunalportalAuth) {
      this.route.queryParams.subscribe(params => {
        if (params?.authToken !== undefined) {
          sessionStorage.setItem('authToken', params.authToken);
          sessionStorage.setItem('mode', params.mode);
          sessionStorage.setItem('portalUrl', params.portalUrl);
          this.benutzerSessionService.initBenutzerSession();
        }
      });
    } else if (this.configService.featureConfig.jwtAuth) {
      this.benutzerSessionService.initBenutzerSessionJwt();
    }
  }

}
