import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SchuleService} from "../../../@core/services/schule/schule.service";
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {faPlus, faSort} from "@fortawesome/free-solid-svg-icons";
import {CompositMessageService} from "../../../@core/services/compositMessage/compositMessage.service";
import {EMPTY} from "rxjs";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {nullLastComparator} from "../../../@core/utils/forms/null-last-comparator";

@Component({
  selector: 'app-schulen',
  templateUrl: './schulen.component.html',
  styleUrls: ['./schulen.component.css']
})
export class SchulenComponent implements OnInit {

  faPlus = faPlus;
  sortInitial = faSort;

  public columns: any;
  public rows: any;

  isLoading: boolean = false;
  dataFetched: boolean = false;

  entityNameTranslationKey: string = 'SCHULE';

  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;

  constructor(
    private router: Router,
    private schuleService: SchuleService,
    private customToastService: CustomToastService,
    private compositMessageService: CompositMessageService
  ) { }

  ngOnInit(): void {

    this.columns = [
      { name: 'name', headerTemplate: this.hdrTpl, comparator: nullLastComparator.bind(this)  },
    ];

    this.reloadTable();

  }

  onActivate(event) {
    if(event.type == 'click') {
      this.router.navigate(['/pages/schulenDetail/'+event.row.id ]).catch(()=>{});
    }
  }

  reloadTable() {
    this.isLoading = true;
    this.dataFetched = false;
    this.schuleService.getSchuleListBulk()
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'getBulk'));
          this.isLoading = false;
          this.dataFetched = null;
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.rows = data;
        this.isLoading = false;
        this.dataFetched = this.rows.length>0;
      });
  }

}
