import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ModuleConfigCreateOrUpdateDto} from "../../../models/module-config/ModuleConfigCreateOrUpdateDto";
import {ModuleConfigDto} from "../../../models/module-config/ModuleConfigDto";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class ModuleConfigService {

  configUrl = 'api/module-config';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  createOrUpdateModuleConfig(dto: ModuleConfigCreateOrUpdateDto): Observable<any> {
    return this.http.post(
      this.configUrl,
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getModuleConfigList(): Observable<any> {
    return this.http.get<ModuleConfigDto>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  isModuleEnabled(module: string): boolean {
    if (sessionStorage.getItem('module-config')) {
      const moduleConfigList: ModuleConfigDto[] = JSON.parse(sessionStorage.getItem('module-config'));
      for (let moduleConfig of moduleConfigList) {
        if (moduleConfig.module === module && moduleConfig.enabled) {
          return true;
        }
      }
    }
    return false;
  }

}
