import {FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export const registrationPwRepeatValidator: ValidatorFn = (fg: FormGroup) : ValidationErrors | null => {

  // this is a validator that applies to entire form,
  // as multiple formcontrols are needed for validation

  const kennwort = fg.get('kennwort')?.value;
  const kennwortbestaetigung = fg.get('kennwortbestaetigung')?.value;

  if(!kennwort)
    return null;    // missing values should be handled by Validators.required

  if(!kennwortbestaetigung) {
    fg.get('kennwortbestaetigung')?.setErrors({pwRepeatMissing: true});
    fg.get('kennwortbestaetigung')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.PASSWORD_CHANGE_REPEAT_MISSING";
    return {pwRepeatMissing: true};
  } // else remove these specific errors
  if(fg.get('kennwortbestaetigung')?.errors && fg.get('kennwortbestaetigung')?.errors['pwRepeatMissing'])
    delete fg.get('kennwortbestaetigung')?.errors['kennwortbestaetigung'];
  fg.get('kennwortbestaetigung')['infotextKey'] = undefined;

  if(kennwort !== kennwortbestaetigung) {
    fg.get('kennwortbestaetigung')?.setErrors({pwMismatch: true});
    fg.get('kennwortbestaetigung')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.PASSWORD_MISMATCH_2";
    return {pwMismatch: true};
  } // else remove these specific errors
  if(fg.get('kennwortbestaetigung')?.errors && fg.get('kennwortbestaetigung')?.errors['pwMismatch'])
    delete fg.get('kennwortbestaetigung')?.errors['pwMismatch'];
  fg.get('kennwortbestaetigung')['infotextKey'] = undefined;

  return null;

}
