import {Injectable} from '@angular/core';
import {CustomToastService} from "../custom-toast.service";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class ValidationHelperService {

  private _emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
  private _abonummerPattern = /7\d{5}$|7\d{6}$|7\d{5}-0\d$|7\d{6}-0\d$|7\d{5}-\d$|7\d{6}-\d$/;
  private _strasseHausnummerPattern = /^(?=.*\d)[a-zA-ZäöüÄÖÜß\s\d!"§$%&/()=?´`+*~'#-_.:,;]+ \d.*$/;
  private _telefonnummerPattern = /^[\d\s()+]+$/;

  constructor(private customToast: CustomToastService, public translate: TranslateService) { }

  get emailPattern(): string {
    return this._emailPattern;
  }

  get abonummerPattern(): RegExp {
    return this._abonummerPattern;
  }

  get strasseHausnummerPattern(): RegExp {
    return this._strasseHausnummerPattern;
  }

  get telefonnummerPattern(): RegExp {
    return this._telefonnummerPattern;
  }

  // toast is only displayed if c is actually passed to the funciton
  isEmailValid(email: string, showToast = false): boolean {
    if (email === null || email === undefined){
      this.customToast.showError(
        'Emaileingabe fehlt'
      );
      return false
    }
    const isValid = (email.match(this._emailPattern) !== null);
    if(!isValid){
      if (showToast) {
        this.customToast.showError(
          'Ungültige Email: ' + email
        );
      }
    }
    return isValid;
  }



}
