import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {AntragTicketDTO} from "../../../models/antrag-ticket/AntragTicketDTO";
import {AntragTicketUpdateDTO} from "../../../models/antrag-ticket/AntragTicketUpdateDTO";
import {AntragTicketCreateDTO} from "../../../models/antrag-ticket/AntragTicketCreateDTO";
import {PictureFileDTO} from "../../../models/routing/PictureFileDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {AntragTicketMinimalDTO} from "../../../models/antrag-ticket/AntragTicketMinimalDTO";

@Injectable({
  providedIn: 'root'
})
export class AntragTicketService {

  configUrl = 'api/antrag-ticket';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getAllAntraegeMinimal(): Observable<Array<AntragTicketMinimalDTO>> {
    return this.http.get<Array<AntragTicketMinimalDTO>>(
      this.configUrl+'/bulk-minimal',
      { headers: this.headerService.getAuthHeaderApplicationJson()}
    );
  }

  getAntrag(id: string): Observable<AntragTicketDTO> {
    return this.http.get<AntragTicketDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  updateAntrag(id: number, dto: AntragTicketUpdateDTO, praktikumsvertrag: File, sonderfallNachweis: File, bezugNachweis: File): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    if (praktikumsvertrag !== undefined) {
      formData.append('praktikumsvertrag', praktikumsvertrag);
    }
    if (sonderfallNachweis !== undefined) {
      formData.append('sonderfallNachweis', sonderfallNachweis);
    }
    if (bezugNachweis !== undefined) {
      formData.append('bezugNachweis', bezugNachweis);
    }
    return this.http.patch<AntragTicketDTO>(
      this.configUrl+'/'+id,
      formData,
      { headers: this.headerService.getAuthHeader() }
    );
  }

  createAntrag(dto: AntragTicketCreateDTO, praktikumsvertrag: File, sonderfallNachweis: File, bezugNachweis: File): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    if (praktikumsvertrag !== undefined) {
      formData.append('praktikumsvertrag', praktikumsvertrag);
    }
    if (sonderfallNachweis !== undefined) {
      formData.append('sonderfallNachweis', sonderfallNachweis);
    }
    if (bezugNachweis !== undefined) {
      formData.append('bezugNachweis', bezugNachweis);
    }
    return this.http.post<AntragTicketDTO>(
      this.configUrl,
      formData,
      { headers: this.headerService.getAuthHeader() }
    );
  }

  deleteAntrag(id: number): Observable<any> {
    return this.http.delete(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeader(), responseType: 'text' }
    );
  }

  getVorgaenger(schuelerId: number): Observable<any> {
    if (schuelerId !== null) {
      return this.http.get<AntragTicketDTO>(
        this.configUrl+'/vorgaenger?schueler-id='+schuelerId,
        { headers: this.headerService.getAuthHeaderApplicationJson() }
      );
    } else {
      return this.http.get<AntragTicketDTO>(
        this.configUrl+'/vorgaenger',
        { headers: this.headerService.getAuthHeaderApplicationJson() }
      );
    }
  }

  downloadPraktikumsvertrag(antragTicketId: string): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+antragTicketId+'/praktikumsvertrag',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadSonderfallNachweis(antragTicketId: string): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+antragTicketId+'/sonderfallNachweis',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadBezugNachweis(antragTicketId: string): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+antragTicketId+'/bezugNachweis',
      { headers: this.headerService.getAuthHeader() }
    );
  }

}
