<app-default-layout
  [title]="'Login'"
  [noHeaderMargin]="true">
  <div class="pl-4 col col-md-6 offset-md-3 no-header-margin">
    <form (ngSubmit)="this.login()" [formGroup]="loginForm" class="form container">
      <div class=" group-for-email1 col mb-3">
        <div class="row">
          <div class="d-flex flex-row">
            <div class="flex-grow-1 mr-2">
              <app-custom-input [id]="'username'"
                                [customFormGroup]="loginForm"
                                [type]="'email'"
                                [label]="'STANDARD.EMAIL' | translate"
                                [placeholder]="'LOGIN.EMAIL-BSP' | translate"
                                data-cy="username"
              ></app-custom-input>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group group-for-passwort col mb-3">
        <div class="row">
          <div class="d-flex flex-row">
            <div class="flex-grow-1 mr-2">
              <app-custom-input [id]="'password'"
                                [type]="pwHide ? 'password' : 'text'"
                                [customFormGroup]="loginForm"
                                [label]="'LOGIN.PW' | translate"
                                [placeholder]="'LOGIN.PW-BSP' | translate"
                                data-cy="password">
              </app-custom-input>
            </div>
            <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                      [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                      [title]="pwHide? translate.instant('LOGIN.PW-SHOW'):translate.instant('LOGIN.PW-HIDE')">
            </fa-icon>

          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <button type="submit" class="btn btn-primary btn-block" data-cy="login">
          {{'APPLICATION.LOGIN.LOGIN'|translate}}
        </button>
      </div>
      <div *ngIf="displayLoginFailed">
        <p>{{ 'LOGIN.FAILED' | translate}}</p>
      </div>
      <span>
      <div class="d-flex flex-column">
        <button
          type="button"
          class="btn btn-primary btn-block"
          [routerLink]="['/authentication/registrierung']"
          data-cy="btn-register"
        >
          {{'APPLICATION.LOGIN.REGISTER'|translate}}
        </button>
      </div>
         <div class="d-flex flex-column">
        <button
          type="button"
          class="btn btn-primary btn-block"
          [routerLink]="['/authentication/kennwort-vergessen']"
          data-cy="btn-reset-pw"
        >
          {{'APPLICATION.LOGIN.FORGOTPW'|translate}}
        </button>
      </div>
      </span>

    </form>
  </div>

</app-default-layout>
