import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {TextbausteinDTO} from "../../../models/textbaustein/TextbausteinDTO";
import {TextbausteinUpdateDTO} from "../../../models/textbaustein/TextbausteinUpdateDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {KeyString} from "../../../models/textbaustein/KeyString";

@Injectable({
  providedIn: 'root'
})
export class TextbausteinService{

  configUrl = 'api/textbaustein';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getTextbaustein(id: string): Observable<TextbausteinDTO> {
    return this.http.get<TextbausteinDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getEmptyTextbausteine(): Observable<Array<TextbausteinDTO>> {
    return this.http.get<Array<TextbausteinDTO>>(
      this.configUrl+'/getAll/empty',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  updateTextbaustein(id: number, updateTextbaustein: TextbausteinUpdateDTO): Observable<any> {
    return this.http.patch<TextbausteinDTO>(
      this.configUrl+'/'+id,
      updateTextbaustein,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getTextbausteinList(params: HttpParams): Observable<Array<TextbausteinDTO>> {
    return this.http.get<Array<TextbausteinDTO>>(
      this.configUrl+'/bulk',
      {headers: this.headerService.getAuthHeaderApplicationJson(), params:params});
  }

  getEditedTextbausteinList(): Observable<Array<TextbausteinDTO>> {
    return this.http.get<Array<TextbausteinDTO>>(
      this.configUrl+'/getAll/edited',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  // if key does not exist or is empty, return empty string regardless default or suffix
  // if key is not empty
  //    if value of Textbaustein is "DEFAULT" then return defined defaultValue + suffix
  //    else return the actual value of the Textbaustein + suffix
  printTextbausteinByKey(key: KeyString, defaultValue: string, suffix: string = "") : string {
    if(environment.displayTextbausteinKeys)
      return key.valueOf();
    const textmoduleDtoList: TextbausteinDTO[] = JSON.parse(sessionStorage.getItem('textbausteine'));

    const textBausteinContent = textmoduleDtoList?.find((dto: TextbausteinDTO) => dto.key === key)?.value?.trim();
    if (textBausteinContent === undefined || textBausteinContent === null || textBausteinContent === '') {
      return ''; // this needs to return '' and not defaultValue. You must sync your ASM with SBM first.
    }

    if (textBausteinContent === "DEFAULT"){
      return defaultValue + suffix;
    } else {
      return textBausteinContent + suffix;
    }
  }


}
