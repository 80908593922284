import {Injectable, Injector} from '@angular/core';
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {TokenRefreshService} from "./token-refresh.service";
import {addSeconds, parseISO} from "date-fns";
import {from, Observable} from "rxjs";
import {BenutzerNameDTO} from "../../../models/benutzer/BenutzerNameDTO";
import {jwtDecode} from "jwt-decode";
import {BenutzerSessionService} from "../benutzer/benutzer-session.service";
import {ConfigService} from "../../config/services/config.service";
import {Router} from "@angular/router";

interface LoginData {
  user: BenutzerDTO;
  timestamp: Date;
  refreshToken: string;
  authToken: string;
  tokenTimeToLive: number;
}

@Injectable()
export class AuthService {

  private logoutTimerId;

  constructor(
    private tokenRefreshService: TokenRefreshService,
    private configService: ConfigService,
    private readonly injector: Injector
  ) {}

  public readFromStorage(): Observable<LoginData> {
    return new Observable<LoginData>((subscriber) => {

      const timestamp = parseISO(sessionStorage.getItem('timestamp'));
      const authToken = sessionStorage.getItem('authToken');
      const refreshToken = sessionStorage.getItem('refreshToken');
      const user = sessionStorage.getItem('user');
      const tokenTimeToLive = parseInt(sessionStorage.getItem('tokenTimeToLive'), 10);

      if (
        !timestamp ||
        !this.isAuthenticated() ||
        !user ||
        !refreshToken ||
        !authToken
      ) {
        this.clearSessionStorage().then(() => {
          subscriber.error('No valid credentials/user in storage');
          subscriber.complete();
        });
      }
      // triggers notification of all observers
      // observers will receive (user, authToken, refreshToken, ...)
      subscriber.next({
        user: JSON.parse(user) as BenutzerDTO,
        timestamp,
        authToken,
        refreshToken,
        tokenTimeToLive,
      });

      // notify each observer that all observers were notified
      subscriber.complete();

      this.startlogoutTimer(new Date(timestamp.getMilliseconds() + tokenTimeToLive));
    });
  }

  public saveToStorage(obj: {
    user: BenutzerNameDTO;
    timestamp: Date,
    refreshToken: string;
    authToken: string;
    expires: Date;
  }) {
    sessionStorage.setItem('user', JSON.stringify(obj.user));
    sessionStorage.setItem('timestamp', obj.timestamp.toISOString());
    sessionStorage.setItem('authToken', obj.authToken);
    sessionStorage.setItem('refreshToken', obj.refreshToken);
    sessionStorage.setItem('tokenTimeToLive', ((obj.expires.getTime() ) - (obj.timestamp.getTime() )).toString());
    const benutzerSessionService = this.injector.get(BenutzerSessionService);
    benutzerSessionService.initBenutzerSessionJwt();
  }

  public saveRefreshedCredentials(obj: {
    access_token: string;
    refresh_token: string;
    timestamp: Date;
    expiresAt: Date;
  }) {
    sessionStorage.setItem('timestamp', obj.timestamp.toISOString());
    sessionStorage.setItem('authToken', obj.access_token);
    sessionStorage.setItem('tokenTimeToLive', ((obj.expiresAt.getTime()) - (obj.timestamp.getTime() )).toString());
    const benutzerSessionService = this.injector.get(BenutzerSessionService);
    benutzerSessionService.broadcastBenutzerChange();
    benutzerSessionService.broadcastTimestampChange();
  }

  private getDecodedJwt(): any {
    const jwt = sessionStorage.getItem('authToken');
    if (jwt != null) {
      return jwtDecode(jwt);
    } else {
      return null
    }
  }

  public isAuthenticated(): boolean {
    const decodedJwt = this.getDecodedJwt();
    if (decodedJwt == null) {
      return false;
    }
    const expires: Date = new Date(decodedJwt.exp * 1000); // * 1000 because we need timestamp in ms
    if (expires) {
      this.startlogoutTimer(expires);
      return expires?.getTime() > (new Date()).getTime();
    } else {
      return false;
    }
  }

  public refreshToken(): void {
    this.readFromStorage().subscribe((data: any) => {
      this.tokenRefreshService.refreshToken(data?.refreshToken, data?.user?.email)
        .subscribe((data: any) => {
            const jwtAnswer = {...data}; // '...' flattens / unpacks values
            const timestamp = parseISO(jwtAnswer.timestamp);
            const expiresAt = addSeconds(timestamp, jwtAnswer.expires_in);
            this.saveRefreshedCredentials({
              access_token: jwtAnswer.access_token,
              refresh_token: jwtAnswer.refresh_token,
              timestamp: timestamp,
              expiresAt: expiresAt
            });
          this.startlogoutTimer(expiresAt);
        });
    });
  }

  private startlogoutTimer(targetDate:Date){

    if(window['Cypress']) // check if this is a currently running cypress test
      return;

    if(targetDate < new Date())
      return;

    let currentDate = new Date();
    let delay = targetDate.getTime() - currentDate.getTime();
    if (delay > 0) {
      this.clearLogoutTimer();
      this.logoutTimerId = setTimeout(() => {
        this.clearLogoutTimer();
        this.logout("delay logouttimer "+delay);
      }, delay);
    }
  }

  private clearLogoutTimer() {
    if (this.logoutTimerId) {
      clearTimeout(this.logoutTimerId);
    }
  }

  public logout(callerId: string) {
    this.clearSessionStorage().then(()=>{
      if (this.configService.featureConfig.kommunalportalAuth ){
        window.location.href = sessionStorage.getItem('portalUrl');
      } else if (this.configService.featureConfig.jwtAuth) {
        const router = this.injector.get(Router);
        router.navigate(['/authentication/login'])
      } else {
        window.location.href = null; // TODO: replace with "Oops" page, see: https://jira.regioit.intern/browse/SFK2-1570
      }
    });
  }

  clearSessionStorage(): Promise<boolean> {
    return new Promise( (resolve) => {
      sessionStorage.removeItem('authToken');
      sessionStorage.removeItem('mode');
      sessionStorage.removeItem('portalUrl');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('expiresAt');
      sessionStorage.removeItem('timestamp');
      sessionStorage.removeItem('tokenTimeToLive');
      sessionStorage.clear();
      window.sessionStorage.clear();
      localStorage.clear();
      const benutzerSessionService = this.injector.get(BenutzerSessionService)
      benutzerSessionService.broadcastBenutzerChange();
      benutzerSessionService.broadcastTimestampChange();
      resolve(true);
    });
  }


}
