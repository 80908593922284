import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SchuleDTO} from "../../../models/schule/SchuleDTO";
import {SchuleKoordinateDTO} from "../../../models/schule-koordinate/SchuleKoordinateDTO";
import {CreateSchuleKoordinateDTO} from "../../../models/schule-koordinate/CreateSchuleKoordinateDTO";
import {UpdateSchuleKoordinateDTO} from "../../../models/schule-koordinate/UpdateSchuleKoordinateDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class SchuleKoordinateService {

  configUrl = 'api/schuleKoordinate';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getKoordinate(id: string): Observable<SchuleKoordinateDTO> {
    return this.http.get<SchuleKoordinateDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  createKoordinate(create: CreateSchuleKoordinateDTO): Observable<any> {
    return this.http.post<SchuleDTO>(
      this.configUrl,
      create,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  deleteKoordinate(id: number):Observable<any>{
    return this.http.delete(
      this.configUrl+'/'+id,
      {headers: this.headerService.getAuthHeader(), responseType: 'text'}
    );
  }

  updateKoordinate(id: number, dto: UpdateSchuleKoordinateDTO): Observable<any> {
    return this.http.patch<SchuleDTO>(
      this.configUrl+'/'+id,
      dto,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getKoordinateListBySchule(schuleId: number): Observable<Array<SchuleKoordinateDTO>> {
    return this.http.get<Array<SchuleKoordinateDTO>>(
      this.configUrl+'?schuleId='+schuleId,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

}
