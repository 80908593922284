import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BelegartDTO} from "../../../models/belegart/BelegartDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class BelegartService{

  configUrl = 'api/belegart';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getBelegarten(): Observable<Array<BelegartDTO>> {
    return this.http.get<Array<BelegartDTO>>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

}
