<app-default-layout
  [title]="'Ihr Benutzerkonto'"
  [subtitle]=""
>

  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 col-sm-12">
      <div class="frame frame-default">
        <div id="edit-account" class="content scaffold-edit " role="main">
          <form [formGroup]="accountForm" class="form-horizontal" accept-charset="UTF-8">
            <div class="form-group group-for-email col">
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="email"
                    [customFormGroup]="accountForm"
                    [label]="'STANDARD.EMAIL' | translate"
                  ></app-custom-input>
                </div>
              </div>
            </div>
            <div class="row justify-content-start"
                 *ngIf="!isActiveAccountChange"
            >
              <div class="col">
                <button class="btn btn-secondary save me-1"
                        (click)="toggleEmailChange()"
                        data-cy="change-email-button"
                >
                  <fa-icon [icon]="faEmail"></fa-icon>
                  {{'APPLICATION.BUTTON_EMAIL_AENDERN' |translate }}
                </button>
                <button class="btn btn-secondary save"
                        (click)="togglePasswordChange()"
                        data-cy="change-password-button"
                >
                  <fa-icon [icon]="faKey"></fa-icon>
                  {{'APPLICATION.BUTTON_PW_AENDERN' |translate }}
                </button>
              </div>
            </div>
          </form>

<!--          EMAIL NEU-->
          <form [formGroup]="accountForm"
                class="form-horizontal"
                accept-charset="UTF-8"
                *ngIf="isActiveEmailChange"
          >
            <div class="form-group group-for-emailNeu col">
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input
                    id="emailNeu"
                    [customFormGroup]="emailChangeForm"
                    [label]="'STANDARD.EMAIL_NEU' | translate"
                  ></app-custom-input>
                  <div class="formcontrol-error-infotext"
                       *ngIf="emailChangeForm?.get('emailNeu')?.errors"
                  >
                    {{ emailChangeForm?.get('emailNeu')['infotextKey'] | translate }}
                    <!-- error generated by angular's Validators.email, if format is wrong : -->
                    <div *ngIf="emailChangeForm?.get('emailNeu')?.errors?.email">
                      {{ 'AUTHENTICATION.FORM_ERRORS.EMAIL_INVALID' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group group-for-emailNeu col">
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="emailNeuRepeat"
                    [customFormGroup]="emailChangeForm"
                    [label]="'STANDARD.EMAIL_NEU_REPEAT' | translate"
                  ></app-custom-input>
                  <div class="formcontrol-error-infotext"
                       *ngIf="emailChangeForm?.get('emailNeuRepeat')?.errors"
                  >
                    {{ emailChangeForm?.get('emailNeuRepeat')['infotextKey'] | translate }}
                    <!-- error generated by angular's Validators.email, if format is wrong : -->
                    <div *ngIf="emailChangeForm?.get('emailNeuRepeat')?.errors?.email">
                      {{ 'AUTHENTICATION.FORM_ERRORS.EMAIL_INVALID' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-start ms-1 me-1 mb-3">
              {{ 'AUTHENTICATION.EMAIL_CHANGE_INFO' | translate }}
            </div>
            <div class="row justify-content-start">
              <div class="col">
                <button class="btn btn-primary save me-1"
                        *ngIf="!emailChangeForm.errors"
                        (click)="showConfirmationModal()"
                >
                  <fa-icon [icon]="faSave"></fa-icon>
                  {{'APPLICATION.BUTTON_SPEICHERN' |translate }}
                </button>
                <button class="btn btn-secondary save"
                        (click)="untoggleAccountChange()"
                >
                  <fa-icon [icon]="faCancel"></fa-icon>
                  {{'APPLICATION.BUTTON_ABBRECHEN' |translate }}
                </button>
              </div>
            </div>
          </form>

<!--          PASSWORT NEU-->
          <form [formGroup]="pwChangeForm"
                class="form-horizontal"
                accept-charset="UTF-8"
                *ngIf="isActivePasswordChange"
          >
            <div class="form-group group-for-kennwortNeu col mb-3">
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="kennwortNeu"
                                    [type] = "pwHide? 'password':'text'"
                                    [customFormGroup]="pwChangeForm"
                                    [label]="'REGISTRIERUNG.NEW-PW' | translate"
                                    [pwAutocomplete]="false"
                                    data-cy="kennwort"
                  ></app-custom-input>
                  <div class="formcontrol-error-infotext"
                       *ngIf="pwChangeForm?.get('kennwortNeu')?.errors"
                       data-cy="kennwort-error">
                    {{ pwChangeForm?.get('kennwortNeu')['infotextKey'] | translate }}
                    {{ pwChangeForm?.get('kennwortNeu')['lengthKey'] | translate }}
                    {{ pwChangeForm?.get('kennwortNeu')['numKey'] | translate }}
                    {{ pwChangeForm?.get('kennwortNeu')['upperKey'] | translate }}
                    {{ pwChangeForm?.get('kennwortNeu')['lowerKey'] | translate }}
                    {{ pwChangeForm?.get('kennwortNeu')['specialKey'] | translate }}
                  </div>
                </div>
                <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                          [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                          [title]="pwHide? translateService.instant('LOGIN.PW-SHOW'):translateService.instant('LOGIN.PW-HIDE')"
                          data-cy="btn-pwhide"
                >
                </fa-icon>
              </div>
            </div>
            <div class="form-group group-for-kennwortNeuRepeat col mb-3">
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="kennwortNeuRepeat"
                                    [type] = "pwHide? 'password':'text'"
                                    [customFormGroup]="pwChangeForm"
                                    [label]="'REGISTRIERUNG.NEW-PW_REPEAT' | translate"
                                    [pwAutocomplete]="false"
                                    data-cy="kennwortbestaetigung"
                  ></app-custom-input>
                  <div class="formcontrol-error-infotext"
                       *ngIf="pwChangeForm?.get('kennwortNeuRepeat')?.errors"
                       data-cy="kennwortbestaetigung-error"
                  >
                    {{ pwChangeForm?.get('kennwortNeuRepeat')['infotextKey'] | translate }}
                  </div>
                </div>
                <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                          [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                          [title]="pwHide? translateService.instant('LOGIN.PW-SHOW'):translateService.instant('LOGIN.PW-HIDE')"
                          data-cy="btn-pwhide-repeat"
                >
                </fa-icon>
              </div>
            </div>
            <div class="row justify-content-start ms-1 me-1 mb-3">
              {{ 'AUTHENTICATION.PW_CHANGE_INFO' | translate }}
            </div>
            <div class="row justify-content-start">
              <div class="col">
                <button class="btn btn-primary save me-1"
                        *ngIf="!pwChangeForm.get('kennwortNeu')?.errors && !pwChangeForm.get('kennwortNeuRepeat')?.errors"
                        (click)="showConfirmationModal()"
                >
                  <fa-icon [icon]="faSave"></fa-icon>
                  {{'APPLICATION.BUTTON_SPEICHERN' |translate }}
                </button>
                <button class="btn btn-secondary save"
                        (click)="untoggleAccountChange()"
                >
                  <fa-icon [icon]="faCancel"></fa-icon>
                  {{'APPLICATION.BUTTON_ABBRECHEN' |translate }}
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>

</app-default-layout>
