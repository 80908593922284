import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {EMPTY, Observable, tap} from "rxjs";
import {FeatureConfig} from "../interfaces/featureConfig";
import {CustomerConfig} from "../interfaces/customerConfig";
import {CustomToastService} from "../../utils/custom-toast.service";
import {catchError} from "rxjs/operators";
import {DataDTO} from "../../../models/data/DataDTO";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  featureConfig: FeatureConfig;
  customerConfig: CustomerConfig;
  logo: DataDTO;

  headers: HttpHeaders;

  configUrl = 'assets/config';

  constructor(
    private http: HttpClient,
    private customToastService: CustomToastService
  ) { }

  getFeatureConfig(): Observable<FeatureConfig> {
    return this.http.get<FeatureConfig>(this.configUrl+'/featureConfig.json').pipe(
      catchError(() => {
        this.customToastService.showError('FeatureConfig konnte nicht geladen werden.');
        return EMPTY;
      }),
      tap(featureConfig => {
        this.featureConfig = featureConfig;
      })
    );
  }

  getCustomerConfig(): Observable<CustomerConfig> {
    return this.http.get<CustomerConfig>(this.configUrl+'/customerConfig.json').pipe(
      catchError(() => {
        this.customToastService.showError('CustomerConfig konnte nicht geladen werden.');
        return EMPTY;
      }),
      tap(customerConfig => {
        this.customerConfig = customerConfig;
        this.applyActiveThemeToDom();
      })
    );
  }

  getLogoSrc(): Observable<DataDTO> {
    return this.http.get<DataDTO>('api/datei/logosrc').pipe(
      catchError(() => {
        console.log('No custom logo found.');
        return EMPTY;
      }),
      tap(logo => {
        this.logo = logo;
      })
    );
  }

  private applyActiveThemeToDom(): void {

    document.documentElement.style.setProperty("--brand-primary", this.customerConfig.primaryColor);
    document.documentElement.style.setProperty("--brand-primary-dark", this.customerConfig.primaryColorDark);
    document.documentElement.style.setProperty("--brand-primary-darker", this.customerConfig.primaryColorDarker);
    document.documentElement.style.setProperty("--brand-primary-medium", this.customerConfig.primaryColorMedium);
    document.documentElement.style.setProperty("--brand-primary-light", this.customerConfig.primaryColorLight);
    document.documentElement.style.setProperty("--brand-primary-lighter", this.customerConfig.primaryColorLighter);

    document.documentElement.style.setProperty("--brand-secondary", this.customerConfig.secondaryColor);
    document.documentElement.style.setProperty("--brand-secondary-dark", this.customerConfig.secondaryColorDark);
    document.documentElement.style.setProperty("--brand-secondary-darker", this.customerConfig.secondaryColorDarker);
    document.documentElement.style.setProperty("--brand-secondary-medium", this.customerConfig.secondaryColorMedium);
    document.documentElement.style.setProperty("--brand-secondary-lighter", this.customerConfig.secondaryColorLighter);
    document.documentElement.style.setProperty("--brand-secondary-light", this.customerConfig.secondaryColorLight);

    document.documentElement.style.setProperty("--brand-white", this.customerConfig.whiteColor);
    document.documentElement.style.setProperty("--brand-dark-grey", this.customerConfig.darkGreyColor);
    document.documentElement.style.setProperty("--brand-light-grey", this.customerConfig.lightGreyColor);
    document.documentElement.style.setProperty("--brand-lighter-grey", this.customerConfig.lighterGreyColor);

    document.documentElement.style.setProperty("--background-color", this.customerConfig.backgroundColor);
    document.documentElement.style.setProperty("--logo-height", this.customerConfig.logoHeight);

  }
}
