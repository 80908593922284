<app-default-layout
  [title]="'Aktivierung'"
>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <form  [formGroup]="aktivierungForm" (ngSubmit)="this.activate()" class="form-horizontal"
             accept-charset="UTF-8">
        <div class="form-group group-for-email col mb-4">

          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">
              <app-custom-input id="email" type="text"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.MAIL' |translate }}"
                                [customFormGroup]="aktivierungForm"
                                [readonly]="true"
              ></app-custom-input>
            </div>
          </div>
        </div>

        <button type="submit"
                class="btn btn-primary save btn-block me-3">
          {{'APPLICATION.BUTTON_AKTIVIEREN'|translate}}
        </button>

      </form>
    </div>
  </div>
</app-default-layout>
