export enum Typ {
  NEUANTRAG,
  AENDERUNGSANTRAG_UMZUG,
  AENDERUNGSANTRAG_NAMENSAENDERUNG,
  AENDERUNGSANTRAG_HALTESTELLENAENDERUNG,
  AENDERUNGSANTRAG_SCHULWECHSEL,
  FOLGEANTRAG,
  AENDERUNGSANTRAG_BANKDATEN,
  KUENDIGUNG
}
