import {FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export const registrationEmailRepeatValidator: ValidatorFn = (fg: FormGroup) : ValidationErrors | null => {

  // this is a validator that applies to entire form,
  // as multiple formcontrols are needed for validation
  const email = fg.get('email')?.value;
  const emailbestaetigung = fg.get('emailbestaetigung')?.value;

  if(!email)
    return { emailMissing: true };

  if(!emailbestaetigung) {
    fg.get('emailbestaetigung')?.setErrors({emailbestaetigungMissing: true});
    fg.get('emailbestaetigung')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.EMAIL_REPEAT_MISSING";
    return {emailbestaetigungMissing: true};
  } // else remove these specific errors
  if(fg.get('emailbestaetigung')?.errors && fg.get('emailbestaetigung')?.errors['emailbestaetigungMissing'])
    delete fg.get('emailbestaetigung')?.errors['emailbestaetigungMissing'];
  fg.get('emailbestaetigung')['infotextKey'] = undefined;

  let modEmail = email?.trim().toLowerCase();
  let modEmailbestaetigung = emailbestaetigung?.trim().toLowerCase();

  if(modEmail !== modEmailbestaetigung) {
    fg.get('emailbestaetigung')?.setErrors({emailMismatch: true});
    fg.get('emailbestaetigung')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.EMAIL_MISMATCH";
    return {emailMismatch: true};
  } // else remove these specific errors
  if(fg.get('emailbestaetigung')?.errors && fg.get('emailbestaetigung')?.errors['emailMismatch'])
    delete fg.get('emailbestaetigung')?.errors['emailMismatch'];
  fg.get('emailbestaetigung')['infotextKey'] = undefined;

  return null;


}
