<div *ngIf="showRouteInformation && errorString?.length === 0" class="map-info-container">
  {{
    existsSessionStorageTextbausteine?
      textbausteinService.printTextbausteinByKey( KeyString.ENTFERNUNGSBERECHNUNG_INFOTEXT,('MAP.ENTFERNUNGSBERECHNUNG_INFOTEXT' | translate ))
      : ('MAP.ENTFERNUNGSBERECHNUNG_INFOTEXT' | translate )
  }}
</div>
<div *ngIf="errorString?.length !== 0" class="map-error-container">
  {{errorString}}
</div>
<br>
<ng-container *ngIf="errorString?.length === 0">
  <div class="map-container">
    <div class="map-frame">
      <div id="map"></div>
    </div>
  </div>
  <div *ngIf="showWaypointButtons" style="margin: 30px">
    <button type="button" class="btn btn-primary" (click)="saveWaypoints()">{{ 'MAP.ROUTE_SPEICHERN' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="deleteWaypoints()">{{ 'MAP.ROUTE_LOESCHEN' | translate }}</button>
    <button *ngIf="showRouteImageButton && routeImage === undefined" type="button" class="btn btn-secondary">{{ 'MAP.ROUTE_IMAGE_BUTTON' | translate }}</button>
  </div>
  <br *ngIf="showWaypointButtons">
  <div *ngIf="routeImage !== undefined" class="route-image">
    {{ 'MAP.ROUTE_IMAGE_INFOTEXT' | translate }}<br>
    <img [src]="routeImage" alt="" />
  </div>
</ng-container>
