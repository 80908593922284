import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../utils/custom-toast.service";
import {BenutzerService} from "../../services/benutzer/benutzer.service";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";

/*
  Route is called via confirmation email
  ("Um die Änderung erfolgreich abschließen zu können ..")

  Confirmation email is generated via endpoint: PATCH benutzer/id -> BenutzerService.updateBenutzer
  CASE: User-Password has been changed via Profil

  Confirmation is tied to email address, hence ..
  formControl email => readonly = true
  formControl kennwort => readonly = false, validate: required

 */

@Component({
  selector: 'app-kennwort-aendern',
  templateUrl: './kennwort-aendern.component.html',
  styleUrls: ['./kennwort-aendern.component.css']
})
export class KennwortAendernComponent implements OnInit {

  public kennwortAenderungForm: UntypedFormGroup;
  public dto: BenutzerDTO;

  pwHide = true;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  token: string;
  email: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private customToastService: CustomToastService,
              private benutzerService: BenutzerService,
              private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.kennwortAenderungForm = this.fb.group({
            email:[params.email],
            kennwort:['',[Validators.required]]
          });
          this.token = params.token;
        }
      );

  }

  getFormItem(s: string) {
    return this.kennwortAenderungForm?.get(s);
  }

  save(){
    if(this.kennwortAenderungForm.invalid){
      this.customToastService.showError('Die Angaben sind falsch oder unvollständig.');
      return;
    }
    const emailInput =  this.getFormItem('email')?.value.length !== 0 ? this.getFormItem('email').value : null;
    const kennwort = this.getFormItem('kennwort')?.value.length !== 0 ? this.getFormItem('kennwort').value : null;
    this.benutzerService.activateNewPw({
      email : emailInput,
      kennwort: kennwort,
      token : this.token
    }).pipe(
      catchError(() => {
        this.customToastService.showError('Die Änderung konnte nicht durchgeführt werden.');
        return EMPTY;
      })
    )
      .subscribe((data: BenutzerDTO) => {
        this.dto = {...data};
        this.router.navigate(['/authentication/login']);
        this.customToastService.showSuccess("Das Passwort wurde erfolgreich geändert.");
      });
  }


}
