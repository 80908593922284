import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../../../environments/environment";

@Component({
	selector: 'app-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit, OnDestroy {
	@Input() id: string;
	@Input() label: any;
	@Input() customFormGroup: UntypedFormGroup;
	@Input() noteText: string;
	@Input() showMaxLength = false;
	@Input() maxLength = 1;
	@Input() placeholder = '';
	@Input() rows = 10;
	@Input() readonly = false;
  @Input() disabled = false;
  @Input() infoText: string;

  faInfo = faInfoCircle;

	currentLength = 0;
	destroySub$: Subject<void> = new Subject<void>();

  htmlFieldName : string;

	ngOnDestroy(): void {
		this.destroySub$.next();
		this.destroySub$.complete();
	}

	ngOnInit(): void {
    this.htmlFieldName = TextareaComponent.getHtmlTextAreaIdByFieldName(this.id)
		this.customFormGroup
			.get(this.id)
			.valueChanges.pipe(takeUntil(this.destroySub$))
			.subscribe((value) => {
				if (value) {
					this.currentLength = value.length;
				} else {
					this.currentLength = 0;
				}
			});
	}

	getFormElement(id: string) {
		return this.customFormGroup.get(id);
	}

  static getHtmlTextAreaIdByFieldName(fieldName: string){
    return "input"+fieldName
  }

    protected readonly environment = environment;
}
