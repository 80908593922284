import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Data, Router} from "@angular/router";
import {SchuelerDTO} from "../../../models/schueler/SchuelerDTO";
import {SchuelerService} from "../../../@core/services/schueler/schueler.service";
import {faPlus, faSort} from '@fortawesome/free-solid-svg-icons';
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {nullLastComparator} from "../../../@core/utils/forms/null-last-comparator";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {CompositMessageService} from "../../../@core/services/compositMessage/compositMessage.service";
import {SessionService} from "../../../@core/services/session/session.service";
import {KeyString} from "../../../models/textbaustein/KeyString";

@Component({
  selector: 'app-schueler-table',
  templateUrl: './schueler-table.component.html',
  styleUrls: ['./schueler-table.component.scss']
})
export class SchuelerTableComponent implements OnInit {

  public data: Data | undefined;
  public columns: any;
  public rows: any;

  isLoading: boolean = false;
  dataFetched: boolean = false;

  faPlus = faPlus;
  sortInitial = faSort

  entityNameTranslationKey: string = 'SCHUELER';

  existsSessionStorageTextbausteine: boolean = false;

  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;

  constructor(
    private router: Router,
    private schuelerService: SchuelerService,
    public textbausteinService: TextbausteinService,
    private customToastService: CustomToastService,
    private compositMessageService: CompositMessageService,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.columns = [
      { name: 'name', headerTemplate: this.hdrTpl, comparator: nullLastComparator.bind(this) },
      { name: 'vorname', headerTemplate: this.hdrTpl, comparator: nullLastComparator.bind(this) }
    ];

    this.isLoading = true;
    this.dataFetched = false;

    this.schuelerService.getSchuelerList()
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'getBulk'));
          this.isLoading   = false;
          this.dataFetched = null;
          return EMPTY;
        })
      )
      .subscribe((data: Array<SchuelerDTO>) => {
        this.rows = data;
        this.isLoading = false;
        this.dataFetched = this.rows.length>0;
      });
  }

  onActivate(event) {
    if(event.type == 'click') {
      this.router.navigate(['/pages/schueler-detail/'+event.row.id ]).catch(()=>{});
    }
  }

    protected readonly KeyString = KeyString;
}
