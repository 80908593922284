import {FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export const accountPwChangeValidator: ValidatorFn = (fg: FormGroup) : ValidationErrors | null => {

  // this is a validator that applies to entire form,
  // as multiple formcontrols are needed for validation

  const novel = fg.get('kennwortNeu')?.value;
  const novelRepeat = fg.get('kennwortNeuRepeat')?.value;

  if(!novel) {
    fg.get('kennwortNeu')?.setErrors({newPwMissing: true});
    fg.get('kennwortNeu')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.PASSWORD_CHANGE";
    return {newPwMissing: true};
  } // else remove these specific errors
  if(fg.get('kennwortNeu')?.errors && fg.get('kennwortNeu')?.errors['newPwMissing'])
    delete fg.get('kennwortNeu')?.errors['newPwMissing'];
  fg.get('kennwortNeu')['infotextKey'] = undefined;

  if(!novelRepeat) {
    fg.get('kennwortNeuRepeat')?.setErrors({newPwRepeatMissing: true});
    fg.get('kennwortNeuRepeat')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.PASSWORD_CHANGE_REPEAT_MISSING";
    return {newPwRepeatMissing: true};
  } // else remove these specific errors
  if(fg.get('kennwortNeuRepeat')?.errors && fg.get('kennwortNeuRepeat')?.errors['newPwRepeatMissing'])
    delete fg.get('kennwortNeuRepeat')?.errors['newPwRepeatMissing'];
  fg.get('kennwortNeuRepeat')['infotextKey'] = undefined;


  if(novel !== novelRepeat) {
    fg.get('kennwortNeuRepeat')?.setErrors({newPwMismatch: true});
    fg.get('kennwortNeuRepeat')['infotextKey'] = "AUTHENTICATION.FORM_ERRORS.PASSWORD_MISMATCH_2";
    return {newPwMismatch: true};
  } // else remove these specific errors
  if(fg.get('kennwortNeuRepeat')?.errors && fg.get('kennwortNeuRepeat')?.errors['newPwMismatch'])
    delete fg.get('kennwortNeuRepeat')?.errors['newPwMismatch'];
  fg.get('kennwortNeuRepeat')['infotextKey'] = undefined;

  return null;

}


