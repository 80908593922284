export function parseInternationalNumberToGerman(incoming: any) : string {
  if(typeof incoming === 'number'){
    let numStr = ''+incoming;
    numStr = numStr.replace(/\./g,',')
    return numStr;
  } else if (typeof incoming === 'string'){
    return incoming.replace(/\./g,',');
  } else {
    return undefined;
  }
}
