import {Injectable} from '@angular/core';
import {SortDirection} from "@swimlane/ngx-datatable";
import {HttpParams} from "@angular/common/http";

export interface Pagination {
  limit?: number;
  count?: number;
  offset?: number;
  orderBy?: string;
  orderDir?: SortDirection;
  filter?:string;
}

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  pageDefault : Pagination = {
    limit:10,
    count: 0,
    offset: 0,
    orderBy: 'id',
    orderDir: SortDirection.desc,
    filter: ''
  };

  getDefaultPagination(orderBy?:string, limit?:number, orderDir?:SortDirection) {
    const pagination =  {...this.pageDefault}; // copy of pageDefault
    pagination.limit = limit || pagination.limit;
    pagination.orderBy = orderBy || pagination.orderBy;
    pagination.orderDir = orderDir || pagination.orderDir;
    return pagination
  }

  getHttpParamsForPagination(pagination: Pagination){
    return new HttpParams()
      .set('page', `${pagination.offset}`) //offset= page
      .set('limit', `${pagination.limit}`)
      .set('direction', `${pagination.orderDir.toString()}`)
      .set('orderbycolumn', `${pagination.orderBy}`)
      .set('filter', `${pagination.filter}`);
  }
}
