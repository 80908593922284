import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BenutzerDTO} from 'src/app/models/benutzer/BenutzerDTO';
import {TranslateService} from "@ngx-translate/core";
import {SchuleService} from "../../../@core/services/schule/schule.service";
import {SchuljahrService} from "../../../@core/services/schuljahr/schuljahr.service";
import {SchuelerService} from "../../../@core/services/schueler/schueler.service";
import {Typ} from "../../../models/antrag-ticket/Typ";
import {Wegtyp} from "../../../models/antrag-ticket/Wegtyp";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AntragTicketDTO} from "../../../models/antrag-ticket/AntragTicketDTO";
import {TicketStatus} from "../../../models/antrag-ticket/TicketStatus";
import {AntragTicketService} from 'src/app/@core/services/antrag-ticket/antrag-ticket.service';
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {FieldConfigService} from 'src/app/@core/services/field-config/field-config.service';
import {Land} from "../../../models/land/Land";
import {catchError, pairwise, startWith} from "rxjs/operators";
import {EMPTY, forkJoin, Observable, Subscription} from "rxjs";
import {HaltestelleService} from "../../../@core/services/haltestelle/haltestelle.service";
import {Pagination, PaginationService} from "../../../@core/utils/pagination.service";
import {HaltestellenPageDTO} from "../../../models/haltestelle/HaltestellenPageDTO";
import {PictureFileComponent} from "../../picture-file/picture-file/picture-file.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AntragTicketCreateDTO} from "../../../models/antrag-ticket/AntragTicketCreateDTO";
import {AntragTicketSepaCreateDTO} from 'src/app/models/antrag-ticket/AntragTicketSepaCreateDTO';
import {SchuelerDTO} from "../../../models/schueler/SchuelerDTO";
import {AntragTicketUpdateDTO} from "../../../models/antrag-ticket/AntragTicketUpdateDTO";
import {AntragTicketSepaUpdateDTO} from "../../../models/antrag-ticket/AntragTicketSepaUpdateDTO";
import {faInfoCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {TicketDetailParentComponent} from "../ticket-detail-parent/ticket-detail-parent.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ConfirmComponent} from "../../../shared/confirm/confirm.component";
import {ValidatorService} from "angular-iban";
import {ModuleConfigService} from "../../../@core/services/module-config/module-config.service";
import {PlzValidationHelperService} from "../../../@core/utils/forms/plz-validation-helper.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ImageHelperService} from "../../../@core/utils/forms/image-helper.service";
import {
  createDateIsBetweenValidator,
  createVormundAgeValidator,
  DateTimeHelperService
} from "../../../@core/utils/forms/datetime-helper.service";
import {TicketDTO} from "../../../models/ticket/TicketDTO";
import {toTitlecase} from "../../../@core/utils/forms/string-helper";
import {SchuleDTO} from "../../../models/schule/SchuleDTO";
import {getObjIfExistsInListByAttribute} from "../../../@core/utils/forms/object-list-helper";
import {PostleitzahlenService} from "../../../@core/services/postleitzahlen/postleitzahlen.service";
import {SessionService} from "../../../@core/services/session/session.service";
import {KeyString} from "../../../models/textbaustein/KeyString";
import {ValidationHelperService} from "../../../@core/utils/forms/validation-helper.service";
import {ConfigService} from 'src/app/@core/config/services/config.service';
import {PFLICHTFELDMARKER} from "../../../../assets/constants/constants";
import {
  HaltestelleDisplayComboNameService
} from "../../../@core/services/haltestelle/haltestelle-display-comboname.service";

@Component({
  selector: 'app-ticket-detail-sb-true',
  templateUrl: './ticket-detail-sb-true.component.html',
  styleUrls: ['./ticket-detail-sb-true.component.scss']
})
export class TicketDetailSbTrueComponent implements OnInit, OnDestroy, OnChanges {

  faInfo = faInfoCircle;
  faDelete = faTimesCircle;

  @Input() antragTicketId: string | null;
  @Input() benutzer: BenutzerDTO;
  @Input() benutzerRoles: string[];
  @Input() fieldConfig;
  @Input() optionTyp = [];
  @Input() optionErmaessigung = [];
  @Input() optionGeschlecht = [];
  @Input() optionWegtyp = [];
  @Input() optionSonderfall = [];
  @Input() landList = [];

  @Input() requiredFields: string[] = [];
  @Input() visibleFields: string[] = [];

  @Input() antragTicketFormFields = [];
  @Input() antragTicketSepaFormFields = [];
  @Input() antragTicketGeschwister1FormFields = [];
  @Input() antragTicketGeschwister2FormFields = [];
  @Input() antragTicketGeschwister3FormFields = [];
  @Input() antragTicketGeschwister4FormFields = [];
  @Input() antragTicketGeschwister5FormFields = [];

  @Input() schulwegOnOff = [];
  @Input() praktikumOnOff = [];
  @Input() beidesOnOff = [];
  @Input() schuleOnOff = [];
  @Input() berufskollegOnOff = [];
  @Input() schuljahrOnOff = [];
  @Input() vormundOnOff = [];
  @Input() vormundAdresseAbweichendOnOff;
  @Input() sonderfallOnOff = [];
  @Input() sonderfallSonstOnOff = [];
  @Input() asylOnOff = [];
  @Input() sepaMandatgeberNotAntragst = [];
  @Input() sepaMandatgeberYesAntragst = [];

  pflichtFeldMarker = PFLICHTFELDMARKER;

  schuleList = [];
  schuljahreList = [];
  filteredSchuljahreList = [];
  filteredKlassenList = [];
  filteredBildungsgangList = [];
  filteredTicketList : Array<TicketDTO> = [];
  schuelerList = [];

  antragTicket: AntragTicketDTO;
  antragTicketForm: UntypedFormGroup;

  draftStatus = TicketStatus.ENTWURF;
  eingereichtStatus = TicketStatus.EINGEREICHT;
  wegtypBEIDES = Wegtyp.BEIDES;
  wegtypPRAKTIKUMSWEG = Wegtyp.PRAKTIKUMSWEG;

  readonly : boolean = false;
  schuelerDeleted : boolean = false;

  isSchuelerUnderAge: boolean = false;
  isAntragstellerYoungerThanMinimumAge: boolean = false;
  isAntragstellerUnderage: boolean = false;

  today  : string;
  minDate : string;
  maxDate : string;
  minDatePraktikumEnddatum : string;
  maxDateVormundGeburtsdatum : string;
  minDateBirthday : string;
  minDateTicketGueltigAb;
  maxDateTicketGueltigAb;
  gueltigAbInfoText: string;
  gueltigAbNoteText: string;

  // picturefiles
  praktikumsvertrag: string;
  praktikumsvertragIsPDF: boolean = false;
  sonderfallNachweis: string;
  sonderfallNachweisIsPDF: boolean = false;
  bezugNachweis: string;
  bezugNachweisIsPDF: boolean = false;

  //haltestellen
  einstiegHaltestelleList = [];
  einstiegHaltestellePage : HaltestellenPageDTO;
  einstiegHaltestellePagination : Pagination = {...this.paginationService.getDefaultPagination("name" , 100)};
  einstiegHaltestelleLoading : boolean = false;

  ausstiegHaltestelleList = [];

  //buttons
  isDeleteDraftButtonVisible: boolean = false;
  isUpdateEntwurfButtonVisible: boolean = false;
  isEinreichenButtonVisible: boolean = false;

  //subscriptions
  formItemSubscriptions: Subscription[] = [];

  allVormundFieldsInvisible: boolean = false;

  //validators
  effectiveValidators: string[] = [];
  effectivelyVisible: string[] = [];
  isInitializing: boolean = true;
  isSubmitted: boolean = false;
  isMonthSelection: boolean = false;
  bildungsgangSetBySachbearbeiter: boolean = false;

  // disable checkboxes if readonly == true
  // isBerufskolleg is always disabled in SBtrue, so it's not in this list
  disableCheckboxes: string[] = [
    'bezugAsylbewerberleistungsgesetz','sonderfallEnabled','einverstaendnisErklaerung',
    'isAntragstellerNotMandatsgeber','datenschutz','marketingfreigabe',
  ];
  disableGueltigAb: boolean = true;

  allowedNachweisFormats : string = ImageHelperService.ALLOWED_NACHWEIS_FILE_FORMATS;

  postkorbReference: string;

  existsSessionStorageTextbausteine: boolean = false;
  dateToGueltigAbField: number = 25;

  isAntragTicketSonderfallModuleEnabled: boolean = false;
  isAntragTicketTypModuleEnabled: boolean = false;

  constructor(
    public translateService: TranslateService,
    private schuleService: SchuleService,
    private schuljahrService: SchuljahrService,
    private schuelerService: SchuelerService,
    private fb: UntypedFormBuilder,
    private antragTicketService: AntragTicketService,
    private customToastService: CustomToastService,
    private router: Router,
    public fieldConfigService: FieldConfigService,
    public moduleConfigService: ModuleConfigService,
    private haltestelleService: HaltestelleService,
    private paginationService: PaginationService,
    private modalService: NgbModal,
    public textbausteinService: TextbausteinService,
    private parent: TicketDetailParentComponent,
    private sanitizer: DomSanitizer,
    private plzHelper : PlzValidationHelperService,
    private spinner: NgxSpinnerService,
    private imageHelper: ImageHelperService,
    private datetimeHelper: DateTimeHelperService,
    private postleitzahlenService: PostleitzahlenService,
    private sessionService: SessionService,
    private validationHelper: ValidationHelperService,
    private configService: ConfigService,
    private haltestelleDisplayComboNameService: HaltestelleDisplayComboNameService
  ) {
    this.isAntragTicketSonderfallModuleEnabled = this.moduleConfigService.isModuleEnabled('antragTicketSonderfall');
    this.isAntragTicketTypModuleEnabled = this.moduleConfigService.isModuleEnabled('antragTicketTyp');
  }

  ngOnInit(): void {
    this.spinner.show().catch(()=>{});

    this.dateToGueltigAbField = this.configService.featureConfig.dateToGueltigAbField;
    this.setBildungsgangSetBySachbearbeiterBoolean();

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.effectivelyVisible  = [...this.visibleFields];
    this.effectiveValidators = [...this.requiredFields];

    this.makeInit().then(()=> {

      this.spinner.hide().catch(()=>{});

      this.adjustSubtitle();
      this.isInitializing = false;
      this.setVormundVisibility();
    }).catch(()=>{});

    this.checkAntragstellerAge();

  }

  makeInit(): Promise<boolean>{
    return new Promise<boolean>((resolve)=>{
      this.isInitializing = true;

      this.initEmptyForm();
      this.setDateMinMax();
      this.initAboDatumGueltigAb();

      let listsObservable = this.initListsForSBtrue();
      listsObservable.subscribe({
        complete: () => {
          this.createSubscriptions();
          if(this.antragTicketId){
            this.antragTicketService.getAntrag(this.antragTicketId)
              .pipe(
                catchError(() => {
                  this.customToastService.showError('Der Antrag konnte nicht geladen werden.');
                  resolve(true);
                  return EMPTY;
                })
              )
              .subscribe((data: AntragTicketDTO) => {
                this.antragTicket = {...data};
                this.postkorbReference = this.antragTicket.postkorbReference;
                this.autoPatchForm(this.antragTicket,this.antragTicketFormFields);
                // "manual" trigger for sonderfallTypChange() is needed due to valueChanges subscription
                // valueChanges only reacts if new value is not null
                // however, sonderfall-Option "trifft nicht zu" has null-value,
                // which necessitates this explicit trigger.
                // this facilitates the "make invisible" of sonderfallBegruendung and sonderfallNachweis
                // if option "trifft nicht zu" is loaded from antragDTO
                this.sonderfallTypChange();
                this.isBerufskollegChanged();
                if(this.antragTicket.antragTicketSepa)
                  this.autoPatchForm(this.antragTicket.antragTicketSepa,this.antragTicketSepaFormFields);
                this.antragTicketForm.patchValue({ isAntragstellerNotMandatsgeber: !this.antragTicket?.antragTicketSepa?.isAntragstellerMandatsgeber } );
                if(this.isMonthSelection){
                  //remove first day from Month from  ('2022-08-01') to ('2022-08')
                  this.antragTicketForm.patchValue({aboGueltigkeit: this.antragTicket?.aboGueltigkeit?.toString().slice(0,7)});
                }
                if(this.antragTicket.geschwisterkind1) {
                  this.autoPatchForm(this.antragTicket.geschwisterkind1,this.antragTicketGeschwister1FormFields);
                  this.setGeschwisterkind(1);
                }
                if(this.antragTicket.geschwisterkind2) {
                  this.autoPatchForm(this.antragTicket.geschwisterkind2,this.antragTicketGeschwister2FormFields);
                  this.setGeschwisterkind(2);
                }
                if(this.antragTicket.geschwisterkind3) {
                  this.autoPatchForm(this.antragTicket.geschwisterkind3,this.antragTicketGeschwister3FormFields);
                  this.setGeschwisterkind(3);
                }
                if(this.antragTicket.geschwisterkind4) {
                  this.autoPatchForm(this.antragTicket.geschwisterkind4,this.antragTicketGeschwister4FormFields);
                  this.setGeschwisterkind(4);
                }
                if(this.antragTicket.geschwisterkind5) {
                  this.autoPatchForm(this.antragTicket.geschwisterkind5,this.antragTicketGeschwister5FormFields);
                  this.setGeschwisterkind(5);
                }
                if(this.antragTicket.schule){
                  this.antragTicketForm.patchValue({schule: this.antragTicket.schule});
                  this.schuleChange();
                }

                if(this.antragTicket.praktikumsvertragId)
                  this.antragTicketService.downloadPraktikumsvertrag(this.antragTicketId)
                    .pipe(catchError(() => {
                      this.customToastService.showError("Praktikumsvertrag konnte nicht geladen werden.");
                      return EMPTY;
                    }))
                    .subscribe(result => {
                      this.praktikumsvertragIsPDF = result.image.indexOf("application/pdf")>-1;
                      this.praktikumsvertrag = result.image;
                    });
                if(this.antragTicket.sonderfallNachweisId)
                  this.antragTicketService.downloadSonderfallNachweis(this.antragTicketId)
                    .pipe(catchError(() => {
                      this.customToastService.showError("Sonderfallnachweis konnte nicht geladen werden.");
                      return EMPTY;
                    }))
                    .subscribe(result => {
                      this.sonderfallNachweisIsPDF = result.image.indexOf("application/pdf")>-1;
                      this.sonderfallNachweis = result.image;
                    });
                if(this.antragTicket.bezugNachweisId)
                  this.antragTicketService.downloadBezugNachweis(this.antragTicketId)
                    .pipe(catchError(() => {
                      this.customToastService.showError("Bezugnachweis konnte nicht geladen werden.");
                      return EMPTY;
                    }))
                    .subscribe(result => {
                      this.bezugNachweisIsPDF = result.image.indexOf("application/pdf")>-1;
                      this.bezugNachweis = result.image;
                    });
                this.readonly = this.antragTicket.status != TicketStatus.ENTWURF;
                this.schuelerDeleted = this.antragTicket.schueler?.deleted || false;
                if(this.schuelerDeleted){
                  this.customToastService.showWarning(this.readonly? "Schüler:in wurde gelöscht." :
                    "Schüler:in wurde gelöscht. Sie müssen eine/n gültige/n Schüler:in auswählen, um den Antrag einreichen zu können.");
                }
                if(!this.readonly) {
                  this.correctLand();
                }
                this.setButtonVisibility();
                this.disableCheckboxesFunc();
                resolve(true);
              });
          } else {
            this.setButtonVisibility();
            // "manual" trigger for valueChanges subscriptions for form elements
            // where null is a legit input value, e.g. Sonderfall option "trifft nicht zu"
            // valueChanges only reacts if new value does not equal previous value
            // it's set up like that to prevent unnecessary updates
            // however, initvalue is null, and if loaded legit value is null too, no changeDetection is triggered
            this.sonderfallTypChange();
            this.isBerufskollegChanged();
            this.disableCheckboxesFunc();
            resolve(true);
          }
        },error: (error => {
          console.log(error);
          this.spinner.hide().catch(()=>{});
        })
      });
    });
  }

  private checkAntragstellerAge() {
    if(!this.benutzer)
      return;
    this.isAntragstellerYoungerThanMinimumAge = this.datetimeHelper.isYoungerThan(this.benutzer?.geburtsdatum?.toString(),this.configService.featureConfig.minimumAge);
    this.isAntragstellerUnderage = this.datetimeHelper.isYoungerThan(this.benutzer?.geburtsdatum?.toString(),18);
    if(this.isAntragstellerUnderage!==true) {
      const idx = this.effectivelyVisible.indexOf('zustimmungGesetzlicherVertreter');
      if (idx != -1)
        this.effectivelyVisible.splice(idx, 1);
    }
  }

  private setVormundVisibility() {
    const vormundNameVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundName', this.fieldConfig)?.visible;
    const vormundVornameVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundVorname', this.fieldConfig)?.visible;
    const isVormundadresseAbweichendVisible = this.fieldConfigService.getFieldFromFieldConfig('isVormundadresseAbweichend', this.fieldConfig)?.visible;
    const vormundStrasseVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundStrasse', this.fieldConfig)?.visible;
    const vormundOrtVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundOrt', this.fieldConfig)?.visible;
    const vormundPlzVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundPlz', this.fieldConfig)?.visible;
    const vormundAdresszusatzVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundAdresszusatz', this.fieldConfig)?.visible;
    const vormundLandVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundLand', this.fieldConfig)?.visible;
    const vormundGeburtsdatumVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundGeburtsdatum', this.fieldConfig)?.visible;
    const vormundTelefonnummerVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundTelefonnummer', this.fieldConfig)?.visible;
    const vormundEmailVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundEmail', this.fieldConfig)?.visible;
    const vormundGeschlechtVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundGeschlecht', this.fieldConfig)?.visible;

    if (!vormundNameVisible
      && !vormundVornameVisible
      && !isVormundadresseAbweichendVisible
      && !vormundStrasseVisible
      && !vormundOrtVisible
      && !vormundPlzVisible
      && !vormundAdresszusatzVisible
      && !vormundLandVisible
      && !vormundGeburtsdatumVisible
      && !vormundTelefonnummerVisible
      && !vormundEmailVisible
      && !vormundGeschlechtVisible) {
      this.allVormundFieldsInvisible = true;
    }
  }

  setDateMinMax(){
    this.minDate = DateTimeHelperService.CALENDAR_MINIMUM;
    this.minDateBirthday = DateTimeHelperService.BIRTHDATE_MINIMUM;
    this.today   = this.datetimeHelper.formattedToday();
    this.maxDate = DateTimeHelperService.CALENDAR_MAXIMUM;
    this.maxDateVormundGeburtsdatum = this.datetimeHelper.maxDateVormund();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setBildungsgangSetBySachbearbeiterBoolean();
    this.updateEffectiveValidators();
    if(changes.optionSonderfall)
      this.filterAntragTicketSonderfallByFieldConfig();
    if(changes.optionTyp)
      this.filterAntragTicketTypByFieldConfig();
  }

  createSubscriptions(){
    /*
      SonarLint marks startWith as deprecated, but it's only deprecated if used with a scheduler
      startWith(null) and startWith(undefined) are ok
     */
    this.formItemSubscriptions.push(
      this.antragTicketForm.get('geburtsdatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.vormundChange();
          }
        }),
      this.antragTicketForm.get('wegtyp').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.wegtypChange();
          }
        }),
      this.antragTicketForm.get('schule').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.schuleChange();
          }
        }),
      this.antragTicketForm.get('isBerufskolleg').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.isBerufskollegChanged();
          }
        }),
      this.antragTicketForm.get('schuljahr').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.schuljahrChange();
          }
        }),
      this.antragTicketForm.get('isAntragstellerNotMandatsgeber').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.sepaChange();
          }
        }),
      this.antragTicketForm.get('sonderfall').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.sonderfallTypChange();
          }
        }),
      this.antragTicketForm.get('isVormundadresseAbweichend').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.isVormundadresseAbweichendChanged();
          }
        }),
      this.antragTicketForm.get('bezugAsylbewerberleistungsgesetz').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.asylChange();
          }
        }),
      this.antragTicketForm.get('praktikumStartdatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.praktikumDatumChange();
          }
        }),
      this.antragTicketForm.get('praktikumEnddatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.praktikumDatumChange();
          }
        }),
      this.antragTicketForm.get('einstieghaltestelle').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.checkForHaltestellenMatch('einstieghaltestelle');
          }
        }),
      this.antragTicketForm.get('ausstieghaltestelleHinweg').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.checkForHaltestellenMatch('ausstieghaltestelleHinweg');
          }
        }),
      this.getFormItem('land')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('plz');
      }),
      this.getFormItem('vormundLand')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('vormundPlz');
      }),
      this.getFormItem('praktikumLand')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('praktikumPlz');
      }),
      this.getFormItem('landSepa')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('plzSepa');
      })
    )
  }

  disableCheckboxesFunc(){
    if(this.readonly === true && !(!this.antragTicketForm)){
      //programmatically disables checkboxes: safest option
      this.disableCheckboxes.forEach(cb=>this.antragTicketForm.get(cb)?.disable());
    } else if(this.readonly !== true && !(!this.antragTicketForm)) {
      this.disableCheckboxes.forEach(cb=>this.antragTicketForm.get(cb)?.enable());
    }
  }

  resetFormValues(varnames: string[]) {
    if( this.readonly || this.isInitializing || !this.antragTicketForm )
      return;
    varnames.forEach((varname: string) => {
      this.antragTicketForm?.get(varname)?.patchValue(null);
    });
  }

  getInfoTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey? this.translateService.instant(translateKey) : '') : '';
  }

  adjustSubtitle(){
    if(this.readonly!==true)
      Promise.resolve(null).then(() => this.parent.subtitle =
        this.textbausteinService.printTextbausteinByKey( KeyString.SUBTITLE_TEXT_TICKET,
          this.translateService.instant('TICKETDETAIL.SUBTITLE_TEXT_TICKET'))
      ).catch(()=>{});
    else
      Promise.resolve(null).then(() => this.parent.subtitle = '').catch(()=>{});
  }

  updateEffectivelyVisible(add: string[], remove: string[]){
    if(this.effectivelyVisible) {
      remove.forEach(varName => {
        if (!add.includes(varName)) {
          while (this.effectivelyVisible.includes(varName)) {
            this.effectivelyVisible.splice(this.effectivelyVisible.indexOf(varName), 1);
          }
          this.resetFormValues([varName]);
        }
      });
      add.forEach(varName => {
        if (!this.effectivelyVisible.includes(varName)) {
          this.effectivelyVisible = [...this.effectivelyVisible, varName];
        }
      });
    }
    this.updateEffectiveValidators();
  }

  resetFormValidators(varnames: string[]){
    varnames.forEach((varname:string) => {
      if(this.getFormItem(varname)){
        this.getFormItem(varname).clearValidators();
        this.getFormItem(varname).clearAsyncValidators();
        this.getFormItem(varname).setErrors(null);
        this.getFormItem(varname).updateValueAndValidity();
      }
    });
  }

  updateEffectiveValidators(){
    if(this.effectivelyVisible.length > 0) {
      this.effectiveValidators = [];
      this.requiredFields.forEach(varName => {
        if ( varName==='bildungsgang' && this.bildungsgangSetBySachbearbeiter)
          return; // do not validate bildungsgang for Kunde Wesel
        if (this.effectivelyVisible.includes(varName))
          this.effectiveValidators = [...this.effectiveValidators,varName];
      });
      this.checkAntragstellerAge();
    }
    if(this.isSubmitted===true)
      this.applyValidators().catch(()=>{});
    else {  // these validators always react on bad input
      this.addFormalValidators();
    }
  }

  wegtypChange(){
    const status = this.getFormItem('wegtyp')?.value;
    if(!status) return;
    let add, remove;
    switch(status){
      case(Wegtyp.SCHULWEG): {
        add = this.schulwegOnOff;
        remove = this.beidesOnOff.concat(this.praktikumOnOff);
        break;
      }
      case(Wegtyp.BEIDES): {
        add = this.schulwegOnOff.concat(this.praktikumOnOff).concat(this.beidesOnOff);
        remove = [];
        break;
      }
      case(Wegtyp.PRAKTIKUMSWEG): {
        add = this.praktikumOnOff;
        remove = this.schulwegOnOff.concat(this.beidesOnOff);
      }
    }
    if(remove.includes('praktikumsvertrag'))
      this.deleteNachweis('praktikumsvertrag');
    this.updateEffectivelyVisible(add,remove);
  }

  schuleChange(){
    // reset lists and values that depend on schule
    if( !this.readonly && !this.isInitializing && !!this.antragTicketForm ) {
      this.filteredKlassenList = [];
      this.filteredBildungsgangList = [];
      this.filteredSchuljahreList = [];
      this.ausstiegHaltestelleList = [];
      this.antragTicketForm.patchValue({
        isBerufskolleg: null,
        ticket: null,
        klasse: null,
        bildungsgang: null,
        fachbereich: null,
        bildungsgangdatum: null,
        schuljahr: null,
        ausstieghaltestelleHinweg: null,
        aboGueltigkeit: null
      });
    }

    let schule = this.filterListsForSelectedSchule();
    this.antragTicketForm.patchValue({isBerufskolleg: schule?.berufskolleg});

    // handle visibility and validation
    this.isBerufskollegChanged();
  }

  private filterListsForSelectedSchule() {
    // load lists fitting for current schule:

    const schuleName = this.getFormItem('schule').value;
    const schuleDto = this.schuleList.find(x => x.name === schuleName);

    if (schuleDto) {
      this.antragTicketForm.patchValue({isBerufskolleg: schuleDto.berufskolleg});

      // if readonly -> not Entwurf -> show all tickets in existence for this schule (tickets)
      // if !readonly -> Entwurf -> show only ticket selection (ticketsa), and only non-archived
      this.filteredTicketList = this.readonly ? schuleDto.tickets : schuleDto.ticketsa?.filter(t => t['archiv'] !== true) || [];

      // similar goes for the other lists
      this.filteredSchuljahreList = this.schuljahreList.filter(schuljahr => schuljahr.berufskolleg === schuleDto?.berufskolleg && schuljahr['archiv'] !== true) || [];
      this.filteredKlassenList = this.readonly ? schuleDto.klassen : schuleDto.klassen?.filter(t => t['archiv'] !== true) || [];
      this.filteredBildungsgangList = this.readonly ? schuleDto.bildungsgaenge : schuleDto.bildungsgaenge?.filter(t => t['archiv'] !== true) || [];
      this.ausstiegHaltestelleList = [];
      schuleDto.haltestellen.forEach( haltestelle =>
        this.ausstiegHaltestelleList = this.ausstiegHaltestelleList.concat({
          id: haltestelle.id,
          name: haltestelle.name,
          tarifgebietName: haltestelle.tarifgebietName,
          comboName: this.haltestelleDisplayComboNameService.getComboName(haltestelle),
          archiv: haltestelle.archiv
        })
      );
    }
    return schuleDto;
  }

  isBerufskollegChanged(){
    let add, remove;
    let isB = this.getFormItem('isBerufskolleg').value;
    if(!(!isB)&&isB===true){
      add = this.berufskollegOnOff;
      remove = this.schuleOnOff;
    } else if(isB===false){
      add = this.schuleOnOff;
      remove = this.berufskollegOnOff;
    } else {  // undefined or null value
      add = [];
      remove = this.schuleOnOff.concat(this.berufskollegOnOff);
    }
    this.updateEffectivelyVisible(add,remove);
  }

  schuljahrChange() {

    if (!this.getFormItem('schuljahr')) return;

    let schuljahrname = this.getFormItem("schuljahr")?.value;
    let selectedSchuljahr = this.schuljahreList.find(schuljahr => schuljahr.name === schuljahrname);
    this.gueltigAbNoteText = "";
    if (selectedSchuljahr) {
      this.disableGueltigAb = false;
      this.gueltigAbInfoText = "";
    } else {
      this.disableGueltigAb = true;
      this.gueltigAbInfoText = this.textbausteinService.printTextbausteinByKey( KeyString.ABO_GUELTIG_AB_INFO_KEIN_SCHULJAHR,
        this.translateService.instant("TICKETDETAIL.ABO_GUELTIG_AB_INFO_KEIN_SCHULJAHR"));
      return;
    }

    if(this.antragTicketId == null || this.antragTicket.status === TicketStatus.ENTWURF) {

      let startDatumSchuljahr: Date = new Date(selectedSchuljahr?.start);
      let endDatumSchuljahr: Date = new Date(selectedSchuljahr?.ende);

      let todayDate: Date = new Date(this.today);
      let isOverBorder: boolean = todayDate.getDate() >= this.dateToGueltigAbField + 1;//26;
      //use isOverBorder only in isMonthSelection
      if (!this.isMonthSelection) {
        isOverBorder = false;
      }
      //helpToday when 26. eines Monats ist, dann addiere ich ein paar Tage um max Datum auf den Folgemonat zu setzen
      let helpToday: Date = todayDate; //helptoday should be in the next month
      if (isOverBorder) {
        helpToday.setDate(0);
        helpToday.setMonth(todayDate.getMonth() + 1);
      }
      //set min and max Date for MonthlySelection
      if (this.isMonthSelection) {
        // Falls der Erste des Monats des Schuljahresbeginns in der Zukunft liegt: kein Text
        if (startDatumSchuljahr > todayDate) {
          this.gueltigAbInfoText = "";
          this.minDateTicketGueltigAb = new Date(startDatumSchuljahr.getFullYear(), startDatumSchuljahr.getMonth(), 1);//1. des Monats //wichtig für die Validierung, der Wert wird beim Setzen des Datums weiter unten ageschnitte, so dass immer  der 1. des Monats gesetzt wird
          this.maxDateTicketGueltigAb = endDatumSchuljahr;
        } // Falls kein Monat auswählbar ist (= es nach dem 25. (environment.dateToGueltigAbField) des letzten Monats des Schuljahres ist): "Für das ausgewählte Schuljahr kann kein Ticket mehr beantragt werden"
        else if (endDatumSchuljahr < helpToday && isOverBorder) {
          this.gueltigAbInfoText = this.textbausteinService.printTextbausteinByKey(KeyString.ABO_GUELTIG_AB_INFO_KEIN_TICKET,
            this.translateService.instant("TICKETDETAIL.ABO_GUELTIG_AB_INFO_KEIN_TICKET"));
          this.disableGueltigAb = true;
        } //Falls der aktuell laufende Monat auswählbar ist (=es höchstens der 25. (environment.dateToGueltigAbField) ist): "Es ist die Beantragung eines Tickets für den laufenden Monat möglich. Bei Ausstellung ist für diesen Monat der volle Eigenanteil zu leisten."
        else if (endDatumSchuljahr > todayDate && !isOverBorder) {
          this.gueltigAbInfoText = this.textbausteinService.printTextbausteinByKey(KeyString.ABO_GUELTIG_AB_INFO_LAUFENDER_MONAT,
            this.translateService.instant("TICKETDETAIL.ABO_GUELTIG_AB_INFO_LAUFENDER_MONAT"));
          this.minDateTicketGueltigAb = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1); //wichtig für die Validierung, der Wert wird beim Setzen des Datums weiter unten ageschnitte, so dass immer  der 1. des Monats gesetzt wird
          this.maxDateTicketGueltigAb = endDatumSchuljahr;
        } else { // > 26. (this.configService.featureConfig.dateToGueltigAbField + 1) des Monats isOverBorder && endDatumSchuljahr > helpToday
          this.minDateTicketGueltigAb = new Date(helpToday.getFullYear(), helpToday.getMonth(), 1); //wichtig für die Validierung, der Wert wird beim Setzen des Datums weiter unten ageschnitte, so dass immer  der 1. des Monats gesetzt wird
          this.maxDateTicketGueltigAb = endDatumSchuljahr;
          this.gueltigAbInfoText = this.textbausteinService.printTextbausteinByKey(KeyString.ABO_GUELTIG_AB_INFO_FOLGENDER_MONAT,
            this.translateService.instant("TICKETDETAIL.ABO_GUELTIG_AB_INFO_FOLGENDER_MONAT"));
        }
      } else { //isMonthSelection = false, default
        this.minDateTicketGueltigAb = startDatumSchuljahr;
        this.maxDateTicketGueltigAb = endDatumSchuljahr;
      }

      //get value if set
      let aboGueltigkeitValue: Date;
      if (this.isMonthSelection) {
        aboGueltigkeitValue = this.getFormItem('aboGueltigkeit')?.value?.length > 0 ? new Date(this.getFormItem('aboGueltigkeit')?.value?.toString() + '-01') : null;
      } else {
        aboGueltigkeitValue = this.getFormItem('aboGueltigkeit')?.value?.length > 0 ? new Date(this.getFormItem('aboGueltigkeit')?.value) : null;
      }

      //reset Value if not in min_max range or disabled
      // let patchNewValue;
      if (this.disableGueltigAb) {
        this.antragTicketForm.patchValue({aboGueltigkeit: undefined});
      } else if (aboGueltigkeitValue < this.minDateTicketGueltigAb || this.maxDateTicketGueltigAb < aboGueltigkeitValue) {
        this.minDateTicketGueltigAb = this.datetimeHelper.removeTimeZone(this.minDateTicketGueltigAb);
        if (this.isMonthSelection) {
          this.minDateTicketGueltigAb = this.minDateTicketGueltigAb?.toISOString().slice(0, 7);
        } else {
          this.minDateTicketGueltigAb = this.minDateTicketGueltigAb?.toISOString().slice(0, 10);
        }
        this.antragTicketForm.patchValue({aboGueltigkeit: this.minDateTicketGueltigAb});
      }

      if (this.isMonthSelection) {
        this.gueltigAbNoteText = 'Mindestens: ' + this.datetimeHelper.formattedMonth(this.minDateTicketGueltigAb) + '\n Maximal:' + this.datetimeHelper.formattedMonth(this.maxDateTicketGueltigAb);
      } else {
        this.gueltigAbNoteText = "";
      }

      this.getFormItem('aboGueltigkeit')?.setValidators([createDateIsBetweenValidator(this.minDateTicketGueltigAb, this.maxDateTicketGueltigAb)]);
      this.getFormItem('aboGueltigkeit')?.updateValueAndValidity();
    }
  }


  vormundChange(){
    if (!this.getFormItem('geburtsdatum')) return;
    const underage: boolean = this.datetimeHelper.isYoungerThan(this.getFormItem('geburtsdatum')?.value,18);

    this.antragTicketForm?.patchValue({isUnderAge: underage});
    this.isSchuelerUnderAge = underage;
    let add, remove;
    if(underage){
      add = this.vormundOnOff;
      remove = ['emailSchueler','telefonnummer'];
    } else {
      add = ['emailSchueler','telefonnummer'];
      remove = this.vormundOnOff;
    }
    this.isVormundadresseAbweichendChanged();
    this.updateEffectivelyVisible(add,remove);
  }

  sonderfallTypChange(){
    let add, remove;
    if (this.getFormItem('sonderfall')?.value) {
      if (this.getFormItem('sonderfall')?.value === 'SONSTIGES'){
        add = this.sonderfallOnOff.concat(this.sonderfallSonstOnOff);
        remove = [];
      } else {
        add = this.sonderfallOnOff;
        remove = this.sonderfallSonstOnOff;
      }
    } else {
      add = [];
      remove = this.sonderfallOnOff.concat(this.sonderfallSonstOnOff);
    }
    if(remove.includes('sonderfallNachweis'))
      this.deleteNachweis('sonderfallNachweis');
    this.updateEffectivelyVisible(add,remove);
  }

  asylChange(){
    let add, remove;
    if(!this.getFormItem('bezugAsylbewerberleistungsgesetz')) return;
    if(this.getFormItem('bezugAsylbewerberleistungsgesetz').value){
      add = this.asylOnOff;
      remove = [];
    } else {
      add = [];
      remove = this.asylOnOff;
    }
    if(remove.includes('bezugNachweis'))
      this.deleteNachweis('bezugNachweis');
    this.updateEffectivelyVisible(add,remove);
  }

  sepaChange(){
    let add, remove;
    if(!this.getFormItem('isAntragstellerNotMandatsgeber')) return;
    if(this.getFormItem('isAntragstellerNotMandatsgeber').value){
      add = this.sepaMandatgeberNotAntragst;
      remove = this.sepaMandatgeberYesAntragst;
      this.getFormItem('iban').removeValidators([ ValidatorService.validateIban]);
      this.getFormItem('ibanSepa').setValidators([ ValidatorService.validateIban]);

      this.getFormItem('iban').updateValueAndValidity();
      this.getFormItem('ibanSepa').updateValueAndValidity();
    } else {
      add = this.sepaMandatgeberYesAntragst;
      remove = this.sepaMandatgeberNotAntragst;
      this.getFormItem('iban').setValidators([ ValidatorService.validateIban]);
      this.getFormItem('ibanSepa').removeValidators([ ValidatorService.validateIban]);

      this.getFormItem('iban').updateValueAndValidity();
      this.getFormItem('ibanSepa').updateValueAndValidity();
    }
    this.updateEffectivelyVisible(add,remove);
  }

  isVormundadresseAbweichendChanged(){
    let add, remove;
    if( (this.getFormItem('isVormundadresseAbweichend')?.value) ){
      add = this.vormundAdresseAbweichendOnOff;
      remove = [];
    } else {
      add = [];
      remove = this.vormundAdresseAbweichendOnOff;
    }
    this.updateEffectivelyVisible(add,remove);
  }

  praktikumDatumChange() {
    if (this.getFormItem('praktikumStartdatum')) {
      this.minDatePraktikumEnddatum = this.getFormItem('praktikumStartdatum')?.value;
      if (!(!this.getFormItem('praktikumEnddatum'))
        && this.getFormItem('praktikumEnddatum')?.value < this.getFormItem('praktikumStartdatum')?.value) {
        this.getFormItem('praktikumEnddatum')?.patchValue(this.getFormItem('praktikumStartdatum')?.value);
      }
    }
  }

  setButtonVisibility(){
    if( !this.readonly ){
      this.isEinreichenButtonVisible = !this.schuelerDeleted;
      this.isUpdateEntwurfButtonVisible = !this.schuelerDeleted;
      this.isDeleteDraftButtonVisible = !!this.antragTicketId;
    } else {
      this.isDeleteDraftButtonVisible = false;
      this.isUpdateEntwurfButtonVisible = false;
      this.isEinreichenButtonVisible = false;
    }
  }

  autoPatchForm(source: any, fields: string[]){
    fields.forEach((varname) => {
      if (source[varname]!= null && typeof source[varname] == "object"){ //geschwisterkind, haltestelle etc.
        if(varname==='einstieghaltestelle'){
          const haltestelle = source[varname];
          // make sure einstieghaltestelleList is prepopulated with einstieghaltestelle loaded with antrag
          this.einstiegHaltestelleList = this.einstiegHaltestelleList.concat({
            id: haltestelle.id,
            name: haltestelle.name,
            tarifgebietName: haltestelle.tarifgebietName,
            comboName: this.haltestelleDisplayComboNameService.getComboName(haltestelle),
            archiv: haltestelle.archiv
          });
        }
        this.antragTicketForm.patchValue({[varname] : source[varname]?.id});
      }
      else if(source[varname]){
       this.antragTicketForm.patchValue({[varname] : source[varname]});
      }
    });
  }

  /**
   * Auto create DTO from form values
   * @param targetDTO - the DTO to be filled
   * @param fields - the fields to be filled in the DTO
   */
  autoCreateDTO(targetDTO: any, fields: string[]){
    fields.forEach((varname) => {
      targetDTO[varname] = this.getFormItem(varname)?.value;
    });
  }

  addSchueler(){
    this.saveOrUpdate(TicketStatus.ENTWURF,
      "Der Antrag wurde als Entwurf gespeichert. Sie werden zur Schülerseite weitergeleitet.",
      'schueler-detail');
  }

  applyValidators(): Promise<any> {
    return new Promise ((resolve) => {
      this.clearAllValidators();
      this.checkAntragstellerAge();
      this.effectiveValidators.forEach((varName)=>{
        this.getFormItem(varName)?.setValidators([Validators.required]);
        if (
          varName === 'datenschutz'
          || varName === 'marketingfreigabe'
          || varName === 'richtigkeitDerAngaben'
          || varName === 'einverstaendnisErklaerung'
          || (varName === 'zustimmungGesetzlicherVertreter')
        ) {
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.requiredTrue]);
        }
        else if (varName === 'emailSchueler') {
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.email]);
        }
        else if (varName === 'vormundEmail') {
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.email]);
        }
        else if (varName === 'emailSepa') {
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.email]);
        }
        else if (varName.toLowerCase().includes('plz')) {
          this.setPLZValidatorForControl(varName);
        }
        else if (varName.toLowerCase().includes('iban')) {
          this.getFormItem(varName)?.setValidators([Validators.required,ValidatorService.validateIban]);
        }
        else if (varName === 'vormundGeburtsdatum') {
          this.getFormItem(varName)?.setValidators([Validators.required,createVormundAgeValidator()])
        }
        else if (varName === 'praktikumsvertrag' && !(!this.praktikumsvertrag)) {
          this.getFormItem(varName)?.clearValidators();
        }
        else if (varName === 'sonderfallNachweis' && !(!this.sonderfallNachweis)) {
          this.getFormItem(varName)?.clearValidators();
        }
        else if (varName === 'bezugNachweis' && !(!this.bezugNachweis)) {
          this.getFormItem(varName)?.clearValidators();
        }
        else if (varName === 'aboGueltigkeit') {
          this.getFormItem(varName)?.setValidators([Validators.required, createDateIsBetweenValidator(this.minDateTicketGueltigAb,this.maxDateTicketGueltigAb)]);
        }
        else if (varName === 'strasse') { // add validators for strasse and hausnummer
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.pattern(this.validationHelper.strasseHausnummerPattern)]);
        }
        else if (varName === 'telefonnummer' || varName === 'vormundTelefonnummer' || varName === 'telefonnummerSepa') { // add validators for telefonnummer
          this.getFormItem(varName)?.setValidators([Validators.pattern(this.validationHelper.telefonnummerPattern)]);
        }
        this.getFormItem(varName)?.updateValueAndValidity();
      });
      // add formal validators to validate non required fields with Regex patterns
      this.addFormalValidators();
      resolve('hurra');
    })
  }

  clearAllValidators(){
    const controls = this.antragTicketForm.controls;
    for (const name in controls) {
      controls[name].clearAsyncValidators();
      controls[name].clearValidators();
      if( !( (name === 'iban') || (name === 'ibanSepa') || (name === 'vormundGeburtsdatum') ) )
        controls[name].setErrors(null);
    }
  }

  createAntragTicket(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    this.saveCreate(targetStatus).then((result)=>{
      if(result){
          if(goToPath=='ticket-detail') goToPath += '/'+this.antragTicket.id;
          this.router.navigate(['pages/'+goToPath]).then(() => {
            this.customToastService.showSuccess(saveMsg);
          }).catch(()=>{});
      } else {
        this.spinner.hide().catch(()=>{});
      }
    }).catch(()=>{
      this.spinner.hide().catch(()=>{});
    });
  }

  saveCreate(targetStatus) : Promise<boolean> {
    return new Promise ((resolve)=>{
      let antragTicketCreateDTOtemp : AntragTicketCreateDTO = {} as AntragTicketCreateDTO;
      this.autoCreateDTO(antragTicketCreateDTOtemp,this.antragTicketFormFields);
      antragTicketCreateDTOtemp.geschwisterkind1Id = this.getFormItem('geschwisterkind1')?.value?.length !== 0 ? this.getFormItem('geschwisterkind1').value : null;
      antragTicketCreateDTOtemp.geschwisterkind2Id = this.getFormItem('geschwisterkind2')?.value?.length !== 0 ? this.getFormItem('geschwisterkind2').value : null;
      antragTicketCreateDTOtemp.geschwisterkind3Id = this.getFormItem('geschwisterkind3')?.value?.length !== 0 ? this.getFormItem('geschwisterkind3').value : null;
      antragTicketCreateDTOtemp.geschwisterkind4Id = this.getFormItem('geschwisterkind4')?.value?.length !== 0 ? this.getFormItem('geschwisterkind4').value : null;
      antragTicketCreateDTOtemp.geschwisterkind5Id = this.getFormItem('geschwisterkind5')?.value?.length !== 0 ? this.getFormItem('geschwisterkind5').value : null;
      antragTicketCreateDTOtemp.einstieghaltestelleId = this.getFormItem('einstieghaltestelle')?.value || null;
      antragTicketCreateDTOtemp.ausstieghaltestelleHinwegId = this.getFormItem('ausstieghaltestelleHinweg')?.value || null;
      //add first day to Month from ('2022-08') to ('2022-08-01')
      if(this.isMonthSelection){
        antragTicketCreateDTOtemp.aboGueltigkeit = this.getFormItem('aboGueltigkeit')?.value?.length > 0 ? new Date(this.getFormItem('aboGueltigkeit')?.value?.toString()+'-01') : null;
      }

      let antragTicketSepaCreateDTOtemp : AntragTicketSepaCreateDTO = {} as AntragTicketSepaCreateDTO;
      this.autoCreateDTO(antragTicketSepaCreateDTOtemp, this.antragTicketSepaFormFields);
      antragTicketCreateDTOtemp.status = targetStatus;
      antragTicketSepaCreateDTOtemp.isAntragstellerMandatsgeber = this.getFormItem('isAntragstellerNotMandatsgeber')?.value?.length !== 0 ? !this.getFormItem('isAntragstellerNotMandatsgeber').value : null;
      antragTicketCreateDTOtemp.schuelerId = this.getFormItem('schuelerId')?.value || undefined;
      antragTicketCreateDTOtemp.antragTicketSepa = antragTicketSepaCreateDTOtemp;

      let praktikumsvertrag: File = undefined;
      if (!(!this.getFormItem('praktikumsvertrag')) && !(!this.getFormItem('praktikumsvertragFile')?.value)) {
        praktikumsvertrag = this.getFormItem('praktikumsvertragFile').value;
      }
      let sonderfallNachweis: File = undefined;
      if (!(!this.getFormItem('sonderfallNachweis')) && !(!this.getFormItem('sonderfallNachweisFile')?.value)) {
        sonderfallNachweis = this.getFormItem('sonderfallNachweisFile').value;
      }
      let bezugNachweis: File = undefined;
      if (!(!this.getFormItem('bezugNachweis')) && !(!this.getFormItem('bezugNachweisFile')?.value)) {
        bezugNachweis = this.getFormItem('bezugNachweisFile').value;
      }
      this.antragTicketService.createAntrag(antragTicketCreateDTOtemp, praktikumsvertrag, sonderfallNachweis, bezugNachweis)
        .pipe(
          catchError((err) => {
            if (err.error.message == 'required_fields_not_present') {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden. Bitte füllen Sie alle Pflichtfelder aus.');
            } else {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden.');
            }
            resolve(false);
            return EMPTY;
          })
        )
        .subscribe((data: AntragTicketDTO) => {
          this.antragTicket = {...data};
          this.antragTicketId = ''+this.antragTicket.id;
          resolve(true);
        });
    });
  }

  updateAntragTicket(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    this.saveUpdate(targetStatus).then((result)=>{
      if (result) {
        if(goToPath=='ticket-detail') goToPath += '/'+this.antragTicket.id;
        this.router.navigate(['pages/'+goToPath]).then(() => {
          this.customToastService.showSuccess(saveMsg);
        }).catch(()=>{});
      } else {
        this.spinner.hide().catch(()=>{});
      }
    }).catch(()=>{
      this.spinner.hide().catch(()=>{});
    });
  }

  saveUpdate(targetStatus): Promise<boolean> {
    return new Promise<boolean> ((resolve)=>{
      let antragTicketUpdateDTOtemp : AntragTicketUpdateDTO = {} as AntragTicketUpdateDTO;
      this.autoCreateDTO(antragTicketUpdateDTOtemp,this.antragTicketFormFields);
      antragTicketUpdateDTOtemp.geschwisterkind1Id = this.getFormItem('geschwisterkind1')?.value?.length !== 0 ? this.getFormItem('geschwisterkind1').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind2Id = this.getFormItem('geschwisterkind2')?.value?.length !== 0 ? this.getFormItem('geschwisterkind2').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind3Id = this.getFormItem('geschwisterkind3')?.value?.length !== 0 ? this.getFormItem('geschwisterkind3').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind4Id = this.getFormItem('geschwisterkind4')?.value?.length !== 0 ? this.getFormItem('geschwisterkind4').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind5Id = this.getFormItem('geschwisterkind5')?.value?.length !== 0 ? this.getFormItem('geschwisterkind5').value : null;
      antragTicketUpdateDTOtemp.referenzFuerZahlungen = this.getFormItem('referenzFuerZahlungen').value;
      antragTicketUpdateDTOtemp.einstieghaltestelleId = this.getFormItem('einstieghaltestelle')?.value || null;
      antragTicketUpdateDTOtemp.ausstieghaltestelleHinwegId = this.getFormItem('ausstieghaltestelleHinweg')?.value || null;
      //add first day to Month from ('2022-08') to ('2022-08-01')
      if(this.isMonthSelection){
        antragTicketUpdateDTOtemp.aboGueltigkeit = this.getFormItem('aboGueltigkeit')?.value?.length > 0 ? new Date(this.getFormItem('aboGueltigkeit')?.value?.toString()+'-01') : null;
      }
      let antragTicketSepaUpdateDTOtemp : AntragTicketSepaUpdateDTO = {} as AntragTicketSepaUpdateDTO;
      this.autoCreateDTO(antragTicketSepaUpdateDTOtemp, this.antragTicketSepaFormFields);
      antragTicketUpdateDTOtemp.status = targetStatus;
      antragTicketSepaUpdateDTOtemp.isAntragstellerMandatsgeber = this.getFormItem('isAntragstellerNotMandatsgeber')?.value?.length !== 0 ? !this.getFormItem('isAntragstellerNotMandatsgeber').value : undefined;
      antragTicketUpdateDTOtemp.antragTicketSepa = antragTicketSepaUpdateDTOtemp;
      antragTicketUpdateDTOtemp.schuelerId = this.getFormItem('schuelerId')?.value || null;

      let praktikumsvertrag: File = undefined;
      if (!(!this.getFormItem('praktikumsvertrag')) && !(!this.getFormItem('praktikumsvertragFile')?.value)) {
        praktikumsvertrag = this.getFormItem('praktikumsvertragFile').value;
      }
      let sonderfallNachweis: File = undefined;
      if (!(!this.getFormItem('sonderfallNachweis')) && !(!this.getFormItem('sonderfallNachweisFile')?.value)) {
        sonderfallNachweis = this.getFormItem('sonderfallNachweisFile').value;
      }
      let bezugNachweis: File = undefined;
      if (!(!this.getFormItem('bezugNachweis')) && !(!this.getFormItem('bezugNachweisFile')?.value)) {
        bezugNachweis = this.getFormItem('bezugNachweisFile').value;
      }

      this.antragTicketService.updateAntrag(parseInt(this.antragTicketId), antragTicketUpdateDTOtemp, praktikumsvertrag, sonderfallNachweis, bezugNachweis)
        .pipe(
          catchError((err) => {
            if (err.error.message == 'required_fields_not_present') {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden. Bitte füllen Sie alle Pflichtfelder aus.');
            } else {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden.');
            }
            resolve(false);
            return EMPTY;
          })
        )
        .subscribe((data: AntragTicketDTO) => {
          this.antragTicket = {...data};
          resolve(true);
        });
    });
  }

  //DECISION EINREICHEN
  showDecisionModal(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.textbausteinService.printTextbausteinByKey( KeyString.ANTRAGTICKET_EINREICHEN_MODAL_TITEL,
      this.translateService.instant('MODAL.ANTRAG_EINREICHEN_MODAL_TITEL'));
    modalRef.componentInstance.text = this.textbausteinService.printTextbausteinByKey( KeyString.ANTRAGTICKET_EINREICHEN_MODAL_TEXT,
      this.translateService.instant('MODAL.ANTRAG_EINREICHEN_MODAL_TEXT'));
    modalRef.componentInstance.onYes = () => {
      this.spinner.show().catch(()=>{});
      if(!this.antragTicketId){
        this.createAntragTicket(targetStatus, saveMsg, goToPath);
      } else {
        this.updateAntragTicket(targetStatus, saveMsg, goToPath);
      }
    };
  }

  //SAVE
  saveOrUpdate(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    this.spinner.show().catch(()=>{});
    if (this.getFormItem('schwerbehinderung').value == null || this.getFormItem('schwerbehinderung').value == undefined){
      this.antragTicketForm.patchValue({schwerbehinderung: false});
    }
    if (this.getFormItem('datenschutz').value == null || this.getFormItem('datenschutz').value == undefined){
      this.antragTicketForm.patchValue({datenschutz: false} );
    }
    if (this.getFormItem('marketingfreigabe').value == null || this.getFormItem('marketingfreigabe').value == undefined){
      this.antragTicketForm.patchValue({marketingfreigabe: false} );
    }
    if (this.getFormItem('isAntragstellerNotMandatsgeber').value == null || this.getFormItem('isAntragstellerNotMandatsgeber').value == undefined){
      this.antragTicketForm.patchValue({isAntragstellerNotMandatsgeber: false} );
    }
    if(targetStatus == TicketStatus.EINGEREICHT){
      this.isSubmitted = true;
      this.applyValidators().then(()=>{
        this.spinner.hide().catch(()=>{});
        if (!this.antragTicketForm.valid) {
          this.customToastService.showError('Es sind nicht alle Felder korrekt ausgefüllt: ' + this.getNotValid());
        } else if (this.isAntragstellerYoungerThanMinimumAge) {
          this.customToastService.showError('Der Antragsteller muss mindestens '+this.configService.featureConfig.minimumAge+' Jahre alt sein.');
        } else {
          this.showDecisionModal(targetStatus,saveMsg,goToPath);
        }
      }).catch(err=>console.log(err));
    } else if(targetStatus == TicketStatus.ENTWURF) {
      this.isSubmitted = false;
      this.clearAllValidators();
      if(!this.antragTicketId){
        this.createAntragTicket(targetStatus, saveMsg, goToPath);
      } else {
        this.updateAntragTicket(targetStatus, saveMsg, goToPath);
      }
    }
  }

  deleteDraft(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.textbausteinService.printTextbausteinByKey( KeyString.ENTWURF_LOESCHEN_MODAL_TITEL,
      this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_TITEL'));
    modalRef.componentInstance.text = this.textbausteinService.printTextbausteinByKey( KeyString.ENTWURF_LOESCHEN_MODAL_TEXT,
      this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_TEXT'));
    modalRef.componentInstance.onYes = () => {
      this.antragTicketService.deleteAntrag(parseInt(this.antragTicketId))
        .pipe(
          catchError(() => {
            this.customToastService.showError(this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_ERROR'));
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.router.navigate(['/pages/ticketTabelle']).then(() => {
            this.customToastService.showSuccess(this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_SUCCESS'));
          }).catch(()=>{});
        });
    };
  }

  loadNextPageEinstiegHaltestelle(){
    //nachladen wenn scrolling am Ende der Liste angekommen ist
    if(this.einstiegHaltestellePage.totalPages > this.einstiegHaltestellePagination.offset+1) {
      this.einstiegHaltestellePagination.offset = this.einstiegHaltestellePagination.offset + 1;
      this.updateEinstiegshaltestelle();
    }
  }

  loadErrorMessage(){
    this.customToastService.showError('Daten konnten nicht geladen werden. Stellen Sie sicher, dass Ihre Sitzung im Portal nicht abgelaufen ist.');
    this.router.navigate(['pages/ticket-table']).catch(()=>{});
  }

  loadDataErrorMessage(data: string){
    this.customToastService.showError('Daten konnten nicht geladen werden: ' +data);
    this.router.navigate(['pages/ticket-table']).catch(()=>{});
  }

  updateEinstiegshaltestelle(){
    this.einstiegHaltestelleLoading = true;
    this.haltestelleService.getHaltestellenPage(this.paginationService.getHttpParamsForPagination(this.einstiegHaltestellePagination)).subscribe(pageDto =>
    {
      this.einstiegHaltestelleLoading = false;
      this.einstiegHaltestellePagination.count = pageDto.currentCount;
      this.einstiegHaltestellePage = pageDto;
      let list = this.haltestelleDisplayComboNameService
        .haltestellenListToMinimalList(pageDto.items);
      if(this.einstiegHaltestellePage.page === 0) {
        this.einstiegHaltestelleList = list;
      } else {
        this.einstiegHaltestelleList = this.einstiegHaltestelleList.concat(list);
      }
    });
  }

  checkForHaltestellenMatch(caller:string){
    if(!this.antragTicketForm.get(caller)?.value)
      return;
    let einstieg = this.antragTicketForm.get('einstieghaltestelle')?.value || undefined;
    let ausstieg = this.antragTicketForm.get('ausstieghaltestelleHinweg')?.value || undefined;
    if( einstieg == ausstieg ){
      this.antragTicketForm.get(caller).patchValue(undefined,{emitEvent: false, onlySelf: true});
      this.customToastService.showError("Einstieg- und Ausstieghaltestelle dürfen nicht identisch sein.")
    }
  }

  filterEinstieghaltestelle() {
    const valName = (<HTMLInputElement>document.getElementById('select-einstieghaltestelle')).value.toString().toLowerCase()
    const filterString = this.getFilterStringForServer(valName);
    if(filterString.length > 0) {
      this.einstiegHaltestellePagination.filter = filterString;
      this.einstiegHaltestellePagination.offset = 0;
      this.updateEinstiegshaltestelle();
    } else {
      this.einstiegHaltestelleList = null;
    }
  }

  setOrt(event, formItem: string) {
    this.postleitzahlenService.getPostleitzahlByPlz(event.target.value).subscribe(result => {
      if (result !== null) {
        this.getFormItem(formItem)?.patchValue(result.ort);
      }
    });
  }

  getFilterStringForServer(value: string){
    return ( !value || value.trim().length < 2)? '': "name:"+value;
  }

  initListsForSBtrue() : Observable<any> {

    let schulenObservable = this.schuleService.getSchulen(true);
    schulenObservable.pipe(catchError(() => {
        this.loadErrorMessage();
        return EMPTY;
      }))
      .subscribe(schulen => {
        this.schuleList = schulen;
        if (!schulen || schulen.length < 1) {
          this.loadDataErrorMessage(this.translateService.instant('PLURAL.SCHULE'));
        }
      });

    let schuljahrObservable = this.schuljahrService.getSchuljahre();
    schuljahrObservable.pipe(catchError(() => {
        this.loadErrorMessage();
        return EMPTY;
      }))
      .subscribe(schuljahre => {
        this.schuljahreList = schuljahre;
        if(!schuljahre || schuljahre.length<1){
          this.loadDataErrorMessage(this.translateService.instant('PLURAL.SCHULJAHR'));
        }
      });

    let schuelerListObservable = this.schuelerService.getSchuelerList();
    schuelerListObservable.pipe(catchError(() => {
        this.loadErrorMessage();
        return EMPTY;
      }))
      .subscribe(schueler => {
        if( !schueler || schueler.length===0 || !Array.isArray(schueler) )
          return;
        schueler.forEach((gizmo) => {
          this.schuelerList = [...this.schuelerList, {id: gizmo.id, fullName: ""+gizmo.vorname+' '+gizmo.name}];
        });
      });

    return forkJoin([
      schulenObservable,
      schuljahrObservable,
      schuelerListObservable
    ]);
  }

  checkIfVorgaengerExists(){

    if( this.readonly || this.isInitializing || !this.antragTicketForm )
      return;

    this.resetFormValues(['name','vorname','strasse','plz','ort',
      'land','adresszusatz','geburtsdatum','isSchuelerUnderAge','emailSchueler','telefonnummer',
      'geschlecht','schule','typ','schuelernummer',
      'geschwisterkind1','geschwisterkind2','geschwisterkind3','geschwisterkind4','geschwisterkind5', 'aboGueltigkeit']);
    this.resetFormValidators(['name','vorname','strasse','plz','ort',
      'land','adresszusatz','geburtsdatum','isSchuelerUnderAge','emailSchueler','telefonnummer',
      'geschlecht','schule','typ','schuelernummer',
      'geschwisterkind1','geschwisterkind2','geschwisterkind3','geschwisterkind4','geschwisterkind5', 'aboGueltigkeit']);

    this.isInitializing = true;

    const schuelerId = this.getFormItem('schuelerId')?.value;

    let vorgaenger: AntragTicketDTO = null;
    if(schuelerId) {
      this.antragTicketService.getVorgaenger(schuelerId).subscribe(result => {
        vorgaenger = result;
        if (vorgaenger) {
          this.customToastService.showInfo('Dieser Antrag wird als Folgeantrag gestellt. Die Daten wurden aus einem bereits genehmigten Antrag übernommen (Antrag ID: ' + vorgaenger.id + '). ', 'Information', '/pages/ticket-detail/' + vorgaenger.id)

          // the following checks need be outside patchValue
          // because patchValue does not handle in-line typecasting well -> null error
          const schule = getObjIfExistsInListByAttribute(vorgaenger.schule, this.schuleList, 'name') as SchuleDTO
          const ticket = getObjIfExistsInListByAttribute(vorgaenger.ticket, this.filteredTicketList, 'name') as TicketDTO

          // klasse, schuljahr etc. do not get patched from vorgänger,
          // because those likely change anyways
          // as Schueler progresses from one AntragTicket period to another

          // make sure einstieghaltestelleList is prepopulated with einstieghaltestelle loaded with antrag
          const haltestelle = vorgaenger.einstieghaltestelle;
          this.einstiegHaltestelleList = this.einstiegHaltestelleList.concat({
            id: haltestelle.id,
            name: haltestelle.name,
            tarifgebietName: haltestelle.tarifgebietName,
            comboName: this.haltestelleDisplayComboNameService.getComboName(haltestelle),
            archiv: haltestelle.archiv
          });

          this.antragTicketForm.patchValue({
            name: this.benutzer?.name || null,
            vorname: this.benutzer?.vorname || null,
            strasse: this.benutzer?.strasse || null,
            plz: this.benutzer?.plz || null,
            ort: this.benutzer?.ort || null,
            land: this.benutzer?.land || 'DEUTSCHLAND',
            adresszusatz: this.benutzer?.adresszusatz || null,
            geburtsdatum: this.benutzer?.geburtsdatum || null,
            emailSchueler: this.benutzer?.email || null,
            sonderfall: vorgaenger.sonderfall || null,
            sonderfallBegruendung: vorgaenger.sonderfallBegruendung || null,
            geschlecht: this.benutzer?.geschlecht || null,
            schule: schule?.name || null,
            ticket: ticket?.name || null,
            einstieghaltestelle: vorgaenger.einstieghaltestelle.id || null,
            ausstieghaltestelleHinweg: vorgaenger.ausstieghaltestelleHinweg.id || null,
            bemerkungAntragsteller: vorgaenger.bemerkungAntragsteller || null,
            typ: 'FOLGEANTRAG',
            schuelernummer: vorgaenger.schuelernummer || null,
          });
          this.schuelerDeleted = vorgaenger.schueler?.deleted;
          this.setButtonVisibility();
          this.isInitializing = false;
        }
        else { // no vorgaenger antrag => use schueler data

          this.schuelerService.getSchueler(schuelerId).subscribe(result => {

            const schueler = result;
            const schule = getObjIfExistsInListByAttribute(schueler.schule, this.schuleList, 'name') as SchuleDTO

            this.antragTicketForm.patchValue({
              name: schueler?.name || null,
              vorname: schueler?.vorname || null,
              strasse: schueler?.strasse || null,
              plz: schueler?.plz || null,
              ort: schueler?.ort || null,
              land: schueler?.land || 'DEUTSCHLAND',
              adresszusatz: schueler?.adresszusatz || null,
              geburtsdatum: schueler?.geburtsdatum || null,
              emailSchueler: this.benutzer?.email || null,
              geschlecht: schueler?.geschlecht || null,
              schule: schule?.name || null,
              typ: 'NEUANTRAG',
              schuelernummer: schueler.schuelernummer || null,
            });
            this.schuelerDeleted = schueler.deleted;
            this.setButtonVisibility();
            this.isInitializing = false;
          });
        }
      });
    }
    else { // no vorgaenger and no schueler / schuelerId
      this.antragTicketForm.patchValue({
        name: null,
        vorname: null,
        strasse: null,
        plz: null,
        ort: null,
        land: null,
        adresszusatz: null,
        geburtsdatum: null,
        emailSchueler: null,
        schwerbehinderung: null,
        geschlecht: null,
        schule: null,
        typ: Typ.NEUANTRAG,
        schuelernummer: null,
      });
      this.schuelerDeleted = false;
      this.setButtonVisibility();
      this.isInitializing = false;
    }
  }

  getFormItem(s: string) {
    return this.antragTicketForm?.get(s);
  }

  sanitize(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  previewImg(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.image = src;
  }

  previewPDF(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.width = 500;
    modalRef.componentInstance.height = 600;
    modalRef.componentInstance.pdf = src;
  }

  handleNachweis({currentTarget}){
    if(currentTarget.files != null && currentTarget.files.length > 0) {
      let file : File = currentTarget.files[0];
      this.imageHelper.handleNachweisFile(file)
        .then((processed)=>{
          let fileFormControlName : string = undefined;
          switch (currentTarget.id){
            case 'input-praktikumsvertrag':
              this.praktikumsvertragIsPDF = processed.isPDF;
              this.praktikumsvertrag = processed.image;
              fileFormControlName = 'praktikumsvertragFile';
              break;
            case 'input-sonderfallNachweis':
              this.sonderfallNachweisIsPDF = processed.isPDF;
              this.sonderfallNachweis = processed.image;
              fileFormControlName = 'sonderfallNachweisFile';
              break;
            case 'input-bezugNachweis':
              this.bezugNachweisIsPDF = processed.isPDF;
              this.bezugNachweis = processed.image;
              fileFormControlName = 'bezugNachweisFile';
              break;
            default:
              console.log('Error, formControlId not recognized.',currentTarget.id);
              break;
          }
          if(processed.error) {
            this.customToastService.showError(processed.error);
            this.getFormItem(fileFormControlName).patchValue(undefined);
            return;
          }
          this.getFormItem(fileFormControlName).patchValue(file);
        }).catch(()=>{});
      } else {
        this.deleteNachweis((currentTarget.id).split('input-')[1])
      }
  }

  dynamicWidth(field: string){
    const len = this.getFormItem(field)?.value? this.getFormItem(field).value.length + 1 : 0;
    return 'width:'+len+'ch;'
  }

  setGeschwisterkind(nr: number){
    let varName = 'geschwisterkind'+nr;
    let schuelerIdgeschwister = this.getFormItem(varName)?.value;
    if(!schuelerIdgeschwister)
      return;
    let numbersToCompare = [1,2,3,4,5];
    numbersToCompare.splice(numbersToCompare.indexOf(nr),1);
    let selectedSchueler : SchuelerDTO;

    //case first edit
    if(this.getFormItem('schuelerId')?.value?.length > 0){
      let schuelerIdsToCompare = [this.getFormItem('schuelerId')?.value];
      numbersToCompare.forEach(num=>
        schuelerIdsToCompare = [...schuelerIdsToCompare,this.getFormItem('geschwisterkind'+num).value]);
      if(schuelerIdsToCompare.indexOf(schuelerIdgeschwister)>-1){
        this.antragTicketForm.patchValue({varName : ''});
        this.customToastService.showError("Sie haben diese Person bereits ausgewählt.");
        return;
      }
    } else { //Case load entwurf
            //schuelerIdsToCompare == null
      let schuelerToCompare= [(this.getFormItem('name')?.value + this.getFormItem('vorname')?.value +  this.getFormItem('geburtsdatum')?.value + this.getFormItem('plz')?.value + this.getFormItem('strasse')?.value+ this.getFormItem('ort')?.value)];
      numbersToCompare.forEach(num=>
        schuelerToCompare = [...schuelerToCompare,(this.getFormItem('geschwisterkind'+num+'name')?.value + this.getFormItem('geschwisterkind'+num+'vorname')?.value + this.getFormItem('geschwisterkind'+num+'geburtsdatum')?.value+ this.getFormItem('geschwisterkind'+num+'plz')?.value + this.getFormItem('geschwisterkind'+num+'strasse')?.value + this.getFormItem('geschwisterkind'+num+'ort')?.value)]);

      this.schuelerService.getSchueler(schuelerIdgeschwister).subscribe(
        (schueler) => {
          if (schueler) {
            selectedSchueler = schueler;
            let schuelerCompareStringGeschwister = schueler['name']+ schueler['vorname']+schueler['geburtsdatum']+schueler['plz']+schueler['strasse']+schueler['ort'];
            if(schuelerToCompare.indexOf(schuelerCompareStringGeschwister)>-1){
              this.antragTicketForm.patchValue({varName : ''});
              this.customToastService.showError("Sie haben diese Person bereits ausgewählt!");
              return;
            }
          }
        });
    }

    if(selectedSchueler) {
      this.patchSchuelerFields(selectedSchueler, nr);
    } else {
      this.schuelerService.getSchueler(schuelerIdgeschwister).subscribe(
        (schueler) => {
          this.patchSchuelerFields(schueler, nr);
        }
      )
    }
  }

  private patchSchuelerFields(schueler: SchuelerDTO, nr: number) {
    let varName = 'geschwisterkind'+nr;
    if (schueler) {
      let fieldsToPatch = ['name', 'vorname', 'geburtsdatum', 'strasse', 'plz', 'ort'];
      fieldsToPatch.forEach((field) => {
        this.antragTicketForm.patchValue({[varName + field]: schueler[field]});
      });
      this.antragTicketForm.patchValue({[varName + 'schule']: !!schueler['schule'] ? schueler['schule']['name'] : 'Keine'});
    }
  }

  initEmptyForm(){
    this.antragTicketForm = this.fb.group({
      name: undefined,
      vorname: undefined,
      strasse: undefined,
      plz: undefined,
      ort: undefined,
      geburtsdatum: undefined,
      email: undefined,
      emailSchueler: undefined,
      telefonnummer: undefined,
      schwerbehinderung: undefined,
      aboGueltigkeit: undefined,
      schule: undefined,
      isBerufskolleg: undefined,
      schuljahr: undefined,
      klasse: undefined,
      ticket: undefined,
      bildungsgangdatum: undefined,
      fachbereich: undefined,
      geschlecht: undefined,
      einstieghaltestelle: undefined,
      ausstieghaltestelleHinweg: undefined,
      referenzFuerZahlungen: undefined,
      abonnementnummerAntrag: undefined,
      bemerkungAntragsteller: undefined,
      einverstaendnisErklaerung: undefined,

      geschwisterkind1: undefined,
      geschwisterkind2: undefined,
      geschwisterkind3: undefined,
      geschwisterkind4: undefined,
      geschwisterkind5: undefined,

      geschwisterkind1name: undefined,
      geschwisterkind1vorname: undefined,
      geschwisterkind1geburtsdatum: undefined,
      geschwisterkind1strasse: undefined,
      geschwisterkind1plz: undefined,
      geschwisterkind1ort: undefined,
      geschwisterkind1schule: undefined,

      geschwisterkind2name: undefined,
      geschwisterkind2vorname: undefined,
      geschwisterkind2geburtsdatum: undefined,
      geschwisterkind2strasse: undefined,
      geschwisterkind2plz: undefined,
      geschwisterkind2ort: undefined,
      geschwisterkind2schule: undefined,

      geschwisterkind3name: undefined,
      geschwisterkind3vorname: undefined,
      geschwisterkind3geburtsdatum: undefined,
      geschwisterkind3strasse: undefined,
      geschwisterkind3plz: undefined,
      geschwisterkind3ort: undefined,
      geschwisterkind3schule: undefined,

      geschwisterkind4name: undefined,
      geschwisterkind4vorname: undefined,
      geschwisterkind4geburtsdatum: undefined,
      geschwisterkind4strasse: undefined,
      geschwisterkind4plz: undefined,
      geschwisterkind4ort: undefined,
      geschwisterkind4schule: undefined,

      geschwisterkind5name: undefined,
      geschwisterkind5vorname: undefined,
      geschwisterkind5geburtsdatum: undefined,
      geschwisterkind5strasse: undefined,
      geschwisterkind5plz: undefined,
      geschwisterkind5ort: undefined,
      geschwisterkind5schule: undefined,

      schuelernummer: undefined,
      richtigkeitDerAngaben: undefined,
      typ: undefined,
      schuelerId: undefined,
      marketingfreigabe: undefined,

      land: 'DEUTSCHLAND',
      vormundName: undefined,
      vormundVorname: undefined,
      isVormundadresseAbweichend: undefined,
      vormundStrasse: undefined,
      vormundOrt: undefined,
      vormundPlz: undefined,
      vormundAdresszusatz: undefined,
      vormundLand: undefined,
      vormundGeburtsdatum: undefined,
      vormundTelefonnummer: undefined,
      vormundEmail: undefined,
      vormundGeschlecht: undefined,
      wegtyp: undefined,
      bildungsgang: undefined,
      schultageJeWoche: undefined,
      praktikumHinweis: undefined,
      praktikumStelle: undefined,
      praktikumStrasse: undefined,
      praktikumOrt: undefined,
      praktikumPlz: undefined,
      praktikumLand: undefined,
      praktikumAdresszusatz: undefined,
      praktikumStartdatum: undefined,
      praktikumEnddatum: undefined,
      praktikumstageJeWoche: undefined,
      sonderfallEnabled: undefined,
      sonderfall: undefined,
      sonderfallBegruendung: undefined,
      bezugAsylbewerberleistungsgesetz: undefined,
      adresszusatz: undefined,
      zustimmungGesetzlicherVertreter: undefined,

      // files
      praktikumsvertrag: undefined,
      praktikumsvertragFile: undefined,
      praktikumsvertragId: undefined,
      sonderfallNachweis: undefined,
      sonderfallNachweisFile: undefined,
      sonderfallNachweisId: undefined,
      bezugNachweis: undefined,
      bezugNachweisFile: undefined,
      bezugNachweisId: undefined,

      // SEPA
      vornameSepa: undefined,
      nameSepa: undefined,
      strasseSepa: undefined,
      plzSepa: undefined,
      ortSepa: undefined,
      adresszusatzSepa: undefined,
      landSepa: undefined,
      emailSepa: undefined,
      telefonnummerSepa: undefined,
      geburtsdatumSepa: undefined,
      geschlechtSepa: undefined,
      iban: undefined,
      ibanSepa: undefined,
      isAntragstellerNotMandatsgeber: undefined,
      datenschutz: undefined,
      bicSepa: undefined,
      bic: undefined,
      nameKreditinstitut: undefined,
      nameKreditinstitutSepa: undefined,

      status: undefined,
    });
  }

  correctLand(){
    let occurencesOfLand = ['land','vormundLand','praktikumLand','landSepa'];
    occurencesOfLand.forEach( (occ:string) => {
      let land = this.getFormItem(occ).value;
      if( !land || land.length<1 || (!(<any>Object).keys(Land).includes(land)&&!(<any>Object).keys(Land).includes(land.toUpperCase())) ){
        this.antragTicketForm.patchValue({[occ]: 'DEUTSCHLAND'});
      }
    });
  }

  setPLZValidatorForControl(plzControlname:string) {
    if (!this.isSubmitted)
      return;
    this.getFormItem(plzControlname)?.setValidators([
      Validators.maxLength(this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value)),
      Validators.pattern(this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value))
    ]);
  }
  getMaxLength(plzControlname:string): number {
    return this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }
  getPattern(plzControlname:string): string {
    return this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }

  addFormalValidators(){
    if(this.effectivelyVisible?.includes('iban')) {
      this.getFormItem('iban')?.addValidators([ValidatorService.validateIban]);
      this.getFormItem('iban')?.updateValueAndValidity();
    }
    if(this.effectivelyVisible?.includes('ibanSepa')) {
      this.getFormItem('ibanSepa')?.addValidators([ValidatorService.validateIban]);
      this.getFormItem('ibanSepa')?.updateValueAndValidity();
    }
    if(this.effectivelyVisible?.includes('vormundGeburtsdatum')) {
      this.getFormItem('vormundGeburtsdatum')?.addValidators([createVormundAgeValidator()]);
      this.getFormItem('vormundGeburtsdatum')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('abonnementnummerAntrag') && this.moduleConfigService.isModuleEnabled('patrisExport')) { // add validators for abonnementnummerAntrag for patris export
      this.getFormItem('abonnementnummerAntrag')?.addValidators([Validators.pattern(this.validationHelper.abonummerPattern)]);
      this.getFormItem('abonnementnummerAntrag')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('strasse')) { // add validators for strasse and hausnummer
      this.getFormItem('strasse')?.addValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('strasse')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('vormundStrasse')) { // add validators for strasse and hausnummer
      this.getFormItem('vormundStrasse')?.addValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('vormundStrasse')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('praktikumStrasse')) { // add validators for strasse and hausnummer
      this.getFormItem('praktikumStrasse')?.addValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('praktikumStrasse')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('strasseSepa')) { // add validators for strasse and hausnummer
      this.getFormItem('strasseSepa')?.addValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('strasseSepa')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('telefonnummer')) { // add validators for telefonnummer
      this.getFormItem('telefonnummer')?.addValidators(Validators.pattern(this.validationHelper.telefonnummerPattern));
      this.getFormItem('telefonnummer')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('vormundTelefonnummer')) { // add validators for telefonnummer
      this.getFormItem('vormundTelefonnummer')?.addValidators(Validators.pattern(this.validationHelper.telefonnummerPattern));
      this.getFormItem('vormundTelefonnummer')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('telefonnummerSepa')) { // add validators for telefonnummer
      this.getFormItem('telefonnummerSepa')?.addValidators(Validators.pattern(this.validationHelper.telefonnummerPattern));
      this.getFormItem('telefonnummerSepa')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('emailSchueler')) { // add validators for email
      this.getFormItem('emailSchueler')?.addValidators(Validators.pattern(this.validationHelper.emailPattern));
      this.getFormItem('emailSchueler')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('vormundEmail')) { // add validators for email
      this.getFormItem('vormundEmail')?.addValidators(Validators.pattern(this.validationHelper.emailPattern));
      this.getFormItem('vormundEmail')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('emailSepa')) { // add validators for email
      this.getFormItem('emailSepa')?.addValidators(Validators.pattern(this.validationHelper.emailPattern));
      this.getFormItem('emailSepa')?.updateValueAndValidity();
    }
  }

  getNotValid() : string{
    let notValid: string = '';
    const controls = this.antragTicketForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        let temp = document.getElementById('sfklabel-'+name)
        notValid += ''+ (
          temp?.innerHTML.replace(''+this.pflichtFeldMarker,'').trim()
          || this.fieldConfigService.getFieldLabel(name, this.fieldConfig, toTitlecase(name))) +', ';
      }
    }
    return notValid.substring(0, notValid.length-2);  // remove last comma
  }

  ngOnDestroy(){
    this.formItemSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.spinner.hide().catch(()=>{});
  }

  deleteNachweis(currentTarget: string){
    if( this.readonly || this.isInitializing || !this.antragTicketForm )
      return;
    switch (currentTarget) {
      case 'sonderfallNachweis': this.sonderfallNachweis = null; break;
      case 'praktikumsvertrag': this.praktikumsvertrag = null; break;
      case 'bezugNachweis': this.bezugNachweis = null; break;
      default: return;
    }
    this.antragTicketForm.get(currentTarget).patchValue(null,{emitEvent: false, onlySelf: true});
    this.antragTicketForm.get(currentTarget+'File').patchValue(undefined,{emitEvent: false, onlySelf: true});
    this.antragTicketForm.get(currentTarget+'Id').patchValue(null,{emitEvent: false, onlySelf: true});
    this.antragTicketForm.get(currentTarget).updateValueAndValidity();
  }

  private initAboDatumGueltigAb() {
    this.isMonthSelection = this.moduleConfigService.isModuleEnabled("vertragsbeginnMonthly");
    this.gueltigAbInfoText = this.translateService.instant('TICKETDETAIL.ABO_GUELTIG_AB_INFO_KEIN_SCHULJAHR'); //"Bitte erst ein Schuljahr wählen";
  }

  setBildungsgangSetBySachbearbeiterBoolean(){
    this.bildungsgangSetBySachbearbeiter = this.fieldConfigService.getFieldFromFieldConfig('showInfoBildungsgangSetBySachbearbeiter',this.fieldConfig)?.visible;
  }

  getBildungsgangInfotextBelowInput() : string {
    if (this.bildungsgangSetBySachbearbeiter) {
      return 'Der Bildungsgang wird von dem Sachbearbeiter für den Schüler ausgefüllt werden.'
    } else {
      return this.getInfoTextbausteinOrTranslate(KeyString.BILDUNGSGANG_INFO_BELOW,'');
    }
  }

  getFieldLabelOrTranslate(fieldName: string, translateKey: string): string {
    return ''+(this.effectiveValidators.includes(fieldName)?PFLICHTFELDMARKER:'')
      +this.fieldConfigService.getFieldLabel(fieldName, this.fieldConfig, this.translateService.instant(translateKey));
  }

  getTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey?
        this.translateService.instant(translateKey) : '') : '';
  }

  filterAntragTicketSonderfallByFieldConfig(){
    // apply fieldConfig for Sonderfall options only if this is "fresh" Antrag
    // see: SFK2-1743
    if(
      ( !this.optionSonderfall || this.optionSonderfall.length === 0)
      || (this.antragTicket && this.antragTicket.id)
      || !this.isAntragTicketSonderfallModuleEnabled
    )
      return;
    const fieldConfigAntragTicketSonderfallFields = this.fieldConfigService
      .getVisibleFieldsForModuleFromSessionStorage('antragTicketSonderfall')
      .map( it => it.field );
    this.optionSonderfall = this.optionSonderfall
      .filter(it => !it.value || fieldConfigAntragTicketSonderfallFields.includes(it.value));
  }

  filterAntragTicketTypByFieldConfig(){
    // apply fieldConfig for Typ options only if this is "fresh" Antrag
    // see: SFK2-1743
    if(
      ( !this.optionTyp || this.optionTyp.length === 0)
      || (this.antragTicket && this.antragTicket.id)
      || !this.isAntragTicketTypModuleEnabled
    )
      return;
    const fieldConfigAntragTicketTypFields = this.fieldConfigService
      .getVisibleFieldsForModuleFromSessionStorage('antragTicketTyp')
      .map( it => it.field );
    this.optionTyp = this.optionTyp
      .filter(it => !it.value || fieldConfigAntragTicketTypFields.includes(it.value));
  }

  protected readonly KeyString = KeyString;
}
