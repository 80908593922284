import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-custom-radio',
  templateUrl: './custom-radio.component.html',
  styleUrls: ['./custom-radio.component.scss']
})
export class CustomRadioComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() hideLabel = false;
  @Input() options: { name: string; value: any }[];
  @Input() label: string;
  @Input() customFormGroup: UntypedFormGroup;

	@Input() readonly = false;
  // @Input() disabled = false;
  @Input() infoText: string;
  @Input() infoTextBelowInput: string;

  @Output() onchange = new EventEmitter();

  faInfo = faInfoCircle;
  currentLength = 0;
  destroySub$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.customFormGroup
      .get(this.id)
      .valueChanges.pipe(takeUntil(this.destroySub$))
      .subscribe(value => {
        if (value) {
          this.currentLength = value.length;
        } else {
          this.currentLength = 0;
        }
      });
  }

  triggerOnChange($event) {
      this.onchange.emit($event);
  }

  c(id: string) {
    return this.customFormGroup.get(id);
  }

  ngOnDestroy(): void {
    this.destroySub$.next();
    this.destroySub$.complete();
  }

  protected readonly environment = environment;
}
