import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppInfoDTO} from "../../../models/appinfo/AppInfoDTO";


@Injectable({
  providedIn: 'root'
})
export class VersionService {

  configUrl = 'api/version';

  constructor(private http: HttpClient) {
  }

  getVersion(): Observable<AppInfoDTO> {
    return this.http.get<AppInfoDTO>(this.configUrl);
  }



}
