import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class GeschlechtHelperService {

  constructor(private translateService: TranslateService) {}

  getGeschlechtOptionList(){
    return [{id: null, name: "-" },
      {id:"MAENNLICH", name: this.translateService.instant("STANDARD.GESCHLECHT.MAENNLICH") },
      {id:"WEIBLICH", name: this.translateService.instant("STANDARD.GESCHLECHT.WEIBLICH") },
      {id:"DIVERS", name: this.translateService.instant("STANDARD.GESCHLECHT.DIVERS") }
    ];
  }

}
