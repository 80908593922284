import {NgModule} from '@angular/core';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {PagesModule} from '../../pages/pages.module';
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RegistrierungComponent} from './registrierung/registrierung.component';
import {AktivierungComponent} from './aktivierung/aktivierung.component';
import {EinladungComponent} from './einladung/einladung.component';
import {KennwortVergessenComponent} from './kennwort-vergessen/kennwort-vergessen.component';
import {KennwortZuruecksetzenComponent} from './kennwort-zuruecksetzen/kennwort-zuruecksetzen.component';
import {KennwortAendernComponent} from './kennwort-aendern/kennwort-aendern.component';
import {EmailAendernComponent} from './email-aendern/email-aendern.component';
import {UnlockComponent} from './unlock/unlock.component';
import {DatePipe, NgIf} from "@angular/common";

@NgModule({
  imports: [
    AuthRoutingModule,
    PagesModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    NgIf,
    DatePipe
  ],
	declarations: [AuthComponent, LoginComponent, RegistrierungComponent, AktivierungComponent, EinladungComponent, KennwortVergessenComponent, KennwortZuruecksetzenComponent, KennwortAendernComponent, EmailAendernComponent, UnlockComponent]
})
export class AuthModule {}
