import {RouterModule, Routes} from '@angular/router';
import {PagesComponent} from './pages.component';
import {NgModule} from '@angular/core';
import {TicketTableComponent} from "./antrag-ticket/ticket-table/ticket-table.component";
import {SchuelerTableComponent} from "./schueler/schueler-table/schueler-table.component";
import {SchuelerDetailComponent} from "./schueler/schueler-detail/schueler-detail.component";
import {TicketDetailParentComponent} from "./antrag-ticket/ticket-detail-parent/ticket-detail-parent.component";
import {FahrkostenTableComponent} from "./fahrkosten/fahrkosten-table/fahrkosten-table.component";
import {
  FahrkostenDetailParentComponent
} from "./fahrkosten/fahrkosten-detail-parent/fahrkosten-detail-parent.component";
import {ConfigComponent} from "./admin/config/config.component";
import {TextbausteineComponent} from "./admin/textbausteine/textbausteine.component";
import {TextbausteineDetailComponent} from "./admin/textbausteine/textbausteine-detail/textbausteine-detail.component";
import {SchulenComponent} from './admin/schulen/schulen.component';
import {SchulenDetailComponent} from "./admin/schulen/schulen-detail/schulen-detail.component";
import {HomeComponent} from './home/home.component';
import {SchulekoordinatenComponent} from "./admin/schulen/schulekoordinaten/schulekoordinaten.component";
import {SchultypenComponent} from "./admin/schultypen/schultypen.component";
import {SchultypenDetailComponent} from "./admin/schultypen/schultypen-detail/schultypen-detail.component";
import {authGuard} from "../@core/guards/auth.guard";
import {ProfilComponent} from "./profil/profil.component";
import {AccountComponent} from "./account/account.component";
import {profileGuard} from "../@core/guards/profile.guard";

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'profil',
        component: ProfilComponent
      },
      {
        path: 'account',
        component: AccountComponent,
      },
			{
				path: 'ticket-table',
				component: TicketTableComponent
			},
      {
        path: 'ticket-detail',
        component: TicketDetailParentComponent,
        canActivate: [profileGuard]
      },
      {
        path: 'ticket-detail/:id',
        component: TicketDetailParentComponent,
        canActivate: [profileGuard]
      },
      {
        path: 'fahrkosten',
        component: FahrkostenTableComponent
      },
      {
        path: 'fahrkosten-detail/:id',
        component: FahrkostenDetailParentComponent,
        canActivate: [profileGuard]
      },
      {
        path: 'fahrkosten-detail',
        component: FahrkostenDetailParentComponent,
        canActivate: [profileGuard]
      },
      {
        path: 'schueler-table',
        component: SchuelerTableComponent
      },
      {
        path: 'schueler-detail',
        component: SchuelerDetailComponent
      },
      {
        path: 'schueler-detail/:id',
        component: SchuelerDetailComponent
      },
      {
        path: 'textbausteine',
        component: TextbausteineComponent,
        canActivate: [authGuard]
      },
      {
        path: 'textbausteine-detail',
        component: TextbausteineDetailComponent,
        canActivate: [authGuard]
      },
      {
        path: 'textbausteine-detail/:id',
        component: TextbausteineDetailComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schulen',
        component: SchulenComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schulenDetail',
        component: SchulenDetailComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schulenDetail/:id',
        component: SchulenDetailComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schulekoordinaten',
        component: SchulekoordinatenComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schulekoordinaten/:id',
        component: SchulekoordinatenComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schultypen',
        component: SchultypenComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schultypen-detail',
        component: SchultypenDetailComponent,
        canActivate: [authGuard]
      },
      {
        path: 'schultypen-detail/:id',
        component: SchultypenDetailComponent,
        canActivate: [authGuard]
      },
      {
        path: 'config',
        component: ConfigComponent,
        canActivate: [authGuard]
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
