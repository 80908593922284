<app-default-layout
  [title]="'TEXTBAUSTEINE.TITLE'"
>

  <div class="topbar">
    <div class="row">
      <div class="col-md-8 offset-md-2 ml-auto">
        <button class="btn btn-primary float-end" [routerLink]="['/pages/textbausteine-detail']">
          <fa-icon [icon]="faPlus"></fa-icon>
          {{ "TEXTBAUSTEINE-TABLE.ADDBUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">


        <ngx-datatable #table
                       class="bootstrap"
                       [rowHeight]="'auto'"
                       [footerHeight]="50"
                       [rows]="rows"
                       [columns]="columns"
                       [headerHeight]="40"
                       [columnMode]="'force'"
                       (activate)="onActivate($event)"
                       [loadingIndicator]="isLoading"
                       [sorts]="[{ prop: 'key', dir: 'asc' }]"
                       [messages] = "{
            emptyMessage: 'NGX_DATATABLE.EMPTY' | translate,
            totalMessage: 'NGX_DATATABLE.TOTAL' | translate,
            selectedMessage: false
          }"
        >
          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-curPage="curPage"
            >
              <div style="padding: 5px 10px">
                <div *ngIf="!isLoading && dataFetched"> <strong>{{ rowCount }}</strong> {{'NGX_DATATABLE.TOTAL' | translate}}</div>
                <div *ngIf="isLoading"> {{'NGX_DATATABLE.LOADING' | translate}} </div>
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!((rowCount / pageSize) > 1)"
                (change)="table.onFooterPage($event)">
              </datatable-pager>
            </ng-template>

          </ngx-datatable-footer>
        </ngx-datatable>

        <ng-template #cellTplStringOnly let-value="value">
          <div>{{value}}</div>
        </ng-template>

        <ng-template #hdrTpl let-column="column" let-sort="sortFn">
          <div (click)="sort()">
            <fa-icon
              [icon]="this.sortInitial" >
            </fa-icon>
            <span style="padding-right:.5rem;" title="{{ 'TEXTBAUSTEINE-TABLE.COLUMNS.'+column.name | uppercase | translate  }}">
                <strong> {{ "TEXTBAUSTEINE-TABLE.COLUMNS."+column.name | uppercase | translate  }}</strong>
            </span>
          </div>
        </ng-template>

      </div>
    </div>
  </div>

</app-default-layout>
