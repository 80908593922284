import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../utils/custom-toast.service";
import {BenutzerService} from "../../services/benutzer/benutzer.service";
import {catchError, pairwise, startWith} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {pwFormatValidator} from "../../validators/formatting/password-format.validator";

/*
  Route is called via email link
  ("Für ihren Account wurde ein neues Passwort erstellt. ..")

  Email is generated via endpoint: POST benutzer/lostPW -> BenutzerService.resetPassword
  CASE: User clicks "Passwort vergessen" on login screen

  All is tied to email address, hence ..
  formControl email => readonly = true
  formControl kennwort => temp value from received Email, validate: required
  formControl kennwortNeu => validate: required, password pattern, notMatches kennwort
  formControl kennwortbestaetigung => validate: required, matches kennwortNeu

 */

@Component({
  selector: 'app-kennwort-zuruecksetzen',
  templateUrl: './kennwort-zuruecksetzen.component.html',
  styleUrls: ['./kennwort-zuruecksetzen.component.css']
})
export class KennwortZuruecksetzenComponent implements OnInit {

  public pwZuruecksetzenForm: UntypedFormGroup;
  public dto: BenutzerDTO;
  pwHide = true;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  token: string;
  email: string;

  formItemSubscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private customToastService: CustomToastService,
              private benutzerService: BenutzerService,
              private translateService: TranslateService,
              private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.pwZuruecksetzenForm = this.fb.group({
            email:[params.email],
            kennwort:['',[Validators.required]],
            kennwortNeu: ['',[Validators.required, pwFormatValidator]],
            kennwortbestaetigung: ['',[Validators.required]]
          });
          this.token = params.token;
        this.formItemSubscriptions.push(
          this.pwZuruecksetzenForm.get('kennwort').valueChanges
            .pipe(startWith(null),pairwise())
            .subscribe(([prev, next]: [any, any]) => {
              if(next!==prev){
                this.kennwortChanged();
              }
            }),
          this.pwZuruecksetzenForm.get('kennwortNeu').valueChanges
            .pipe(startWith(null),pairwise())
            .subscribe(([prev, next]: [any, any]) => {
              if(next!==prev){
                this.kennwortChanged();
              }
            }),
          this.pwZuruecksetzenForm.get('kennwortbestaetigung').valueChanges
            .pipe(startWith(null),pairwise())
            .subscribe(([prev, next]: [any, any]) => {
              if(next!==prev){
                this.kennwortChanged();
              }
            }),
        );
      });
  }

  kennwortChanged(){
    let tempPw : string = this.getFormItem('kennwort')?.value;
    let neuPw : string = this.getFormItem('kennwortNeu')?.value;
    let confirmPW : string = this.getFormItem('kennwortbestaetigung')?.value;

    // make sure new password is not copy from temp email password
    if( !!tempPw && tempPw === neuPw ){
      this.pwZuruecksetzenForm?.get('kennwortNeu')?.setErrors({ passwordNotNew: true })
    } else { // clear error
      if( !!this.pwZuruecksetzenForm?.get('kennwortNeu')?.errors
        && !!this.pwZuruecksetzenForm?.get('kennwortNeu')?.errors['passwordNotNew'])
        delete this.pwZuruecksetzenForm?.get('kennwortNeu')?.errors['passwordNotNew']
    }

    // make sure new password is confirmed correctly
    if( neuPw !== confirmPW ){
      this.pwZuruecksetzenForm?.get('kennwortbestaetigung')?.setErrors({ passwordMismatch: true })
    } else { // clear error
      if( !!this.pwZuruecksetzenForm?.get('kennwortbestaetigung')?.errors
        && !!this.pwZuruecksetzenForm?.get('kennwortbestaetigung')?.errors['passwordMismatch'])
        delete this.pwZuruecksetzenForm?.get('kennwortbestaetigung')?.errors['passwordMismatch']
    }
  }

  getFormItem(s: string) {
    return this.pwZuruecksetzenForm?.get(s);
  }


  save(){
    if(this.pwZuruecksetzenForm.invalid){
      this.customToastService.showError('Die Angaben sind falsch oder unvollständig.');
      return;
    }
    const emailInput =  this.getFormItem('email')?.value.length !== 0 ? this.getFormItem('email').value : null;
    const kennwort = this.getFormItem('kennwort')?.value.length !== 0 ? this.getFormItem('kennwort').value : null;
    const neuKennwort = this.getFormItem('kennwortNeu')?.value.length !== 0 ? this.getFormItem('kennwortNeu').value : null;
    const kennwortbestaetigung = this.getFormItem('kennwortbestaetigung')?.value.length !== 0 ? this.getFormItem('kennwortbestaetigung').value : null;

    this.benutzerService.activatePwReset({
      email : emailInput,
      kennwort: kennwort,
      neuKennwort: neuKennwort,
      kennwortbestaetigung: kennwortbestaetigung,
      token : this.token
    }).pipe(
      catchError((err) => {
        if(err.error.message.includes ('user_is_pending'))
          this.customToastService.showError(this.translateService.instant('COMPOSITE-MESSAGE.USER_IS_PENDING'));
        else
          this.customToastService.showError(this.translateService.instant('COMPOSITE-MESSAGE.PASSWORD_RESET_FAILED'));
        return EMPTY;
      })
    )
      .subscribe((data: BenutzerDTO) => {
        this.dto = {...data};
        this.router.navigate(['/authentication/login']);
        this.customToastService.showSuccess("Passwort erfolgreich zurückgesetzt.");
      });
  }

  ngOnDestroy(){
    this.formItemSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
