import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {SchuelerDTO} from "../../../models/schueler/SchuelerDTO";
import {SchuelerUpdateDTO} from "../../../models/schueler/SchuelerUpdateDTO";
import {SchuelerCreateDTO} from "../../../models/schueler/SchuelerCreateDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class SchuelerService {

  configUrl = 'api/schueler';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getSchuelerList(): Observable<Array<SchuelerDTO>> {
    return this.http.get<Array<SchuelerDTO>>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getSchueler(id: string): Observable<SchuelerDTO> {
    return this.http.get<SchuelerDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  updateSchueler(id: number, schuelerUpdateDTO: SchuelerUpdateDTO): Observable<any> {
    return this.http.patch<SchuelerDTO>(
      this.configUrl+'/'+id,
      schuelerUpdateDTO,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  createSchueler(schuelerCreateDTO: SchuelerCreateDTO): Observable<any> {
    return this.http.post<SchuelerDTO>(
      this.configUrl,
      schuelerCreateDTO,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  deleteSchueler(id: number): Observable<any> {
    return this.http.delete(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeader(), responseType: 'text' });
  }
}
