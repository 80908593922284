import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {ConfigService} from "../../config/services/config.service";

@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {

  constructor(
    private configService: ConfigService
  ) {
  }

  getAuthHeaderApplicationJson() : HttpHeaders {
    const authToken: string = sessionStorage.getItem('authToken') ? sessionStorage.getItem('authToken') : '';
    if (this.configService.featureConfig.kommunalportalAuth) {
      return new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Auth-Token': authToken
      });
    } else if (this.configService.featureConfig.jwtAuth) {
      const headers = new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'});
      if (authToken != '') {
        return headers.set('Authorization', 'Bearer ' + authToken);
      }
      return headers;
    }
    throw new Error('Unauthorized. Session not active.');
  }

  getAuthHeader() : HttpHeaders {
    const authToken: string = sessionStorage.getItem('authToken') ? sessionStorage.getItem('authToken') : '';
    if (this.configService.featureConfig.kommunalportalAuth) {
      return new HttpHeaders({
        'X-Auth-Token': authToken
      });
    } else if (this.configService.featureConfig.jwtAuth) {
      const headers = new HttpHeaders();
      if (authToken != '') {
        return headers.set('Authorization', 'Bearer ' + authToken);
      }
      return headers;
    }
    throw new Error('Unauthorized. Session not active.');
  }

}
