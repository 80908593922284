<app-default-layout
  [title]="this.textbaustein?.id? 'TEXTBAUSTEINE-DETAIL.TITLE_OLD':'TEXTBAUSTEINE-DETAIL.TITLE_NEW'"
  [subtitle]="this.textbaustein?.key? this.textbaustein.key.toString():' '"
>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">

        <div id="edit-textbaustein" class="content scaffold-edit" role="main">

          <form [formGroup]="textbausteinForm"
                (ngSubmit)="this.saveOrUpdate()"
                class="form-horizontal"
                accept-charset="UTF-8">

            <div class="form-group group-for-text col mb-4">

              <div class="d-flex flex-row" style="margin-bottom: -15px">
                <div class="flex-grow-1 mr-2">
                  <!--textbaustein missing for infoText?-->
                  <app-custom-select id="key"
                                     class="flex-grow-1"
                                     [customFormGroup]="textbausteinForm"
                                     [items]="textbausteinList"
                                     bindLabel="key"
                                     bindValue="id"
                                     [multiple]="!this.textbaustein?.id"
                                     [readonly]="!!this.textbaustein?.id"
                                     [placeholder] = "this.textbaustein?.key? this.textbaustein.key.toString() :'-'"
                                     label="{{ 'TEXTBAUSTEINE-TABLE.COLUMNS.KEY' | translate }}"
                                     infoText="{{ 'TEXTBAUSTEINE-DETAIL.KEYSELECT_INFO' | translate }}"
                                     (onchange)="this.reloadPageWithSelectedId()"
                  >
                  </app-custom-select>

                </div>
                <div>

                </div>
              </div>
            </div>

            <div class="form-group group-for-text col mb-4">

              <div class="d-flex flex-row" style="margin-bottom: -15px">
                <div class="flex-grow-1 mr-2">

                  <app-textarea
                    id="value"
                    class="flex-grow-1"
                    [rows]="10"
                    [customFormGroup]="textbausteinForm"
                    [maxLength]="10000"
                    [showMaxLength]="true"
                    [placeholder]="'TEXTBAUSTEINE-TABLE.COLUMNS.VALUE_PLACEHOLDER' | translate"
                    noteText="{{'TEXTBAUSTEINE-TABLE.COLUMNS.VALUE_NOTE_TEXT' | translate }}"
                    label="{{'TEXTBAUSTEINE-TABLE.COLUMNS.VALUE' | translate }}"
                    [readonly]="false"
                    [disabled]="false"
                  >
                  </app-textarea>

                </div>
                <div>

                </div>
              </div>
            </div>

            <div class="d-flex flex-row">
            <span class="flex-grow-1">

              <button type="submit"
                      class="btn btn-primary save">
                <fa-icon [icon]="faSave"></fa-icon>
                {{this.textbausteinId?this.translateService.instant('APPLICATION.BUTTON_SPEICHERN')
                :this.translateService.instant('TEXTBAUSTEINE-DETAIL.TITLE_NEW')}}
              </button>

              <button *ngIf="this.textbausteinId" (click)="showDecisionModal()"
                      class="btn btn-danger delete" type="button">
                <fa-icon [icon]="faDelete"></fa-icon>
                {{'APPLICATION.BUTTON_DELETE' |translate }}
              </button>

            </span>

              <button [routerLink]="['/pages/textbausteine']"
                      class="btn btn-outline-primary list">
                {{'APPLICATION.BUTTON_ABBRECHEN' |translate }}
              </button>
            </div>

          </form>

        </div>

      </div>
    </div>
  </div>

</app-default-layout>
