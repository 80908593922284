<app-default-layout
  [title]="'Impressum'"
>

  <div [innerHTML]="existsSessionStorageTextbausteine?
    this.textbausteinService.printTextbausteinByKey( KeyString.IMPRESSUM_TEXT,defaultImpressum)
    : defaultImpressum"
       data-cy="impressum-text"
  ></div>

  <br>
  <br>
  <br>
  <div class="row justify-between mb-4 pb-4">
    <div class = "col-md-5">
      <h4>Attributionen</h4>
<!--      Darstellung von Landkarten-->
<!--      <a class="text-reset fw-bold" style=text-decoration:none href="https://github.com/Leaflet/Leaflet/blob/main/LICENSE"-->
<!--         data-cy="nav-item-home">© Leaflet, BSD-2-Lizenz</a>-->
<!--      <br>-->
<!--      Landkartendaten-->
<!--      <a class="text-reset fw-bold" style=text-decoration:none href="https://www.openstreetmap.org/copyright"-->
<!--         data-cy="nav-item-home">© OpenStreetMap, ODbL-Lizenz</a>-->
<!--      <br>-->
<!--      Geo-Services-->
<!--      <a class="text-reset fw-bold" style=text-decoration:none href="https://openrouteservice.org/terms-of-service/#/attribution"-->
<!--         data-cy="nav-item-home">© openrouteservice.org by HeiGIT, CC-BY 4.0</a>-->
<!--      <br>-->
      Icons
      <a class="text-reset fw-bold" style=text-decoration:none href="https://github.com/FortAwesome/Font-Awesome"
         data-cy="nav-item-home">© Font Awesome Free, CC-BY 4.0, SIL OFL 1.1, MIT</a>
      <br>
      Liberation Sans Font
      <a class="text-reset fw-bold" style=text-decoration:none href="https://github.com/liberationfonts/liberation-fonts/blob/main/LICENSE"
         data-cy="nav-item-home">© 2012 Red Hat, Inc., SIL OFL 1.1</a>
    </div>
    <div class = "col-md-4">
      <h4>Technischer Ansprechpartner</h4>
        regio iT,
        Gesellschaft für Informationstechnologie mbH <br>
        Lombardenstr. 24, 52070 Aachen <br>
        0241 41359-0 <br>
        info&#64;regioit.de <br>
        <a href="https://www.regioit.de/">www.regioit.de</a>
    </div>
    <div class = "col-md-3">
      <h4>Versionsinformationen</h4>
      <div>Version: {{version}}</div>
      <div>Version Backend: {{backendVersion}}</div>
    </div>
  </div>
</app-default-layout>
