import {Injectable} from "@angular/core";
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class DateTimeHelperService {

  public static readonly VORMUND_AGE_MINIMUM : number = 18;
  public static readonly BIRTHDATE_MINIMUM : string = (new Date(1900,0,0)).toISOString().slice(0, 10);
  public static readonly CALENDAR_MINIMUM : string = (new Date(1900,0,0)).toISOString().slice(0, 10);
  public static readonly CALENDAR_MAXIMUM : string = (new Date(2100,0,0)).toISOString().slice(0, 10);

  public formattedToday() : string {
    return new Date().toISOString().slice(0, 10);
  }

  public formattedMonth(date: Date) : string {
    date = this.removeTimeZone(date);
    return date.toISOString().slice(0, 7);
  }

  public removeTimeZone(date: Date) : Date {
    let tzoffset = date?.getTimezoneOffset() * 60000; //offset in milliseconds
    return new Date(date?.getTime() - tzoffset);
  }

  public maxDateVormund() : string {
    let currdate = new Date();
    currdate.setFullYear(currdate.getFullYear() - DateTimeHelperService.VORMUND_AGE_MINIMUM);
    return currdate.toISOString().slice(0, 10);
  }

  /**
   * Returns true if the given date is younger than the given minimum age.
   * @param dateString
   * @param minAge
   */
  public isYoungerThan(dateString: string, minAge: number) : boolean {
    if(!dateString)
      return null;
    const birthdate = new Date(dateString);
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    const currAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    return currAge < minAge;
  }

}

export function createVormundAgeValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

    const value = control.value;
    if (!value)
      return null;

    const birthdate = new Date(value);
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    const currAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    return currAge < DateTimeHelperService.VORMUND_AGE_MINIMUM ? {vormundAgeTooLow: true} : null;
  }
}

export function createDateIsBetweenValidator(fromDateString: string, toDateString: string): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if (!value)
      return null;

    const valueDate = new Date(value);

    if(!isCorrectDate(valueDate)) {
      return {invalidDate:true};
    }
    const fromDate = new Date(fromDateString);
    const toDate = new Date(toDateString);
    return (fromDate > valueDate || valueDate > toDate)? {dateNotInRange:true} : null;

  }
}

const isCorrectDate = (date: Date | string): boolean => {
  return isFinite(+(date instanceof Date ? date : new Date(date)));

};
