import {Injectable} from "@angular/core";
import {NgxImageCompressService} from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class ImageHelperService {

  public static readonly ALLOWED_NACHWEIS_FILE_FORMATS : string = 'image/x-png,image/jpeg,image/bmp,.pdf';
  public static readonly BYTEA_UPLOAD_MAXSIZE : number = 1048576; // 1 MB
  public static readonly OID_UPLOAD_MAXSIZE : number = 10485760; // 10 MB

  constructor (private imageCompress: NgxImageCompressService) {}

  isPDF(file: File) : boolean {
    return file.type.indexOf('application/pdf')>=0;
  }

  isOIDSizeOK(file: File) : boolean {
    return file.size < ImageHelperService.OID_UPLOAD_MAXSIZE;
  }

  isBYTEASizeOK(file: File) : boolean {
    return file.size < ImageHelperService.BYTEA_UPLOAD_MAXSIZE;
  }

  readNachweisFile(file: File) : Promise<any> {
    return new Promise((resolve) =>{
      let reader = new FileReader();
      if(!this.isPDF(file)){
        reader.onloadend = (e) => {
          resolve(e.target.result)
        };
        reader.onerror = (event: any) => {
          console.log("Unable to read file: " + event.target.error.code);
          resolve(undefined);
        };
        reader.readAsDataURL(file);
      } else {
        reader.onloadend = (e) => {
          resolve(e.target.result)
        };
        reader.onerror = (event: any) => {
          console.log("Unable to read file: " + event.target.error.code);
          resolve(undefined);
        };
        reader.readAsArrayBuffer(file);
      }
    });
  }

  readBelegFile(file: File) : Promise<any> {
    return new Promise((resolve) =>{
      let reader = new FileReader();
      reader.onloadend = (e) => {
        resolve(e.target.result);
        return;
      };
      reader.onerror = () => {
        resolve(undefined);
        return;
      };
      reader.readAsDataURL(file);
    });
  }

  handleNachweisFile(file: File) : Promise<any> {
    return new Promise((resolve) => {
      let result = {isPDF: false, error: undefined, noFile: false, image: undefined};
      if (!!file) {
        const isFileSizeOK = this.isOIDSizeOK(file);
        const isPDF = this.isPDF(file);
        // if(!isFileSizeOK && isPDF){
        if(!isFileSizeOK){
          result.error = 'Datei zu groß. Maximum ist 10 MB.';
          resolve(result);
        }
        this.readNachweisFile(file)
          .then((processed) => {
            if(isFileSizeOK) {
              result.image = processed;
              result.isPDF = !!processed ? isPDF : false;
              result.error = !processed ? 'Datei konnte nicht gelesen werden.' : undefined;
              resolve(result);
              return;
            }
            else {
              this.imageCompress.getOrientation(file)
                .then((ori) => {
                  const ratio = ImageHelperService.BYTEA_UPLOAD_MAXSIZE / file.size * 100;
                  this.imageCompress.compressFile(processed, ori, ratio)
                    .then((compressedImage) => {
                      result.image = compressedImage;
                      result.isPDF = !!compressedImage ? isPDF : false;
                      result.error = !compressedImage ? 'Datei konnte nicht gelesen werden.' : undefined;
                      resolve(result);
                      return;
                    }).catch(() => {});
                }).catch(() => {});
            }
          });
      } else {
        result.noFile = true;
        resolve(result);
      }
    });
  }

  handleBelegFile(file: File) : Promise<any> {
    return new Promise((resolve) => {
      let result = {error: undefined, noFile: false, image: undefined};
      if (!!file) {
        this.readBelegFile(file)
          .then( (processed) => {
            if( !this.isBYTEASizeOK(file) )
            {
              if(!file.type.includes('pdf')) {
                this.imageCompress.getOrientation(file)
                  .then((ori) => {
                    const ratio = ImageHelperService.BYTEA_UPLOAD_MAXSIZE / file.size * 100;
                    this.imageCompress.compressFile(processed, ori, ratio)
                      .then((compressedImage) => {
                        result.image = compressedImage;
                        resolve(result);
                        return;
                      }).catch(() => {});
                  }).catch(() => {});
              } else { // we currently can't compress PDFs automatically
                result.error = 'Maximal erlaubte PDF-Größe: '+ ImageHelperService.BYTEA_UPLOAD_MAXSIZE/1024/1024 + ' MB.';
                resolve(result);
                return;
              }
            } else {
              result.image = processed;
              result.error = !processed ? 'Datei konnte nicht gelesen werden.' : undefined;
              resolve(result);
              return;
            }
          }).catch( ()=> {} );
      } else {
        result.noFile = true;
        resolve(result);
        return;
      }
    });
  }

  imageToFile(image: string, filename: string) : File {
    let arr = image.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  compressImage(image: any, size: number) : Promise<any> {
    return new Promise<any>( (resolve) => {
      const ratio = ImageHelperService.BYTEA_UPLOAD_MAXSIZE / size * 100;
      this.imageCompress.compressFile(image, 0, ratio)
        .then((compressedImage) => {
          resolve(compressedImage);
          return;
        }).catch((err) => {console.log("err",err)});
    });
  }


}
