import {Component, OnDestroy, OnInit} from '@angular/core';
import {TextbausteinService} from 'src/app/@core/services/textbaustein/textbaustein.service';
import {ModuleConfigDto} from "../../models/module-config/ModuleConfigDto";
import {SessionService} from "../../@core/services/session/session.service";
import {BenutzerDTO} from "../../models/benutzer/BenutzerDTO";
import {Subscription} from "rxjs";
import {KeyString} from "../../models/textbaustein/KeyString";
import {BenutzerSessionService} from "../../@core/services/benutzer/benutzer-session.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  moduleEnabled = [];
  roleEnabled = [];
  existsSessionUser = false;
  benutzer: BenutzerDTO;
  existsSessionStorageTextbausteine: boolean = false;
  moduleConfigSubscription:Subscription;
  storageBenutzerSubscription:Subscription;
  existsBenutzerSubscription:Subscription;
  existsTextbausteineSubscription: Subscription;

  constructor(
    public textbausteinService: TextbausteinService,
    private sessionService: SessionService,
    private benutzerSessionService: BenutzerSessionService
  ) { }

  ngOnInit(): void {

    this.moduleConfigSubscription = this.sessionService.watchSessionStorageModuleConfig().subscribe(mC => {
      this.moduleEnabled = [];
      this.applyModuleConfig(mC);
    });
    this.storageBenutzerSubscription = this.benutzerSessionService.watchSessionStorageBenutzer()
      .subscribe( user => this.benutzer = user );
    this.existsBenutzerSubscription = this.benutzerSessionService.watchSessionStorageExistsBenutzer()
      .subscribe( yesNo => {
        this.existsSessionUser = yesNo;
        this.applyBenutzerRoles(this.benutzer?.roles);
      });
    this.existsTextbausteineSubscription = this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });
  }

  applyModuleConfig(mC: ModuleConfigDto[]){
    this.moduleEnabled = [];
    mC?.forEach( mc => {
      this.moduleEnabled[mc.module] = mc.enabled;
    });
  }

  applyBenutzerRoles(roles: string[]){
    this.roleEnabled = [];
    let temp = ['Antragsteller','Schule','Schultraeger','Superuser'];
    temp.forEach( role => {
      this.roleEnabled[role] = roles?.includes('ROLE_'+role.toUpperCase()) || false;
    });
  }

 ngOnDestroy():void {
   this.moduleConfigSubscription.unsubscribe();
   this.storageBenutzerSubscription.unsubscribe();
   this.existsBenutzerSubscription.unsubscribe();
   this.existsTextbausteineSubscription.unsubscribe();
 }


    protected readonly KeyString = KeyString;
}
