<div class="form-group" [class.mb-0]="noMb" [formGroup]="customFormGroup">
  <label [class.sr-only]="hideLabel" [for]="'input-'+id"  [id]="'sfklabel-'+id" data-cy="custom-label">
    {{ label }}
  </label>
  <fa-icon class="infoIcon"
           *ngIf="!!infoText && infoText.length > 0"
           [icon]="faInfo"
           [title]="infoText"
           data-cy="custom-info"
  ></fa-icon>
  <span *ngIf="environment.displayTextbausteinKeys">{{infoText}}</span>
  <div style="position:relative;">
    <div *ngIf="showMaxLength" class="position-absolute" style="right: 5px; top: 2px;">
			<span
        [class.text-muted]="currentLength / maxLength < 0.6"
        [class.text-warning]="
					currentLength / maxLength >= 0.6 && currentLength / maxLength < 1
				"
        [class.text-danger]="currentLength / maxLength >= 1"
      >
				{{ c(id).value.length }}/{{ maxLength }}
			</span>
    </div>
    <div class="input-group">
      <div *ngIf="extra_field_text" class="input-group-prepend input-currency-field">
        <span class="input-group-text justify-content-center">{{extra_field_text}}</span>
      </div>
      <div *ngIf="extra_field_icon" class="input-group-prepend input-currency-field">
        <span class="input-group-text justify-content-center"><fa-icon [icon]="extra_field_icon"></fa-icon>
        </span>
      </div>
      <input #input
        [type]="type"
        class="form-control"
        [id]="'input-' + id"
        [placeholder]="placeholder"
        [formControlName]="id"
        [readOnly]="readonly"
        [disabled]="disabled"
        [step]="step"
        [min]="min"
        [max]="max"
        [maxlength]="maxlength"
        [pattern]="pattern"
        (change)="triggerOnChange($event)"
        [accept]="acceptFormats"
        [autocomplete]="pwAutocomplete ? 'current-password' : 'new-password'"
        data-cy="custom-input"
      />
      <button type="button" class="btn-outline-secondary btn-clear"
              *ngIf="clearable && input.value" aria-label="Clear" (click)="input.value=''; triggerOnClear($event); ">
        <fa-icon [icon]="faX" (click)="input.value=''"></fa-icon>
      </button>
    </div>
  </div>

  <small
    *ngIf="c(id)?.invalid && (c(id)?.dirty || c(id)?.touched)"
    [id]="id + 'HelpBlock'"
    class="form-text text-muted"
  >
    {{ noteText }}
  </small>
  <small
    *ngIf="c(id)?.invalid && (c(id)?.dirty || c(id).touched) && max !== null && max !== undefined"
    [id]="id + 'HelpBlock'"
    class="form-text text-muted"
  >
    Der maximale Wert für dieses Feld beträgt {{ max }}. Bitte tragen Sie einen kleineren Wert ein.
  </small>
  <p
    *ngIf="!!infoTextBelowInput && infoTextBelowInput.length > 0"
    class="form-text text-muted"
  >
    {{ infoTextBelowInput }}
  </p>
</div>
