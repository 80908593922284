<app-default-layout
  [title]="'Aktivierung'"
  [subtitle] ="'Um die Aktivierung Ihres Benutzerkontos abzuschließen\n' +
   'setzen Sie bitte ein neues Passwort.'"
>


  <div class="row">
    <div class="col-md-8 offset-md-2">
      <form [formGroup]="aktivierungEinladungForm" (ngSubmit)="this.activate()" class="form-horizontal"
            accept-charset="UTF-8">

        <div class="form-group group-for-email col mb-4">
          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">
              <app-custom-input id="email" type="text"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.MAIL' |translate }}"
                                [customFormGroup]="aktivierungEinladungForm"
                                [readonly]="true"
              ></app-custom-input>
            </div>
          </div>
        </div>

        <div class="form-group group-for-kennwort col mb-4">
          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">
              <app-custom-input id="kennwort" type="text"
                                [type] = "pwHide? 'password':'text'"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.PW' |translate }}"
                                [customFormGroup]="aktivierungEinladungForm"
              ></app-custom-input>
              <div class="formcontrol-error-infotext" *ngIf="aktivierungEinladungForm?.controls?.kennwort?.invalid">
                {{ 'AUTHENTICATION.FORM_ERRORS.PASSWORD_TEMP_INVITATION' | translate }}
              </div>
            </div>
            <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                      [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                      [title]="pwHide? translate.instant('LOGIN.PW-SHOW'):translate.instant('LOGIN.PW-HIDE')">
            </fa-icon>
          </div>
        </div>

        <div class="form-group group-for-neuKennwort col mb-4">
          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">
              <app-custom-input id="kennwortNeu" type="text"
                                [type] = "pwHide? 'password':'text'"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.NEW-PW' |translate }}"
                                [customFormGroup]="aktivierungEinladungForm"
              ></app-custom-input>
              <div class="formcontrol-error-infotext"
                   *ngIf="aktivierungEinladungForm?.get('kennwortNeu')?.errors"
                   data-cy="kennwort-error">
                {{ aktivierungEinladungForm?.get('kennwortNeu')['infotextKey'] | translate }}
                {{ aktivierungEinladungForm?.get('kennwortNeu')['lengthKey'] | translate }}
                {{ aktivierungEinladungForm?.get('kennwortNeu')['numKey'] | translate }}
                {{ aktivierungEinladungForm?.get('kennwortNeu')['upperKey'] | translate }}
                {{ aktivierungEinladungForm?.get('kennwortNeu')['lowerKey'] | translate }}
                {{ aktivierungEinladungForm?.get('kennwortNeu')['specialKey'] | translate }}
              </div>
            </div>
            <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                      [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                      [title]="pwHide? translate.instant('LOGIN.PW-SHOW'):translate.instant('LOGIN.PW-HIDE')">
            </fa-icon>
          </div>
        </div>

        <div class="form-group group-for-kennwortbestaetigung col mb-4">
          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">
              <app-custom-input id="kennwortbestaetigung" type="text"
                                [type] = "pwHide? 'password':'text'"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.PW-CONFIRMATION' |translate }}"
                                [customFormGroup]="aktivierungEinladungForm"
              ></app-custom-input>
              <div class="formcontrol-error-infotext" *ngIf="aktivierungEinladungForm?.controls?.kennwortbestaetigung?.errors?.passwordMismatch">
                {{ 'AUTHENTICATION.FORM_ERRORS.PASSWORD_MISMATCH' | translate }}
              </div>
            </div>
            <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                      [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                      [title]="pwHide? translate.instant('LOGIN.PW-SHOW'):translate.instant('LOGIN.PW-HIDE')">
            </fa-icon>
          </div>
        </div>

        <button type="submit"
                class="btn btn-primary save btn-block me-3">
          {{'APPLICATION.BUTTON_SPEICHERN'|translate}}
        </button>

      </form>
    </div>
  </div>
</app-default-layout>
