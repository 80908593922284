<app-default-layout
  [title]="'KONFIGURATION.TITEL' |translate"
>
  <main role="main" class="" aria-label="">
    <div class="">
      <div class="col-12">
        <div class="">
          <div class="flex-container-right">
            <div class="col-lg-4 col-md-6 col-sm-8 form-group">
              <label class="" [for]="'select-config'" [id]="'sfklabel-config'">
                {{'KONFIGURATION.AUSWAHL' |translate}}
              </label>
              <ng-select id="config"
                         class="flex-grow-1"
                         placeholder="-"
                         [items]="this.modules"
                         bindLabel="selectLabel"
                         [multiple]="false"
                         (change)=setVisibleConfig($event)
                         [clearable]="false"
                         [searchable]="false">
              </ng-select>
            </div>
          </div>
            <div class="flex-row col-md-11 offset-md-1">
              <ng-container *ngFor="let module of modules">
                <div *ngIf="visibleConfig === module.module" style="margin: 40px 0 0 20px">
<!--                  <h1 style="margin-bottom: 20px; text-align: center">-->
<!--                    <strong>{{ module.name }}</strong>-->
<!--                  </h1>-->
                  <br>
                  <div class="flex-row" >
                    <h2>
                        <ng-container *ngIf="moduleConfigService.isModuleEnabled(module.module)" >
                          {{'KONFIGURATION.MODUL' |translate }} <strong>{{ module.name }}</strong>: <span class="badge badge-pill badge-success align-content-center">{{'KONFIGURATION.AKTIV' |translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="!moduleConfigService.isModuleEnabled(module.module)">
                          {{'KONFIGURATION.MODUL' |translate }} <strong>{{ module.name }}</strong>: <span class="badge badge-pill badge-error align-content-center">{{'KONFIGURATION.DEAKTIVIERT' |translate }}</span>
                        </ng-container>
                    </h2>
                    <div >
                      <button type="button" class="btn btn-primary" (click)="createOrUpdateModuleConfig(module);">
                        <ng-container *ngIf="moduleConfigService.isModuleEnabled(module.module)">
                          {{'KONFIGURATION.MODUL' |translate }} {{'KONFIGURATION.DEAKTIVIEREN' |translate }}
                        </ng-container>
                        <ng-container *ngIf="!moduleConfigService.isModuleEnabled(module.module)">
                          {{'KONFIGURATION.MODUL' |translate }} {{'KONFIGURATION.AKTIVIEREN' |translate }}
                        </ng-container>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>


          <ng-container *ngFor="let module of modules">
            <div *ngIf="visibleConfig === module.module" class="flex-row" style="margin: 40px 0 0 20px">
              <div *ngIf="module.fields.length > 0" class="col-md-11 offset-md-1" >
                <form [formGroup]="module.formGroup" class="form-horizontal" accept-charset="UTF-8">
                  <div class="row">
                    <h2 style="margin-bottom: 10px;"><strong> {{'KONFIGURATION.FELDER_KONFIGURIEREN' |translate }}</strong></h2>
                    <div class="col-xl-5">
                      <span style="margin-left: 0px">{{'KONFIGURATION.PFLICHT' |translate}}</span>
                      <span style="margin-left: 22px">{{'KONFIGURATION.SICHTBAR' |translate}}</span>
                      <span style="margin-left: 22px">{{'KONFIGURATION.EDITIERBAR' |translate}}</span>
                      <div class="d-flex flex-row" style="margin-top: 15px; margin-left: 7px">
                        <label class="checkbox-container">
                          <input type="checkbox" class="control control-checkbox custom-control-input"
                                 id="allRequired"
                                 name="allVisible"
                                 (change)="toggleAllFormControlValues($event, module.formGroup, 'required')">
                          <span class="checkmark"></span>
                        </label>
                        <label class="checkbox-container">{{'KONFIGURATION.ALLES_AUSWAEHLEN' |translate}}
                          <input type="checkbox" class="control control-checkbox custom-control-input"
                                 id="allVisible"
                                 name="allVisible"
                                 (change)="toggleAllFormControlValues($event, module.formGroup, 'visible')">
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <br>
                      <div *ngFor="let field of module.fields | slice:0:module.fields?.length/2+1" style="margin-bottom: 10px; margin-left: 7px" class="d-flex flex-row">
                        <label class="checkbox-container">
                          <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="{{field}}Required"
                                 id="{{field}}Required"
                                 name="{{field}}Required">
                          <span class="checkmark"></span>
                        </label>
                        <label class="checkbox-container">{{field}}
                          <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="{{field}}"
                                 id="{{field}}"
                                 name="{{field}}">
                          <span class="checkmark"></span>
                        </label>
                        <input style="margin: -7px 0 5px 0" class="form-control" id="{{field}}Label" formControlName="{{field}}Label" />
                      </div>
                    </div>
                    <div class="col-xl-5 offset-xl-1 offset-lg-1 offset-md-1" style="margin-top: 82px;">
                      <div *ngFor="let field of module.fields | slice:module.fields?.length/2+1" style="margin-bottom: 10px; margin-left: 7px" class="d-flex flex-row">
                        <label class="checkbox-container">
                          <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="{{field}}Required"
                                 id="{{field}}Required"
                                 name="{{field}}Required">
                          <span class="checkmark"></span>
                        </label>
                        <label class="checkbox-container">{{field}}
                          <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="{{field}}"
                                 id="{{field}}"
                                 name="{{field}}">
                          <span class="checkmark"></span>
                        </label>
                        <input style="margin: -7px 0 5px 0" class="form-control" id="{{field}}Label" formControlName="{{field}}Label" />
                      </div>
                    </div>
                  </div>
                </form>
                <br>
                <button type="button" class="btn btn-primary config-btn" (click)="createOrUpdateFieldConfigs(module)">{{'APPLICATION.BUTTON_SPEICHERN' |translate }}</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </main>
</app-default-layout>
