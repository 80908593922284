<app-default-layout [title]="'Passwort vergessen'">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <form [formGroup]="pwZuruecksetzenForm" (ngSubmit)="this.save()" class="form-horizontal"
            accept-charset="UTF-8">

        <div class="form-group group-for-email col mb-4">

          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">

              <app-custom-input id="email" type="text"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.MAIL' |translate }}"
                                [customFormGroup]="pwZuruecksetzenForm">

              </app-custom-input>

            </div>
            <div>

            </div>
          </div>
        </div>


        <div class="d-flex flex-column">
          <button type="submit" class="btn btn-primary btn-block">Absenden</button>
        </div>
      </form>
    </div>
  </div>
</app-default-layout>
