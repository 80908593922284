import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {HaltestellenPageDTO} from "../../../models/haltestelle/HaltestellenPageDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";


@Injectable({
  providedIn: 'root'
})
export class HaltestelleService {

  configUrl = 'api/haltestelle';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getHaltestellenPage(params: HttpParams): Observable<HaltestellenPageDTO> {
    return this.http.get<HaltestellenPageDTO>(
      this.configUrl+'/bulk',
      { headers: this.headerService.getAuthHeaderApplicationJson(), params:params }
    );
  }

}
