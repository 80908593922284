import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../utils/custom-toast.service";
import {BenutzerService} from "../../services/benutzer/benutzer.service";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Component({
  selector: 'app-kennwort-vergessen',
  templateUrl: './kennwort-vergessen.component.html',
  styleUrls: ['./kennwort-vergessen.component.css']
})
export class KennwortVergessenComponent implements OnInit {

  public pwZuruecksetzenForm: UntypedFormGroup;
  public dto: BenutzerDTO;
  pwHide = true;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  token: string;
  email: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translateService: TranslateService,
              private customToastService: CustomToastService,
              private benutzerService: BenutzerService,
              private fb: UntypedFormBuilder
  ) { }

  getFormItem(s: string) {
    return this.pwZuruecksetzenForm?.get(s);
  }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {

          this.pwZuruecksetzenForm = this.fb.group({
            email:[params.email],
          });
          this.token = params.token;
        }
      );

  }

  save(){
    const emailInput =  this.getFormItem('email')?.value.length !== 0 ? this.getFormItem('email').value : null;

    this.benutzerService.pwReset({
      email : emailInput,
      token : this.token
    }).pipe(
      catchError(err => {
        this.customToastService.showError(this.translateService.instant(this.getErrorKey(err)));
        return EMPTY;
      })
    )
      .subscribe((data: BenutzerDTO) => {
        this.dto = {...data};
        this.router.navigate(['/authentication/login']);
        this.customToastService.showSuccess("Passwort erfolgreich zurueckgesetzt.");
      });
  }

  getErrorKey(err: any){
    if(err.status==404)
      return 'COMPOSITE-MESSAGE.EMAIL_NOT_FOUND';
    else
      return 'COMPOSITE-MESSAGE.UNABLE_TO_SEND_PW';
  }

}
