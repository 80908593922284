<app-default-layout
  [title]= "title"
  [subtitle]="subtitle"
>

  <app-ticket-detail-sb-true
    *ngIf="this.hasSachbearbeiterBackend"
    [antragTicketId] = "antragTicketId"
    [benutzer] = "benutzer"
    [benutzerRoles] = "benutzerRoles"
    [fieldConfig] = "fieldConfig"
    [optionTyp] = "optionTyp"
    [optionErmaessigung]="optionErmaessigung"
    [optionGeschlecht]="optionGeschlecht"
    [optionWegtyp]="optionWegtyp"
    [optionSonderfall]="optionSonderfall"
    [landList]="landList"

    [requiredFields]="requiredFields"
    [visibleFields]="visibleFields"

    [schulwegOnOff]="schulwegOnOff"
    [praktikumOnOff]="praktikumOnOff"
    [beidesOnOff]="beidesOnOff"
    [schuleOnOff]="schuleOnOff"
    [berufskollegOnOff]="berufskollegOnOff"
    [schuljahrOnOff]="schuljahrOnOff"
    [vormundOnOff]="vormundOnOff"
    [vormundAdresseAbweichendOnOff] = "vormundAdresseAbweichendOnOff"
    [sonderfallOnOff]="sonderfallOnOff"
    [sonderfallSonstOnOff]="sonderfallSonstOnOff"
    [asylOnOff]="asylOnOff"
    [sepaMandatgeberNotAntragst]="sepaMandatgeberNotAntragst"
    [sepaMandatgeberYesAntragst]="sepaMandatgeberYesAntragst"

    [antragTicketFormFields]="antragTicketFormFields"
    [antragTicketSepaFormFields]="antragTicketSepaFormFields"
    [antragTicketGeschwister1FormFields]="antragTicketGeschwister1FormFields"
    [antragTicketGeschwister2FormFields]="antragTicketGeschwister2FormFields"
    [antragTicketGeschwister3FormFields]="antragTicketGeschwister3FormFields"
    [antragTicketGeschwister4FormFields]="antragTicketGeschwister4FormFields"
    [antragTicketGeschwister5FormFields]="antragTicketGeschwister5FormFields"
  ></app-ticket-detail-sb-true>


  <app-ticket-detail-sb-false
    *ngIf="!this.hasSachbearbeiterBackend"
    [antragTicketId] = "antragTicketId"
    [benutzer] = "benutzer"
    [benutzerRoles] = "benutzerRoles"
    [fieldConfig] = "fieldConfig"
    [optionTyp] = "optionTyp"
    [optionErmaessigung]="optionErmaessigung"
    [optionGeschlecht]="optionGeschlecht"
    [optionWegtyp]="optionWegtyp"
    [optionSonderfall]="optionSonderfall"
    [landList]="landList"
    [requiredFields]="requiredFields"
    [visibleFields]="visibleFields"

    [schulwegOnOff]="schulwegOnOff"
    [praktikumOnOff]="praktikumOnOff"
    [beidesOnOff]="beidesOnOff"
    [schuleOnOff]="schuleOnOff"
    [berufskollegOnOff]="berufskollegOnOff"
    [schuljahrOnOff]="schuljahrOnOff"
    [vormundOnOff]="vormundOnOff"
    [vormundAdresseAbweichendOnOff] = "vormundAdresseAbweichendOnOff"
    [sonderfallOnOff]="sonderfallOnOff"
    [sonderfallSonstOnOff]="sonderfallSonstOnOff"
    [asylOnOff]="asylOnOff"
    [sepaMandatgeberNotAntragst]="sepaMandatgeberNotAntragst"
    [sepaMandatgeberYesAntragst]="sepaMandatgeberYesAntragst"

    [antragTicketFormFields]="antragTicketFormFields"
    [antragTicketSepaFormFields]="antragTicketSepaFormFields"
    [antragTicketGeschwister1FormFields]="antragTicketGeschwister1FormFields"
    [antragTicketGeschwister2FormFields]="antragTicketGeschwister2FormFields"
    [antragTicketGeschwister3FormFields]="antragTicketGeschwister3FormFields"
    [antragTicketGeschwister4FormFields]="antragTicketGeschwister4FormFields"
    [antragTicketGeschwister5FormFields]="antragTicketGeschwister5FormFields"
  ></app-ticket-detail-sb-false>

</app-default-layout>
