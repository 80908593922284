import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PostleitzahlenDTO} from "../../../models/postleitzahlen/PostleitzahlenDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class PostleitzahlenService {

  configUrl = 'api/postleitzahl';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getPostleitzahlByPlz(plz: string): Observable<PostleitzahlenDTO> {
    return this.http.get<PostleitzahlenDTO>(
      this.configUrl+'/plz/'+plz,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

}
