<div class="py-2 custom-card-wrap" style="display: inline-block; width: 100%" [class.h-100]="height100" [class.no-header-margin]="noHeaderMargin">

  <!-- getCustomBackground just injects the class set in Input 'backgroundClass' -->
  <div class="px-3 py-3 custom-card-subpage h-100" [ngClass]="getCustomBackground()">
    <!-- If height100 is set to true, this div is not shown (topbar)-->
    <div *ngIf="!height100" class="row">
      <div class="col">
        <div class="row">
          <div class="pl-4 col col-md-8 offset-md-2">
            <div class="frame frame-narrow">
              <div class="d-flex justify-content-center mx-3">


                <div class="row" style="margin: 0;">
                  <div class="col-xs-12 background-mobile dark-border">

                    <div class="d-flex align-items-center justify-content-center">

                      <!-- Here is the title -->
                      <h2 class="text-color-primary" data-cy="page-title">{{ title | translate}}</h2>
                    </div>

                    <!-- Here is the subtitle -->
                    <pre class="text-muted" style="text-align: center;" data-cy="page-subtitle">{{ subtitle }}</pre>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
            </div>
          </div>
          <div *ngIf="!height100" class="pt-3"></div>
          <div [class.h-100]="height100" style="height: 100%">
            <!-- Content will be injected here (part between <app-default-layout> and </app-default-layout>) -->
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
