<!--header refactor here-->
<header role="main" class="container-fluid ">
  <div class="row d-flex ml-4 mr-4 mt-4 mb-2 flex-nowrap ">

    <div class="pl-4 col col-md-8 offset-md-2">

      <div class="d-flex flex-row-reverse justify-content-between" >
        <app-countdown [endDateObservable$]="this.endDateForLogout$"
                       [loggedoutMessageVisible$]="this.loggedoutMessageVisible$"
                       id="countdown_component">
        </app-countdown>
        <div class="col">
          <img class="logo" [src]="this.safeLogoSource" alt="logo" style="max-height: var(--logo-height, 85px);margin-bottom: 20px" >

          <h3 >{{'APPLICATION.SFK-ONLINE'|translate}} - {{'APPLICATION.SFK-MODUL'|translate}}</h3>
        </div>
      </div>
      <div *ngIf="  !!currentRoute
                    && !currentRoute.includes('/authentication/')
                    && !currentRoute.includes('/information/')
                    && existsSessionUser "
           class="d-flex flex-row-reverse">
        <div data-cy="username-portal" *ngIf="configService.featureConfig.kommunalportalAuth" class="d-inline-block">
          <p class="btn btn-outline-primary" id="antragsteller-name">
            {{benutzer?.vorname}} {{benutzer?.name}}
          </p>
        </div>
        <div data-cy="username-jwt" *ngIf="configService.featureConfig.jwtAuth" class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
          <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
            <ng-container id="antragsteller-name">
              {{benutzer?.vorname}} {{benutzer?.name}}
            </ng-container>
          </button>
          <div ngbDropdownMenu class="custom-dropdown-menu" aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem [routerLink]="['/pages/profil']">
              <fa-icon class="me-1" [icon]="faProfil"></fa-icon>
              {{'APPLICATION.MAIN.PROFIL'|translate}}
            </button>
            <button ngbDropdownItem [routerLink]="['/pages/account']">
              <fa-icon class="me-1" [icon]="faAccount"></fa-icon>
              {{'APPLICATION.MAIN.ACCOUNT'|translate}}
            </button>
            <hr class="mb-1 mt-1">
            <button
              ngbDropdownItem
              (click)="logout()"
              data-cy="profile-logout"
            >
              <fa-icon class="me-1" [icon]="faAbmelden"></fa-icon>
              {{'APPLICATION.MAIN.ABMELDEN'|translate}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <section *ngIf="  !!currentRoute
                    && !currentRoute.includes('/authentication/')
                    && !currentRoute.includes('/information/')
                    && existsSessionUser "
  >
    <app-navbar-responsive
      [moduleEnabled]="moduleEnabled"
      [roleEnabled]="roleEnabled"
    ></app-navbar-responsive>
  </section>
</header>

<div class="container" >
  <router-outlet></router-outlet>
</div>

<app-footer-custom></app-footer-custom>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen] = "true"
  [showSpinner]="loading"
>
</ngx-spinner>
