export enum Rolle {

  ROLE_ANTRAGSTELLER = "ROLE_ANTRAGSTELLER",
  ROLE_SCHULE = "ROLE_SCHULE",
  ROLE_SCHULTRAEGER = "ROLE_SCHULTRAEGER",
  ROLE_SUPERUSER = "ROLE_SUPERUSER",
  ROLE_TRAFFICCOMPANY = "ROLE_TRAFFICCOMPANY",
  ROLE_SCHILD = "ROLE_SCHILD"

}
