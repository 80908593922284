export enum TicketStatus {
  ENTWURF = "ENTWURF",
  EINGEREICHT = "EINGEREICHT",

  IN_BEARBEITUNG_SCHULE = "IN_BEARBEITUNG_SCHULE",
  BESTAETIGT_SCHULE = "BESTAETIGT_SCHULE",
  IN_BEARBEITUNG_SCHULTRAEGER = "IN_BEARBEITUNG_SCHULTRAEGER",
  BESTAETIGT = "BESTAETIGT",
  IN_ANHOERUNG = "IN_ANHOERUNG",
  BESTAETIGT_SCHULTRAEGER = "BESTAETIGT_SCHULTRAEGER",
  ABGELEHNT_SCHULE = "ABGELEHNT_SCHULE",
  ABGELEHNT_SCHULTRAEGER = "ABGELEHNT_SCHULTRAEGER",
  ZURUECKGEZOGEN = "ZURUECKGEZOGEN",
  ABGEMELDET = "ABGEMELDET",
  BESTELLT = "BESTELLT",
  AUSGEHAENDIGT = "AUSGEHAENDIGT",
  BESTAETIGT_SYSTEM = "BESTAETIGT_SYSTEM",
  ABGELEHNT_ALS_SELBSTZAHLER_EXPORTIERT = "ABGELEHNT_ALS_SELBSTZAHLER_EXPORTIERT",
  BESTAETIGT_SCHILD_ABGLEICH = "BESTAETIGT_SCHILD_ABGLEICH",
  BESTAETIGT_AUTOMATISIERUNG = "BESTAETIGT_AUTOMATISIERUNG",
  ABGELEHNT_AUTOMATISIERUNG = "ABGELEHNT_AUTOMATISIERUNG"
}
