import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from "./auth/auth.service";

const SERVICES = [
  AuthService
];

@NgModule({
	imports: [CommonModule],
	providers: [...SERVICES]
})
export class ServicesModule {
	static forRoot(): ModuleWithProviders<ServicesModule> {
		return {
			ngModule: ServicesModule,
			providers: [...SERVICES]
		};
	}
}
