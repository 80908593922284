import {Component, OnInit} from '@angular/core';
import {faAngleDown, faAngleUp, faInfoCircle, faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {SortDirection, SortType} from "@swimlane/ngx-datatable";
import {SchuleService} from "../../../../@core/services/schule/schule.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../shared/confirm/confirm.component";
import {catchError} from "rxjs/operators";
import {CompositMessageService} from "../../../../@core/services/compositMessage/compositMessage.service";
import {EMPTY, Subscription} from "rxjs";
import {SchuleDTO} from "../../../../models/schule/SchuleDTO";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../../../@core/utils/custom-toast.service";
import {SchultypDTO} from 'src/app/models/schultyp/SchultypDTO';
import {SchultypService} from 'src/app/@core/services/schultyp/schultyp.service';
import {parseInternationalNumberToGerman} from "../../../../@core/utils/forms/german-numeral-helper";

@Component({
  selector: 'app-schulen-detail',
  templateUrl: './schulen-detail.component.html',
  styleUrls: ['./schulen-detail.component.scss']
})
export class SchulenDetailComponent implements OnInit {

  faInfo = faInfoCircle;
  faPlus = faPlus;
  faDelete = faTrash;
  faSave = faSave;
  faArrowUp = faAngleUp;
  faArrowDown = faAngleDown;

  sortType = SortType;
  sortDirection = SortDirection;

  subscriptionTranslate: Subscription;
  entityNameTranslationKey: string = 'SCHULE';

  public schuleForm: UntypedFormGroup;
  schultypenList :  SchultypDTO[];
  public schuleDto : SchuleDTO;
  schuleId : string;
  entityNameTranslated: string;
  showMap: boolean = false;
  infoKoordinaten: string ='';



  constructor(private route: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private schuleService: SchuleService,
              private fb: UntypedFormBuilder,
              private customToastService: CustomToastService,
              private translateService: TranslateService,
              private modalService: NgbModal,
              private compositMessageService: CompositMessageService,
              private schultypService: SchultypService
  ) {
    this.schuleId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.initFormAndDTO();
    this.initLists();

    // dummy call for translation subscription makes sure translations work in sync
    this.subscriptionTranslate = this.translateService.get('dummy').subscribe(() => {
      this.entityNameTranslated = this.translateService.instant('STANDARD.SCHULE');
    });

    if(!(!this.schuleId)&&this.schuleId!=''){
      this.schuleService.getSchule(this.schuleId).subscribe((data: SchuleDTO) =>
        {
          this.schuleDto = { ...data };
          if(!(!this.schuleDto)){
            this.schuleForm.patchValue({
              name: this.schuleDto.name || '',
              schulnummer: this.schuleDto.schulnummer || '',
              berufskolleg: this.schuleDto.berufskolleg || false,
              schultyp: this.schuleDto.schultyp.id || undefined,
              schuleKoordinaten: this.schuleDto.schuleKoordinaten || []
            });
            this.infoKoordinaten = this.schuleDto.schuleKoordinaten.length>0? '' : 'Dieser Schule sind noch keine Koordinaten zugewiesen.';
          }
        }
      );
    } else {
      this.infoKoordinaten = 'Sobald die Schule angelegt ist, können ihr Koordinaten zugewiesen werden.'
    }
  }

  updateSchule(){
    this.schuleService.updateSchule(parseInt(this.route.snapshot.paramMap.get('id')),
      {
        name: this.getFormItem('name')?.value.length !== 0 ? this.getFormItem('name').value : undefined,
        schulnummer: this.getFormItem('schulnummer')?.value.length !== 0 ? this.getFormItem('schulnummer').value : undefined,
        berufskolleg: this.getFormItem('berufskolleg')?.value.length !== 0 ? this.getFormItem('berufskolleg').value : undefined,
        schultypId: this.getFormItem('schultyp')?.value !== undefined ? this.getFormItem('schultyp').value : undefined,
      })
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'patch'));
          return EMPTY;
        })
      )
      .subscribe((data: SchuleDTO) => {
        this.schuleDto = {...data};
        this.router.navigate(['/pages/schulen']).then(()=>this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'patch'))).catch(()=>{});
      });
  }

  createSchule(){
    this.schuleService.createSchule({
      name: this.getFormItem('name')?.value.length !== 0 ? this.getFormItem('name').value : null,
      schulnummer: this.getFormItem('schulnummer')?.value.length !== 0 ? this.getFormItem('schulnummer').value : null,
      berufskolleg: this.getFormItem('berufskolleg')?.value.length !== 0 ? this.getFormItem('berufskolleg').value : null,
      schultypId: this.getFormItem('schultyp')?.value !== undefined ? this.getFormItem('schultyp').value : null,
    })
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'post'));
          return EMPTY;
        })
      )
      .subscribe((data: SchuleDTO) => {
        this.schuleDto = {...data};
        this.router.navigate(['/pages/schulenDetail/'+this.schuleDto.id]).then(()=>{this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'post'))}).catch(()=>{});
      });
  }

  deleteSchule(){
    this.schuleService.deleteSchule(parseInt(this.route.snapshot.paramMap.get('id')))
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'delete'));
          return EMPTY;
        })
      )
      .subscribe(
        () => {
          this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'delete'));
          this.navigateToSchuleKlassen();
        },
      );
  }

  onActivate(event) {
    if(event.type == 'click') {
      this.router.navigate(['/pages/schulen']).catch(()=>{});
    }
  }

  getFormItem(s: string) {
    return this.schuleForm?.get(s);
  }

  saveOrUpdate(){
    if(!this.schuleForm.valid){
      this.customToastService.showError(this.compositMessageService.createIncompleteMessage(this.entityNameTranslationKey));
      return;
    }
    if(this.getFormItem('schulnummer').value.length>10){
      this.customToastService.showError('Schulnummer darf maximal 10 Zeichen lang sein.');
      return;
    }
    if(this.route.snapshot.paramMap.get('id')!==null && this.route.snapshot.paramMap.get('id')!== ''){
      this.updateSchule();
    } else {
      this.createSchule();
    }
  }

  navigateToNewCoordinates(){
    this.router.navigate(['/pages/schulekoordinaten'],{queryParams:{schuleId: this.schuleId, schulname: this.schuleDto?.name}} ).catch(()=>{});
  }

  navigateToExistingCoordinates(schuleKoordinateId){
    this.router.navigate(['/pages/schulekoordinaten/'+schuleKoordinateId],{queryParams:{schuleId: this.schuleId, schulname: this.schuleDto?.name}} ).catch(()=>{});
  }

  navigateToSchuleKlassen(){
    this.router.navigate(['/pages/schulen']).catch(()=>{});
  }

  toggleMap(){
    this.showMap = true;
  }

  ngOnDestroy(){
    this.subscriptionTranslate.unsubscribe();
  }

  showDecisionModal(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.compositMessageService.createDeleteWarning(this.entityNameTranslationKey);
    modalRef.componentInstance.onYes = () => {
      this.deleteSchule();
    };
  }

  getFilterStringForServer(value){
    return (value !== "") ?  "name:"+value : "";
  }

  initLists(){
    this.schultypService.getSchultypen().subscribe(schultypen => this.schultypenList = schultypen);
  }

  initFormAndDTO(){
    this.schuleForm = this.fb.group({
      name: ['',[Validators.required,Validators.maxLength(100)]],
      schulnummer: [''], //,Validators.maxLength(10) couldn't add this, because this tags fields as "required"
      berufskolleg: false,
      schultyp: [undefined,[Validators.required]]
    });

    this.schuleDto = {
      berufskolleg: false,
      eigenanteil: false,
      hinweistext: '',
      id: undefined,
      version: undefined,
      name: '',
      schulnummer: '',
      schultyp: undefined,
      schuleKoordinaten: undefined,
      tickets: undefined,
      ticketsa: undefined,
      klassen: undefined,
      bildungsgaenge: undefined,
      haltestellen: undefined
    }
  }


  protected readonly parseInternationalNumberToGerman = parseInternationalNumberToGerman;
}
