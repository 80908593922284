import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {FahrkostenDTO} from "../../../models/fahrkosten/FahrkostenDTO";
import {FahrkostenUpdateDTO} from "../../../models/fahrkosten/FahrkostenUpdateDTO";
import {FahrkostenCreateDTO} from "../../../models/fahrkosten/FahrkostenCreateDTO";
import {PictureFileDTO} from "../../../models/routing/PictureFileDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {FahrkostenMinimalDTO} from "../../../models/fahrkosten/FahrkostenMinimalDTO";

@Injectable({
  providedIn: 'root'
})
export class FahrkostenService {

  configUrl = 'api/fahrkosten';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getFahrkosten(id: string): Observable<FahrkostenDTO> {
    return this.http.get<FahrkostenDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  updateFahrkosten(id: string, dto: FahrkostenUpdateDTO, praktikumsvertrag: File, sonderfallNachweis: File, bezugNachweis: File, fahrkostenNachweis: File): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    if (praktikumsvertrag !== undefined) {
      formData.append('praktikumsvertrag', praktikumsvertrag);
    }
    if (sonderfallNachweis !== undefined) {
      formData.append('sonderfallNachweis', sonderfallNachweis);
    }
    if (bezugNachweis !== undefined) {
      formData.append('bezugNachweis', bezugNachweis);
    }
    if (fahrkostenNachweis !== undefined) {
      formData.append('fahrkostenNachweis', fahrkostenNachweis);
    }
    return this.http.patch<FahrkostenDTO>(
      this.configUrl+'/'+id,
      formData,
      { headers: this.headerService.getAuthHeader() }
    );
  }

  createFahrkosten(dto: FahrkostenCreateDTO, praktikumsvertrag: File, sonderfallNachweis: File, bezugNachweis: File, fahrkostenNachweis: File): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    if (praktikumsvertrag !== undefined) {
      formData.append('praktikumsvertrag', praktikumsvertrag);
    }
    if (sonderfallNachweis !== undefined) {
      formData.append('sonderfallNachweis', sonderfallNachweis);
    }
    if (bezugNachweis !== undefined) {
      formData.append('bezugNachweis', bezugNachweis);
    }
    if (fahrkostenNachweis !== undefined) {
      formData.append('fahrkostenNachweis', fahrkostenNachweis);
    }
    return this.http.post<FahrkostenDTO>(
      this.configUrl,
      formData,
      { headers: this.headerService.getAuthHeader() }
    );
  }

  // getFahrkostenList(): Observable<Array<FahrkostenDTO>> {
  //   return this.http.get<Array<FahrkostenDTO>>(
  //     this.configUrl+'/bulk',
  //     { headers: this.headerService.getAuthHeaderApplicationJson() }
  //   );
  // }

  getAllFahrkostenMinimal(): Observable<Array<FahrkostenMinimalDTO>> {
    return this.http.get<Array<FahrkostenMinimalDTO>>(
        this.configUrl+'/bulk-minimal',
        { headers: this.headerService.getAuthHeaderApplicationJson()}
    );
  }

  getHistory(id: number): Observable<Array<FahrkostenDTO>>{
    return this.http.get<Array<FahrkostenDTO>>(
      this.configUrl+'/revision/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  getRouteImageByFahrkosten(fahrkostenId: string): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+fahrkostenId+'/routeImage',
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  deleteFahrkosten(id: number): Observable<any> {
    return this.http.delete(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeader(), responseType: 'text' }
    );
  }

  downloadPraktikumsvertrag(fahrkostenId: number): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+fahrkostenId+'/praktikumsvertrag',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadSonderfallNachweis(fahrkostenId: number): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+fahrkostenId+'/sonderfallNachweis',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadBezugNachweis(fahrkostenId: number): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+fahrkostenId+'/bezugNachweis',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadFahrkostenNachweis(fahrkostenId: number): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+fahrkostenId+'/fahrkostenNachweis',
      { headers: this.headerService.getAuthHeader() }
    );
  }

}
