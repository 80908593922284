import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../utils/custom-toast.service";
import {BenutzerService} from "../../services/benutzer/benutzer.service";
import {catchError, pairwise, startWith} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {pwFormatValidator} from "../../validators/formatting/password-format.validator";
import {accountPwChangeValidator} from "../../validators/account/account-pw-change.validator";

/*
  Route is called via invitation email
  ("Es wurde ein Account in SFK für Sie erstellt ..")

  Email is created via endpoint: benutzer/einladen -> BenutzerService.benutzerEinladen

  Activation is tied to email address, hence ..
  formControl email => readonly = true
  formControl kennwort => temp value from invitation Email, validate: required
  formControl kennwortNeu => validate: required, password pattern, notMatches kennwort
  formControl kennwortbestaetigung => validate: required, matches kennwortNeu
 */

@Component({
  selector: 'app-einladung',
  templateUrl: './einladung.component.html',
  styleUrls: ['./einladung.component.css']
})
export class EinladungComponent implements OnInit {

  public aktivierungEinladungForm: UntypedFormGroup;
  public dto: BenutzerDTO;
  pwHide = true;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  token: string;
  email: string;

  formItemSubscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private customToastService: CustomToastService,
              private benutzerService: BenutzerService,
              public translateService: TranslateService,
              private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
          this.aktivierungEinladungForm = this.fb.group({
            email:[params.email],
            kennwort:['',[Validators.required]],
            kennwortNeu: ['',[Validators.required, pwFormatValidator]],
            kennwortbestaetigung: ['',[Validators.required, accountPwChangeValidator]]
          });
          this.token = params.token;
          this.formItemSubscriptions.push(
            this.aktivierungEinladungForm.get('kennwort').valueChanges
              .pipe(startWith(null),pairwise())
              .subscribe(([prev, next]: [any, any]) => {
                if(next!==prev){
                  this.kennwortChanged();
                }
              }),
            this.aktivierungEinladungForm.get('neuKennwort').valueChanges
              .pipe(startWith(null),pairwise())
              .subscribe(([prev, next]: [any, any]) => {
                if(next!==prev){
                  this.kennwortChanged();
                }
              }),
            this.aktivierungEinladungForm.get('kennwortbestaetigung').valueChanges
              .pipe(startWith(null),pairwise())
              .subscribe(([prev, next]: [any, any]) => {
                if(next!==prev){
                  this.kennwortChanged();
                }
              }),
          );
      });

  }

  kennwortChanged(){
    let tempPw : string = this.getFormItem('kennwort')?.value;
    let neuPw : string = this.getFormItem('neuKennwort')?.value;
    let confirmPW : string = this.getFormItem('kennwortbestaetigung')?.value;

    // make sure new password is not copy from temp email password
    if( !!tempPw && tempPw === neuPw ){
      this.aktivierungEinladungForm?.get('neuKennwort')?.setErrors({ passwordNotNew: true })
    } else { // clear error
      if( !!this.aktivierungEinladungForm?.get('neuKennwort')?.errors
        && !!this.aktivierungEinladungForm?.get('neuKennwort')?.errors['passwordNotNew'])
        delete this.aktivierungEinladungForm?.get('neuKennwort')?.errors['passwordNotNew']
    }

    // make sure new password is confirmed correctly
    if( neuPw !== confirmPW ){
      this.aktivierungEinladungForm?.get('kennwortbestaetigung')?.setErrors({ passwordMismatch: true })
    } else { // clear error
      if( !!this.aktivierungEinladungForm?.get('kennwortbestaetigung')?.errors
          && !!this.aktivierungEinladungForm?.get('kennwortbestaetigung')?.errors['passwordMismatch'])
        delete this.aktivierungEinladungForm?.get('kennwortbestaetigung')?.errors['passwordMismatch']
    }
  }

  getFormItem(s: string) {
    return this.aktivierungEinladungForm?.get(s);
  }

  activate(){
    if(this.aktivierungEinladungForm.invalid){
      this.customToastService.showError('Die Angaben sind falsch oder unvollständig.');
      return;
    }
    const emailInput =  this.getFormItem('email')?.value.length !== 0 ? this.getFormItem('email').value : null;
    const kennwort = this.getFormItem('kennwort')?.value.length !== 0 ? this.getFormItem('kennwort').value : null;
    const neuKennwort = this.getFormItem('neuKennwort')?.value.length !== 0 ? this.getFormItem('neuKennwort').value : null;
    const kennwortbestaetigung = this.getFormItem('kennwortbestaetigung')?.value.length !== 0 ? this.getFormItem('kennwortbestaetigung').value : null;

    this.benutzerService.activateBenutzerEinladen({
      email : emailInput,
      kennwort: kennwort,
      neuKennwort: neuKennwort,
      kennwortbestaetigung: kennwortbestaetigung,
      token : this.token
    }).pipe(
      catchError((err) => {
        if(err.error.message.includes ('user_is_pending'))
          this.customToastService.showError(this.translateService.instant('COMPOSITE-MESSAGE.USER_IS_PENDING'));
        else
          this.customToastService.showError(this.translateService.instant('COMPOSITE-MESSAGE.INVITATION_FAILED'));
        return EMPTY;
      })
    )
      .subscribe((data: BenutzerDTO) => {
        this.dto = {...data};
        this.router.navigate(['/authentication/login']);
        this.customToastService.showSuccess("Der Nutzer wurde erfolgreich aktiviert.");
      });

  }

  ngOnDestroy(){
    this.formItemSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
