export enum KeyString {


  //Hauptseite
  HAUPTSEITE_BESCHREIBUNG_TEXT="HAUPTSEITE_BESCHREIBUNG_TEXT",
  HAUPTSEITE_AUSWAHL_BESCHREIBUNG_TEXT ="HAUPTSEITE_AUSWAHL_BESCHREIBUNG_TEXT",
  MAIL_BENUTZER_AKTIVIERUNG_TEXT = "MAIL_BENUTZER_AKTIVIERUNG_TEXT", //backend
  MAIL_BENUTZER_AKTIVIERUNG_BETREFF = "MAIL_BENUTZER_AKTIVIERUNG_BETREFF", //backend
  MAIL_BENUTZER_AKTIVIERUNG_ABSENDER = "MAIL_BENUTZER_AKTIVIERUNG_ABSENDER", //backend
  MAIL_ABLEHNUNG_TICKET_TEXT = "MAIL_ABLEHNUNG_TICKET_TEXT", //backend
  MAIL_ABLEHNUNG_TICKET_BETREFF = "MAIL_ABLEHNUNG_TICKET_BETREFF", //backend
  MAIL_ABLEHNUNG_TICKET_ABSENDER = "MAIL_ABLEHNUNG_TICKET_ABSENDER", //backend
  MAIL_BESTAETIGUNG_TICKET_EIGENANTEIL_TEXT = "MAIL_BESTAETIGUNG_TICKET_EIGENANTEIL_TEXT", //backend
  MAIL_BESTAETIGUNG_TICKET_EIGENANTEIL_BETREFF = "MAIL_BESTAETIGUNG_TICKET_EIGENANTEIL_BETREFF", //backend
  MAIL_BESTAETIGUNG_TICKET_EIGENANTEIL_ABSENDER = "MAIL_BESTAETIGUNG_TICKET_EIGENANTEIL_ABSENDER", //backend
  MAIL_KENNWORT_BESTAETIGUNG_TEXT = "MAIL_KENNWORT_BESTAETIGUNG_TEXT", //backend
  MAIL_KENNWORT_BESTAETIGUNG_BETREFF = "MAIL_KENNWORT_BESTAETIGUNG_BETREFF", //backend
  MAIL_KENNWORT_BESTAETIGUNG_ABSENDER = "MAIL_KENNWORT_BESTAETIGUNG_ABSENDER", //backend
  MAIL_EMAIL_BESTAETIGUNG_TEXT = "MAIL_EMAIL_BESTAETIGUNG_TEXT", //backend
  MAIL_EMAIL_BESTAETIGUNG_BETREFF = "MAIL_EMAIL_BESTAETIGUNG_BETREFF", //backend
  MAIL_EMAIL_BESTAETIGUNG_ABSENDER = "MAIL_EMAIL_BESTAETIGUNG_ABSENDER", //backend
  KONTO_IBAN = "KONTO_IBAN", //backend
  KONTO_BIC = "KONTO_BIC", //backend
  KONTO_INSTITUT = "KONTO_INSTITUT", //backend
  ENTFERNUNGSBERECHNUNG_INFOTEXT = "ENTFERNUNGSBERECHNUNG_INFOTEXT", //frontend mapview(old sfk)
  MAIL_BENUTZER_ENTSPERREN_TEXT = "MAIL_BENUTZER_ENTSPERREN_TEXT", //backend
  MAIL_BENUTZER_ENTSPERREN_ABSENDER = "MAIL_BENUTZER_ENTSPERREN_ABSENDER", //backend
  MAIL_BENUTZER_ENTSPERREN_BETREFF = "MAIL_BENUTZER_ENTSPERREN_BETREFF", //backend
  MAIL_VERWENDUNGSZWECK_TEXT = "MAIL_VERWENDUNGSZWECK_TEXT", //backend
  FAHRKOSTEN_INFORMATION_SCHUELER = "FAHRKOSTEN_INFORMATION_SCHUELER", //frontend Fahrkosten
  INSTANZNAME = "INSTANZNAME", //frontend header
  MAIL_ABGESCHLOSSEN_FAHRKOSTEN_TEXT = "MAIL_ABGESCHLOSSEN_FAHRKOSTEN_TEXT", //backend
  MAIL_ABGESCHLOSSEN_FAHRKOSTEN_BETREFF = "MAIL_ABGESCHLOSSEN_FAHRKOSTEN_BETREFF", //backend
  MAIL_ABGESCHLOSSEN_FAHRKOSTEN_ABSENDER = "MAIL_ABGESCHLOSSEN_FAHRKOSTEN_ABSENDER", //backend
  WELCOME_TEXT = "WELCOME_TEXT", //frontend index Antragsteller
  WARTUNG_TEXT = "WARTUNG_TEXT", //Frontend auth
  ANTRAGSTELLER_PORTAL_BESCHREIBUNG = "ANTRAGSTELLER_PORTAL_BESCHREIBUNG", //frontend index
  PORTAL_BESCHREIBUNG = "PORTAL_BESCHREIBUNG", //frontend index
  ANTRAGSTELLER_PORTAL_BESCHREIBUNG_INFO = "ANTRAGSTELLER_PORTAL_BESCHREIBUNG_INFO", //frontend index
  ANTRAGTICKET_ANGELEGT_NACHRICHT = "ANTRAGTICKET_ANGELEGT_NACHRICHT", //backend
  PROFIL_INCOMPLETE_UEBERSCHRIFT = "PROFIL_INCOMPLETE_UEBERSCHRIFT", //frontend Antragsteller
  PROFIL_INCOMPLETE_NACHRICHT = "PROFIL_INCOMPLETE_NACHRICHT", //frontend Antragsteller
  ARCHIV_INFOTEXT = "ARCHIV_INFOTEXT", //customTagLib
  ANTRAGTICKET_EINSTIEGHALTESTELLE = "ANTRAGTICKET_EINSTIEGHALTESTELLE", //frontend customTag
  ANTRAGTICKET_SCHWERBEHINDERUNG = "ANTRAGTICKET_SCHWERBEHINDERUNG", //frontend customTag
  ANTRAGTICKET_EINGEREICHT_NACHRICHT = "ANTRAGTICKET_EINGEREICHT_NACHRICHT",  //frontend Antragsteller
  MAIL_ZAHLUNGSAUFFORDERUNG_TICKET_EIGENANTEIL_BETREFF = "MAIL_ZAHLUNGSAUFFORDERUNG_TICKET_EIGENANTEIL_BETREFF", //backend
  MAIL_ZAHLUNGSAUFFORDERUNG_TICKET_EIGENANTEIL_TEXT = "MAIL_ZAHLUNGSAUFFORDERUNG_TICKET_EIGENANTEIL_TEXT", //backend
  FAHRKOSTEN_KUERZESTE_ENTFERNUNG = "FAHRKOSTEN_KUERZESTE_ENTFERNUNG", //frontend MapView
  FAHRKOSTEN_NICHT_KUERZESTE_ENTFERNUNG = "FAHRKOSTEN_NICHT_KUERZESTE_ENTFERNUNG", //frontend MapView
  INDEX_HINWEISE_TEXT = "INDEX_HINWEISE_TEXT", //frontend Antragsteller
  FAHRKOSTEN_HINWEISE_ANTRAGSTELLER = "FAHRKOSTEN_HINWEISE_ANTRAGSTELLER",  //frontend taglib
  FAHRKOSTEN_ALLGEMEINE_HINWEISE = "FAHRKOSTEN_ALLGEMEINE_HINWEISE", //frontend Fahrkosten
  FAHRKOSTEN_FORMEL="FAHRKOSTEN_FORMEL", //backend
  FAHRKOSTEN_BEMERKUNGEN_SCHULE_INFO="FAHRKOSTEN_BEMERKUNGEN_SCHULE_INFO", //backend
  FAHRKOSTEN_PRAKTIKUMSSTELLE = "FAHRKOSTEN_PRAKTIKUMSSTELLE", //backend
  FAHRKOSTEN_GESAMTBETRAG = "FAHRKOSTEN_GESAMTBETRAG", //backend
  FAHRKOSTEN_NACHWEISLICH_GEFAHRENE_TAGE = "FAHRKOSTEN_NACHWEISLICH_GEFAHRENE_TAGE", //backend

  ANTRAGTICKET_PREISSTUFE_INFO = "ANTRAGTICKET_PREISSTUFE_INFO", //customTag done!
  ANTRAGTICKET_EIGENANTEIL_INFO = "ANTRAGTICKET_EIGENANTEIL_INFO", //customTag done!
  ANTRAGTICKET_ZUZAHLUNG_INFO = "ANTRAGTICKET_ZUZAHLUNG_INFO", //customTag done!
  ANTRAGTICKET_ERMAESSIGUNG_INFO = "ANTRAGTICKET_ERMAESSIGUNG_INFO", //customTag

  ANTRAGTICKET_EINVERSTAENDNIS_ERKLAERUNG = "ANTRAGTICKET_EINVERSTAENDNIS_ERKLAERUNG",  //frontend antragTicket
  ANTRAGTICKET_ABONNEMENTNUMMER_ANTRAG_INFO = "ANTRAGTICKET_ABONNEMENTNUMMER_ANTRAG_INFO", //customTag
  ANTRAGTICKET_DATENSCHUTZ_CHECK = "ANTRAGTICKET_DATENSCHUTZ_CHECK", //frontend antragTicket edit
  ANTRAGTICKET_MARKETINGFREIGABE = "ANTRAGTICKET_MARKETINGFREIGABE", //frontend antragTicket edit
  ANTRAGTICKET_SELBSTZAHLER_EXPORT = "ANTRAGTICKET_SELBSTZAHLER_EXPORT", //frontend antragTicket

  ANTRAGTICKET_ANGABEN_FAHRWEG = "ANTRAGTICKET_ANGABEN_FAHRWEG",
  ANTRAGTICKET_KOSTENBERECHNUNG_INFO = "ANTRAGTICKET_KOSTENBERECHNUNG_INFO",
  ANTRAGTICKET_ANGABEN_SCHULE_TITEL ="ANTRAGTICKET_ANGABEN_SCHULE_TITEL",
  ANTRAGTICKET_ANGABEN_SCHULTRAEGER_TITEL = "ANTRAGTICKET_ANGABEN_SCHULTRAEGER_TITEL",
  ANTRAGTICKET_PRAKTIKUM_HINWEIS = "ANTRAGTICKET_PRAKTIKUM_HINWEIS",
  ANTRAGTICKET_MELDEDATEN_TITLE = "ANTRAGTICKET_MELDEDATEN_TITLE",

  FAHRKOSTEN_KOSTEN_OEFFENTLICH = "FAHRKOSTEN_KOSTEN_OEFFENTLICH",  //frontend Fahrkosten
  FAHRKOSTEN_KOSTEN_FAHRZEUG = "FAHRKOSTEN_KOSTEN_FAHRZEUG", //

  PROFIL_IBAN_HINT = "PROFIL_IBAN_HINT",
  PROFIL_KONTOINHABER_HINT = "PROFIL_KONTOINHABER_HINT",
  PROFIL_TELEFON_HINT = "PROFIL_TELEFON_HINT",

  MAIL_BENUTZER_EINLADUNG_TEXT = "MAIL_BENUTZER_EINLADUNG_TEXT",

  IMPRESSUM_TEXT = "IMPRESSUM_TEXT", //frontend
  DATENSCHUTZ_TEXT = "DATENSCHUTZ_TEXT", //frontend

  EMAIL_ABLEHNUNG_FAHRKOSTEN_1 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_1", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_2 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_2", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_3 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_3", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_4 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_4", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_5 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_5", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_6 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_6", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_7 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_7", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_8 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_8", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_9 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_9", //backend
  EMAIL_ABLEHNUNG_FAHRKOSTEN_10 = "EMAIL_ABLEHNUNG_FAHRKOSTEN_10", //backend

  EMAIL_ABLEHNUNG_TICKET_1 = "EMAIL_ABLEHNUNG_TICKET_1", //backend
  EMAIL_ABLEHNUNG_TICKET_2 = "EMAIL_ABLEHNUNG_TICKET_2", //backend
  EMAIL_ABLEHNUNG_TICKET_3 = "EMAIL_ABLEHNUNG_TICKET_3", //backend
  EMAIL_ABLEHNUNG_TICKET_4 = "EMAIL_ABLEHNUNG_TICKET_4", //backend
  EMAIL_ABLEHNUNG_TICKET_5 = "EMAIL_ABLEHNUNG_TICKET_5", //backend
  EMAIL_ABLEHNUNG_TICKET_6 = "EMAIL_ABLEHNUNG_TICKET_6", //backend
  EMAIL_ABLEHNUNG_TICKET_7 = "EMAIL_ABLEHNUNG_TICKET_7", //backend
  EMAIL_ABLEHNUNG_TICKET_8 = "EMAIL_ABLEHNUNG_TICKET_8", //backend
  EMAIL_ABLEHNUNG_TICKET_9 = "EMAIL_ABLEHNUNG_TICKET_9", //backend
  EMAIL_ABLEHNUNG_TICKET_10 = "EMAIL_ABLEHNUNG_TICKET_10", //backen

//Antragticket
  ANTRAGSART = "ANTRAGSART",
  ADRESSZUSATZ_INFO = "ADRESSZUSATZ_INFO",
  GESCHLECHT_INFO = "GESCHLECHT_INFO",
  ABONNEMENTNUMMER_INFO = "ABONNEMENTNUMMER_INFO",
  ABO_GUELTIG_AB_INFO ="ABO_GUELTIG_AB_INFO",
  BILDUNGSGANGDATUM_INFO = "BILDUNGSGANGDATUM_INFO",
  EINSTIEGHALTESTELLE_INFO = "EINSTIEGHALTESTELLE_INFO",
  AUSSTIEGHALTESTELLE_INFO = "AUSSTIEGHALTESTELLE_INFO",
  GEBURTSDATUM_INFO = "GEBURTSDATUM_INFO",
  VORMUNDNAME_INFO = "VORMUNDNAME_INFO",
  VORMUNDVORNAME_INFO = "VORMUNDVORNAME_INFO",
  VORMUNDSTRASSE_INFO = "VORMUNDSTRASSE_INFO",
  VORMUNDORT_INFO= "VORMUNDORT_INFO",
  FACHBEREICH_INFO= "FACHBEREICH_INFO",
  VORMUNDPLZ_INFO= "VORMUNDPLZ_INFO",
  VORMUNDADRESSZUSATZ_INFO= "VORMUNDADRESSZUSATZ_INFO",
  VORMUNDLAND_INFO= "VORMUNDLAND_INFO",
  VORMUNDGEBURTSTAG_INFO= "VORMUNDGEBURTSTAG_INFO",
  VORMUNDEMAIL_INFO= "VORMUNDEMAIL_INFO",
  KREDITINSITUT_INFO= "KREDITINSITUT_INFO",
  VORMUNDGESCHLECHT_INFO= "VORMUNDGESCHLECHT_INFO",
  GESCHLECHTSEPA_INFO = "GESCHLECHTSEPA_INFO",
  GESCHWISTERKIND1_INFO= "GESCHWISTERKIND1_INFO",
  GESCHWISTERKIND2_INFO= "GESCHWISTERKIND2_INFO",
  GESCHWISTERKIND3_INFO= "GESCHWISTERKIND3_INFO",
  GESCHWISTERKIND4_INFO= "GESCHWISTERKIND4_INFO",
  GESCHWISTERKIND5_INFO= "GESCHWISTERKIND5_INFO",
  KLASSE_INFO= "KLASSE_INFO",
  SCHULJAHR_INFO= "SCHULJAHR_INFO",
  BILDUNGSGANG_INFO= "BILDUNGSGANG_INFO",
  BILDUNGSGANG_INFO_BELOW= "BILDUNGSGANG_INFO_BELOW",
  // PRAKTIKUMHINWEIS_INFO= "PRAKTIKUMHINWEIS_INFO",
  PRAKTIKUMSVERTRAG_INFO= "PRAKTIKUMSVERTRAG_INFO",
  PRAKTIKUMSTELLE_INFO= "PRAKTIKUMSTELLE_INFO",
  PRAKTIKUMSTRASSE_INFO= "PRAKTIKUMSTRASSE_INFO",
  PRAKTIKUMORT_INFO= "PRAKTIKUMORT_INFO",
  PRAKTIKUMSTAGE_JE_WOCHE_INFO="PRAKTIKUMSTAGE_JE_WOCHE_INFO",

  TICKET_INFO= "TICKET_INFO",

  PRAKTIKUMPLZ_INFO= "PRAKTIKUMPLZ_INFO",
  PRAKTIKUMADRESSZUSATZ_INFO= "PRAKTIKUMADRESSZUSATZ_INFO",
  PRAKTIKUMLAND_INFO= "PRAKTIKUMLAND_INFO",
  PRAKTIKUMSTARTDATUM_INFO= "PRAKTIKUMSTARTDATUM_INFO",
  PRAKTIKUMENDDATUM_INFO= "PRAKTIKUMENDDATUM_INFO",
  NAMEKREDITINSTITUT_INFO= "NAMEKREDITINSTITUT_INFO",
  TELEFONNUMMER_INFO= "TELEFONNUMMER_INFO",
  SONDERFALL_INFO= "SONDERFALL_INFO",
  SONDERFALL_INFO_BELOW= "SONDERFALL_INFO_BELOW",
  SONDERFALLNACHWEIS_INFO= "SONDERFALL_NACHWEIS_INFO",
  SONDERFALLBEGRUENDUNG_INFO= "SONDERFALL_BEGRUENDUNG_INFO",
  EINVERSTAENDNISERKLAERUNG_INFO= "EINVERSTAENDNISERKLAERUNG_INFO",
  VORNAMESEPA_INFO= "VORNAMESEPA_INFO",
  NAMESEPA_INFO= "NAMESEPA_INFO",
  STRASSESEPA_INFO= "STRASSESEPA_INFO",
  IBAN_INFO= "IBAN_INFO",
  BIC_INFO= "BIC_INFO",
  WEGTYP_INFO= "WEGTYP_INFO",
  SCHULE_INFO= "ANGABEN_SCHULE_INFO",
  BEMERKUNG_ANTRAGSTELLER_INFO= "BEMERKUNG_ANTRAGSTELLER_INFO",
  ORTSEPA_INFO= "ORTSEPA_INFO",
  PLZSEPA_INFO= "PLZSEPA_INFO",
  ADRESSZUSATZSEPA_INFO= "ADRESSZUSATZSEPA_INFO",
  LANDSEPA_INFO= "LANDSEPA_INFO",
  EMAILSEPA_INFO= "EMAILSEPA_INFO",
  TELEFONNUMMERSEPA_INFO= "TELEFONNUMMERSEPA_INFO",
  GEBURTSDATUMSEPA_INFO= "GEBURTSDATUMSEPA_INFO",
  KREDITINSTITUTSEPA_INFO= "KREDITINSTITUTSEPA_INFO",
  SEPAIBAN_INFO= "SEPAIBAN_INFO",
  SEPABIC_INFO= "SEPABIC_INFO",
  DATENSCHUTZ_INFO= "DATENSCHUTZ_INFO",
  MARKETINGFREIGABE_INFO= "MARKETINGFREIGABE_INFO",
  ASYL_INFO= "ASYL_INFO",

  ANTRAGSART_INFO = "ANTRAGSART_INFO",
  REFERENZZAHLUNG = "REFERENZZAHLUNG",
  REFERENZZAHLUNG_INFO = "REFERENZZAHLUNG_INFO",
  BEMANTRAGSTELLER = "BEMANTRAGSTELLER",

  //Nur SB
  BEMERKUNG_SCHULE_INFO = "BEMERKUNG_SCHULE_INFO",
  BEMERKUNG_SCHULTRAEGER_INFO = "BEMERKUNG_SCHULTRAEGER_INFO",
  INTERNERVERMERK_SCHULE_INFO = "INTERNERVERMERK_SCHULE_INFO",
  INTERNERVERMERK_SCHULTRAEGER_INFO = "INTERNERVERMERK_SCHULTRAEGER_INFO",
  MELDEDATEN_AUTOMATISCH_INFO= "MELDEDATEN_AUTOMATISCH_INFO",
  MELDEDATEN_MANUELL_INFO="MELDEDATEN_MANUELL_INFO",
  ABLEHNUNGSGRUND_INFO="ABLEHNUNGSGRUND_INFO",
  NACHRICHT_AN_ANTRAGSTELLER_INFO="NACHRICHT_AN_ANTRAGSTELLER_INFO",

  //Überschriften
  BEANTRAGENDEPERSON_TITEL_TICKET = "BEANTRAGENDEPERSON_TITEL_TICKET",
  ANGABEN_FAHRWEG_TITEL_TICKET = "ANGABEN_FAHRWEG_TITEL_TICKET",
  INFO_KOSTENBERECHNUNG_TITEL_TICKET = "INFO_KOSTENBERECHNUNG_TITEL_TICKET",
  ERTEILUNG_SEPA_MANDAT_TITEL_TICKET = "ERTEILUNG_SEPA_MANDAT_TITEL_TICKET",
  ANGABEN_SCHULE_TITEL_TICKET = "ANGABEN_SCHULE_TITEL_TICKET",
  ANGABEN_TICKET_TITEL_TICKET = "ANGABEN_TICKET_TITEL_TICKET",
  ERSTATTUNG_GRUENDE_TITEL_TICKET = "ERSTATTUNG_GRUENDE_TITEL_TICKET",

  PERSON_SCHUELER_SINGULAR = "PERSON_SCHUELER_SINGULAR",
  PERSON_SCHUELER_PLURAL = "PERSON_SCHUELER_PLURAL",
  TITLE_SCHUELER_LIST = "TITLE_SCHUELER_LIST",

  //Überschriften Labels Fahrkosten
  ANGABEN_SCHUELER_TITEL_FAHRKOSTEN  = "ANGABEN_SCHUELER_TITEL_FAHRKOSTEN",
  KONTOANGABEN_TITEL_FAHRKOSTEN = "KONTOANGABEN_TITEL_FAHRKOSTEN",
  KOSTEN_NACHWEISE_TITEL_FAHRKOSTEN = "KOSTEN_NACHWEISE_TITEL_FAHRKOSTEN",
  ANGABEN_ERSTATTUNG_TITEL_FAHRKOSTEN = "ANGABEN_ERSTATTUNG_TITEL_FAHRKOSTEN",
  GRUENDE_UEBERNAHME_TITEL_FAHRKOSTEN = "GRUENDE_UEBERNAHME_TITEL_FAHRKOSTEN",
  GRUENDE_ERHOEHTE_TITEL_FAHRKOSTEN = "GRUENDE_ERHOEHTE_TITEL_FAHRKOSTEN",
  BEANTRAGENDEPERSON_TITEL_FAHRKOSTEN = "BEANTRAGENDEPERSON_TITEL_FAHRKOSTEN",
  ANGABEN_SCHULE_TITEL_FAHRKOSTEN = "ANGABEN_SCHULE_TITEL_FAHRKOSTEN",

  NAME_INFO_FAHRKOSTEN = "NAME_INFO_FAHRKOSTEN",
  VORNAME_INFO_FAHRKOSTEN = "VORNAME_INFO_FAHRKOSTEN",
  STRASSE_INFO_FAHRKOSTEN = "STRASSE_INFO_FAHRKOSTEN",
  WOHNORT_INFO_FAHRKOSTEN = "WOHNORT_INFO_FAHRKOSTEN",
  PLZ_INFO_FAHRKOSTEN = "PLZ_INFO_FAHRKOSTEN",
  ADRESSZUSATZ_INFO_FAHRKOSTEN = "ADRESSZUSATZ_INFO_FAHRKOSTEN",
  LAND_INFO_FAHRKOSTEN = "LAND_INFO_FAHRKOSTEN",
  GEBURTSDATUM_INFO_FAHRKOSTEN = "BDAY_INFO_FAHRKOSTEN",
  GESCHLECHT_INFO_FAHRKOSTEN = "GESCHLECHT_INFO_FAHRKOSTEN",
  EMAIL_INFO_FAHRKOSTEN = "EMAIL_INFO_FAHRKOSTEN",
  EMAIL_SCHUELER_INFO_FAHRKOSTEN = "EMAIL_SCHUELER_INFO_FAHRKOSTEN",
  TELEFONNR_SCHUELER_INFO_FAHRKOSTEN = "TELEFONNR_SCHUELER_INFO_FAHRKOSTEN",
  IBAN_INFO_FAHRKOSTEN = "IBAN_INFO_FAHRKOSTEN",
  BIC_INFO_FAHRKOSTEN = "BIC_INFO_FAHRKOSTEN",
  ANTRAGSART_INFO_FAHRKOSTEN = "ANTRAGSART_INFO_FAHRKOSTEN",
  WEGTYP_INFO_FAHRKOSTEN = "WEGTYP_INFO_FAHRKOSTEN",
  BEFOERDERUNG_INFO_FAHRKOSTEN = "BEFOERDERUNG_INFO_FAHRKOSTEN",
  ERSTATTUNGSZEITRAUM_VON_INFO_FAHRKOSTEN = "ERSTATTUNGSZEITRAUM_VON_INFO_FAHRKOSTEN",
  ERSTATTUNGSZEITRAUM_BIS_INFO_FAHRKOSTEN = "ERSTATTUNGSZEITRAUM_BIS_INFO_FAHRKOSTEN",
  SCHULE_INFO_FAHRKOSTEN = "SCHULE_INFO_FAHRKOSTEN",
  ASYL_INFO_FAHRKOSTEN = "ASYL_INFO_FAHRKOSTEN",
  DATENSCHUTZ_INFO_FAHRKOSTEN = "DATENSCHUTZ_INFO_FAHRKOSTEN",
  VORMUNDNAME_INFO_FAHRKOSTEN = "VORMUNDNAME_INFO_FAHRKOSTEN",
  VORMUNDVORNAME_INFO_FAHRKOSTEN = "VORMUNDVORNAME_INFO_FAHRKOSTEN",
  VORMUNDSTRASSE_INFO_FAHRKOSTEN = "VORMUNDSTRASSE_INFO_FAHRKOSTEN",
  VORMUNDORT_INFO_FAHRKOSTEN = "VORMUNDORT_INFO_FAHRKOSTEN",
  VORMUNDPLZ_INFO_FAHRKOSTEN = "VORMUNDPLZ_INFO_FAHRKOSTEN",
  VORMUNDADRESSZUSATZ_INFO_FAHRKOSTEN = "VORMUNDADRESSZUSATZ_INFO_FAHRKOSTEN",
  VORMUNDLAND_INFO_FAHRKOSTEN = "VORMUNDLAND_INFO_FAHRKOSTEN",
  VORMUNDGEBURTSDATUM_INFO_FAHRKOSTEN = "VORMUNDBDAY_INFO_FAHRKOSTEN",
  VORMUNDGESCHLECHT_INFO_FAHRKOSTEN = "VORMUNDGESCHLECHT_INFO_FAHRKOSTEN",
  VORMUNDEMAIL_INFO_FAHRKOSTEN = "VORMUNDEMAIL_INFO_FAHRKOSTEN",
  VORMUNDTELEFONNUMMER_INFO_FAHRKOSTEN = "VORMUNDTELEFONNUMMER_INFO_FAHRKOSTEN",
  KONTOINHABERNAME_INFO_FAHRKOSTEN = "KONTOINHABERNAME_INFO_FAHRKOSTEN",
  KONTOINHABERVORNAME_INFO_FAHRKOSTEN  = "KONTOINHABERVORNAME_INFO_FAHRKOSTEN",
  KONTOINHABERSTRASSE_INFO_FAHRKOSTEN = "KONTOINHABERSTRASSE_INFO_FAHRKOSTEN",
  KONTOINHABERORT_INFO_FAHRKOSTEN = "KONTOINHABERORT_INFO_FAHRKOSTEN",
  KONTOINHABERPLZ_INFO_FAHRKOSTEN = "KONTOINHABERPLZ_INFO_FAHRKOSTEN",
  KONTOINAHBERADRESSZUSATZ_INFO_FAHRKOSTEN = "KONTOINAHBERADRESSZUSATZ_INFO_FAHRKOSTEN",
  KONTOINHABERLAND_INFO_FAHRKOSTEN = "KONTOINHABERLAND_INFO_FAHRKOSTEN",
  KONTOINHABERGEBURTSDATUM_INFO_FAHRKOSTEN = "KONTOINHABERBDAY_INFO_FAHRKOSTEN",
  MITFAHRER_BEI_INFO_FAHRKOSTEN = "MITFAHRER_BEI_INFO_FAHRKOSTEN",
  KENNZEICHEN_INFO_FAHRKOSTEN = "KENNZEICHEN_INFO_FAHRKOSTEN",
  KRANKHEITSTAGE_INFO_FAHRKOSTEN = "KRANKHEITSTAGE_INFO_FAHRKOSTEN",
  FEHLTAGE_INFO_FAHRKOSTEN = "FEHLTAGE_INFO_FAHRKOSTEN",
  ENTFERNUNG_INFO_FAHRKOSTEN = "ENTFERNUNG_INFO_FAHRKOSTEN",
  KOSTEN_INFO_FAHRKOSTEN = "KOSTEN_INFO_FAHRKOSTEN",
  SCHULJAHR_INFO_FAHRKOSTEN = "SCHULJAHR_INFO_FAHRKOSTEN",
  KLASSE_INFO_FAHRKOSTEN = "KLASSE_INFO_FAHRKOSTEN",
  BILDUNGSGANG_INFO_FAHRKOSTEN = "BILDUNGSGANG_INFO_FAHRKOSTEN",
  BILDUNGSGANG_INFO_BELOW_FAHRKOSTEN = "BILDUNGSGANG_INFO_BELOW_FAHRKOSTEN",
  BILDUNGSGANGDATUM_INFO_FAHRKOSTEN = "BILDUNGSGANGDATUM_INFO_FAHRKOSTEN",
  FACHBEREICH_INFO_FAHRKOSTEN = "FACHBEREICH_INFO_FAHRKOSTEN",
  SCHULTAGEJEWOCHE_INFO_FAHRKOSTEN = "SCHULTAGEJEWOCHE_INFO_FAHRKOSTEN",
  UNTERRICHTSZEITENVON_INFO_FAHRKOSTEN = "UNTERRICHTSZEITENVON_INFO_FAHRKOSTEN",
  UNTERRICHTSZEITENBIS_INFO_FAHRKOSTEN = "UNTERRICHTSZEITENBIS_INFO_FAHRKOSTEN",
  PRAKTIKUMSTELLE_INFO_FAHRKOSTEN = "PRAKTIKUMSTELLE_INFO_FAHRKOSTEN",
  FAHRKOSTEN_PRAKTIKUM_HINWEIS = "FAHRKOSTEN_PRAKTIKUM_HINWEIS",
  PRAKTIKUMORT_INFO_FAHRKOSTEN = "PRAKTIKUMORT_INFO_FAHRKOSTEN",
  PRAKTIKUM_PLZ_INFO_FAHRKOSTEN = "PRAKTIKUM_PLZ_INFO_FAHRKOSTEN",
  PRAKTIKUM_ADRESSZUSATZ_INFO_FAHRKOSTEN = "PRAKTIKUM_ADRESSZUSATZ_INFO_FAHRKOSTEN",
  PRAKTIKUM_LAND_INFO_FAHRKOSTEN = "PRAKTIKUM_LAND_INFO_FAHRKOSTEN",
  PRAKTIKUMSTARTDATUM_INFO_FAHRKOSTEN = "PRAKTIKUMSTARTDATUM_INFO_FAHRKOSTEN",
  PRAKTIKUMENDDATUM_INFO_FAHRKOSTEN = "PRAKTIKUMENDDATUM_INFO_FAHRKOSTEN",
  PRAKTIKUMSTAGEJEWOCHE_INFO_FAHRKOSTEN = "PRAKTIKUMSTAGEJEWOCHE_INFO_FAHRKOSTEN",
  PRAKTIKUMSTRASSE_INFO_FAHRKOSTEN = "PRAKTIKUMSTRASSE_INFO_FAHRKOSTEN",

  SONDERFALL_INFO_FAHRKOSTEN = "SONDERFALL_INFO_FAHRKOSTEN",
  SONDERFALL_INFO_BELOW_FAHRKOSTEN = "SONDERFALL_INFO_BELOW_FAHRKOSTEN",
  SONDERFALL_BEGRUEDUNG_INFO_FAHRKOSTEN = "SONDERFALL_BEGRUEDUNG_INFO_FAHRKOSTEN",

  SONDERFALL_SCHULE_AUSSERHALB_INFO_FAHRKOSTEN = "SONDERFALL_SCHULE_AUSSERHALB_INFO_FAHRKOSTEN",
  SONDERFALL_SCHULUNGSMOEGLICHKEIT_INFO_FAHRKOSTEN = "SONDERFALL_SCHULUNGSMOEGLICHKEIT_INFO_FAHRKOSTEN",
  SONDERFALL_FAMILIENHEIMFAHRTEN_INFO_FAHRKOSTEN = "SONDERFALL_FAMILIENHEIMFAHRTEN_INFO_FAHRKOSTEN",
  NACHWEISHINZUFUEGEN_INFO_FAHRKOSTEN = "NACHWEISHINZUFUEGEN_INFO_FAHRKOSTEN",
  KONTOINHABER_ABWEICHEND_INFO = "KONTOINHABER_ABWEICHEND_INFO",
  BERUFSKOLLEG_INFO_FAHRKOSTEN = "BERUFSKOLLEG_INFO_FAHRKOSTEN",


  SUBTITLE_FAHRKOSTEN = "SUBTITLE_FAHRKOSTEN",
  SUBTITLE_TEXT_FAHRKOSTEN = "SUBTITLE_TEXT_FAHRKOSTEN",

  SUBTITLE_TICKET = "SUBTITLE_TICKET",
  SUBTITLE_TEXT_TICKET = "SUBTITLE_TEXT_TICKET",

  VORMUND_TITLE_TICKET = "VORMUND_TITLE_TICKET",
  ANGABEN_VORMUND_TITLE_FAHRKOSTEN = "ANGABEN_VORMUND_TITLE_FAHRKOSTEN",

  ANTRAEGE_VERWALTEN_MENUE = "ANTRAEGE_VERWALTEN_MENUE",
  FAHRKOSTEN_VERWALTEN_MENUE = "FAHRKOSTEN_VERWALTEN_MENUE",

  HTML_TITLE = "HTML_TITLE",

  // Ergänzungen SFK2-1039

  // home component
  TITLE_HOME='TITLE_HOME',
  SUBTITLE_HOME='SUBTITLE_HOME',
  LINK_SCHUELER_TABLE_HOME='LINK_SCHUELER_TABLE_HOME',
  WELCOME_TEXT_HOME='WELCOME_TEXT_HOME',
  SCHUELER_VERWALTEN_MENUE="SCHUELER_VERWALTEN_MENUE",        // added in backend
  LINK_TICKET_TABLE_HOME='LINK_TICKET_TABLE_HOME',
  LINK_TICKET_DETAIL_HOME='LINK_TICKET_DETAIL_HOME',
  LINK_FAHRKOSTEN_TABLE_HOME='LINK_FAHRKOSTEN_TABLE_HOME',
  LINK_FAHRKOSTEN_DETAIL_HOME='LINK_FAHRKOSTEN_DETAIL_HOME',

  // fahrkosten detail
  ENTWURF_LOESCHEN_MODAL_TITEL='ENTWURF_LOESCHEN_MODAL_TITEL',
  ENTWURF_LOESCHEN_MODAL_TEXT='ENTWURF_LOESCHEN_MODAL_TEXT',
  FAHRKOSTEN_EINREICHEN_MODAL_TITEL='FAHRKOSTEN_EINREICHEN_MODAL_TITEL',
  FAHRKOSTEN_EINREICHEN_MODAL_TEXT='FAHRKOSTEN_EINREICHEN_MODAL_TEXT',
  ANGABEN_PRAKTIKUM_TITLE_FAHRKOSTEN='ANGABEN_PRAKTIKUM_TITLE_FAHRKOSTEN',  // added in backend

  // ticket detail
  ANTRAGTICKET_EINREICHEN_MODAL_TEXT='ANTRAGTICKET_EINREICHEN_MODAL_TEXT',
  ANTRAGTICKET_EINREICHEN_MODAL_TITEL='ANTRAGTICKET_EINREICHEN_MODAL_TITEL',
  ABO_GUELTIG_AB_INFO_FOLGENDER_MONAT='ABO_GUELTIG_AB_INFO_FOLGENDER_MONAT',
  ABO_GUELTIG_AB_INFO_LAUFENDER_MONAT='ABO_GUELTIG_AB_INFO_LAUFENDER_MONAT',
  ABO_GUELTIG_AB_INFO_KEIN_TICKET='ABO_GUELTIG_AB_INFO_KEIN_TICKET',
  ABO_GUELTIG_AB_INFO_KEIN_SCHULJAHR='ABO_GUELTIG_AB_INFO_KEIN_SCHULJAHR',
  RICHTIGKEIT_DER_ANGABEN_INFO='RICHTIGKEIT_DER_ANGABEN_INFO',
  VORMUNDTELEFONNUMMER_INFO='VORMUNDTELEFONNUMMER_INFO',
  VORMUNDTELEFON_INFO='VORMUNDTELEFON_INFO',
  EMAIL_SCHUELER_INFO='EMAIL_SCHUELER_INFO',
  EMAIL_INFO='EMAIL_INFO',
  LAND_INFO='LAND_INFO',
  WOHNORT_INFO='WOHNORT_INFO',
  PLZ_INFO='PLZ_INFO',
  STRASSE_INFO='STRASSE_INFO',
  VORNAME_INFO='VORNAME_INFO',
  NAME_INFO='NAME_INFO',

  // Ergänzungen SFK2-1424 / Ivanti-1089629
  GEBURTSDATUM_INFO_BELOW='GEBURTSDATUM_INFO_BELOW',
  GEBURTSDATUM_INFO_BELOW_FAHRKOSTEN='GEBURTSDATUM_INFO_BELOW_FAHRKOSTEN',

  // SFK2-1346
  ANTRAGTICKET_ID_NAME_ASM='ANTRAGTICKET_ID_NAME_ASM',
  ANTRAGTICKET_ID_NAME_SBM='ANTRAGTICKET_ID_NAME_SBM',
  FAHRKOSTEN_ID_NAME_ASM='FAHRKOSTEN_ID_NAME_ASM',
  FAHRKOSTEN_ID_NAME_SBM='FAHRKOSTEN_ID_NAME_SBM',

}

