import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SchuleKoordinateDTO} from "../../../../models/schule-koordinate/SchuleKoordinateDTO";
import {ConfirmComponent} from "../../../../shared/confirm/confirm.component";
import {catchError} from "rxjs/operators";
import {SchuleKoordinateService} from "../../../../@core/services/schule-koordinate/schule-koordinate.service";
import {HttpClient} from "@angular/common/http";
import {CompositMessageService} from "../../../../@core/services/compositMessage/compositMessage.service";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY} from "rxjs";
import {CustomToastService} from "../../../../@core/utils/custom-toast.service";

@Component({
  selector: 'app-schulekoordinaten',
  templateUrl: './schulekoordinaten.component.html',
  styleUrls: ['./schulekoordinaten.component.css']
})
export class SchulekoordinatenComponent implements OnInit {

  public koordinatenForm: UntypedFormGroup;
  public selectedCoordinate: SchuleKoordinateDTO;
  public coordinateList: SchuleKoordinateDTO[];
  koordinateId;
  schuleId:string;
  schulname:string ="";

  entityNameTranslationKey: string = 'KOORDINATE';

  constructor(private http: HttpClient,
              public route: ActivatedRoute,
              private router: Router,
              private translateService:TranslateService,
              private fb: UntypedFormBuilder,
              private koordinateService: SchuleKoordinateService,
              private customToastService: CustomToastService,
              private compositMessageService: CompositMessageService,
              private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.koordinatenForm = this.fb.group({
      //https://stackoverflow.com/questions/3518504/regular-expression-for-matching-latitude-longitude-coordinates
      lat: ['', {validators: [ Validators.pattern("^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))$"), Validators.required ]}],
      lon: ['', {validators: [ Validators.pattern("^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?))$"), Validators.required] }]
    });

    this.koordinateId = this.route.snapshot.paramMap.get('id');
    this.schuleId = this.route.snapshot.queryParamMap.get('schuleId');
    this.schulname = this.route.snapshot.queryParamMap.get('schulname');
    this.koordinateService.getKoordinateListBySchule(+this.schuleId).subscribe((data: SchuleKoordinateDTO[]) => {
      this.coordinateList = data;
    });

    if(this.route.snapshot.paramMap.get('id') && this.route.snapshot.paramMap.get('id') !== '' &&
      this.route.snapshot.paramMap.get('id')!==null){
      this.koordinateService.getKoordinate(this.koordinateId).subscribe(
        (data: SchuleKoordinateDTO) => {
          this.selectedCoordinate = {...data};
          this.koordinatenForm.patchValue({
            lat: this.selectedCoordinate.lat,
            lon: this.selectedCoordinate.lon
          });
        });
    }
  }

  updateKoordinate(){
    this.koordinateService.updateKoordinate(parseInt(this.route.snapshot.paramMap.get('id')),
      {
        lat: this.getFormItem('lat')?.value.length !== 0 ? this.getFormItem('lat').value : undefined,
        lon: this.getFormItem('lon')?.value.length !== 0 ? this.getFormItem('lon').value : undefined,
        schuleId: this.selectedCoordinate?.schule?.id,
      })
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'patch'));
          return EMPTY;
        })
      )
      .subscribe((data: SchuleKoordinateDTO) => {
        this.selectedCoordinate = {...data};
        this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'patch'));
      });
  }


  createKoordinate(){
    this.koordinateService.createKoordinate({
      lat: this.getFormItem('lat')?.value.length !== 0 ? this.getFormItem('lat').value : undefined,
      lon: this.getFormItem('lon')?.value.length !== 0 ? this.getFormItem('lon').value : undefined,
      schuleId: parseInt(this.schuleId),
    })
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'post'));
          return EMPTY;
        })
      )
      .subscribe((data: SchuleKoordinateDTO) => {
        this.selectedCoordinate = {...data};
        this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'post'));
      });
  }

  deleteKoordinate(){
    this.koordinateService.deleteKoordinate(parseInt(this.koordinateId))
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'delete'));
          return EMPTY;
        })
      )
      .subscribe(
        () => {
          this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'delete'));
          this.backClicked();
        },
      );
  }

  onActivate(event) {
    if(event.type == 'click') {
      this.router.navigate(['/pages/schulen']);
    }
  }

  getFormItem(s: string) {
    return this.koordinatenForm?.get(s);
  }

  saveOrUpdate(){
    if(!this.koordinatenForm.valid){
      this.customToastService.showError(this.compositMessageService.createIncompleteMessage(this.entityNameTranslationKey));
      return;
    }
    if(this.new()){
      this.createKoordinate();
    }else {
      this.updateKoordinate();
    }
  }

  setLatLon(event) {
    this.koordinatenForm?.patchValue({
      lat: event[0].toFixed(6),
      lon: event[1].toFixed(6)
    });
    this.koordinatenForm?.markAllAsTouched();
  }

  new() :boolean {
    return !(this.route.snapshot.paramMap.get('id')!==null && this.route.snapshot.paramMap.get('id')!== '');
  }

  backClicked(){
    this.router.navigate([this.backUrl()]);
  }

  backUrl(): string{
    return '/pages/schulenDetail/' + (this.new()?  this.schuleId: this.selectedCoordinate?.schule?.id?.toString());
  }

  getTitle():string {
    return this.new()? this.translateService.instant("SCHULE-KOORDINATE.TITLE_NEW"): this.translateService.instant('SCHULE-KOORDINATE.TITLE');
  }
  getSubTitle():string {
    return this.new() ? this.translateService.instant('SCHULE-KOORDINATE.LEGENDE_NEW', { schulname: this.schulname } ) : this.translateService.instant('SCHULE-KOORDINATE.LEGENDE' ,{ schulname: this.selectedCoordinate?.schule?.name } );

  }

  getBackTitle():string {
    return  this.translateService.instant('SCHULE-KOORDINATE.BACK_BUTTON');
  }

  showDecisionModal(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.compositMessageService.createDeleteWarning(this.entityNameTranslationKey);
    modalRef.componentInstance.onYes = () => {
      this.deleteKoordinate();
    };
  }
}
