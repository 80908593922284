import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplicationPropertiesDTO} from "../../../models/applicationproperties/ApplicationPropertiesDTO";
import {Observable} from "rxjs";
import {HttpHeaderService} from "../../utils/http/http-header.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationPropertiesService {

  configUrl = 'api/properties';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService
  ) {}

  getApplicationProperties(): Observable<any>{
     return this.http.get<ApplicationPropertiesDTO>(
       this.configUrl+'/all',
       { headers: this.headerService.getAuthHeaderApplicationJson() }
     );
  }

}

