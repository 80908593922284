import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SchuelerService} from "../../../@core/services/schueler/schueler.service";
import {SchuleService} from "../../../@core/services/schule/schule.service";
import {SchuleDTO} from "../../../models/schule/SchuleDTO";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {Land} from "../../../models/land/Land";
import {catchError, pairwise, startWith} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {FieldConfigService} from "../../../@core/services/field-config/field-config.service";
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {PlzValidationHelperService} from "../../../@core/utils/forms/plz-validation-helper.service";
import {PostleitzahlenService} from "../../../@core/services/postleitzahlen/postleitzahlen.service";
import {GeschlechtHelperService} from "../../../@core/utils/forms/geschlecht-helper.service";
import {SessionService} from "../../../@core/services/session/session.service";
import {KeyString} from "../../../models/textbaustein/KeyString";
import {ValidationHelperService} from "../../../@core/utils/forms/validation-helper.service";
import {BenutzerSessionService} from "../../../@core/services/benutzer/benutzer-session.service";
import {PFLICHTFELDMARKER} from "../../../../assets/constants/constants";

@Component({
  selector: 'app-schueler-detail',
  templateUrl: './schueler-detail.component.html',
  styleUrls: ['./schueler-detail.component.scss']
})
export class SchuelerDetailComponent implements OnInit, OnDestroy {
  schuelerForm: UntypedFormGroup;
  schuelerId;
  schueler;
  schuleList: SchuleDTO[] = [];
  geschlechtList = [];
  landList = [];
  benutzer: BenutzerDTO;

  hasSachbearbeiterBackend : boolean = false;

  formItemSubscriptions: Subscription[] = [];

  // validation
  fieldConfig;

  existsSessionStorageTextbausteine : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private schuelerService: SchuelerService,
    private schuleService: SchuleService,
    private customToastService: CustomToastService,
    public fieldConfigService: FieldConfigService,
    public textbausteinService: TextbausteinService,
    public plzHelper: PlzValidationHelperService,
    private postleitzahlenService: PostleitzahlenService,
    private geschlechtHelperService: GeschlechtHelperService,
    private sessionService: SessionService,
    private validationHelperService: ValidationHelperService,
    private benutzerSessionService: BenutzerSessionService
  ) { }

  ngOnInit(): void {

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.benutzerSessionService.watchSessionStorageBenutzer()
      .subscribe( user => {
        this.benutzer = user;
      });

    this.landList = Object.keys(Land).map((name) => {
      return { name, value: Land[name as keyof typeof Land],};
    });

    this.schuelerId = this.route.snapshot.paramMap.get('id');

    this.schuleService.getSchulen(true)
      .pipe(catchError(() => {
        this.customToastService.showError('Die Liste der Schulen konnte nicht geladen werden.');
        return EMPTY;
      }))
      .subscribe(schuleList => {
        this.schuleList = schuleList;
      });

    let subscriptionTranslate : Subscription = this.translateService.get('STANDARD.NAME').subscribe(() => { //Warten bis Übersetzungen da sind!
      this.geschlechtList = this.geschlechtHelperService.getGeschlechtOptionList(); // und dann die übersetzte Liste setzen
    });

    this.loadFieldConfig();
    this.initEmptyForm();
    this.patchBenutzer();

    if (this.schuelerId && this.schuelerId !== '' && this.schuelerId !== null) {
      this.schuelerService.getSchueler(this.schuelerId)
        .pipe(catchError(() => {
          this.customToastService.showError('Schüler:in konnte nicht geladen werden.');
          return EMPTY;
        }))
        .subscribe(schueler => {
          this.schueler = schueler;
          this.patchSchuelerToForm();
        });
    }

    this.formItemSubscriptions.push(
      this.schuelerForm.get('land').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.setPLZValidatorForControl('plz');
          }
        }),
      subscriptionTranslate
    );

  }

  setOrt(event, formItem: string) {
    this.postleitzahlenService.getPostleitzahlByPlz(event.target.value).subscribe(result => {
      if (result !== null) {
        this.getFormItem(formItem)?.patchValue(result.ort);
      }
    });
  }

  getFormItem(s: string) {
    return this.schuelerForm?.get(s);
  }

  saveOrUpdate() {
    this.applyFieldConfigValidators().then(()=>{
      this.setPLZValidatorForControl('plz');
      this.schuelerForm.markAllAsTouched();
      if(this.schuelerForm.invalid){
        this.customToastService.showError('Das Formular ist unvollständig oder fehlerhaft, und darf so nicht gespeichert werden.');
        return;
      }
      this.finalizeUpdate();
    });
  }

  finalizeUpdate() {
    if (this.schuelerForm.valid) {
      // update
      if (this.schuelerId && this.schuelerId !== '' && this.schuelerId !== null) {
        this.schuelerService.updateSchueler(this.schuelerId, {
          name: this.getFormItem('name')?.value?.length !== 0 ? this.getFormItem('name').value : undefined,
          vorname: this.getFormItem('vorname')?.value?.length !== 0 ? this.getFormItem('vorname').value : undefined,
          strasse: this.getFormItem('strasse')?.value?.length !== 0 ? this.getFormItem('strasse').value : undefined,
          ort: this.getFormItem('ort')?.value?.length !== 0 ? this.getFormItem('ort').value : undefined,
          plz: this.getFormItem('plz')?.value?.length !== 0 ? this.getFormItem('plz').value : undefined,
          schuleId: this.getFormItem('schule')?.value?.length !== 0 ? this.getFormItem('schule').value : undefined,
          geburtsdatum: this.getFormItem('geburtsdatum')?.value?.length !== 0 ? this.getFormItem('geburtsdatum').value : undefined,
          schuelernummer: this.getFormItem('schuelernummer')?.value?.length !== 0 ? this.getFormItem('schuelernummer').value : undefined,
          geschlecht: this.getFormItem('geschlecht')?.value?.length !== 0 ? this.getFormItem('geschlecht').value : undefined,
          abonnementnummer: this.getFormItem('abonnementnummer')?.value?.length !== 0 ? this.getFormItem('abonnementnummer').value : undefined,
          adresszusatz: this.getFormItem('adresszusatz')?.value?.length !== 0 ? this.getFormItem('adresszusatz').value : undefined,
          land: this.getFormItem('land')?.value?.length !== 0 ? this.getFormItem('land').value : undefined
        })
          .pipe(catchError(() => {
            this.customToastService.showError('Schüler:in konnte nicht gespeichert werden.');
            return EMPTY;
          }))
          .subscribe(() => {
            this.router.navigate(['/pages/schueler-table']).then(() => {
              this.customToastService.showSuccess('Schüler:in wurde erfolgreich gespeichert.')
            });
          });
      } else { // create
        this.schuelerService.createSchueler({
          name: this.getFormItem('name')?.value?.length !== 0 ? this.getFormItem('name').value : null,
          vorname: this.getFormItem('vorname')?.value?.length !== 0 ? this.getFormItem('vorname').value : null,
          strasse: this.getFormItem('strasse')?.value?.length !== 0 ? this.getFormItem('strasse').value : null,
          ort: this.getFormItem('ort')?.value?.length !== 0 ? this.getFormItem('ort').value : null,
          plz: this.getFormItem('plz')?.value?.length !== 0 ? this.getFormItem('plz').value : null,
          schuleId: this.getFormItem('schule')?.value?.length !== 0 ? this.getFormItem('schule').value : null,
          geburtsdatum: this.getFormItem('geburtsdatum')?.value?.length !== 0 ? this.getFormItem('geburtsdatum').value : null,
          schuelernummer: this.getFormItem('schuelernummer')?.value?.length !== 0 ? this.getFormItem('schuelernummer').value : null,
          geschlecht: this.getFormItem('geschlecht')?.value?.length !== 0 ? this.getFormItem('geschlecht').value : null,
          abonnementnummer: this.getFormItem('abonnementnummer')?.value?.length !== 0 ? this.getFormItem('abonnementnummer').value : null,
          antragstellerId: this.benutzer.id,
          adresszusatz: this.getFormItem('adresszusatz')?.value?.length !== 0 ? this.getFormItem('adresszusatz').value : null,
          land: this.getFormItem('land')?.value?.length !== 0 ? this.getFormItem('land').value : null
        })
          .pipe(catchError(()=>{
            this.customToastService.showError('Schüler:in konnte nicht gespeichert werden.');
            return EMPTY;
          }))
          .subscribe(() => {
            this.router.navigate(['/pages/schueler-table']).then(() => {
              this.customToastService.showSuccess('Schüler:in wurde erfolgreich angelegt.')
            });
          });
      }
    }
  }

  deleteSchueler() {
    if (confirm('Soll die Schüler:in unwiderruflich gelöscht werden?')) {
      this.schuelerService.deleteSchueler(this.schuelerId)
        .pipe(catchError(() => {
          this.customToastService.showError('Schüler:in konnte nicht gelöscht werden.');
          return EMPTY;
        }))
        .subscribe(() => {
          this.router.navigate(['/pages/schueler-table']).then(() => {
            this.customToastService.showSuccess('Schüler:in wurde erfolgreich gelöscht.');
          });
        });
    }
  }

  patchSchuelerToForm(){
    this.schuelerForm.patchValue({
      name: this.schueler?.name || undefined,
      vorname: this.schueler?.vorname || undefined,
      strasse: this.schueler?.strasse || undefined,
      ort: this.schueler?.ort || undefined,
      plz: this.schueler?.plz || undefined,
      schule: this.schueler?.schule?.id || undefined,
      geburtsdatum: this.schueler?.geburtsdatum || undefined,
      schuelernummer: this.schueler?.schuelernummer || undefined,
      geschlecht: this.schueler?.geschlecht || undefined,
      abonnementnummer: this.schueler?.abonnementnummer || undefined,
      adresszusatz: this.schueler?.adresszusatz || undefined,
      land: this.schueler?.land || undefined
    });
  }

  patchBenutzer(){
    this.schuelerForm.patchValue({
      name: undefined,
      vorname: undefined,
      strasse: this.benutzer?.strasse,
      ort: this.benutzer?.ort,
      plz: this.benutzer?.plz,
      land: this.benutzer?.land,
      geburtsdatum: undefined,
      schuelernummer: undefined,
      abonnementnummer: undefined,
    });
    this.setPLZValidatorForControl('plz');
  }

  initEmptyForm(){
    this.schuelerForm = this.fb.group({
      name: undefined,
      vorname: undefined,
      strasse: undefined,
      ort: undefined,
      plz: undefined,
      schule: undefined,
      geburtsdatum: undefined,
      schuelernummer: undefined,
      geschlecht: undefined,
      abonnementnummer: [undefined,Validators.pattern(this.validationHelperService.abonummerPattern)],
      adresszusatz: undefined,
      land: undefined
    });
  }

  loadFieldConfig(){
    this.fieldConfig = this.fieldConfigService.getFieldConfigFromSessionStorage(['schueler']);
    if(!this.fieldConfig || this.fieldConfig.length <1){
      console.log('No fieldConfig found: setting default values');
      this.setFieldConfigDefault();
    }
  }

  isVisible(fieldName: string): boolean {
    return this.fieldConfigService.getFieldFromFieldConfig(fieldName, this.fieldConfig)?.visible;
  }

  isRequired(fieldName: string): boolean {
    return this.fieldConfigService.getFieldFromFieldConfig(fieldName, this.fieldConfig)?.required;
  }

  getTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey?
        this.translateService.instant(translateKey) : '') : '';
  }

  getFieldLabelOrTranslate(fieldName: string, translateKey: string): string {
    return ''+(this.isRequired(fieldName)?PFLICHTFELDMARKER:'')
      +this.fieldConfigService.getFieldLabel(fieldName, this.fieldConfig, this.translateService.instant(translateKey));
  }

  applyFieldConfigValidators() : Promise<any>{
    return new Promise<void>((resolve)=>{
      if(!(!this.fieldConfig)&&this.fieldConfig.length>0){
        this.fieldConfig.forEach( entry => {
          if(entry.visible&&entry.required){
            this.schuelerForm.get(entry.field).addValidators([Validators.required]);
            this.schuelerForm.get(entry.field).updateValueAndValidity();
          }
        });
      }
      resolve();
    });
  }

  setFieldConfigDefault(){
    this.fieldConfig = [
      {field: 'vorname', visible: true, required: true, label: null, module: 'schueler'},
      {field: 'name', visible: true, required: true, label: null, module: 'schueler'},
      {field: 'geburtsdatum', visible: true, required: true, label: null, module: 'schueler'},
      {field: 'strasse', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'plz', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'ort', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'adresszusatz', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'land', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'geschlecht', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'schule', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'schuelernummer', visible: true, required: false, label: null, module: 'schueler'},
      {field: 'abonnementnummer', visible: true, required: false, label: null, module: 'schueler'},
    ];
  }

  setPLZValidatorForControl(plzControlname:string) {
    this.getFormItem(plzControlname)?.setValidators([
      Validators.maxLength(this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value)),
      Validators.pattern(this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value))
    ]);
  }
  getMaxLength(plzControlname:string): number {
    return this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }
  getPattern(plzControlname:string): string {
    return this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }


  ngOnDestroy(){
    this.formItemSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

    protected readonly KeyString = KeyString;
}
