import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {AuthService} from "../services/auth/auth.service";
import {BenutzerSessionService} from "../services/benutzer/benutzer-session.service";
import {ConfigService} from "../config/services/config.service";

export const authGuard = (activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const configService = inject(ConfigService);

  if (configService.featureConfig.jwtAuth) {
    // inject services
    const authService = inject(AuthService);
    const router = inject(Router);

    if (!authService.isAuthenticated()) {
      router.navigate(['authentication/login']);
      return of(false);
    }
    return of(true);
  }
  else if (configService.featureConfig.kommunalportalAuth) {
    if (
      state.url === '/pages/home'
      || (
        activatedRoute.toString().includes('home')
        && activatedRoute.queryParamMap.keys.includes('authToken')
        && activatedRoute.queryParamMap.keys.includes('mode')
        && activatedRoute.queryParamMap.keys.includes('portalUrl')
      )
    ) { // if kommunalportalAuth is true and home page is called, no auth needed
      return of(true);
    }
    const router = inject(Router);
    const benutzerSessionService = inject(BenutzerSessionService);

    const subject = new Subject<boolean>();
    benutzerSessionService.watchSessionStorageBenutzer().subscribe( benutzerDto => {
        if (!benutzerDto?.roles?.includes('ROLE_SUPERUSER')){
          router.navigate(['/pages/home']);
          subject.next(false);
        } else {
          subject.next(true);
        }
    });

    return subject.asObservable();
  }

  return of(false);
}
