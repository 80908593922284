import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {CustomToastService} from "../utils/custom-toast.service";
import {AuthService} from "../services/auth/auth.service";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {ConfigService} from "../config/services/config.service";


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  authService;
  configService;

  constructor(
    private readonly injector: Injector
  ) {
    this.authService = this.injector.get(AuthService);
    this.configService = this.injector.get(ConfigService);
  }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				console.error('Error caught from http interceptor', error);
        let toastMessage;
        let timeoutValue;
        let translateService = this.injector.get(TranslateService);

        if (this.configService.featureConfig.kommunalportalAuth) { // TODO: change later to this.configService.featureConfig.kommunalportalAuth
          toastMessage = translateService.instant('AUTHENTICATION.SESSION.TIMEOUT_KOMMUNALPORTAL');
          timeoutValue = 5000;
        } else if (this.configService.featureConfig.jwtAuth) { // TODO: change later to this.configService.featureConfig.jwtAuth
          toastMessage = translateService.instant('AUTHENTICATION.SESSION.TIMEOUT_JWT');
          timeoutValue = 2000;
        } else {
          toastMessage = translateService.instant('AUTHENTICATION.SESSION.TIMEOUT_GENERIC');
          timeoutValue = 2000;
        }
        const customToast = this.injector.get(CustomToastService)
				switch (error.status) {
          case 401:
            // most of the time there are multiple 401 errors on the same time. Call isDuplicate() to prevent multiple toasts to be shown.
            if (!customToast.isDuplicate(toastMessage, 'error') && !request.url.includes('auth/token')) {
              customToast.showError(toastMessage);
              setTimeout(() => {
                this.authService.logout();
              }, timeoutValue)
            }
						break;
          case 0: // cross-origin block or other client-side abort
            // most of the time there are multiple 401 errors on the same time. Call isDuplicate() to prevent multiple toasts to be shown.
            if (!customToast.isDuplicate(toastMessage, 'error')) {
              customToast.showError(toastMessage);
              setTimeout(() => {
                this.authService.logout();
              }, timeoutValue)
            }
            break;
					default:
						break;
				}

        return throwError(() => error);
			}),
			finalize(() => {})
		) as Observable<HttpEvent<any>>;
	}
}
