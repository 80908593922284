/**
 * Comparator for sorting of tables.
 * It ensures that table entries with null-values always get listed last in the the table,
 * regardless of sort direction
 *
 * @param val1  comparable 1
 * @param val2  comparable 2
 * @param row1  table row associated with val1
 * @param row2  table row associated with val2
 * @param sortDir current sort direction of table
 */

export function nullLastComparator(val1,val2,row1,row2,sortDir) : number {
  const isAsc = sortDir === 'asc'
  if( !val1 || val1.length<1 )
    return isAsc? 1 : -1 ;
  if( !val2 || val2.length<1 )
    return isAsc? -1 : 1 ;
  if( val1.toLowerCase() < val2.toLowerCase() ) {
    return -1;
  }
  if( val1.toLowerCase() > val2.toLowerCase() ) {
    return 1;
  }
  return 0;
}
