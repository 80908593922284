<app-default-layout
  [title]="'Passwortänderung'"
  [subtitle] ="'Für Ihr Benutzerkonto wurde ein neues Passwort hinterlegt.\n' +
   'Bestätigen Sie die Änderung durch Eingabe des neuen Passworts.'"
>


  <div class="row">
    <div class="col-md-8 offset-md-2">
      <form  [formGroup]="kennwortAenderungForm" (ngSubmit)="this.save()" class="form-horizontal"
             accept-charset="UTF-8">
        <div class="form-group group-for-email col mb-4">

          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">
              <app-custom-input id="email" type="text"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.MAIL' |translate }}"
                                [customFormGroup]="kennwortAenderungForm"
                                [readonly] = "true"
              ></app-custom-input>
            </div>
          </div>
        </div>

        <div class="form-group group-for-kennwort col mb-4">

          <div class="d-flex flex-row" style="margin-bottom: -15px">
            <div class="flex-grow-1 mr-2">

              <app-custom-input id="kennwort" type="text"
                                [type] = "pwHide? 'password':'text'"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.PW' |translate }}"
                                [customFormGroup]="kennwortAenderungForm"
              ></app-custom-input>
            </div>
            <fa-icon  style="margin-left: -25px; padding-top: 17px; margin-top: 15px; width: 26px; z-index:1"
                      [icon]="pwHide? faEye:faEyeSlash" (click) = "pwHide=!pwHide"
                      [title]="pwHide? translate.instant('LOGIN.PW-SHOW'):translate.instant('LOGIN.PW-HIDE')">
            </fa-icon>
          </div>
        </div>

        <button type="submit"
                class="btn btn-primary save btn-block me-3">
          {{'APPLICATION.BUTTON_SPEICHERN'|translate}}
        </button>

      </form>
    </div>
  </div>


</app-default-layout>
