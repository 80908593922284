import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService {

  configUrl = 'auth/token';
  options;

  constructor(private http: HttpClient) {

  }

  refreshToken(refreshToken: string, email: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Pragma': 'no-cache',
      'Expires': '0',
    });

    this.options = {
      headers: headers
    };
    const rawPostMessage="grant_type=refresh_token&refresh_token="+refreshToken+"&email="+email;
    const finalPath = this.configUrl;
    return this.http.post<any>(finalPath, rawPostMessage, this.options); // why BenutzerDTO? -> TokenResponseDto
    // > {% client.global.set("auth_token", response.body.access_token); %} // here
  }

}
