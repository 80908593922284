import {APP_INITIALIZER, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {CommonModule, registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {AppRoutingModule} from './app-routing.module';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {PagesModule} from "./pages/pages.module";
import {CoreModule} from './@core/core.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ToastrModule} from 'ngx-toastr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmComponent} from "./shared/confirm/confirm.component";
import {ErrorHandlerModule} from "./@core/errors/error-handler.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {NgxImageCompressService} from "ngx-image-compress";
import {AuthModule} from "./@core/authentication/auth.module";
import {InformationModule} from "./information/information.module";
import {NavbarResponsiveComponent} from './shared/navbar-responsive/navbar-responsive.component';
import {FooterCustomComponent} from './shared/footer-custom/footer-custom.component';
import {ConfigService} from "./@core/config/services/config.service";
import {CountdownComponent} from "./shared/countdown/countdown/countdown.component";

registerLocaleData(localeDe);

@Injectable()
export class MyStrategy extends RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  // TODO: ' unexpected empty method 'store' ' > Sonar doesn't like this
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
    return null;
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return false;
  }
}

export function initFeatureConfig(configService: ConfigService) {
  return () => configService.getFeatureConfig();
}

export function initCustomerConfig(configService: ConfigService) {
  return () => configService.getCustomerConfig();
}

export function initLogo(configService: ConfigService) {
  return () => configService.getLogoSrc();
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent,
    NavbarResponsiveComponent,
    FooterCustomComponent,
    CountdownComponent
  ],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    PagesModule,
    InformationModule,
    FontAwesomeModule,
    NgbDatepickerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        // AoT requires an exported function for factories
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
    NgbModule,
    ErrorHandlerModule,
    NgxSpinnerModule,
    AuthModule,
    CommonModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: RouteReuseStrategy, useClass: MyStrategy},
    {provide: NgxImageCompressService},
    { // load feature-config on app start
      provide: APP_INITIALIZER,
      useFactory: initFeatureConfig,
      deps: [ConfigService],
      multi: true,
    },
    { // load customer-config on app start
      provide: APP_INITIALIZER,
      useFactory: initCustomerConfig,
      deps: [ConfigService],
      multi: true,
    },
    { // load logo on app start
      provide: APP_INITIALIZER,
      useFactory: initLogo,
      deps: [ConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
